import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router, browserHistory, Redirect} from 'react-router';
import Home from './login/index';
import Signup from './signup/index';
import DefaultHome from './home/index';
import Govern from './govern/index';
import resetPasswordForm from './reset_password/index';
import setPasswordForm from './set_password/index';
import InitiateSetPassword from './set_password/components/initiateSetPassword';
import Configure from './configure/index';
import Comply from './comply/index';
import Metrics from './metrics/index';
import Monitor from './monitor/index';
import Consume from './consume/index';
import Setup from './setup/index';
import Settings from './settings/index';
import Discover from './discover/index';
import QuickStart from './components/quick_start';
import VideoHelp from './components/video_help';
import PrivacyPolicy from './components/privacyPolicy';
import FreeTrialAgreement from './components/freeTrialAgreement';
import CookiePolicy from './components/cookiePolicy';
import Notifications from './notifications/index';
import DataProfile from './profile/data/index';
import MLProfile from './profile/ml/index';
import ChoosePlan from './components/choose_plan';
import Payment from './components/payment';
import showUserStatus from './settings/components/userDisabledMessage'
import EmailNotification from './signup/components/email';
import Unsubscribe from './components/unsubscribe';
import Reports from './reports/index';
import PBISetup from './pbi/pbisetup';
import PBIMapping from './pbi/pbimapping';
import PBIErrors from './pbi/pbierrors';
import MSILogin from './msi_login/index'
import GoogleLoginLoader from './login/google_login_loader'


import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './sass/main.scss';
import {
    // preFetchMetaData,
    // preFetchUserNotifications,
    // prefetchDatasetDetails,
    // prefetchRecencyDetails,
    // prefetchModelABData,
    // prefetchRecentHistory, prefetchDataSize,
} from "./utils/dataFetch";


 import {
//     preFetchMonitorPreview,
//     preFetchMonitorInfo,
//     preFetchMonitorModelInfo,
//     preFetchModelDetails,
//     preFetchMonitorListViewData,
//     preFetchMetrics,
//     preFetchFollowAttributes,
     preFetchCorrelationData
 } from "./utils/monitorDataFetch";

import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import store from "./redux/store";

// import {
//     setDataSource,
//     setMlDataSource,
//     addRelationship,
//     addMetaData,
//     addDataSource,
//     addInfo,
//     addLastProfilingTime,
//     setStartDate,
//     setEndDate,
//     addDiscoveryStatus,
//     addProcessingStatus,
//     addEnvironment,
//     addEnvironmentDataSourceMapping,
//     addDataSourceDataSetMapping,
//     addDataSetAttributeMapping,
//     setTenantId,
//     setInitialValues,
//     setAttribute,
//     setDataSet,
//     addMonitorPreview,
//     addPerformancePreview,
//     addCompletenessDataSetInfo,
//     setMonitorInitialValues,
//     addCompletenessAttributeInfo,
//     addTimelinessDataSetInfo,
//     addTimelinessAttributeInfo,
//     addConformityDataSetInfo,
//     addConformityAttributeInfo,
//     addAccuracyDataSetInfo,
//     addAccuracyAttributeInfo,
//     addDriftDataSetInfo,
//     addDriftAttributeInfo,
//     addConsistencyDataSetInfo,
//     addConsistencyAttributeInfo,
//     addMLModelMapping,
//     addMLDataSetInfo,
//     addMLAttributeInfo,
//     setMLModel,
//     addMLModel, addModelPerformance, addModelDetails,
//     addMonitorDQListViewData,
//     addMetrics,
//     addUserNotificationToState, addDatasetVersions,
//     addUniquenessDataSetInfo,
//     addUniquenessAttributeInfo, addRecencyDetails, addRecentHistory, addDataSize, setModelABDataToList,
//     addRecencyPreview,prefetchMonitorState,
//     addFollowAttributes, noIntegration
// } from "./redux/actions";

import {
    setTenantId,
    setInitialValues,
    setMonitorInitialValues,
    storeCorrelationData
} from "./redux/actions";

import {ACCOUNT_FREE_URLS, IS_NONE_CHECK, MANDATE_RESET_PASSWORD, QualdoEditionMapping} from "./utils/constant";
import {QualdoPlanCycle, QualdoPlanVersions, LOCAL_STORE_KEY,IS_SAAS_EDITION} from "./utils/constant";
import {getRedirectionLinkToExpiredPlan, getRedirectionLinkToChangePassword, isDataLimitExceeded,
        removeLocalStorageKeys, decryptMsg, encryptForLocalUsage, checkExpiryPowerBI, decryptForLocalUsage,
        getRedirectionLinkToDiscover } from "./utils/common_utils";
import {call_get_api} from "./utils/event_handling";
import {ui_service_ip} from "./utils/ip_details";
import {postMixpanel} from './mixpanel';
import ForgotPassword from './login/components/forgotPassword';

import {
    GTM_INTEGRATION_TOKEN, GTM_ID,
    GTM_PREVIEW, GTM_EVENTS, PERMISSIONS_LOCAL_STORE_KEY
} from "./utils/constant";
import TagManager from 'react-gtm-module'

let persistor = persistStore(store);

const PrivateRoute = ({
        component: Component,
        ...rest }) => {
            let search = window.location.search;
            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
            let isPlanExpired = decrypt_data['isPlanExpired']
            let isInBufferPeriod = decrypt_data['isInBufferPeriod']
            // let isPlanExpired = localStorage.getItem("isPlanExpired");
            // let isInBufferPeriod = localStorage.getItem("isInBufferPeriod");
            let reset_password = localStorage.getItem(MANDATE_RESET_PASSWORD.Reset_Password)

            if(isPlanExpired === true && isInBufferPeriod=== false && window.location.pathname !== "/" && window.location.pathname !== "/payment" && window.location.pathname !== "/choose-plan") {
                let redirectionLink = getRedirectionLinkToExpiredPlan();
                browserHistory.push(redirectionLink);
            }
            if(reset_password === MANDATE_RESET_PASSWORD.One && window.location.pathname !== "/") {
                let redirectionLink = getRedirectionLinkToChangePassword();
                browserHistory.push(redirectionLink);
                return;
            }


            if (window.location.pathname === "/") {
                let sessionToken = localStorage.getItem('sessionToken');
                if (sessionToken !== undefined && sessionToken !== null) {
                     const item = JSON.parse(sessionToken);
                     const now = new Date();
                     if (now.getTime() > item.expiry) {
                        if(checkExpiryPowerBI()===false){
                            removeLocalStorageKeys();
                        }
                     } else if(isPlanExpired === true && isInBufferPeriod=== false){
                         let redirectionLink = getRedirectionLinkToExpiredPlan();
                        browserHistory.push(redirectionLink);
                     } else if (reset_password === MANDATE_RESET_PASSWORD.One) {
                        let redirectionLink = getRedirectionLinkToChangePassword();
                        this.props.router.push(redirectionLink);
                     } else {
                         window.location.href = "/configure";
                     }
                } else {
                   localStorage.removeItem("sessionToken");
                }
            } else if(search.length > 0) {
                let params = new URLSearchParams(search);
                window.history.replaceState({}, document.title, window.location.pathname);
                if(window.location.pathname === "/setup") {
                    localStorage.setItem("DataSetupStatus", false);
                }
                let token = params.get('auth_token');
                let team_id = params.get('team_id');
                let user_name = params.get('user_name');
                let user_id = params.get('user_id');
                let org_id = params.get("org_id");
                let msi_login = params.get('msi_login');
                let google_login = params.get('google_login');

                let req_key = "msi_data";
                if (google_login === "True") {
                    req_key = "user_data";
                }

                if (msi_login === "True" || google_login === "True") {
                    let decryptMsgData = params.get(req_key);
                    decryptMsgData = decryptMsg(decryptMsgData);
                    token = decryptMsgData['auth_token']
                    team_id = decryptMsgData['team_id']
                    user_id = decryptMsgData['user_id']
                }

                let name = params.get('name');
                let email = params.get('email');
                let resetPwdToken = params.get('token');
                let currentStatus = params.get("current_status");
                if (currentStatus !== undefined && currentStatus !== null && currentStatus === "disabled") {
                    window.location = '/user_status';
                }

                localStorage.setItem('msi_login', msi_login);
                let permissions = params.get("permissions");
                if (permissions !== undefined && permissions !== null) {
                    localStorage.setItem('permissions', permissions);
                }
                if (resetPwdToken !== null && resetPwdToken !== "undefined") {
                    localStorage.setItem('resetPwdToken', resetPwdToken);
                }

                if (email !== "undefined" && email != null) {
                    // Keys emailId and user_name are used predominantly
                    localStorage.setItem('emailId', email);
                    localStorage.setItem('user_name', name);
                }
                if (token !=="undefined" && token != null) {
                    const now = new Date();
                    const item = {
                                value: token,
                                expiry: now.getTime() + 90*60000
                            };
                    localStorage.setItem('sessionToken', JSON.stringify(item));
                    localStorage.setItem('user_name', user_name);
                    localStorage.setItem("user_id", user_id);
                    localStorage.setItem("org_id", org_id);

                }
                if (team_id !=="undefined" &&  token !=null) {
                    localStorage.setItem('team_id',team_id)
                }

            }
            let DataSetupStatus = localStorage.getItem("DataSetupStatus");
            if(window.location.pathname !== "/quick-start" && window.location.pathname !== "/setup" && DataSetupStatus === "false") {
                window.location.href = "/setup";
            }
            const isAuth = localStorage.getItem('sessionToken');
            return (
                <Route
                    {...rest}
                    render={props =>
                        isAuth ? (
                            <Component {...props} {...rest} />
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: {
                                    from: props.location
                                    }
                                }}
                            />
                        )
                    }
                />
            );
        }

const onBeforeLift = () => {
    let search = window.location.search;
    if(localStorage.getItem(LOCAL_STORE_KEY) === null || localStorage.getItem(LOCAL_STORE_KEY).length === 0){
         /**
          * If user tries to change value for this key
          * While reload oy moving to another page, We'll direct the user to login page
          */
        removeLocalStorageKeys();
        let data = {'isPlanExpire':false,'isBufferPeriod':false,'warning_message':false}
        let encrypt_data = encryptForLocalUsage(data)
        localStorage.setItem(LOCAL_STORE_KEY,encrypt_data)
    }
    let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
    let isPlanExpired = decrypt_data['isPlanExpired']
    let isInBufferPeriod = decrypt_data['isInBufferPeriod']
    let reset_password = localStorage.getItem(MANDATE_RESET_PASSWORD.Reset_Password);
    const windowPathName = window.location.pathname;
    if(isPlanExpired === true && isInBufferPeriod=== false && windowPathName !== "/" && windowPathName !== "/payment" && windowPathName !== "/choose-plan") {
        let redirectionLink = getRedirectionLinkToExpiredPlan();
        browserHistory.push(redirectionLink);
    }
    let isDrxFreeTrailExpired = false
    let isDrxFreeTrail = decrypt_data['is_drx_free_trail']
    let DrxFreeTrailStatus = decrypt_data['drx_free_trail_status']
    if (isDrxFreeTrail !== undefined && isDrxFreeTrail !== null && isDrxFreeTrail === true){
        if(DrxFreeTrailStatus !== undefined && DrxFreeTrailStatus !== null && DrxFreeTrailStatus === "Expired"){
            isDrxFreeTrailExpired = true
        }
    }
    if(isDrxFreeTrailExpired === true && windowPathName !== "/") {
        let redirectionLink = getRedirectionLinkToDiscover();
        browserHistory.push(redirectionLink);
    }

    if(reset_password === MANDATE_RESET_PASSWORD.One && window.location.pathname !== "/") {
        let redirectionLink = getRedirectionLinkToChangePassword();
        browserHistory.push(redirectionLink);
        return;
    }

    let logged_in_status = false;
    let sessionToken = localStorage.getItem('sessionToken');
    let isPlanDetailAvailable = localStorage.getItem('isPlanDetailAvailable');
    if (sessionToken !== undefined && sessionToken !== null) {
         const item = JSON.parse(sessionToken);
         const now = new Date();
         if (now.getTime() > item.expiry) {
            if(checkExpiryPowerBI()===false){
            removeLocalStorageKeys();
            }
         } else {
             logged_in_status = true;
         }
    } else {
        if (isPlanDetailAvailable === undefined || isPlanDetailAvailable === null) {
            removeLocalStorageKeys();
        }
    }


    // Some URLs will be allowed only when user is NOT LOGGED IN.
    // If user tries to access them while logged in, we will redirect them to other pages
    if (logged_in_status  === true && ACCOUNT_FREE_URLS.includes(windowPathName)){
        if(isPlanExpired === true && isInBufferPeriod === false) {
             let redirectionLink = getRedirectionLinkToExpiredPlan();
             browserHistory.push(redirectionLink);
        } else if (reset_password === MANDATE_RESET_PASSWORD.One) {
            let redirectionLink = getRedirectionLinkToChangePassword();
            if (this === undefined) {
                browserHistory.push(redirectionLink);
            }else {
                this.props.router.push(redirectionLink);
            }

        }
        else if (isDrxFreeTrailExpired === true){
            let redirectionLink = getRedirectionLinkToDiscover();
            browserHistory.push(redirectionLink);
        }
        else {
            browserHistory.push("/configure");
        }
    } else if(search.length > 0) {
        let params = new URLSearchParams(search);

        window.history.replaceState({}, document.title, windowPathName);
        if(windowPathName === "/setup") {
            localStorage.setItem("DataSetupStatus", false);
        }
        let error_message = params.get("error_message");
        localStorage.setItem("error_message", error_message);
        let msi_login = params.get('msi_login');
        let token = params.get('auth_token');
        let team_id = params.get('team_id');
        let user_name = params.get('user_name');
        let user_id = params.get('user_id');
        let org_id = params.get("org_id");
        let google_login = params.get('google_login');

        let req_key = "msi_data";
        if (google_login === "True") {
            req_key = "user_data";
        }

        if (msi_login === "True" || google_login === "True") {
            let decryptMsgData = params.get(req_key);
            decryptMsgData = decryptMsg(decryptMsgData);
            token = decryptMsgData['auth_token']
            team_id = decryptMsgData['team_id']
            user_id = decryptMsgData['user_id']
        }

        let name = params.get('name');
        let email = params.get('email');
        let resetPwdToken = params.get('token');
        let currentStatus = params.get("current_status");
        let user_signup_type = params.get("user_signup_type");
        // let tenantData = {"tenant_id": team_id};

        if (currentStatus !== undefined && currentStatus !== "None" && currentStatus === "disabled") {
            browserHistory.push("/user_status");
        }

        let notification_id = params.get("notification_id");
        if (!IS_NONE_CHECK.includes(notification_id)) {
            localStorage.setItem("selected_notification_id", notification_id);
        }

        localStorage.setItem('msi_login', msi_login);
        let isPlanExpired;
        let isInBufferPeriod;
        if (params.get("account_features") !== null && params.get("account_features") !== undefined) {
            let features = params.get("account_features");
            let account_features = decryptMsg(features);
            let plan_details = account_features["plan_details"];
            plan_details["payment_status"] = account_features["payment_status"];

            /**
             * Get data from response and encrypt the data
             * storing encrypted data in localStorage
             * then decrypt the data for update and after update
             * again encrypt and storing in localStorage
             */
            let encrypt_data = encryptForLocalUsage(plan_details)
            localStorage.setItem(LOCAL_STORE_KEY, encrypt_data)
            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))

            isPlanExpired =  plan_details["is_plan_expired"];
            isInBufferPeriod =  plan_details["is_in_buffer_period"];
            decrypt_data['isPlanExpired'] = isPlanExpired
            decrypt_data['isInBufferPeriod'] = isInBufferPeriod
            decrypt_data['warning_message'] = account_features["warning_message"]
            encrypt_data = encryptForLocalUsage(decrypt_data)
            localStorage.setItem(LOCAL_STORE_KEY, encrypt_data)

            // localStorage.setItem("isPlanExpired",isPlanExpired);
            // localStorage.setItem("isInBufferPeriod", isInBufferPeriod);
            // localStorage.setItem("warning_message", account_features["warning_message"]);

            localStorage.setItem("plan_details",  JSON.stringify(plan_details));
            // These keys used only for new user when redirected from web page
            localStorage.removeItem("isPlanDetailAvailable");
            localStorage.removeItem("qualdoPlan");
            localStorage.removeItem("qualdoPlanCycle");
            localStorage.removeItem("qualdoEdition");
        }
        let feature_access = params.get("feature_access")
        if(feature_access){
           feature_access = JSON.parse(feature_access);
           const encryptedFA = encryptForLocalUsage(feature_access);
           localStorage.setItem('featureAccess', encryptedFA);
        }

        let permissions = params.get("permissions");
        if (permissions !== undefined && permissions !== null) {
            permissions = JSON.parse(permissions);
            const encryptedPermissions = encryptForLocalUsage(permissions);
            localStorage.setItem(PERMISSIONS_LOCAL_STORE_KEY, encryptedPermissions);
        }
        if (resetPwdToken !== null && resetPwdToken !== "undefined") {
            localStorage.setItem('resetPwdToken', resetPwdToken);
        }

        if (email !== "undefined" && email != null) {
            localStorage.setItem('emailId', email);
            localStorage.setItem('user_name', name);
        }
        if (token !=="undefined" && token != null) {
            const now = new Date();
            const item = {
                            value: token,
                            user_id : user_id,
                            team_id: team_id,
                            expiry: now.getTime() + 150*60000
                        };
            setTimeout(() => browserHistory.push('/'), 9000000);
            localStorage.setItem('sessionToken', JSON.stringify(item));
            localStorage.setItem('user_name', user_name);
            localStorage.setItem("user_id", user_id);
            localStorage.setItem("org_id", org_id);
            localStorage.setItem('user_signup_type', user_signup_type);
        }
        if (team_id !=="undefined" && team_id !== null &&  token !=null) {
            localStorage.setItem('team_id',team_id)
        }

//        call_get_api(ui_service_ip, "account/features?tenant_id="+team_id).then((res_1) => {
//            if (res_1.code === 200) {
//                localStorage.setItem("plan_details",  JSON.stringify(res_1["plan_details"]));
//           }  else {
//                localStorage.setItem("plan_details", null);
//            }
//        });
//

        let isPlanDetailAvailable = localStorage.getItem('isPlanDetailAvailable');
        if ((isPlanDetailAvailable !== undefined && isPlanDetailAvailable !== null) || isPlanDetailAvailable === true) {
            localStorage.removeItem("isPlanDetailAvailable");
        } else {
            let qualdoPlan = params.get("plan");
            let qualdoPlanCycle = params.get("cycle");
            let qualdoEdition = params.get("edition");
            if ((qualdoPlan === "undefined" || qualdoPlan === null) &&
                (qualdoPlanCycle === "undefined" || qualdoPlanCycle === null) &&
                (qualdoEdition === "undefined" || qualdoEdition === null)){
                // plan, cycle, edition details are not present in url. so assign free trail plan
                qualdoPlan = QualdoPlanVersions.FREE;
                qualdoEdition = QualdoEditionMapping["MME"];
                qualdoPlanCycle = QualdoPlanCycle.TRIAL_14_DAYS;
                localStorage.setItem('qualdoPlan', qualdoPlan);
                localStorage.setItem('qualdoPlanCycle', qualdoPlanCycle);
                localStorage.setItem('qualdoEdition', qualdoEdition);
            }else if(qualdoPlan !== "undefined" && qualdoPlanCycle !== "undefined" && qualdoEdition !== "undefined")
            {
                // plan, cycle, edition details(all 3) are available. check plan is valid
                let url = "plan/active";
                let planData = {"plan":qualdoPlan,"cycle":qualdoPlanCycle,"edition":qualdoEdition}
                call_get_api(ui_service_ip, url, planData).then((res_1) => {
                if (res_1.code === 200) {
                    // plan details are valid.
                    localStorage.setItem('isPlanDetailAvailable', true);
                    localStorage.setItem('qualdoPlan', qualdoPlan);
                    localStorage.setItem('qualdoPlanCycle', qualdoPlanCycle);
                    localStorage.setItem('qualdoEdition', qualdoEdition);
                }else{
                    browserHistory.push("/invalid-request");
                }
               });
            } else {
                //some details(plan, cycle, edition) are missing in url params
                browserHistory.push("/invalid-request");
            }
        }

        let dataLimitReached = isDataLimitExceeded();
        if ((isPlanExpired === true || isPlanExpired === "true") && (isInBufferPeriod === "false" || isInBufferPeriod === false)) {
            let redirectionLink = getRedirectionLinkToExpiredPlan();
            browserHistory.push(redirectionLink);
        } else if (dataLimitReached) {
            browserHistory.push("/plan");
        } else if (reset_password === MANDATE_RESET_PASSWORD.One) {
            let redirectionLink = getRedirectionLinkToChangePassword();
            this.props.router.push(redirectionLink);
        }

        if (team_id !== undefined && team_id !== null) {
               store.dispatch(setInitialValues())
               store.dispatch(setTenantId(team_id))
               store.dispatch(setMonitorInitialValues())

                preFetchCorrelationData(team_id, storeCorrelationData, store);

            /* ##
            preFetchMonitorPreview(tenantData, addMonitorPreview,
                addPerformancePreview, addModelPerformance, store); */
                /*preFetchRelationshipData(
                    tenantData, setDataSource, addRelationship,
                    addDataSource, addInfo, addLastProfilingTime, store
                );*/
                /* ##  preFetchMetaData(
                    tenantData, addMetaData, addEnvironment,
                    addEnvironmentDataSourceMapping, addDataSourceDataSetMapping,
                    addDataSetAttributeMapping, setDataSet, setAttribute,
                    setDataSource, setMlDataSource, addRelationship, addDataSource, addInfo,
                    addLastProfilingTime, setStartDate, setEndDate, addDiscoveryStatus, addProcessingStatus, noIntegration, store
                ); */
                 /* ##
                preFetchMonitorInfo(
                    tenantData, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
                    addTimelinessDataSetInfo, addTimelinessAttributeInfo,
                    addAccuracyDataSetInfo, addAccuracyAttributeInfo,
                    addConformityDataSetInfo, addConformityAttributeInfo,
                    addConsistencyDataSetInfo, addConsistencyAttributeInfo,
                    addDriftDataSetInfo, addDriftAttributeInfo,addMLDataSetInfo,
                    addMLAttributeInfo,addMonitorPreview,addUniquenessDataSetInfo, addUniquenessAttributeInfo,
                    addRecencyPreview, prefetchMonitorState, store
                );

                preFetchMonitorModelInfo(
                    team_id, addMLModelMapping,
                    addMLDataSetInfo, addMLAttributeInfo,
                    addMLModel, setMLModel, store
                );


//                preFetchModelPerformanceInfo(
//                    team_id, addModelPerformance, store
//                );

                preFetchModelDetails(team_id, addModelDetails, store);

                preFetchMetrics(team_id, addMetrics, store);

                preFetchMonitorListViewData(team_id, addMonitorDQListViewData, store);

                // Fetch first page result of user notifications
                preFetchUserNotifications(tenantData, addUserNotificationToState, store);

                // Fetch dataset version details
                prefetchDatasetDetails(tenantData, addDatasetVersions, store);

                // Fetch Recency Details
                prefetchRecencyDetails(tenantData, addRecencyDetails, store);
                // Fetch recent history - discover page
                prefetchRecentHistory(tenantData, addRecentHistory, store);
                prefetchDataSize(tenantData, addDataSize, store);

                // Fetch Model AB configuration list
                prefetchModelABData(tenantData, setModelABDataToList, store);
                preFetchFollowAttributes(tenantData, addFollowAttributes, store)

                */
            }

    }

    // User is trying to view /quick_start page by modifying the URL.
    // We will prevent them since data setup status is not complete yet
    let DataSetupStatus = localStorage.getItem("DataSetupStatus");
    let feature_access = localStorage.getItem("featureAccess")
    if(windowPathName === "/quick-start" && DataSetupStatus === "false" && feature_access["skip_setup"] === true ) {
        browserHistory.push("/quick-start");
    }
    else if(windowPathName === "/quick-start" && DataSetupStatus === "false" ) {
        browserHistory.push("/setup");
    }

    if(windowPathName !== "/quick-start" && windowPathName !== "/setup" && DataSetupStatus === "false" && feature_access["skip_setup"] === true) {
        browserHistory.push("/quick-start");
    }
    else if(windowPathName !== "/quick-start" && windowPathName !== "/setup" && DataSetupStatus === "false") {
        browserHistory.push("/quick-start");
    }
}

let DEPLOYED_ENVIRONMENT = process.env.REACT_APP_CUSTOM_NODE_ENV

const routing = (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor} onBeforeLift={onBeforeLift} >
        <Router history={browserHistory}>
            <div>
                <Route exact path="/" component={Home} />
                <Route path="/login" component={Home} isFirstTimeLogin={true}/>
                {(DEPLOYED_ENVIRONMENT === "managed_service") ?
                    <>
                        <Route path="/signup" component={Home}/>
                        <Route exact path="/google-signup" component={Home}/>
                    </>
                    :
                    <>
                        <Route path="/signup" pageType={0} component={Signup}/>
                        <Route exact path="/google-signup" pageType={2} component={Signup}/>
                    </>
                }
                <Route path="/forget-password" component={ForgotPassword} />
                <Route path="/verify-email" isNormalSignup={true} component = {EmailNotification} />
                <PrivateRoute path="/home" component={DefaultHome} />
                <Route path="/password-reset" component={resetPasswordForm} />
                <Route path="/set-password" component={setPasswordForm} />
                <Route path = "/initiate-set-password" component={InitiateSetPassword} />
                { IS_SAAS_EDITION  &&
                <>
                 <PrivateRoute path="/choose-plan" component={ChoosePlan} history={browserHistory}/>
                 <PrivateRoute path="/model" component={Configure} navKey={"ml"}/>
                 <PrivateRoute path="/comply" component={Comply} />
                 <PrivateRoute path="/billing" component={(props) => {
                    postMixpanel("Billing and Payments Page");
                    return (<Settings navKey={"billing"}/>);
                }
                }/>
                <Route exact path="/plan" component={(props) => {
                    postMixpanel("Plan and Usage Page");
                    return (<Settings navKey={"usage"}/>);
                }
                }/>
                <PrivateRoute path="/ml-profile" component={(props) => {
                    postMixpanel("Profile -> Model Page");
                    return (<MLProfile/>);
                }
                }/>
                </>
                }
                <PrivateRoute path="/payment" component={Payment} />
                <PrivateRoute path="/configure" component={Configure} />
                <PrivateRoute path="/reports" component={Reports} />
                <PrivateRoute path="/comply" component={Comply} />
                <PrivateRoute path="/pipeline" component={Configure} navKey={"pipeline"}/>
                <PrivateRoute path="/metrics" component={Metrics} />
                <PrivateRoute path="/monitor(/:parentTab)(/:innerTab)(/:additional_info)"
                              component={(props) => {
                                  const propsParams = props.params;
                                  const parentTab = propsParams.parentTab;
                                  if (parentTab === undefined) {
                                      return (<Monitor/>);
                                  }

                                  const innerTab = propsParams.innerTab;
                                  if (innerTab === undefined) {
                                      return (<Monitor activeTabKey={parentTab}/>);
                                  }

                                  const additionalInfo = propsParams.additional_info;
                                  if (additionalInfo === undefined) {
                                      return (<Monitor activeTabKey={parentTab}
                                                       innerTab={innerTab}
                                      />);
                                  }

                                  return (<Monitor activeTabKey={parentTab}
                                                   innerTab={innerTab}
                                                   additionalInfo={additionalInfo}
                                  />);
                              }
                              }/>
                <PrivateRoute path="/setup-monitor" component={Monitor}  activeTabKey={"dq"} />
                <PrivateRoute path="/alerts" component={Consume} />
                <PrivateRoute path="/govern" component={Govern} />
                <PrivateRoute path="/setup" component={Setup} />
                <PrivateRoute path="/user-status" component={showUserStatus} />
                <PrivateRoute path="/settings" component={(props) => {
                    postMixpanel("Profile Page");
                    return (<Settings navKey={"account"}/>);
                }
                }/>
                <PrivateRoute path="/settings" component={Settings} navKey={"account"}/>

                <PrivateRoute path="/discover" component={Discover} />

                <PrivateRoute path="/quick-start" component={QuickStart} />
                <PrivateRoute path="/quick-start-video" component={VideoHelp} />
                <PrivateRoute path="/notification" component={Notifications} />
                <Route exact path="/teammate" component={(props) => {
                    postMixpanel("Teammate Page");
                    return (<Settings navKey={"teammate"}/>);
                }
                }/>
                <PrivateRoute path="/data-profile" component={(props) => {
                    postMixpanel("Profile -> Data Page");
                    return (<DataProfile/>);
                }
                }/>
                <PrivateRoute path="/privacy-policy" component={PrivacyPolicy} />
                <PrivateRoute path="/cookie-policy" component={CookiePolicy} />
                <PrivateRoute path="/free-trial" component={FreeTrialAgreement} />
                <PrivateRoute path="/invalid-request" component={Signup} pageType={4}/>
                <Route path="/unsubscribe/:emailid/:tenantid" component={Unsubscribe} />
                 {/* Power BI  */}
                <PrivateRoute path="/pbi-setup" component={PBISetup} />
                <PrivateRoute path="/pbi-report"  component={PBIErrors} />
                <PrivateRoute path="/pbi-mapping" component={PBIMapping} />
                <PrivateRoute path="/login_user" component={MSILogin} />
                <PrivateRoute path="/google_login_user" component={GoogleLoginLoader} />
            </div>
        </Router>
        </PersistGate>
    </Provider>
)

if(IS_SAAS_EDITION === true && GTM_ID.length > 0){
    
    const tagManagerArgs = {
        gtmId: GTM_ID,
        auth: GTM_INTEGRATION_TOKEN,
        events: GTM_EVENTS,
        preview: GTM_PREVIEW
       }
    
    TagManager.initialize(tagManagerArgs)
    
}

ReactDOM.render(routing, document.getElementById('root'));
serviceWorker.unregister();
