import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Select from 'react-select';
import Dropdown from 'react-bootstrap/Dropdown';
import ChartBody from '../../components/chartBody';
import domtoimage from 'dom-to-image';


import {
    compareDqCharts,
    compareMlCharts,
    setDistanceFilter,
    setTimeFilter
} from "../../utils/event_handling";

import {
    chartFilterTable, convertChartTypeToError,
    extractMetricNameFromChartName,
    getChartTitleBasedOnData,
    getNormalizedName,
    getPerformanceChartData,
    updateChartDataErrorType,
    filterBoxPlotData,
    getChartFilterData,
    setModelPerformanceTimeFilter,
} from "../../utils/common_utils";

import Image from '../../components/image';
import {faChartArea, faDownload, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
//import html2canvas from "html2canvas";
import BasicPortlet from '../../components/basicPortlet';
import BootstrapTable from '../../components/bootstrapTable';
import {
    CHART_TITLE_METRIC_SEPARATOR,
    CHART_TITLE_SEPARATOR,
    chartTypes,
    DATA_TYPE_CONSISTENCY, ML_DATA_CONSISTENCY,
    REFERENCE_TYPE_COLUMN_NAME, ROW_ERROR_CLASS,
    showMoreVariants,
    BOX_PLOT_METRICS, SINGLE_ARROW_CHART_TYPE, IS_NONE_CHECK,
    SEE_FULL_VIEW_ICONS
} from "../../utils/constant";
import Load from "../../components/loadAction";
import MonitorStatsComponent from "./monitorStatsComponent";
import SingleArrowChart from "../../components/charts/singleArrowChart";

const chartNameMapping = {
    "barChart": "Bar",
    "barWithError": "Bar",
    "areaChart": "Area",
    "areaWithError": "Area",
    "baseLine": "BaseLine",
    "bumpChart": "Bump",
    "boxPlotZoomable": "BoxPlot",
    "groupedVerticalBarChart": "Bar",
    "multiLineChart": "Line"
}

const chartSvg = {
    "barChart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
        <g fill="none" stroke="currentColor" strokeLinecap="square">
            <path d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20"/>
        </g>
    </svg>,
    "barWithError": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
        <g fill="none" stroke="currentColor" strokeLinecap="square">
            <path d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20"/>
        </g>
    </svg>,
    "boxPlotZoomable": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
        <g fill="none" stroke="currentColor" strokeLinecap="square">
            <path strokeDasharray="1,1" d="M4 14.5h22"></path>
            <path strokeLinecap="round" strokeLinejoin="round"
             d="M7.5 12.5l2-4 1 2 2-4 3 6"></path>
            <path strokeLinecap="round" d="M5.5 16.5l-1 2"></path>
            <path strokeLinecap="round" strokeLinejoin="round"
            d="M17.5 16.5l2 4 2-4m2-4l1-2-1 2z"></path>
        </g>
    </svg>,
    "defaultIcon": <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 28 28" width="28" height="28">
        <g fill="currentColor">
            <path
                d="M15.09 13.877l-6.033 6.536-1.47-1.356 6.678-7.235a1 1 0 0 1 1.375-.09l5.27 4.391 6.033-6.536 1.47 1.356-6.678 7.235a1 1 0 0 1-1.375.09l-5.27-4.391zM11 25H9v-2a1 1 0 0 1 2 0v2zm8 0h-2v-3a1 1 0 0 1 2 0v3zm8 0h-2v-6a1 1 0 0 1 2 0v6z">
            </path>
            <path d="M15 25h-2v-5a1 1 0 1 1 2 0v5zm8 0h-2v-2a1 1 0 0 1 2 0v2z"/>
        </g>
    </svg>,
    "areaChart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"
                      fill="currentColor">
        <path
            d="M12.5 17.207L18.707 11h2l3.647-3.646-.708-.708L20.293 10h-2L12.5 15.793l-3-3-4.854 4.853.708.708L9.5 14.207z">
        </path>
        <path
            d="M9 16h1v1H9zm1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-3-3h1v1H8zm-1 1h1v1H7zm-1 1h1v1H6zm2 0h1v1H8zm-1 1h1v1H7zm-2 0h1v1H5zm17-9h1v1h-1zm1-1h1v1h-1zm0 2h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-5-7h1v1h-1zm2 0h1v1h-1zm1-1h1v1h-1zm-2 2h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-2-6h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-3-3h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1z">
        </path>
    </svg>,
    "areaWithError": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"
                  fill="currentColor">
    <path
        d="M12.5 17.207L18.707 11h2l3.647-3.646-.708-.708L20.293 10h-2L12.5 15.793l-3-3-4.854 4.853.708.708L9.5 14.207z">
    </path>
    <path
        d="M9 16h1v1H9zm1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-3-3h1v1H8zm-1 1h1v1H7zm-1 1h1v1H6zm2 0h1v1H8zm-1 1h1v1H7zm-2 0h1v1H5zm17-9h1v1h-1zm1-1h1v1h-1zm0 2h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-5-7h1v1h-1zm2 0h1v1h-1zm1-1h1v1h-1zm-2 2h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-2-6h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-3-3h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1z">
    </path>
</svg>,
    "baseLine": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
        <g fill="none" stroke="currentColor">
            <path strokeDasharray="1,1" d="M4 14.5h22"/>
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M7.5 12.5l2-4 1 2 2-4 3 6"/>
            <path strokeLinecap="round" d="M5.5 16.5l-1 2"/>
            <path strokeLinecap="round" strokeLinejoin="round"
                  d="M17.5 16.5l2 4 2-4m2-4l1-2-1 2z"/>
        </g>
    </svg>,
    "bumpChart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"
                      fill="currentColor">
        <path
            d="M14.5 16a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 7a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm3.293-15.5l4.707 4.707.707-.707L18.5 4.793z">
        </path>
        <path
            d="M18.5 10.207L23.207 5.5l-.707-.707L17.793 9.5zm-.707 1.293l4.707 4.707.707-.707-4.707-4.707z">
        </path>
        <path
            d="M18.5 16.207l4.707-4.707-.707-.707-4.707 4.707zM5.793 17.5l4.707 4.707.707-.707L6.5 16.793z">
        </path>
        <path
            d="M6.5 22.207l4.707-4.707-.707-.707L5.793 21.5zM5.793 5.5l4.707 4.707.707-.707L6.5 4.793z">
        </path>
        <path
            d="M6.5 10.207L11.207 5.5l-.707-.707L5.793 9.5zM5.793 11.5l4.707 4.707.707-.707L6.5 10.793z">
        </path>
        <path d="M6.5 16.207l4.707-4.707-.707-.707L5.793 15.5z"/>
    </svg>,
    "groupedVerticalBarChart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
        <g fill="none" stroke="currentColor" strokeLinecap="square">
            <path d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20"/>
        </g>
    </svg>,
    "multiLineChart": <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M11.982 16.689L17.192 12h3.033l4.149-4.668-.748-.664L19.776 11h-2.968l-4.79 4.311L9 12.293l-4.354 4.353.708.708L9 13.707z">
        </path>
        </svg>
}

let start = moment().subtract(29, 'days');
let end = moment();


const chartToggle = React.forwardRef(
    ({ children, onClick, chartType,panelchartid}, ref) => {
        let svgIcon = chartSvg[chartType];
        let chartName = chartNameMapping[chartType];
        return (
            <div ref={ref} onClick={(e) => {
                if(document.getElementById('body_'+panelchartid)!==null){
                 if(chartType==="BoxPlot" || chartType==="boxPlotZoomable"){
                    document.getElementById('body_'+panelchartid).classList.add("boxplot");
                 }else {
                    document.getElementById('body_'+panelchartid).classList.remove("boxplot");
                 }}     e.preventDefault(); onClick(e);}} className="qd-cw-btn">
                {svgIcon}
                <div className="qd-cw-btn-text">{chartName}</div>
                {children}
            </div>
        );
    },
);

const distanceToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} className="qd-cw-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <g id="time-iocn" transform="translate(-3 -3)">
                <path id="Path_3" data-name="Path 3"
                      d="M7.161,17.343,17.343,7.161l-.514-.514L6.647,16.829Z"
                      transform="translate(-0.995 -0.995)" />
                <path id="Path_4" data-name="Path 4"
                      d="M17.182,5.909a1.091,1.091,0,1,0-1.091-1.091A1.091,1.091,0,0,0,17.182,5.909Zm0,.727A1.818,1.818,0,1,1,19,4.818,1.818,1.818,0,0,1,17.182,6.636ZM4.818,19a1.818,1.818,0,1,1,1.818-1.818A1.818,1.818,0,0,1,4.818,19Z" />
            </g>
        </svg>
        <div className="qd-cw-btn-text">Distance</div>
        {children}
    </div>
));

const settingsToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} className="qd-cw-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
            <g fill="currentColor" fillRule="evenodd">
                <path fillRule="nonzero"
                      d="M14 17a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"/>
                <path
                    d="M5.005 16A1.003 1.003 0 0 1 4 14.992v-1.984A.998.998 0 0 1 5 12h1.252a7.87 7.87 0 0 1 .853-2.06l-.919-.925c-.356-.397-.348-1 .03-1.379l1.42-1.42a1 1 0 0 1 1.416.007l.889.882A7.96 7.96 0 0 1 12 6.253V5c0-.514.46-1 1-1h2c.557 0 1 .44 1 1v1.253a7.96 7.96 0 0 1 2.06.852l.888-.882a1 1 0 0 1 1.416-.006l1.42 1.42a.999.999 0 0 1 .029 1.377s-.4.406-.918.926a7.87 7.87 0 0 1 .853 2.06H23c.557 0 1 .447 1 1.008v1.984A.998.998 0 0 1 23 16h-1.252a7.87 7.87 0 0 1-.853 2.06l.882.888a1 1 0 0 1 .006 1.416l-1.42 1.42a1 1 0 0 1-1.415-.007l-.889-.882a7.96 7.96 0 0 1-2.059.852v1.248c0 .56-.45 1.005-1.008 1.005h-1.984A1.004 1.004 0 0 1 12 22.995v-1.248a7.96 7.96 0 0 1-2.06-.852l-.888.882a1 1 0 0 1-1.416.006l-1.42-1.42a1 1 0 0 1 .007-1.415l.882-.888A7.87 7.87 0 0 1 6.252 16H5.005zm3.378-6.193l-.227.34A6.884 6.884 0 0 0 7.14 12.6l-.082.4H5.005C5.002 13 5 13.664 5 14.992c0 .005.686.008 2.058.008l.082.4c.18.883.52 1.71 1.016 2.453l.227.34-1.45 1.46c-.004.003.466.477 1.41 1.422l1.464-1.458.34.227a6.959 6.959 0 0 0 2.454 1.016l.399.083v2.052c0 .003.664.005 1.992.005.005 0 .008-.686.008-2.057l.399-.083a6.959 6.959 0 0 0 2.454-1.016l.34-.227 1.46 1.45c.003.004.477-.466 1.422-1.41l-1.458-1.464.227-.34A6.884 6.884 0 0 0 20.86 15.4l.082-.4h2.053c.003 0 .005-.664.005-1.992 0-.005-.686-.008-2.058-.008l-.082-.4a6.884 6.884 0 0 0-1.016-2.453l-.227-.34 1.376-1.384.081-.082-1.416-1.416-1.465 1.458-.34-.227a6.959 6.959 0 0 0-2.454-1.016L15 7.057V5c0-.003-.664-.003-1.992 0-.005 0-.008.686-.008 2.057l-.399.083a6.959 6.959 0 0 0-2.454 1.016l-.34.227-1.46-1.45c-.003-.004-.477.466-1.421 1.408l1.457 1.466z">
                </path>
            </g>
        </svg>
        {children}
    </div>
));

const AggregateTogle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} className="qd-cw-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.431" height="15.575" viewBox="0 0 15.431 15.575">
            <g id="Group_3" data-name="Group 3" transform="translate(-56.592 -3.24)">
            <g id="Group_1" data-name="Group 1">
                <path id="Path_1" data-name="Path 1" d="M64.34,3.24l-7.5,4.35,7.5,4.35,7.5-4.35ZM58.833,7.59,64.34,4.4,69.846,7.59,64.34,10.784Z"/>
            </g>
            <g id="Group_2" data-name="Group 2">
                <path id="Path_2" data-name="Path 2" d="M72.022,11.314l-.5-.865L64.359,14.6l-7.271-4.157-.5.868,7.771,4.443Z"/>
                <path id="Path_3" data-name="Path 3" d="M64.359,17.661,57.088,13.5l-.5.867,7.771,4.443,7.659-4.444-.5-.865Z"/>
            </g>
            </g>
        </svg>
        <div className="qd-cw-btn-text">Aggregate</div>
        {children}
    </div>
));


const metricsTogle = React.forwardRef(({ children, onClick }, ref) => (
    <div ref={ref} onClick={(e) => { e.preventDefault(); onClick(e);}} className="qd-cw-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.431" height="15.575" viewBox="0 0 15.431 15.575">
            <g id="Group_3" data-name="Group 3" transform="translate(-56.592 -3.24)">
            <g id="Group_1" data-name="Group 1">
                <path id="Path_1" data-name="Path 1" d="M64.34,3.24l-7.5,4.35,7.5,4.35,7.5-4.35ZM58.833,7.59,64.34,4.4,69.846,7.59,64.34,10.784Z"/>
            </g>
            <g id="Group_2" data-name="Group 2">
                <path id="Path_2" data-name="Path 2" d="M72.022,11.314l-.5-.865L64.359,14.6l-7.271-4.157-.5.868,7.771,4.443Z"/>
                <path id="Path_3" data-name="Path 3" d="M64.359,17.661,57.088,13.5l-.5.867,7.771,4.443,7.659-4.444-.5-.865Z"/>
            </g>
            </g>
        </svg>
        <div className="qd-cw-btn-text">Metrics</div>
        {children}
    </div>
));


let ranges = {
    'Today': [moment(), moment()],
    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
};

class ChartFilterHeader extends React.Component {
    constructor(props) {
        super(props);
        this.handleEvent = this.handleEvent.bind(this);
        this.setCompare = this.setCompare.bind(this);
        this.setSelectedFilterChartData = this.setSelectedFilterChartData.bind(this);
        this.getChartDataFromResult = this.getChartDataFromResult.bind(this);
        this.setTimeFilter = setTimeFilter.bind(this);
        this.setDistanceFilter = setDistanceFilter.bind(this);
        this.setChartFilter = this.setChartFilter.bind(this);
        this.setShowSaveImage = this.setShowSaveImage.bind(this);
        this.saveChartScreenShot = this.saveChartScreenShot.bind(this);
        this.settingFilter = this.settingFilter.bind(this);
        this.setModelPerformanceConfusionTimeFilterChartData = this.setModelPerformanceConfusionTimeFilterChartData.bind(this);
        this.setModelPerformanceTimeFilter = setModelPerformanceTimeFilter.bind(this);
        this.setModelPerformanceTimeFilterChartData = this.setModelPerformanceTimeFilterChartData.bind(this);
        this.showCompare = this.showCompare.bind(this);
        this.closeCompare = this.closeCompare.bind(this);
        this.compareDqCharts = compareDqCharts.bind(this);
        this.compareMlCharts = compareMlCharts.bind(this);
        this.setCompareChart = this.setCompareChart.bind(this);
        this.setAttrCompare = this.setAttrCompare.bind(this);
        this.setAggregate = this.setAggregate.bind(this);
        this.setMlCompare = this.setMlCompare.bind(this);
        this.setMlAttributeCompare = this.setMlAttributeCompare.bind(this);
        this.getPerformanceChartData = getPerformanceChartData.bind(this);
        this.changeSource = this.changeSource.bind(this);
        this.expandChart = this.expandChart.bind(this);
        this.headerRef = React.createRef();
        let chartName;

        // If the props has filtered data, we can display the filtered data initially
        let data;
        const filteredData = this.props.filteredData;
        if (filteredData === undefined || filteredData === null) {
            if (this.props.chartType === "groupedHorizontalBarChart" || this.props.chartType === "horizontalCompareChart") {
               data = this.props.data;
            } else if(this.props.timeFilterApplied === true) {
               data = filteredData;
            } else {
               data = this.props.data;
            }
        } else {
            // We have received 'time filtered data', so it is used
            data = filteredData;
        }

        if (data !== undefined && data !== null && data.name !== undefined ) {
            chartName =data.name.replace(" ","_");
        }
//        let compareType = "Dataset";
//        const attribute_name = this.props.data.attribute_name;

        let title;
        if (this.props.usePropsTitle === undefined || this.props.usePropsTitle === null) {
            title = getChartTitleBasedOnData(data, this.props.selectedIntegration);
            chartName = title;
//            if (this.props.data.model_name !== undefined && attribute_name !== undefined) {
//                compareType = "modelAttribute";
//            } else if (this.props.data.model_name !== undefined && attribute_name === undefined) {
//                compareType = "modelDataSet";
//            } else if (attribute_name !== undefined && attribute_name !== null) {
//                compareType = "Attribute";
//            }
        }
        else {
            title = this.props.title;
        }

        let customChartKey = "";
        if (data !== undefined && data !== null) {
            customChartKey = data.key;
        }

        let currentData = data;
        let settings = null;
        if (currentData !== undefined && currentData !== null){
            settings = currentData.settings;
        }

        let multipleLines = this.props.multipleLines === undefined ? null : this.props.multipleLines;
        let errorChart = false;
        if (data !== null && data !== undefined) {
            errorChart = data.errorChart;
        }

        let chartType = this.props.chartType;
        chartType = convertChartTypeToError(chartType, errorChart)

        if (data !== null && data !== undefined && this.props.page === "attribute") {
            let boxplot_data = data["boxplot_data"]
            if (BOX_PLOT_METRICS.includes(data.name) && (!boxplot_data.includes("NA"))) {
                chartType = "boxPlotZoomable";
                data["toolTiptopHeader"] = data["attribute_name"];
                data["boxPlotData"] = filterBoxPlotData(data["boxPlotData"])
            }
        }
        let startDate = null;
        let endDate = null;
        if (this.props.startDate !== undefined && this.props.startDate !== null &&
            this.props.endDate !== undefined && this.props.endDate !== null) {
            startDate = this.props.startDate;
            endDate = this.props.endDate;
        }

        this.state = {
            startDate: startDate,
            endDate: endDate,
            distance: null,
            compareDataSet:null,
            compareChart: multipleLines,
            showCompare: false,
            settings: settings,
            backdrop: '',
            expand: this.props.page === "attribute" ? this.props.expand :false,
            data: data,
            dataSetId: this.props.dataSetId,
            mlModelId: this.props.mlModelId,
            compareModelOptions: this.props.compareModelOptions,
            compareMLAttributeOptions: this.props.compareMLAttributeOptions,
            compareAttributeOptions:this.props.compareAttributeOptions,
            aggregateOptions: this.props.aggregateOptions,
            chartName: chartName,
            chartTitle: title,
            errorChart: errorChart,
            compareType: this.props.compareType,
            chartType: chartType,
            variant: this.props.variant,
            chartID: getNormalizedName(`modal_${this.props.variant}_${title}_${customChartKey}`),
            showSaveImage: false,
            showSettingsMenu: this.props.showSettingsMenu,
            loadValue: false,
            height1: 0,
            height2: 0,
            showHeatMap: this.props.showHeatMap,
        }
    }

    setShowSaveImage() {
        let saveImage = this.state.showSaveImage ? false : true;
        let backdrop = this.state.showSaveImage ? "": "show";
        this.setState({showSaveImage: saveImage,  backdrop:backdrop});
    }

    expandChart() {
        let expand = this.state.expand ? false : true;
        this.setState({expand: expand});
    }

    getChartDataFromResult(res, variant, chartID) {
        let chartData = null;
        if (res === undefined || res === null){
            return chartData;
        }

        let chartValues = this.getPerformanceChartData(res["results"], this.props.mapModel, false, null, null);
        chartValues.forEach(function (chart) {
            let title = chart["chartTitle"];
            let customChartKey = chart.key;
            if (customChartKey === undefined) {
                customChartKey = "";
            }

            let currentID = getNormalizedName(`modal_${variant}_${title}_${customChartKey}`);

            if (chartID === currentID) {
                chartData = chart;
                updateChartDataErrorType(chartData);
            }
        });

        return chartData;
    }

    setModelPerformanceTimeFilterChartData(res, variant, chartID) {
        let data = this.getChartDataFromResult(res, variant, chartID);
        this.setState({data: data, loadValue: false});
    }

    setModelPerformanceConfusionTimeFilterChartData(res) {
        this.setState({data: res, loadValue: false});
    }

    handleEvent(event, picker) {
        let startDate = picker.startDate;
        let endDate = picker.endDate;
        this.setState({loadValue: true});
        let currentData = this.props.data;

        const chartType = this.state.chartType;
        let isPatternNotNeeded = this.props.isAttribute === undefined ? false : true

        if (this.props.customTimeFilterFunction !== undefined) {
            const filteredData = this.props.customTimeFilterFunction(currentData, startDate, endDate, chartType, isPatternNotNeeded);
            this.setState({data: filteredData, loadValue: false});
            return;
        }

        if(currentData === null && this.props.errorNotAvailable){
           this.setState({loadValue: false})
           return
        }
        const isModelPerf = this.props.variant === "mlModelPerformance";
//      let startTime = startDate.format('YYYY-MM-DD 00:00:00');
//      let endTime = endDate.format('YYYY-MM-DD  23:59:59')
//      let exStartDate =  moment().subtract(180, 'days');
//      let valuesAvailable = startDate.isSameOrAfter(exStartDate) && endDate.isSameOrAfter(exStartDate);
//      if (isModelPerf) {
//          valuesAvailable = true;
//      }
//        if (valuesAvailable === true) {
//            const chartType = this.state.chartType;
//            const filteredData = getChartFilterData(currentData, startDate, endDate, chartType);
//            this.setState({data: filteredData, loadValue: false});
//        } else {
//            // this.setTimeFilter(this.state.dataSetId, this.props.variant,
//            //     this.props.title, this.state.chartTitle, startTime, endTime);
//            this.setState({data: null, loadValue: false});
//        }


        let isNonTimeSeries = (currentData.time === undefined || currentData.isNonTimeSeries);

        if (isNonTimeSeries && isModelPerf) {
            // For filtering 'non time series' - model performance charts like Confusion matrix, ROC Curve
            this.setModelPerformanceTimeFilter(currentData, startDate, endDate);
            return;
        }

        const filteredData = getChartFilterData(currentData, startDate, endDate, chartType, isPatternNotNeeded);

        let chartID = this.state.chartID;
        if (filteredData !== undefined && filteredData !== null) {
            let customChartKey = filteredData.key;
            let title = this.props.title;
            chartID = getNormalizedName(`modal_${this.props.variant}_${title}_${customChartKey}`)
        }

        this.setState({data: filteredData, loadValue: false, chartID:chartID});
    }

    saveChartScreenShot() {
        let chartTitle = this.state.chartTitle;
        let downloadFileName = chartTitle.split('-').join('');
        downloadFileName = getNormalizedName(downloadFileName);
        downloadFileName = downloadFileName + "_" + new Date().getTime();
        let ChartId = this.state.chartID + this.props.chartLevel;

        if(this.state.settings === 'contribution'){
            ChartId = "body_"+this.state.chartID;
        }
        let divId= getNormalizedName(ChartId);
        domtoimage.toJpeg(document.getElementById(divId), {bgcolor: 'white'}).then(function (dataUrl) {
            var link = document.createElement('a');
            link.download = downloadFileName + '.jpg';
            link.href = dataUrl;
            link.click();
        });
//        html2canvas(document.getElementById(divId)).then(
//            function (canvas) {
//                let a = document.createElement('a');
//                a.href = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
//                a.download = downloadFileName + '.jpg';
//                a.click();
//            }
//        );
    }

    setCompareChart(res, key, modelTitle, ExChartName, dataSetId, filters){
        this.closeCompare();
        let metrics = res["metrics"];
        let chartData = null;
        modelTitle = modelTitle.replace('_',' ');
        for(let row of metrics) {
            if (row.name === modelTitle && row.data !=null) {
                for(let chart of row.data) {
                    let chartName = getChartTitleBasedOnData(chart);
                    let isAttribute = (chart.attribute_name !== undefined &&
                        chart.attribute_name !== null);
                    let metricName = extractMetricNameFromChartName(chartName, isAttribute);
                    let exMetricName = extractMetricNameFromChartName(ExChartName, isAttribute);

                    if (metricName === exMetricName) {
                        chartData = chart;
                        break;
                    }
                }
                break;
            }
        }
        let compareChart = null;
        let exData  = {...this.state.data};
        if(chartData !== null) {
            if ("dq_chart_points" in chartData) {
                exData.dq_chart_points1 = chartData.dq_chart_points;
            } else {
                exData.drift1 = chartData.drift;
            }
            compareChart = true;
        }
        this.setState({ data: exData, compareChart: compareChart });
    }

    setSelectedFilterChartData(res, key, modelTitle, chartTitle, dataSetId, filters) {
        let chartData = this.getChartDataFromResult(res, key, modelTitle, chartTitle);
        let compareAttributeOptions = [{"label":"", "value":""}];
        let compareMLAttributeOptions = [{"label":"", "value":""}];
        let compareModelOptions = [{"label":"", "value":""}];
        if (key !== "mlModelPerformance") {
            let selectedMlModel = this.props.selectedMLModel;
            let selectedSource = this.props.selectedIntegration;

            let metrics = res["metrics"];
            modelTitle = modelTitle.replace('_',' ');
            for(let i = 0; i < metrics.length; i++) {
                let row = metrics[i];
                if (row.name === modelTitle && row.data !=null) {
                    for(let chart of row.data) {
                        if (selectedMlModel !== undefined) {
                            chart["selectedMlModel"] = selectedMlModel;
                        }

                        let chartName = getChartTitleBasedOnData(chart, selectedSource);
                        let type = "dataSet";
                        if (chart.model_name !== undefined && chart.attribute_name !== undefined){
                            type = "modelAttribute";
                        } else if(chart.model_name !== undefined && chart.attribute_name === undefined)
                        {
                            type = "modalDataSet";

                        } else if (chart.attribute_name !== undefined && chart.attribute_name !== null) {
                            type = "attribute";
                        }

                        if (chartName === chartTitle) {
                            chartData = chart;
                        } else if(type === "attribute" &&  chartTitle.includes([chart.name.replace(" ", '_'),chart.data_set_name.replace(".", '_')].join("_"))){
                            let dataSetName = chartTitle.split(CHART_TITLE_SEPARATOR)[1];
                            dataSetName = dataSetName.split(CHART_TITLE_SEPARATOR)[0];
                            if (chartTitle.split(CHART_TITLE_SEPARATOR)[0] === chartName.split(CHART_TITLE_SEPARATOR)[0] &&
                                chartTitle.split(CHART_TITLE_METRIC_SEPARATOR)[1] === chartName.split(CHART_TITLE_METRIC_SEPARATOR)[1] &&
                                chart.data_set_name === dataSetName)
                                {
                                    compareAttributeOptions.push({
                                        "label": chart.attribute_name,
                                        "value": chart.name, "data": chart
                                    });
                                 }
                        } else if(type === "modelAttribute"){
                            let dataSetName = chartTitle.split(CHART_TITLE_SEPARATOR)[1];
                            dataSetName = dataSetName.split(CHART_TITLE_SEPARATOR)[0];
                            if (chartTitle.split(CHART_TITLE_SEPARATOR)[0] === chartName.split(CHART_TITLE_SEPARATOR)[0] &&
                                chartTitle.split(CHART_TITLE_METRIC_SEPARATOR)[1] === chartName.split(CHART_TITLE_METRIC_SEPARATOR)[1] &&
                                chart.data_set_name === dataSetName)
                                {
                                    compareMLAttributeOptions.push({
                                        "label": chart.attribute_name,
                                        "value": chart.name, "data": chart
                                    });
                                }
                        }  else if(type === "modalDataSet"){
                            if (chartTitle.split(CHART_TITLE_SEPARATOR)[0] === chartName.split(CHART_TITLE_SEPARATOR)[0] && chartTitle.split(CHART_TITLE_METRIC_SEPARATOR)[1] === chartName.split(CHART_TITLE_METRIC_SEPARATOR)[1])
                            {
                                compareModelOptions.push({
                                    "label": chart.data_set_name,
                                    "value": chart.name, "data": chart
                                });
                            }
                        }
                    }
                    break;
                }
            }
            if (chartData !== null) {
                updateChartDataErrorType(chartData);
            }
        }

        let startDate = this.state.startDate;
        let endDate = this.state.endDate;
        let distance = this.state.distance;
        if (filters !== null) {
            Object.keys(filters).forEach(function(key) {
                if (key === "startDate") {
                    startDate =  filters[key];
                } else if (key === "endDate") {
                    endDate = filters[key];
                } else if(key === "distance") {
                    distance = filters[key];
                }
            });
        }

        let compareList = this.state.compareAttributeOptions;
        let compareMlDataset = this.state.compareModelOptions;
        let compareMlAttributes = this.state.compareMLAttributeOptions;
        if (compareAttributeOptions.length > 1) {
            compareList = compareAttributeOptions;
        }
        if( compareModelOptions.length > 1) {
            compareMlDataset = compareModelOptions;
        }

        if(compareMLAttributeOptions.length > 1) {
            compareMlAttributes = compareMLAttributeOptions;
        }

        this.setState({ data: chartData,
            startDate: startDate,
            endDate: endDate,
            distance: distance,
            compareChart: null,
            compareModelOptions: compareMlDataset,
            compareMLAttributeOptions: compareMlAttributes,
            compareAttributeOptions: compareList,
            loadValue: false
        });
    }

    chartTimeFilter(startDate, endDate, stateKey, title) {
        this.setTimeFilter(this.state.modalData["dataSetId"], stateKey, title,
            startDate.format('YYYY-MM-DD 00:00:00'), endDate.format('YYYY-MM-DD 23:59:59'));
    }

    showCompare() {
        this.setState({showCompare:true, backdrop:"show"});
    }

    setAttrCompare(event) {
        this.closeCompare();
        let compareChart = null;
        let exData  = {...this.state.data};
        const eventData = event.data;
        if(eventData !== null) {
            if ("dq_chart_points" in eventData) {
                exData.dq_chart_points1 = eventData.dq_chart_points;
            } else {
                exData.drift1 = eventData.drift;
            }

            exData.compareLabel = eventData.attribute_name;
            compareChart = true;
        }

        this.setState({ data: exData, compareChart: compareChart});
    }

    setAggregate(event) {
        let chartData = this.props.aggregateOptions.filter(function (d) { return d.value === event});
        let Data  = null;
        if (chartData.length > 0) {
            Data = chartData[0].data;
        }
        this.setState({data:Data});
    }

    setMlCompare(event) {
        this.closeCompare();
        let compareChart = null;
        let exData  = {...this.state.data};
        if(event.data !== null) {
            exData.drift1 = event.data.drift;
            compareChart = true;
        }
        this.setState({ data: exData, compareChart: compareChart });
    }

    setMlAttributeCompare(event) {
        this.closeCompare();
        let compareChart = null;
        let exData  = {...this.state.data};
        if(event.data !== null) {
            exData.drift1 = event.data.drift;
            compareChart = true;
        }
        this.setState({ data: exData, compareChart: compareChart});
    }

    closeCompare() {
        this.setState({showCompare: false, backdrop:""});
    }

    setCompare(event) {
        this.closeCompare();
        let compareChart = null;
        let exData  = {...this.state.data};
        const eventData = event.data;
        if(exData !== undefined && Object.keys(exData).length > 0 && eventData !== null) {
            if ("dq_chart_points" in eventData) {
                exData.dq_chart_points1 = eventData.dq_chart_points;
            } else {
                exData.drift1 = eventData.drift;
            }

            exData.compareLabel = eventData.datasetName;
            compareChart = true;
        } else {
            exData = null;
        }

        this.setState({ data: exData, compareChart: compareChart});

        // For showing compare charts, we will make the chart type as area.
        // Currently we are showing compare charts as area chart only
        let compareChartType = "areaChart";
        compareChartType = convertChartTypeToError(compareChartType, this.state.errorChart);
        this.setState({chartType: compareChartType});
    }

    setChartFilter(event) {
        this.setState({chartType: event});
    }

    renderChart() {
        if (this.state.loadValue === true) {
            return (<BasicPortlet
                className="mb-0 pb-0" title={this.state.title} bodyClassName="pb-0"
                content={<Load/>}
            />);
        }

        let colorType = "success";
        if (this.props.data !== undefined &&
            this.props.data !== null &&
            this.props.data.hasDrift !==undefined &&
            this.props.data.hasDrift) {
            colorType="error";
        }

        // For multiline charts that are used in Model Performance - Compare View,
        // we will use colorType from props
        if (this.props.variant === "model_perf_comp_view") {
            colorType = this.props.colorCode;
        }
        const stateData = this.state.data;
        let chartHasValidPoints = (!IS_NONE_CHECK.includes(stateData) && stateData.time && stateData.time.length > 0);

        chartHasValidPoints = chartHasValidPoints || this.props.chartType === chartTypes.CONFUSION_MATRIX || this.props.chartType ===  chartTypes.GROUPED_HORIZONTAL_BAR_CHART || this.props.chartType === chartTypes.HORIZONTAL_COMPARE_CHART
        if (stateData !== null && stateData !== undefined && stateData.colorType !== undefined && stateData.colorType !== null){
            colorType = stateData.colorType;
        }

        switch (this.state.settings) {
            case "timeSeries":
                if (chartHasValidPoints) {
                    let chartId = this.state.chartID;
                    return (
                        <div key={this.state.settings}>
                            <ChartBody
                                colorCode= {this.props.colorCode}
                                lineColorCode= {this.props.lineColorCode}
                                scale={this.props.scale}
                                yValue={this.props.yValue}
                                xValue={this.props.xValue}
                                isError={this.props.isError}
                                toolTipTitle={this.props.toolTipTitle}
                                label={this.props.label}
                                compareChart={this.state.compareChart}
                                id={chartId + this.props.chartLevel}
                                classValue={"h-100"}
                                chartType={this.state.chartType}
                                style={{height: "250px"}}
                                chart_data={this.state.data}
                                colorType={colorType}
                            />

                        </div>
                    );
                } else {
                    return (
                        this.props.errorNotAvailable ?
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Error found in the selected metric</h4>
                        </div>
                        :
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Data found in the selected Time period</h4>
                        </div>
                    );
                }
            case "heatMap":
                if (this.state.data) {
                    let chartId = this.state.chartID;
                    return (
                        <div key={this.state.settings}>
                            <ChartBody
                                colorCode= {this.props.colorCode}
                                lineColorCode= {this.props.lineColorCode}
                                scale={this.props.scale}
                                yValue={this.props.yValue}
                                xValue={this.props.xValue}
                                isError={this.props.isError}
                                toolTipTitle={this.props.toolTipTitle}
                                label={this.props.label}
                                id={chartId + this.props.chartLevel}
                                chartType={"heatMap"}
                                style={{height: "250px"}}
                                chart_data={this.state.data}
                                colorType={colorType}
                            />

                        </div>
                    );
                } else {
                    return (
                        this.props.errorNotAvailable ?
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Error found in the selected metric</h4>
                        </div>
                        :
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Data found in the selected Time period</h4>
                        </div>
                    );
                }
            case "contribution":
                let data = [];
                if (this.state.data !== null && this.state.data !== "undefined") {
                    data = chartFilterTable(this.state.data);
                    let metricName;
                    if (this.state.data !== null) {
                        metricName = this.state.data.name;
                    }
                    let showDataTypeDifferences = (metricName === DATA_TYPE_CONSISTENCY ||
                        metricName === ML_DATA_CONSISTENCY);

                    let errorRowHighlighter = undefined;

                    if (showDataTypeDifferences) {
                        // Error row highlighter for data type consistency
                        errorRowHighlighter = (row, rowIndex) => {
                            if (row === undefined || row === null) {
                                return "";
                            }

                            const referenceColumnHeader = REFERENCE_TYPE_COLUMN_NAME[metricName];
                            if (row["Data type"] !== row[referenceColumnHeader]) {
                                return ROW_ERROR_CLASS;
                            }

                            return "";
                        };
                    }

                    return(
                       <BootstrapTable
                            data={data}
                            notShow={true}
                            showToast={this.showToast}
                            environment={this.state.environment}
                            customRowHighlighter={errorRowHighlighter}
                            component_name="contribution"
                       />
                    );


                } else {
                    return (
                        this.props.errorNotAvailable ?
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Error found in the selected metric</h4>
                        </div>
                        :
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Data found in the selected Time period</h4>
                        </div>
                    );
                }
            default:
                let chartId = this.state.chartID;

                if (this.state.data && this.state.chartType === SINGLE_ARROW_CHART_TYPE) {
                    return (<SingleArrowChart
                        id={chartId + this.props.chartLevel}
                        key={chartId + this.props.chartLevel}
                        data={this.state.data}/>)
                }
                let showViewAllButton = false;
                if((this.state.chartType === chartTypes.MULTILINE_FEATURE_CHART && this.state.expand === false) ||
                    (this.state.chartType === chartTypes.GROUPED_HORIZONTAL_BAR_CHART && this.state.expand === false) ||
                    (this.state.chartType === chartTypes.HORIZONTAL_BAR_CHART && this.state.expand === false && this.state.data !==null &&
                     this.state.data.chartDataPoints !== undefined && this.state.data.chartDataPoints.length > 0 && this.state.data.chartDataPoints[0].length > 8)){
                    showViewAllButton = true;
                }
                if (chartHasValidPoints && this.state.data ) {
                if (this.state.data.is_all_string_column !== undefined && this.state.data.is_all_string_column.length > 0 && !this.state.data.is_all_string_column.includes(false)){
                        return (
                            <div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                                <h4>Data Drift is not applicable since the dataset has no numeric attribute</h4>
                            </div>
                        );
                    }
                    else{
                    return (
                        <>
                            <ChartBody
                                colorCode= {this.props.colorCode}
                                lineColorCode= {this.props.lineColorCode}
                                scale={this.props.scale}
                                yValue={this.props.yValue}
                                y1Value={this.props.y1Value}
                                y2Value={this.props.y2Value}
                                xValue={this.props.xValue}
                                isError={this.props.isError}
                                toolTipTitle={this.props.toolTipTitle}
                                label={this.props.label}
                                id={chartId + this.props.chartLevel}
                                compareChart={this.state.compareChart}
                                chartType={this.state.chartType}
                                style={{height: "250px"}}
                                classValue={"h-100"}
                                chart_data={this.state.data}
                                expand={this.state.expand}
                                colorType={colorType}
                                isNonTimeSeries={this.state.data.isNonTimeSeries}
                            />
                            {
                                (showViewAllButton === true) &&
                                <div className="qd-chartWidget_overflowY-ctrl" onClick={this.expandChart}>
                                  <button className="btn btn-light btn-circle">
                                    <svg className="expand-arrows" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                      width="20" height="20">
                                      <g fill="currentColor">
                                        <path d="M21 7v4h1V6h-5v1z"></path>
                                        <path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"></path>
                                        <path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"></path>
                                        <path d="M16.854 16.146l5 5-.708.708-5-5z"></path>
                                        <g>
                                          <path d="M7 21v-4H6v5h5v-1z"></path>
                                          <path d="M11.146 16.146l-5 5 .708.708 5-5z"></path>
                                        </g>
                                      </g>
                                    </svg>View all
                                  </button>
                                </div>
                            }
                        </>
                    );
                    }
                } else {
                    return (
                        this.props.errorNotAvailable ?
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Error found in the selected metric</h4>
                        </div>
                        :
                        <div className="text-center text-muted py-5">
                            <h1><i><FontAwesomeIcon icon={faChartArea}/></i></h1>
                            <h4>No Data found in the selected Time period</h4>
                        </div>
                    );
                }
        }
    }

    settingFilter(event, dataSetId, key, modelTitle, chartTitle) {
        this.setState({settings: event})
    }

    renderCompareOptions(){
        if(this.state.compareType === "Dataset") {
            return <><label >Datasets:</label>
                <Select
                    name="data_sets"
                    filterOption={({label}, query) => label.includes(query)}
                    onChange={this.setCompare}
                    id="compareWithDatasets"
                    options={this.props.compareDataSrc}
                    value={this.state.CompareSrc}
                    placeholder="Choose dataset"
                    classNamePrefix='select-control'
                /></>
        } else if(this.state.compareType === "Attribute") {
            return <>
                <label >Attributes:</label>
                <Select
                    name="attributes"
                    filterOption={({label}, query) => label.includes(query)}
                    id="compareWithAttributes"
                    onChange={this.setAttrCompare}
                    options={this.state.compareAttributeOptions}
                    placeholder="Choose attribute"
                    classNamePrefix='select-control'
                />
            </>
        } else if(this.state.compareType === "modelDataSet") {
            return <>
                <label >Models:</label>
                <Select
                    name="mlModelsDataSet"
                    filterOption={({label}, query) => label.includes(query)}
                    onChange={this.setMlCompare}
                    id="compareWithModels"
                    options={this.state.compareModelOptions}
                    placeholder="Choose ML-Model"
                    classNamePrefix='select-control'
                />
            </>

        } else if(this.state.compareType === "modelAttribute") {
            return <>
                <label >Attribute:</label>
                <Select
                    name="mlModelsAttribute"
                    filterOption={({label}, query) => label.includes(query)}
                    id="compareWithModelAttributes"
                    onChange={this.setMlAttributeCompare}
                    options={this.state.compareMLAttributeOptions}
                    placeholder="Choose Attribute"
                    classNamePrefix='select-control'
                />
            </>

        }
    }

    fillAggregateDropDown(item, key){
        return(
            <Dropdown.Item
                key={key}
                className="qd-cw_dp-item"
                eventKey={item.value}
                onSelect={this.changeSource}>
                {item.label}
            </Dropdown.Item>
        );
    }

    monitorFillAggregateDropDown(item, key){
        return(
            <Dropdown.Item
                key={key}
                className="qd-cw_dp-item"
                eventKey={item.value} >
                {item.label}
            </Dropdown.Item>
        );
    }

    changeSource(event, obj)
    {
        this.props.changeSource(event, this.props.id, this.props.title,this.state.expand)
        this.props.handleLoad(true);
    }

    getCompareHTML(showCompareInHeader) {
        if (showCompareInHeader && this.state.data !== null) {
            return(
                <div className="qd-cw-col">
                    <div className="qd-cw-btn"
                         qd-cw-modal-open="qd-cw__compare"
                         onClick={this.showCompare}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                             width="28" height="28">
                            <path fill="currentColor"
                                  d="M13.5 6a8.5 8.5 0 1 0 0 17 8.5 8.5 0 0 0 0-17zM4 14.5a9.5 9.5 0 1 1 19 0 9.5 9.5 0 0 1-19 0z">
                            </path>
                            <path fill="currentColor"
                                  d="M9 14h4v-4h1v4h4v1h-4v4h-1v-4H9v-1z"/>
                        </svg>
                        <div className="qd-cw-btn-text">Compare</div>
                    </div>
                </div>
            );
        }
        return '';
    }

    componentDidMount() {
        let currentHeaderRef = this.headerRef.current;
        let currentFooterRef = this.footerRef.current;
        if (currentHeaderRef !== undefined && currentHeaderRef !== null) {
            this.setState({height1: currentHeaderRef.offsetHeight});
        }
        if (currentFooterRef !== undefined && currentFooterRef !== null) {
            this.setState({height2: currentFooterRef.offsetHeight});
        }
    }

    footerRef = (ref) => {
      this.footerRef.current = ref;
    }

    componentDidUpdate(prevProps) {
        if (this.state.expand === true) {
            let expandChartId = this.state.chartID+this.props.chartLevel;
            let x = document.getElementById(expandChartId);
            if(x!=null){
                if(SEE_FULL_VIEW_ICONS.includes(this.props.chartType)){
                         this.renderChart()
                    }
                    else{
                        let ele = x.querySelectorAll("svg");
                        if(ele!=null){
                            if(ele.length  > 0){
                            let viewboxsize = "0,0,1506,500"
                            setTimeout(function(){
                                 x = document.getElementById(expandChartId);
                                 ele = x.querySelectorAll("svg");
                                 let res = viewboxsize.split(",");
                                 ele[0].setAttribute("viewBox",viewboxsize)
                                 viewboxsize = ele[0].getAttribute("viewBox")
                                  ele[0].style.height = "100%";
                                  ele[0].setAttribute("width",res[2])
                            }, 100);
                        } else {
                        }
                     }
                }
            }
        }


    }

    static getDerivedStateFromProps(props, state) {
        if (props !== null && props !== undefined) {
            if (props.page === "attribute" && props.data !== state.data) {
                return {data : props.data};
            }
        }
        return null;
     }

    render() {
        let metricsOptions = [];
        let attributeProfileOptions = [];
        let page = "monitor";
        let widgetName = this.props.widgetClass ? this.props.widgetClass : "qd-chartWidget";
        if (this.props.metricsOptions !== null && this.props.metricsOptions !== undefined) {
            metricsOptions = this.props.metricsOptions;
        }
        if (this.props.attributeProfileOptions !== null && this.props.attributeProfileOptions !== undefined) {
            attributeProfileOptions = this.props.attributeProfileOptions;
            page = "attribute";
            widgetName = "qd-chartWidget m-0 border-0";
        }


        let isModelPerformance = false;
        let isCompareChart = false;
        let isBoxplot = false;
        let isMultiBar = false;
        let isMultiLine = false;
        if (this.props.data !== null && this.props.data !== undefined) {
            isModelPerformance = this.props.data["isModelPerformance"];
            isCompareChart = this.props.data["multipleLines"];
            isBoxplot = this.props.data["boxPlotData"];
            isMultiBar = this.props.data["isMultiBar"];
            isMultiLine = this.props.data["isMultiLine"];
            if (isBoxplot !== undefined) {
                let quantile = isBoxplot.statistics[0].quantile;
                if (quantile.length > 0 && (!quantile.includes("NA"))) {
                    isBoxplot = true;
                }
            }
        }

        if (attributeProfileOptions === undefined || attributeProfileOptions === null){
            attributeProfileOptions = [];
        }

        let attributeMonitorPage = [];
        if (this.props.aggregateOptions !== undefined && this.props.aggregateOptions !== null) {
            attributeMonitorPage = this.props.aggregateOptions;
        }

        if (attributeMonitorPage === undefined || attributeMonitorPage === null) {
            attributeMonitorPage = [];        }

        let variant_list_view = null;
        if(this.props.variant !== undefined){
         variant_list_view = this.props.variant.substring(0,28);  }

        let containerName = "Datasource:";
        let canShowMetrics = true;
        let metricLabel = "Metric with issue:";
        const propsData = this.state.data;
        let attributeName = this.props.attributeName;
        if (this.props.data !== null && (this.props.variant === "mlModelPerformance"  || this.props.data["isModelPerformance"])){
            containerName = "Model:"
            canShowMetrics = false;
            metricLabel = "Metric:";
            attributeName = undefined;
            if(this.props.data["name"] === "mean_shap_value"){
                attributeName = this.props.data["attribute_name"]
            }
        } else if (this.props.variant === "mlModel" || variant_list_view === "listViewFullScreenModelError") {
            containerName = "Model:"
        } else if(this.props.dqpGrid !== undefined && this.props.dqpGrid === true){
            metricLabel = "Metric:";
            canShowMetrics = false;
        }

        let ErrorPercentage;
        let LastErrorPercentage;
        let dataSource;
        let MetricName;
        let Drift ="No Error";
        let multipleLines = this.props.multipleLines;


        if (propsData !== undefined && propsData !== null) {
            ErrorPercentage = propsData["error_percentage"];
            LastErrorPercentage = propsData["last_error"];
            dataSource = propsData["data_source_name"];
            MetricName = propsData["name"];
            Drift = propsData["type"];
        }else if(this.props.data) {
            ErrorPercentage = null;
            LastErrorPercentage = null;
            dataSource = this.props.data["data_source_name"];
            MetricName = this.props.data["name"];
            Drift = null;
            canShowMetrics = false;
        }

        if (Drift === "success") {
            Drift = "NO_DRIFT";

        }

        if (this.props.page === "attribute" || (this.props.dqpGrid !== undefined && this.props.dqpGrid === true) ||
           this.props.variant === "mlModelPerformanceListView"  || this.props.variant === "mlModelPerformanceListView") {
            Drift = undefined;
        }

        let expandIconClass = "qd-cw-btn qd-cw-btn_fullscreen";
        let pageHeight =  'auto';

        let e = window;
        let a = 'inner';
        if (!('innerWidth' in window)) {
            a = 'client';
            e = document.documentElement || document.body;
        }

//        var height = App.getViewPort().height - portlet.children('.qd-chartWidget_top').outerHeight() - portlet.children('.qd-chartWidget_bottom').outerHeight() - 2;

        if (this.state.expand === true) {
            expandIconClass = "qd-cw-btn qd-cw-btn_fullscreen on";
            widgetName = widgetName + " chartWidget-fullscreen";
            document.body.classList.add("page-chartWidget-fullscreen");
            pageHeight =  e[a + 'Height'];
            let h1 = this.state.height1;
            let h2= this.state.height2;
            pageHeight = pageHeight - h1- h2 -2;
            // let expandChartId = this.state.chartID+this.props.chartLevel;
            // let x = document.getElementById(expandChartId);
            //  if(x!=null){
            //     let ele = x.querySelectorAll("svg");
            //     ele[0].style.height = "auto"; 
            //  }
        } else {
            document.body.classList.remove("page-chartWidget-fullscreen");
        }

        let showCompareInHeader = true;
        if (this.props.compareType === "Dataset") {
            showCompareInHeader = this.props.compareDataSrc.length > 0;
        } else if (this.props.compareType === "Attribute") {
            showCompareInHeader = this.props.compareAttributeOptions.length > 0;
        } else if (this.props.compareType === "modelDataSet") {
            showCompareInHeader = this.props.compareModelOptions.length > 0;
        } else if (this.props.compareType === "modelAttribute") {
            showCompareInHeader = this.props.compareMLAttributeOptions.length > 0;
        } else if (this.props.chartType === chartTypes.CONFUSION_MATRIX) {
            showCompareInHeader = false;
        }
        if (MetricName === "Recency") {
            canShowMetrics = false;
        }

        if (this.state.startDate !== null && this.state.endDate !== null) {
            start = this.state.startDate;
            end = this.state.endDate;
        }

        let chart_body_class_name =  this.state.chartType === "boxPlotZoomable"  ? "qd-chartWidget_body boxplot" : "qd-chartWidget_body";
        let page_height;
        if(SEE_FULL_VIEW_ICONS.includes(this.props.chartType)){
            chart_body_class_name = "qd-chartWidget_body position-relative overflow-hidden"
            page_height = "250px";
            if (this.state.expand === true){
                page_height = pageHeight
                chart_body_class_name = "qd-chartWidget_body position-relative overflow-auto"
            }
        }
        const doNotShowChartDropdowns = (this.props.chartType === chartTypes.CONFUSION_MATRIX ||
             this.props.hideChartOptions === true || this.state.settings === "contribution" ||
            this.props.chartType === chartTypes.MULTILINE_FEATURE_CHART || this.props.chartType === chartTypes.HORIZONTAL_BAR_CHART || this.props.chartType === chartTypes.GROUPED_HORIZONTAL_BAR_CHART || this.props.chartType === chartTypes.HORIZONTAL_COMPARE_CHART);
            
            let datasetName = this.props.dataSetName
            if(this.state.data !== undefined && this.state.data !== null){
                datasetName = this.state.data.datasetName
            }

            return (
            <>
                <div className={widgetName}>
                     <div className={'qd-chartWidget_backdrop fade ' + this.state.backdrop}/>
                     <div className="qd-cw-modal fade show"
                          style={{display: this.state.showCompare ? 'block' : 'none' }}>
                          <div className="qd-cw-modal_dialog modal_dialog-lg" role="document">
                            <div className="qd-cw-modal_content">
                              <div className="modal-header">
                                <h5 className="modal-title">Compare</h5>
                                <button type="button" className="close close-sm"
                                        onClick={this.closeCompare}/>
                              </div>
                               <div className="modal-body text-left">
                                {
                                    this.renderCompareOptions()
                                }
                              </div>
                            </div>
                          </div>
                    </div>
                    <div className="qd-cw-modal fade show" style={{display: this.state.showSaveImage ? 'block' : 'none' }}>
                      <div className="qd-cw-modal_dialog" role="document">
                        <div className="qd-cw-modal_content">
                          <div className="modal-header">
                            <h5 className="modal-title">{this.state.chartTitle.replace(" ", "_")}</h5>
                            <button type="button" className="close close-sm" onClick={this.setShowSaveImage}/>
                          </div>
                          <div className="modal-body text-center">
                            <Image src="timeSeries" alt="" styles={{width: "150px", height: "auto"}}/>
                          </div>
                          <div className="modal-footer justify-content-center border-top-0 pt-0">
                            <button type="button" className="btn btn-link" onClick={this.saveChartScreenShot}>
                              <i>
                                <FontAwesomeIcon icon={faDownload}/>
                               </i> Save Image
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div ref={this.headerRef}
                        hidden={this.props.hideTopMenuOptions}
                         className="qd-chartWidget_top">
                        <div className="qd-cw-content">
                            <div className="qd-cw-wrap">
                                <div className="qd-cw-left">
                                    <div className="qd-cw-col">
                                        {this.props.hideDateFilter ? '' :
                                        <DateRangePicker style={{display: 'block'}}
                                                         startDate={start}
                                                         onApply={this.handleEvent}
                                                         endDate={end}
                                                         ranges={ranges}>
                                            <div className="qd-cw-btn reportrange">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.2 20.2">
                                                    <g id="Group_3" data-name="Group 3" transform="translate(-3.9 -3.9)">
                                                        <g id="Group_2" data-name="Group 2">
                                                            <g id="Group_1" data-name="Group 1">
                                                                <path id="Path_1" data-name="Path 1"
                                                                      d="M19.417,24.1a4.671,4.671,0,0,1-3.61-1.7l-10.14.036A1.768,1.768,0,0,1,3.9,20.667V7.334A1.769,1.769,0,0,1,5.667,5.567H8.9V4.417a.517.517,0,1,1,1.033,0v1.15h7.3V4.417a.517.517,0,0,1,1.034,0v1.15h2.4a1.769,1.769,0,0,1,1.766,1.767v8.55A4.485,4.485,0,0,1,24.1,19.417,4.689,4.689,0,0,1,19.417,24.1Zm0-8.333a3.651,3.651,0,1,0,3.65,3.65A3.654,3.654,0,0,0,19.417,15.767Zm-14.483,4.9a.734.734,0,0,0,.733.734h9.512a4.622,4.622,0,0,1-.445-1.984,4.689,4.689,0,0,1,4.683-4.683,4.627,4.627,0,0,1,1.983.445V10.767H4.934ZM21.4,9.734v-2.4a.734.734,0,0,0-.733-.734h-2.4V8.167a.934.934,0,1,1-1.034-.928V6.6h-7.3V8.167A.933.933,0,1,1,8.9,7.239V6.6H5.667a.735.735,0,0,0-.734.734v2.4Z" />
                                                                <path id="Path_2" data-name="Path 2"
                                                                      d="M18.584,21.6a.51.51,0,0,1-.365-.152l-1.25-1.25a.508.508,0,0,1-.153-.366.517.517,0,0,1,.153-.364.514.514,0,0,1,.729,0l.885.884L21.135,17.8a.518.518,0,0,1,.883.366.513.513,0,0,1-.153.364l-2.917,2.916A.508.508,0,0,1,18.584,21.6Z" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <div className="qd-cw-btn-text"> Time</div>
                                            </div>
                                        </DateRangePicker>
                                        }

                                    </div>
                                    {doNotShowChartDropdowns ?
                                        '' :
                                        <div className="qd-cw-col">
                                            <Dropdown className="qd-cw_dropdown-wrap"
                                                      onSelect={evt => {
                                                          this.setChartFilter(evt)
                                                      }}>
                                                <Dropdown.Toggle
                                                    panelchartid={this.state.chartID}  
                                                    chartType={this.state.chartType} 
                                                    as={chartToggle}>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="qd-cw_dropdown">
                                                    {
                                                        multipleLines !== true?
                                                        <Dropdown.Item className="qd-cw_dp-item"
                                                                       eventKey={this.state.errorChart ? "barWithError": "barChart"}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                                                 width="28" height="28">
                                                                <g fill="none" stroke="currentColor"
                                                                   strokeLinecap="square">
                                                                    <path
                                                                        d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20">
                                                                    </path>
                                                                </g>
                                                            </svg>
                                                            Bar
                                                        </Dropdown.Item>
                                                        : ""
                                                    }
                                                    {
                                                        isMultiLine !== true?
                                                        <Dropdown.Item className="qd-cw_dp-item"
                                                                    eventKey={this.state.errorChart ? "areaWithError": "areaChart"}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                                                width="28" height="28"
                                                                fill="currentColor">
                                                                <path
                                                                    d="M12.5 17.207L18.707 11h2l3.647-3.646-.708-.708L20.293 10h-2L12.5 15.793l-3-3-4.854 4.853.708.708L9.5 14.207z">
                                                                </path>
                                                                <path
                                                                    d="M9 16h1v1H9zm1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1H9zm2 0h1v1h-1zm-3-3h1v1H8zm-1 1h1v1H7zm-1 1h1v1H6zm2 0h1v1H8zm-1 1h1v1H7zm-2 0h1v1H5zm17-9h1v1h-1zm1-1h1v1h-1zm0 2h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-5-7h1v1h-1zm2 0h1v1h-1zm1-1h1v1h-1zm-2 2h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-2-6h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-3-3h1v1h-1zm-1 1h1v1h-1zm-1 1h1v1h-1zm2 0h1v1h-1zm-1 1h1v1h-1z">
                                                                </path>
                                                            </svg>
                                                            Area
                                                        </Dropdown.Item>
                                                        : <Dropdown.Item className="qd-cw_dp-item"
                                                                    eventKey="multiLineChart">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28"><path fill="currentColor" d="M11.982 16.689L17.192 12h3.033l4.149-4.668-.748-.664L19.776 11h-2.968l-4.79 4.311L9 12.293l-4.354 4.353.708.708L9 13.707z">
                                                                </path>
                                                                </svg>
                                                            Line
                                                        </Dropdown.Item>
                                                    }
                                                    {
                                                        isMultiBar === true?
                                                        <Dropdown.Item className="qd-cw_dp-item"
                                                                    eventKey="groupedVerticalBarChart">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                                                width="28" height="28">
                                                                <g fill="none" stroke="currentColor"
                                                                   strokeLinecap="square">
                                                                <path
                                                                    d="M10.5 7.5v15M7.5 20.5H10M13.5 11.5H11M19.5 6.5v15M16.5 9.5H19M22.5 16.5H20">
                                                                </path>
                                                                </g>
                                                            </svg>
                                                            Bar
                                                        </Dropdown.Item>
                                                        : ""
                                                    }
                                                    {/*
                                                    <Dropdown.Item className="qd-cw_dp-item" eventKey="bumpChart">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                                             width="28" height="28"
                                                             fill="currentColor">
                                                            <path
                                                                d="M14.5 16a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm0 7a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5zm0-1a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm3.293-15.5l4.707 4.707.707-.707L18.5 4.793z">
                                                            </path>
                                                            <path
                                                                d="M18.5 10.207L23.207 5.5l-.707-.707L17.793 9.5zm-.707 1.293l4.707 4.707.707-.707-4.707-4.707z">
                                                            </path>
                                                            <path
                                                                d="M18.5 16.207l4.707-4.707-.707-.707-4.707 4.707zM5.793 17.5l4.707 4.707.707-.707L6.5 16.793z">
                                                            </path>
                                                            <path
                                                                d="M6.5 22.207l4.707-4.707-.707-.707L5.793 21.5zM5.793 5.5l4.707 4.707.707-.707L6.5 4.793z">
                                                            </path>
                                                            <path
                                                                d="M6.5 10.207L11.207 5.5l-.707-.707L5.793 9.5zM5.793 11.5l4.707 4.707.707-.707L6.5 10.793z">
                                                            </path>
                                                            <path
                                                                d="M6.5 16.207l4.707-4.707-.707-.707L5.793 15.5z"/>
                                                        </svg>
                                                        Bump
                                                    </Dropdown.Item> */}
                                                    {
                                                        isBoxplot === true?
                                                        <Dropdown.Item className="qd-cw_dp-item" eventKey="boxPlotZoomable">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                                                 width="28" height="28">
                                                                <g fill="none" stroke="currentColor">
                                                                    <path strokeDasharray="1,1" d="M4 14.5h22"/>
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M7.5 12.5l2-4 1 2 2-4 3 6"/>
                                                                    <path strokeLinecap="round" d="M5.5 16.5l-1 2"></path>
                                                                    <path strokeLinecap="round" strokeLinejoin="round"
                                                                          d="M17.5 16.5l2 4 2-4m2-4l1-2-1 2z"></path>
                                                                </g>
                                                            </svg>
                                                            BoxPlot
                                                        </Dropdown.Item> : ""
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    }
                                    {
                                        page === "attribute" && attributeProfileOptions.length > 0 ?
                                        <div className="qd-cw-col">
                                            <Dropdown className="qd-cw_dropdown-wrap">
                                                <div className="qd-cw-btn">
                                                <Dropdown.Toggle as ={AggregateTogle}>
                                                    {
                                                        (this.props.showAggregateError === true)?
                                                        <i className="text-danger ml-1">
                                                            <FontAwesomeIcon icon={faExclamationCircle}/></i>:""
                                                    }
                                                </Dropdown.Toggle>
                                                </div>
                                                <Dropdown.Menu className="qd-cw_dropdown">
                                                    {
                                                        attributeProfileOptions.map((item, key) =>
                                                            this.fillAggregateDropDown(item, key)
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> : ""
                                    }

                                    {/* ==== Fix for 1436 issue ====
                                        (page === "monitor" &&
                                            this.props.chartLevel !== "Dataset"  &&
                                            this.props.variant === "drift" &&
                                            attributeMonitorPage.length > 0 && this.state.chartType !== "boxPlotZoomable" )?
                                        <div className="qd-cw-col">
                                            <Dropdown className="qd-cw_dropdown-wrap"  onSelect={evt => {
                                                          this.setAggregate(evt)
                                                      }}>
                                                <div className="qd-cw-btn">
                                                <Dropdown.Toggle as ={AggregateTogle}>
                                                    {
                                                    (this.props.showAggregateError === true)?
                                                    <i className="text-danger ml-1">
                                                        <FontAwesomeIcon icon={faExclamationCircle}/>
                                                    </i>: ""
                                                    }
                                                </Dropdown.Toggle>
                                                </div>
                                                <Dropdown.Menu className="qd-cw_dropdown">
                                                    {
                                                        attributeMonitorPage.map((item, key) =>
                                                            this.monitorFillAggregateDropDown(item, key)
                                                        )
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        : ""
                                    */}

                                    {this.props.isAttribute === true ?
                                        <div className="qd-cw-col">
                                            <Dropdown className="qd-cw_dropdown-wrap">
                                                <div className="qd-cw-btn">
                                                <Dropdown.Toggle as ={metricsTogle}>
                                                {
                                                    (this.props.showMetricError === true)?
                                                    <i className="text-danger ml-1"><FontAwesomeIcon icon={faExclamationCircle}/></i>:""
                                                }
                                                </Dropdown.Toggle>
                                                </div>
                                                <Dropdown.Menu className="qd-cw_dropdown">
                                                    {
                                                        metricsOptions !==null && metricsOptions !==undefined ? metricsOptions.map((item, key) =>
                                                            this.fillAggregateDropDown(item, key)
                                                        )
                                                        : ""
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    :""
                                    }
                                    {
                                      !this.props.hideDistanceFilter === true?
                                        <div className="qd-cw-col">
                                            <Dropdown className="qd-cw_dropdown-wrap"
                                                    onSelect={evt => {
                                                        this.setDistanceFilter(evt, this.state.dataSetId,
                                                            this.props.variant, this.props.title,
                                                            this.state.chartTitle)
                                                    }}>
                                                <Dropdown.Toggle as={distanceToggle}>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="qd-cw_dropdown">
                                                    <Dropdown.Item className="qd-cw_dp-item" eventKey="Eucledian distance">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="time-iocn" transform="translate(-3 -3)">
                                                                <path id="Path_3" data-name="Path 3"
                                                                    d="M7.161,17.343,17.343,7.161l-.514-.514L6.647,16.829Z"
                                                                    transform="translate(-0.995 -0.995)" />
                                                                <path id="Path_4" data-name="Path 4"
                                                                    d="M17.182,5.909a1.091,1.091,0,1,0-1.091-1.091A1.091,1.091,0,0,0,17.182,5.909Zm0,.727A1.818,1.818,0,1,1,19,4.818,1.818,1.818,0,0,1,17.182,6.636ZM4.818,19a1.818,1.818,0,1,1,1.818-1.818A1.818,1.818,0,0,1,4.818,19Z" />
                                                            </g>
                                                        </svg>
                                                        Eucledian distance
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="qd-cw_dp-item" eventKey="Chebyshev distance">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="time-iocn" transform="translate(-3 -3)">
                                                                <path id="Path_3" data-name="Path 3"
                                                                    d="M7.161,17.343,17.343,7.161l-.514-.514L6.647,16.829Z"
                                                                    transform="translate(-0.995 -0.995)" />
                                                                <path id="Path_4" data-name="Path 4"
                                                                    d="M17.182,5.909a1.091,1.091,0,1,0-1.091-1.091A1.091,1.091,0,0,0,17.182,5.909Zm0,.727A1.818,1.818,0,1,1,19,4.818,1.818,1.818,0,0,1,17.182,6.636ZM4.818,19a1.818,1.818,0,1,1,1.818-1.818A1.818,1.818,0,0,1,4.818,19Z" />
                                                            </g>
                                                        </svg>
                                                        Chebyshev distance
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    :""
                                    }
                                    {
                                        (this.state.chartType === "barChart" || this.state.chartType === "barWithError"
                                        || this.state.chartType === "boxPlotZoomable")?
                                        ""
                                        :
                                        (this.state.chartType !== "barChart" && this.state.chartType !== "bumpChart") ?
                                            (this.props.isAttribute === true ||
                                                showMoreVariants.includes(this.props.variant)) ?
                                                ''
                                                :
                                                this.getCompareHTML(showCompareInHeader)
                                            :
                                            ""
                                    }

                                </div>
                                {this.state.showSettingsMenu ?
                                    <div className="qd-cw-col">
                                        <Dropdown className="qd-cw_dropdown-wrap"
                                                  onSelect={evt => {
                                                      this.settingFilter(evt, this.state.dataSetId);
                                                      this.setState({
                                                          compareChart: isCompareChart
                                                      });
                                                  }}>
                                            <Dropdown.Toggle as={settingsToggle}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="qd-cw_dropdown qd-cw_dropdown-right">
                                                <Dropdown.Item className="qd-cw_dp-item"
                                                               eventKey="timeSeries">
                                                    Time Series
                                                </Dropdown.Item>
                                                {this.state.showHeatMap
                                                    ?
                                                    <Dropdown.Item className="qd-cw_dp-item"
                                                                   eventKey="heatMap">
                                                        Heat Map
                                                    </Dropdown.Item>
                                                    :
                                                    ''
                                                }

                                                {
                                                    this.props.showContribution === true && MetricName !== "Row Count"?
                                                        <Dropdown.Item className="qd-cw_dp-item"
                                                                       eventKey="contribution">
                                                            Contribution
                                                        </Dropdown.Item>
                                                        : ''
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    :
                                    ''}

                                <div className="qd-cw-col">
                                    <div className={expandIconClass} onClick={this.expandChart}>
                                        <svg className="expand-arrows" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                                            <g fill="currentColor">
                                            <path d="M21 7v4h1V6h-5v1z"/>
                                            <path d="M16.854 11.854l5-5-.708-.708-5 5zM7 7v4H6V6h5v1z"/>
                                            <path d="M11.146 11.854l-5-5 .708-.708 5 5zM21 21v-4h1v5h-5v-1z"/>
                                            <path d="M16.854 16.146l5 5-.708.708-5-5z"/>
                                            <g>
                                                <path d="M7 21v-4H6v5h5v-1z"/>
                                                <path d="M11.146 16.146l-5 5 .708.708 5-5z"/>
                                            </g>
                                            </g>
                                        </svg>
                                        <svg className="compress-arrows" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"
                                            width="28" height="28">
                                            <g fill="currentColor">
                                            <g>
                                                <polygon
                                                points="21.1,17.1 21.1,16.1 16.1,16.1 16.1,21.1 17.1,21.1 17.1,17.9 21.2,22 21.9,21.3 17.8,17.1 		" />
                                                <polygon
                                                points="21.1,10.9 17.8,10.9 21.9,6.7 21.2,6 17.1,10.1 17.1,6.9 16.1,6.9 16.1,11.9 21.1,11.9 		" />
                                                <polygon
                                                points="10.9,10.1 6.8,6 6.1,6.7 10.2,10.9 6.9,10.9 6.9,11.9 11.9,11.9 11.9,6.9 10.9,6.9 		" />
                                                <polygon
                                                points="6.9,17.1 10.2,17.1 6.1,21.3 6.8,22 10.9,17.9 10.9,21.1 11.9,21.1 11.9,16.1 6.9,16.1 		" />
                                            </g>
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                                {
                                this.props.isAttribute === null || this.props.isAttribute === undefined ?

                                    <div className="qd-cw-col" qd-cw-modal-open="qd-cw__snapshot" onClick={this.setShowSaveImage}>
                                        <div className="qd-cw-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M11.118 6a.5.5 0 0 0-.447.276L9.809 8H5.5A1.5 1.5 0 0 0 4 9.5v10A1.5 1.5 0 0 0 5.5 21h16a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 21.5 8h-4.309l-.862-1.724A.5.5 0 0 0 15.882 6h-4.764zm-1.342-.17A1.5 1.5 0 0 1 11.118 5h4.764a1.5 1.5 0 0 1 1.342.83L17.809 7H21.5A2.5 2.5 0 0 1 24 9.5v10a2.5 2.5 0 0 1-2.5 2.5h-16A2.5 2.5 0 0 1 3 19.5v-10A2.5 2.5 0 0 1 5.5 7h3.691l.585-1.17z">
                                                </path>
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                    d="M13.5 18a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm0 1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z">
                                                </path>
                                            </svg>
                                        </div>
                                    </div>
                                :""
                                }

                            </div>
                        </div>
                    </div>

                    <div className={chart_body_class_name} id={"body_"+this.state.chartID} style={{height: page_height}}>
                     {this.renderChart()}
                    </div>
                    <MonitorStatsComponent 
                        parentDivClass={"qd-chartWidget_bottom qd-monitor__footer d-flex"}
                        footerRef={this.footerRef}
                        errorDivClass={"pr-0 w-100"}
                        isModelPerformance={isModelPerformance}
                        containerLabel={containerName}
                        containerName={dataSource}
                        metricLabel={metricLabel}
                        metricName={MetricName}
                        canShowError={canShowMetrics}
                        errorPercentage={ErrorPercentage}
                        lastErrorPercentage={LastErrorPercentage}
                        driftLabel={Drift}
                        chart_data={this.state.data}
                        dataSetName = {datasetName}
                        attributeName = {attributeName}
                        variant = {this.props.variant}
                        color = {this.props.colorCode}
                        isAttribute ={this.props.isAttribute}
                        referenceDataSetName ={this.props.referenceDataSetName}
                        referenceDataSetLabel ={this.props.referenceDataSetLabel}
                    >
                    </MonitorStatsComponent>
                </div>
             </>
        );
    }
}

export default ChartFilterHeader;