import React from 'react';
import Select from 'react-select';
import {faPlusCircle, faCheck} from '@fortawesome/free-solid-svg-icons';
import {faQuestionCircle, faTimesCircle} from '@fortawesome/fontawesome-free-regular';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postModelData } from "../../utils/event_handling";
import {userValidation, sortDict} from "../../utils/common_utils";
import {
    K_VALUE_TOOLTIP,
    DELIMITER_OPTIONS,
    MODEL_PERFORMANCE_THRESHOLDS,
    RECOMMENDATION_MODEL_DEFAULTS, SUPPORTED_ML_ALGO_TYPES,
    SUPPORTED_ML_STACK_OPTIONS,
    SUPPORTED_ML_TYPE_OPTIONS,
    SUPPORTED_ML_COLUMN_TYPES
    // ,
    // USE_SERVING_AS_PREDICTION
} from "../../utils/constant";
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Popover} from "react-bootstrap";
import { normalizeChildDatasetName } from '../../utils/attribute_name_utils';

const renderK_ValueTooltip = (props) => (
    <Popover {...props} bsPrefix="popover popover-lg">
      <Popover.Content>
          {K_VALUE_TOOLTIP}
      </Popover.Content>
    </Popover>
  );
//   const renderUseServingAsPredictionTooltip = (props) => (
//     <Popover {...props} bsPrefix="popover popover-lg">
//       <Popover.Content>
//           {USE_SERVING_AS_PREDICTION}
//       </Popover.Content>
//     </Popover>
//   );

const Features = (props) => {
    if (props.clickedValue === undefined){
        return '';
    }
    return props.clickedValue.map((value,idx) => {
        let name = props.name;
        if(props.clickedValue[idx][name] === undefined){
          return (
                <Form.Group controlId={props.name} key={`${idx}_${props.clickedValue.length}`} className="col-md-6">
                     <Form.Label className="has-close-option">
                         <span>{props.name + " Column " + (idx+1)}</span>
                            <i className="text-danger" title="Close"
                            onClick={props.removeOption.bind(null,idx,props.name,props.clickedValue[idx][name])}>
                                <FontAwesomeIcon icon={faTimesCircle}/></i>
                      </Form.Label>
                     <Select
                         classNamePrefix='select-control'
                         name={props.name}
                         filterOption={({label}, query) => label.includes(query)}
                         id={props.name}
                         onChange={selectedOption => {
                                       props.handleChange(props.name,(idx+1),selectedOption.value);
                         }}
                         number={idx}
                         options={ props.attributes }
                         placeholder={"Choose "+props.name+" Column "+ (idx+1)}
                     />
                </Form.Group>
            );
        }
        else{
            return (
                <Form.Group controlId={props.name} key={`${idx}_${props.clickedValue.length}`} className="col-md-6">
                     <Form.Label className="has-close-option">
                            <span>
                                {props.name + " Column " + (idx+1)}
                            </span>
                            <i className="text-danger" title="Close" onClick={props.removeOption.bind(null,idx,props.name,props.clickedValue[idx][name])}>
                                <FontAwesomeIcon icon={faTimesCircle}/>
                            </i>
                        </Form.Label>
                     <Select
                         classNamePrefix='select-control'
                         name={props.name}
                         filterOption={({label}, query) => label.includes(query)}
                         id={props.name}
                         onChange={selectedOption => {
                                       props.handleChange(props.name,(idx+1),selectedOption.value);
                         }}
                         number={idx}
                         defaultValue={{"label":props.clickedValue[idx][name],"value":props.clickedValue[idx][name]}}
                         options={ props.attributes }
                         placeholder={"Choose "+props.name+" Column "+ (idx+1)}
                     />
                </Form.Group>
            );
        }

    });
}



class AddModel extends React.Component {
    constructor(props) {
        super(props);
        this.getDatasets = this.getDatasets.bind(this);
        this.postModelData = postModelData.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.cancelButton = this.cancelButton.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            optionalTraining: [],
            optionalServing: [],
            optionalAccuracy: [],
            hideSubmitButton: false,
            hideUseServingAsPredictionCheckbox: true,
            hidePredictionDataBox: false,
            optionsMLStack: SUPPORTED_ML_STACK_OPTIONS,
            optionsMlType : SUPPORTED_ML_TYPE_OPTIONS,
            optionsAlgoType: SUPPORTED_ML_ALGO_TYPES,
            optionsColumnType: SUPPORTED_ML_COLUMN_TYPES,
            exValues: {
                modelName: '', modelAuthored: '', version: '',
                mlStack: '', mlType: '',predictionColumn: '',responseColumn: '',
                predictionGroupBY: '', responseGroupBY:'', predictionItemCol: '',
                responseItemCol: '',
                integrationId: '', algoType: '', columnType: '',
                thresholdRecall: MODEL_PERFORMANCE_THRESHOLDS.RECALL,
                thresholdPrecision: MODEL_PERFORMANCE_THRESHOLDS.PRECISION,
                thresholdScore: MODEL_PERFORMANCE_THRESHOLDS.F1_SCORE,
                thresholdAccuracy: MODEL_PERFORMANCE_THRESHOLDS.RECALL,
                thresholdRMSE: MODEL_PERFORMANCE_THRESHOLDS.RMSE,
                thresholdSilhouette: MODEL_PERFORMANCE_THRESHOLDS.SILHOUETTE,
                predictionDelimiter: DELIMITER_OPTIONS[0],
                customPredictionDelimiter: null,
                responseDelimiter: DELIMITER_OPTIONS[0],
                customResponseDelimiter: null,
                recommendationKValue: RECOMMENDATION_MODEL_DEFAULTS.K_VALUE,
                ratchetingRecall: '', ratchetingPrecision: '',
                ratchetingScore: '', ratchetingAccuracy: '',customAlgoType: '',
                modelDeployment: '', customMlStack: '',customMlType: '',
                accuracy: '', rawData: '',testData: '',modelData: '',
                prediction: '', response: '',
                trainingData: '', servingData: ''
            },
            exValues_edit:"",
            algoType: '',
            columnType: null,
            predictionColumn: [],
            responseColumn: [],
            predictionGroupBY: [],
            responseGroupBY: [],
            predictionItemCol: [],
            responseItemCol: [],
            featuresServing: [],
            featuresTraining: [],
            accuracy: [],
            integrationId: null,
            integrationOption: null,
            datasets: [],
            checkedR: false,
            checkedT: true,
            servingIntoPrediction: false,
            mlStack: "",
            mlType: "",
            modelDeployment: "",
            custom_deployment: '',
            predictionDelimiter: null,
            customPredictionDelimiter: null,
            responseDelimiter: null,
            customResponseDelimiter: null,
            errors: {}
        }

    }

    componentDidMount() {
        this.fillDetails();
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleChange = (name,number,value) => {
        if (["Feature Training","Feature Serving","Accuracy"].includes(name)) {
            switch(name) {
                case "Feature Training":
                    let featuresTraining = [...this.state.featuresTraining];
                    featuresTraining[number-1][name] = value;
                    this.setState({ featuresTraining: featuresTraining });
                    this.setState({optionalTraining : featuresTraining});
                    break;
                case "Feature Serving":
                    let featuresServing = [...this.state.featuresServing];
                    featuresServing[number-1][name] = value;
                    this.setState({ featuresServing: featuresServing });
                    this.setState({optionalServing : featuresServing});
                    break;
                case "Accuracy":
                    let accuracy = [...this.state.accuracy];
                    accuracy[number-1][name] = value;
                    this.setState({ accuracy: accuracy });
                    this.setState({optionalAccuracy : accuracy});
                    break;
                default:
                    break
            }
        }

    }


    removeOption = (idx,name) => {
        if (["Feature Training","Feature Serving","Accuracy"].includes(name)) {
            switch(name) {
                case "Feature Training":
                    this.state.featuresTraining.splice(idx,1)
                    this.setState({featuresTraining:this.state.featuresTraining});
                    // this.state.optionalTraining.splice(idx,1)
                    // this.setState({optionalTraining:this.state.optionalTraining});

                    break;

                case "Feature Serving":
                    this.state.featuresServing.splice(idx,1)
                    this.setState({featuresServing:this.state.featuresServing});
                    // this.state.optionalServing.splice(idx,1)
                    // this.setState({optionalServing:this.state.optionalServing});
                    break;
                case "Accuracy":
                    this.state.accuracy.splice(idx,1)
                    this.setState({accuracy:this.state.accuracy});
                    // this.state.optionalAccuracy.splice(idx,1)
                    // this.setState({optionalAccuracy:this.state.optionalAccuracy});
                    break;
                default:
                    break
            }
        }
    }


    addFeature = (e) => {
        let tempData = null
        let idx;
        if(tempData===null){
            switch(e.target.name) {
                case "Accuracy":
                    tempData = Object.assign({});
                    idx = this.state.accuracy.length-1;
                    if((this.state.optionalAccuracy[idx] === undefined && idx === -1) || (this.state.optionalAccuracy[idx] !== undefined && idx > -1)){
                        this.setState((prevState) => ({
                            accuracy: [...prevState.accuracy, tempData]
                        }));
                    }
                    break
                case "Feature Training":
                    tempData = Object.assign({});
                    idx = this.state.featuresTraining.length-1;
                    if((this.state.optionalTraining[idx] === undefined && idx === -1) || (this.state.optionalTraining[idx] !== undefined && idx > -1)){
                        this.setState((prevState) => ({
                            featuresTraining: [...prevState.featuresTraining, tempData]
                        }));
                    }
                    break
                case "Feature Serving":
                    tempData = Object.assign({});
                    idx = this.state.featuresServing.length-1;
                    if((this.state.optionalServing[idx] === undefined && idx === -1) || (this.state.optionalServing[idx] !== undefined && idx > -1))
                    {
                        this.setState((prevState) => ({
                            featuresServing: [...prevState.featuresServing, tempData]
                        }));
                    }

                    break
                default:
                   break

            }
        }
    }

    fillDetails() {
        if (this.props.exMLData === undefined) {
            return;
        }

        let mlData = this.state;
        const exValues = mlData.exValues;
        const stateDatasets = mlData.datasets;

        let exMLData = this.props.exMLData;
        let exFeature = exMLData.features[0];
        let exAccuracy = exMLData.features[1];
        let exPrediction = exMLData.features[2];
        let exResponse = exMLData.features[3];

        if (exResponse.target_data_set === "-1") {
            exResponse.target_data_set = ''
        }

        if (exFeature.base_line_data_set === "-1") {
            exFeature.base_line_data_set = ''
        }


        if (exFeature !== undefined) {

            this.setState({
                optionalServing: exFeature.model_info_details.target_column_serving,
                optionalTraining: exFeature.model_info_details.target_column_training,
                featuresServing: exFeature.model_info_details.target_column_serving,
                featuresTraining: exFeature.model_info_details.target_column_serving,
            });
        }

        if (exAccuracy !== undefined) {
            this.setState({
               accuracy: exAccuracy.model_info_details.target_column_names,
               optionalAccuracy: exAccuracy.model_info_details.target_column_names,
           });
        }

        if (exPrediction !== undefined) {
            const exPredictionModelInfoDetails = exPrediction.model_info_details;

            let prediction = exPredictionModelInfoDetails.target_column_names[0]["Prediction1"];
            exValues.predictionColumn = prediction;

            const predictionGroupBY = {
                "label": exPredictionModelInfoDetails["group_by"],
                "value": exPredictionModelInfoDetails["group_by"]
            };

            this.setState({
               predictionColumn: {"label":prediction,"value":prediction},
                predictionGroupBY: predictionGroupBY
           });

            exValues.predictionGroupBY = exPredictionModelInfoDetails["group_by"];

            const exPredictionModelInfoItemCol = exPredictionModelInfoDetails["item_col"];
            if (exPredictionModelInfoItemCol !== null) {
                const predictionItemCol = {
                    "label": exPredictionModelInfoItemCol,
                    "value": exPredictionModelInfoItemCol
                };
                this.setState({
                    predictionItemCol: predictionItemCol
                });
                exValues.predictionGroupBY = exPredictionModelInfoItemCol;
            }


//            // If the model is recommendation model, we need delimiter information
//            const predDelimiter = exPredictionModelInfoDetails.delimiter;
//            if (!IS_NONE_CHECK.includes(predDelimiter)) {
//                mlData.exValues.predictionDelimiter = predDelimiter;
//                mlData.predictionDelimiter = {"label": predDelimiter, "value": predDelimiter};
//                mlData.exValues.customPredictionDelimiter = exPredictionModelInfoDetails.custom_delimiter;
//            }
        }

        if (exResponse !== undefined) {
            let exResponseModelInfoDetails = exResponse.model_info_details;
            let response = exResponseModelInfoDetails.target_column_names[0]["Response1"];
            this.setState({
                responseColumn: {"label": response, "value": response},
                responseGroupBY: {
                    "label": exResponseModelInfoDetails["group_by"],
                    "value": exResponseModelInfoDetails["group_by"]
                }

            });

            exValues.responseColumn = response;
            exValues.responseGroupBY = exResponseModelInfoDetails["group_by"];

            const exRepItemCol = exResponseModelInfoDetails["item_col"];
            if (exRepItemCol !== null) {
                this.setState({
                    responseItemCol: {"label": exRepItemCol, "value": exRepItemCol},
                });

                exValues.responseItemCol = exRepItemCol;
            }

//            // If the model is recommendation model, we need delimiter information
//            const respDelimiter = exResponseModelInfoDetails.delimiter;
//            if (!IS_NONE_CHECK.includes(respDelimiter)) {
//                mlData.exValues.responseDelimiter = respDelimiter;
//                mlData.responseDelimiter = {"label": respDelimiter, "value": respDelimiter};
//                mlData.exValues.customResponseDelimiter = exResponseModelInfoDetails.custom_delimiter;
//            }
        }

        if (exMLData.model_details !== null) {
            let algoType = exMLData.model_details.ml_algo_type;
            let servingInPrediction = exMLData.model_details.serving_into_prediction;
            let columnType = exMLData.model_details.pred_res_column_type;
            let colMatch = this.state.optionsColumnType.filter(x => x.value === columnType);

            if (colMatch.length > 0) {
                this.setState({columnType: colMatch[0]});
                exValues.columnType = colMatch[0].value;
            } else {
                this.setState({columnType: null});
                exValues.columnType = null;
            }
            this.setState({servingIntoPrediction: servingInPrediction});

            let algoMatch = this.state.optionsAlgoType.filter(x => x.value === algoType);
            if (algoMatch.length > 0) {
                this.setState({algoType: algoMatch[0]});
                exValues.algoType = algoMatch[0].value;

            } else {
                const reqAlgoType = {"label": "Others", "value": "others"}
                this.setState({algoType: reqAlgoType});
                exValues.algoType = "others";
                exValues.customAlgoType = algoType;
            }

            stateDatasets.rawData = {
              label: exMLData.model_details.raw_data,
              value: exMLData.model_details.raw_data_id
            }

            exValues.rawData = exMLData.model_details.raw_data_id;

            stateDatasets.testData = {
              label: exMLData.model_details.test_data,
              value: exMLData.model_details.test_data_id
            }
            exValues.testData = exMLData.model_details.test_data_id;

            stateDatasets.modelData = {
              label: exMLData.model_details.model_data,
              value: exMLData.model_details.model_data_id
            }
            exValues.modelData = exMLData.model_details.model_data_id;


            if (exMLData.model_details.rmse_threshold !== undefined) {
                exValues.thresholdRMSE = exMLData.model_details.rmse_threshold;
                this.setState({checkedT: true});
            }

            if (exMLData.model_details.accuracy_threshold !== undefined) {
                exValues.thresholdAccuracy = exMLData.model_details.accuracy_threshold;
                this.setState({checkedT: true});
            }

            if (exMLData.model_details.recommendation_k_value !== undefined) {
                exValues.recommendationKValue = exMLData.model_details.recommendation_k_value;
            }

            if(exMLData.model_details.accuracy_ratcheting !== undefined) {
                 exValues.ratchetingAccuracy = exMLData.model_details.accuracy_ratcheting;
                 this.setState({checkedR: true});
            }
            if(exMLData.model_details.precision_threshold !== undefined) {
                 exValues.thresholdPrecision = exMLData.model_details.precision_threshold;
                 this.setState({checkedT: true});
            }
            if(exMLData.model_details.precision_ratcheting !== undefined) {
                 exValues.ratchetingPrecision = exMLData.model_details.precision_ratcheting;
                 this.setState({checkedR: true});
            }
            if(exMLData.model_details.recall_threshold !== undefined) {
                 exValues.thresholdRecall = exMLData.model_details.recall_threshold;
                 this.setState({checkedT: true});
            }
            if(exMLData.model_details.recall_ratcheting !== undefined) {
                 exValues.ratchetingRecall = exMLData.model_details.recall_ratcheting;
                 this.setState({checkedR: true});
            }
            if(exMLData.model_details.score_threshold !== undefined) {
                 exValues.thresholdScore = exMLData.model_details.score_threshold;
                 this.setState({checkedT: true});
            }
            if(exMLData.model_details.score_ratcheting !== undefined) {
                 exValues.ratchetingScore = exMLData.model_details.score_ratcheting;
                 this.setState({checkedR: true});
            }
            if(exMLData.model_details.silhouette_threshold !== undefined) {
                exValues.thresholdSilhouette = exMLData.model_details.silhouette_threshold;
                this.setState({checkedT: true});
            }
            if(exMLData.model_details.silhouette_ratcheting !== undefined) {
                exValues.ratchetingSilhouette = exMLData.model_details.silhouette_ratcheting;
                this.setState({checkedR: true});
            }
            let deployments = ["aws", "azure", "gcp", "databricks", ''];
            let reqModelDeployment = exMLData.model_details.model_deployment;

            if (!(deployments.includes(exMLData.model_details.model_deployment))) {
                reqModelDeployment = "others"
                this.setState({custom_deployment: exMLData.model_details.model_deployment});
            }

            this.setState({modelDeployment: reqModelDeployment});

        }
        let mlStack = exMLData.ml_stack;
        let mlStackMatch = this.state.optionsMLStack.filter(x => x.value === mlStack );

        let reqMLStack;
        if (mlStackMatch.length > 0) {
            reqMLStack = mlStackMatch[0];
            exValues.mlStack = exMLData.ml_stack;
        }else {
            reqMLStack = {"label":"Others","value":"others"}
            exValues.mlStack = "others";
            exValues.customMlStack = mlStack;
        }

        this.setState({mlStack: reqMLStack});

        exValues.modelName = exMLData.model_name;
        let mlType = exMLData.ml_model_type;
        let mlMatch = this.state.optionsMlType.filter(x => x.value === mlType );

        let reqMLType;
        if (mlMatch.length > 0) {
            reqMLType = mlMatch[0];
            exValues.mlType = mlMatch[0].value;
        }else {
            reqMLType = {"label":"Others","value":"others"}
            exValues.mlType = "others";
            exValues.customMlType = mlType;
        }

        this.setState({mlType: reqMLType});

        exValues.integrationId = exMLData.integration_id;
        exValues.modelAuthored = exMLData.author;
        exValues.version = exMLData.version;

        let reqIntegrationOption = {
            label: exMLData.integration_name,
            value: exMLData.integration_id
        }

        this.setState({
            features: exMLData.features,
            integrationId: exMLData.integration_id,
            integrationOption: reqIntegrationOption
        });

        if (exFeature !== undefined) {
            stateDatasets.trainingData = {
                label: exFeature.base_line_data_set,
                value: exFeature.model_info_details.base_line_data_set_id
            }
            exValues.trainingData = exFeature.model_info_details.base_line_data_set_id;
            stateDatasets.servingData = {
                label: exFeature.target_data_set,
                value: exFeature.model_info_details.target_data_set
            }
            exValues.servingData = exFeature.model_info_details.target_data_set;
        }

        if (exAccuracy !== undefined) {
            stateDatasets.accuracy = {
                label: exAccuracy.target_data_set,
                value: exAccuracy.model_info_details.target_data_set
            }
            exValues.accuracy = exAccuracy.model_info_details.target_data_set;

        }

        if (exPrediction !== undefined ){
            stateDatasets.prediction = {
                label: exPrediction.target_data_set,
                value: exPrediction.model_info_details.target_data_set
            }
            exValues.prediction = exPrediction.model_info_details.target_data_set;
        }

        if (exResponse !== undefined){
            stateDatasets.response = {
                label: exResponse.target_data_set,
                value: exResponse.model_info_details.target_data_set
            }
            exValues.response = exResponse.model_info_details.target_data_set;
        }
        this.setState({
            exValues_edit: exValues,
            datasets: stateDatasets
        });

    }

    handleCheckClick1 = () => {
        this.setState({ checkedR: !this.state.checkedR });
    }

    handleCheckClick2 = () => {
        this.setState({ checkedT: !this.state.checkedT });
    }

    populateServingIntoPrediction = () => {
        let prediction_dataset_autopopulate = this.state.datasets;
        let prediction_column_clear = this.state.predictionColumn;
        this.setState({ servingIntoPrediction: !this.state.servingIntoPrediction });
        prediction_dataset_autopopulate.prediction = prediction_dataset_autopopulate.servingData;
        this.setState({ datasets: prediction_dataset_autopopulate });
        prediction_column_clear = "";
        this.setState({predictionColumn:prediction_column_clear});
    }

    handleModelDeployment =(e)=> {
        this.setState({modelDeployment:e.target.value});
    }

    cancelButton() {
        if (this.props.srcPage === "edit_ml_model") {
            this.props.closeModal();
        } else {
            this.props.setPage(false);
        }
    }

    resetForm() {
        let datasets = this.state.datasets;
        datasets.accuracy = null;
        datasets.rawData = null;
        datasets.servingData = null;
        datasets.trainingData = null;
        datasets.prediction = null;
        datasets.testData = null;
        datasets.modelData = null;
        datasets.response = null;
        this.setState({
                        algoType: null,
                        mlStack: null,
                        mlType: "",
                        modelDeployment: "",
                        integrationId: null,
                        integrationOption: "",
                        featuresServing: [],
                        featuresTraining: [],
                        accuracy: [],
                        predictionColumn: [],
                        responseColumn: [],
                        predictionGroupBY: [],
                        responseGroupBY: [],
                        predictionItemCol: [],
                        responseItemCol: [],
                        checkedR:false,
                        checkedT: false,
                        servingIntoPrediction: false,
                        data:{
                            targetData: "",
                            servingData: ""
                        }});
    }

    getDatasets() {
        let id = this.state.integrationId;
        let optionArr = [];

        if (id !== null) {
            if (this.props.datasets !== undefined && this.props.datasets !== null && this.props.datasets.length > 0) {
                this.props.datasets.map(function (row) {
                if (row["integration_id"] === id) {
                    optionArr.push({"label": normalizeChildDatasetName(row["data_set_name"]), "value": row["data_set_id"]});
                }
                return 1;
            });
        }
        return optionArr
    }}

    getAlgoTypes(){
        let optionArr = [];
        let type = "classification";
        if(this.state.mlType !== ""){
            type = this.state.mlType["value"];
        }
        this.state.optionsAlgoType.map(function (row) {
            if(row["type"].includes(type) || row["type"] === "others") {
                optionArr.push(row);
            }
            return 1;
        });
        return optionArr;
    }

    getAttributes(data_set_id) {
        let option_arr = [];
        if (this.props.attributes !== null) {
            this.props.attributes.map(function (row) {
                if (row["data_set_id"] === Number(data_set_id)) {
                    option_arr.push({"label": row["attribute_name"], "value": row["attribute_name"]});
                }
                return 1;
            });
        }
        return option_arr;
    }

    getDelimiter(handleChange, values, isPrediction) {
        /**
         * Gives the delimiter components (for recommendation models)
         *
         * Delimiter drop down and delimiter text box (for custom option)
         * Same function is used for both prediction and response column sections
         * */
        if (this.state.mlType.value !== "recommendation") {
            return '';
        }

        let delimiterSelectName;
        let customDelimiterName;
        let defaultValueForDelimiter;
        let labelPrefix;
        if (isPrediction) {
            delimiterSelectName = "predictionDelimiter";
            customDelimiterName = "customPredictionDelimiter";
            defaultValueForDelimiter = "";
            labelPrefix = "Prediction ";
        } else {
            delimiterSelectName = "responseDelimiter";
            customDelimiterName = "customResponseDelimiter";
            defaultValueForDelimiter = "";
            labelPrefix = "Response ";
        }

        const currentSelected = this.state[delimiterSelectName];
        let canShowCustom = currentSelected !== null && currentSelected.value === "custom";

        return (
            <>
                <Form.Group controlId={delimiterSelectName} className="col-md-6">
                    <Form.Label>{`${labelPrefix}`} Delimiter</Form.Label>
                    <Select name={delimiterSelectName}
                            filterOption={({label}, query) => label.includes(query)}
                            onChange={selectedOption => {
                                handleChange(delimiterSelectName)(selectedOption.value);
                                this.setState({
                                    [delimiterSelectName]: {
                                        "label": selectedOption["label"],
                                        "value": selectedOption["value"]
                                    }
                                });
                            }}
                            id={delimiterSelectName}
                            key={delimiterSelectName + 'key'}
                            defaultValue={defaultValueForDelimiter}
                            options={DELIMITER_OPTIONS}
                            placeholder="Delimiter"
                            classNamePrefix='select-control'
                    />
                </Form.Group>

                {canShowCustom
                    ?
                    <Form.Group controlId={customDelimiterName} className="col-md-6">
                        <Form.Label>Custom Delimiter</Form.Label>
                        <Form.Control
                            type="text"
                            value={values[customDelimiterName]}
                            onChange={handleChange}
                            className="form-control"
                            name={customDelimiterName}
                            placeholder="Custom Delimiter"/>
                    </Form.Group>
                    :
                    ''
                }

            </>
        );
    }

    getDetailsforRM(handleChange, values, isPrediction) {
        /**
         * Gives the group_by and item_col components (for recommendation models)
         * */
        if (this.state.mlType.value !== "recommendation" || values["recommendationKValue"] === null ||
        values["recommendationKValue"] === "") {
            return '';
        }

        let name;
        let labelPrefix;
        let item_name;
        if (isPrediction) {
            name = "predictionGroupBY";
            item_name = "predictionItemCol";
            labelPrefix = "Prediction";
        } else {
            name ="responseGroupBY";
            item_name = "responseItemCol"
            labelPrefix = "Response";
        }

        let edit_1 = name+"Edit";
        let edit_2 = item_name+"Edit";
        let add_1 = name+"Add";
        let add_2 = item_name+"Add";

        return (
            <>
            <Form.Group controlId={name} className="col-md-6">
                <Form.Label>{labelPrefix} Group By
                    <span className="text-danger">*</span> </Form.Label>
                                 <Select
                    classNamePrefix='select-control'
                    name={name}
                    filterOption={({label}, query) => label.includes(query)}
                    id={this.props.exMLData !== undefined ? {edit_1} : {add_1}}
                    options={this.getAttributes(values.prediction)}
                    onChange={ selectedOption => {
                                handleChange(name)(selectedOption.label.toString());
                                this.setState({[name]:selectedOption});
                    }}
                    value={this.state[name]}
                    placeholder={`Choose ${labelPrefix} Group Column`}
                />
                <ErrorMessage component="div" className="error-text" name={name} />
            </Form.Group>
            {
            (values.columnType === "rating") &&
            <Form.Group controlId={item_name} className="col-md-6">
                 <Form.Label>{labelPrefix} Item Column </Form.Label>
                 <Select
                    classNamePrefix='select-control'
                    name={item_name}
                    filterOption={({label}, query) => label.includes(query)}
                    id={this.props.exMLData !== undefined ? {edit_2} : {add_2}}
                    options={this.getAttributes(values.prediction)}
                    onChange={ selectedOption => {
                                handleChange(item_name)(selectedOption.label.toString());
                                this.setState({[item_name]:selectedOption});
                    }}
                    value={this.state[item_name]}
                    placeholder={`Choose ${labelPrefix} Item Column`}
                />
                <ErrorMessage component="div" className="error-text"
                              name={item_name} />
            </Form.Group>
            }
            </>
        );
    }

    getKValueConfigForRecommendationModel(handleChange, values) {
        if (this.state.mlType.value !== "recommendation") {
            return '';
        }

        return (<>
            <Form.Group controlId="recommendationKValue" className="col-md-3">

                <Form.Label>K-value
                    <OverlayTrigger placement="top"
                                    overlay={renderK_ValueTooltip}>
                    <i className="ml-2 text-muted"><FontAwesomeIcon icon={faQuestionCircle}/></i>
                    </OverlayTrigger>
                </Form.Label>

                <Form.Control
                    className="form-control"
                    name="recommendationKValue"
                    type="number"
                    min="1"
                    value={values.recommendationKValue}
                    onChange={handleChange}
                    placeholder="K value"/>
            </Form.Group>

        </>);
    }

    getKValueField(handleChange, values, errors) {

    let isRequired = false;
    if (this.state.columnType !== null && this.state.columnType.value === "item"){
        isRequired = true;
    }
        return (<>
            <Form.Group controlId="recommendationKValue" className="col-md-6">
                {
                isRequired === true ?
                <Form.Label>K-value <span className="text-danger">*</span>
                    <OverlayTrigger placement="top"
                                    overlay={renderK_ValueTooltip}>
                    <i className="ml-2 text-muted"><FontAwesomeIcon icon={faQuestionCircle}/></i>
                    </OverlayTrigger>
                </Form.Label>
                :
                <Form.Label>K-value
                    <OverlayTrigger placement="top"
                                    overlay={renderK_ValueTooltip}>
                    <i className="ml-2 text-muted"><FontAwesomeIcon icon={faQuestionCircle}/></i>
                    </OverlayTrigger>
                </Form.Label>
                }
                <Form.Control
                    className="form-control"
                    name="recommendationKValue"
                    type="number"
                    min="1"
                    value={values.recommendationKValue}
                    onChange={handleChange}
                    placeholder="K value"/>
                <ErrorMessage component="div" className="error-text"
                              name={"recommendationKValue"} />
            </Form.Group>

        </>);
    }
    getResponseField() {

        let isRequired = true;
        if (this.state.mlType.value === "clustering"){
            isRequired = false;
        }
            return (<>
                    {
                    isRequired === true ?
                    <Form.Label>Response Data <span className="text-danger">*</span> </Form.Label>
                    :
                    <Form.Label>Response Data </Form.Label>
                    }
            </>);
        }
    getTrainingField() {

        let isRequired = true;
        if (this.state.mlType.value === "clustering"){
            isRequired = false;
        }
            return (<>
                    {
                    isRequired === true ?
                    <Form.Label>Training Dataset <span className="text-danger">*</span> </Form.Label>
                    :
                    <Form.Label>Training Dataset </Form.Label>
                    }
            </>);
        }
    getResColumnField() {

        let isRequired = true;
        if (this.state.mlType.value === "clustering"){
            isRequired = false;
        }
            return (<>
                    {
                    isRequired === true ?
                    <Form.Label>Response Column <span className="text-danger">*</span> </Form.Label>
                    :
                    <Form.Label>Response Column </Form.Label>
                    }
            </>);
        }

    getThresholdSections(handleChange, values) {
        if (this.state.mlType.value === "recommendation"){
            return (<>
                <Form.Group controlId="thresholdRMSE" className="col-md-3">
                <Form.Label>RMSE for Threshold test</Form.Label>
                <Form.Control
                    type="text"
                    value={values.thresholdRMSE}
                    onChange={handleChange}
                    className="form-control"
                    name="thresholdRMSE"
                    placeholder="Threshold value"/>
                </Form.Group>
                <Form.Group controlId="thresholdPrecision" className="col-md-3">
                    <Form.Label>Precision for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        name="thresholdPrecision"
                        onChange={handleChange}
                        value={values.thresholdPrecision}
                        placeholder="Threshold value"/>
                </Form.Group>
                <Form.Group controlId="thresholdRecall" className="col-md-3">
                    <Form.Label>Recall for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        name="thresholdRecall"
                        value={values.thresholdRecall}
                        onChange={handleChange}
                        placeholder="Threshold value"/>
                </Form.Group>
            </>);
        }else if (this.state.mlType.value !== "regression" && this.state.mlType.value !== "RCF" && this.state.mlType.value !== "forecast") {
            return (<>
                <Form.Group controlId="thresholdAccuracy" className="col-md-3">
                    <Form.Label>Accuracy for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        value={values.thresholdAccuracy}
                        onChange={handleChange}
                        className="form-control"
                        name="thresholdAccuracy"
                        placeholder="Threshold value"/>
                </Form.Group>
                <Form.Group controlId="thresholdScore" className="col-md-3">
                    <Form.Label>F1 Score for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        name="thresholdScore"
                        value={values.thresholdScore}
                        onChange={handleChange}
                        placeholder="Threshold value"/>
                </Form.Group>
                <Form.Group controlId="thresholdPrecision" className="col-md-3">
                    <Form.Label>Precision for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        name="thresholdPrecision"
                        onChange={handleChange}
                        value={values.thresholdPrecision}
                        placeholder="Threshold value"/>
                </Form.Group>
                <Form.Group controlId="thresholdRecall" className="col-md-3">
                    <Form.Label>Recall for Threshold test</Form.Label>
                    <Form.Control
                        type="text"
                        className="form-control"
                        name="thresholdRecall"
                        value={values.thresholdRecall}
                        onChange={handleChange}
                        placeholder="Threshold value"/>
                </Form.Group>
            </>);
        }
        else if (this.state.mlType.value === "clustering") {
            return (<>
                <Form.Group controlId="thresholdSilhouette" className="col-md-3">
                <Form.Label>Silhouette Score for Threshold test</Form.Label>
                <Form.Control
                    type="text"
                    value={values.thresholdSilhouette}
                    onChange={handleChange}
                    className="form-control"
                    name="thresholdSilhouette"
                    placeholder="Threshold value"/>
            </Form.Group>
            </>);
        }
        return (<>
            <Form.Group controlId="thresholdRMSE" className="col-md-3">
                <Form.Label>RMSE for Threshold test</Form.Label>
                <Form.Control
                    type="text"
                    value={values.thresholdRMSE}
                    onChange={handleChange}
                    className="form-control"
                    name="thresholdRMSE"
                    placeholder="Threshold value"/>
            </Form.Group>
        </>);
    }

    render() {

        let {optionalTraining,optionalServing,optionalAccuracy} = this.state;
        let modelSchema = yup.object().shape({
                modelName: yup.string().min(1,"Name must be at least 1 characters").required("Model Name is a required field").test(
                    'ModelName test',
                    'invalid Model name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                modelAuthored: yup.string().min(1,"Name must be at least 1 characters").required("Author Name is a required field").test(
                    'AuthorName test',
                    'invalid author name',
                    function test(value) {
                       let val = userValidation(value);
                       return val;
                }
                ),
                version: yup.string().min(1,"Name must be at least 1 characters").required("Version is a required field").test(
                    'versionName test',
                    'invalid version name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                mlStack: yup.string().required("ML Stack is a required field"),
                mlType: yup.string().required("Model Type is a required field"),
                algoType: yup.string().required("Algo Type is a required field"),
                integrationId: yup.string().required("Datasource is a required field"),
                servingData: yup.string().required("Serving Data is a required field"),
                trainingData: yup.string().when("mlType", {
                    is: (mlType) => (mlType !== "clustering"),
                    then: yup.string().required("Training Data is a required field")
                }),
                prediction: yup.string().required("Prediction Data is a required field"),
                response: yup.string().when("mlType", {
                    is: (mlType) => (mlType !== "clustering"),
                    then: yup.string().required("Response Data is a required field")
                }),
                responseColumn: yup.string().when("mlType", {
                    is: (mlType) => (mlType !== "clustering"),
                    then: yup.string().required("Response Column is a required field")
                }),
                predictionColumn: yup.string().required("Prediction Column is a required field"),
                recommendationKValue: yup.number().nullable().when("columnType", {
                                      is: (columnType) => (columnType !== null && columnType === "item"),
                                      then: yup.number().required("K Value is a required field")
                }),
                columnType: yup.string().nullable().when("mlType", {
                                      is: (mlType) => (mlType !== null && mlType === "recommendation"),
                                      then: yup.string().required("Select the column Type")
                }),
                predictionGroupBY: yup.string().when("recommendationKValue",{
                                    is: (recommendationKValue) => (recommendationKValue !==  null && recommendationKValue !== 0 && isNaN(recommendationKValue) === false),
                                    then: yup.string().required("Prediction Group-by Column is a required field")}),
                responseGroupBY: yup.string().when("recommendationKValue",{
                                    is: (recommendationKValue) => (recommendationKValue !== null && recommendationKValue !== 0 && isNaN(recommendationKValue) === false),
                                    then: yup.string().required("Response Group-by Column is a required field")}),
                customMlStack: yup.string().when("mlStack", {
                                      is: "others",
                                      then: yup.string().min(1,"Name must be at least 1 characters").required("Mlstack is a required field").test(
                                        'mlStack test',
                                        'invalid Ml-stack name',
                                        function test(value) {
                                           let val = userValidation(value,"input");
                                           return val;
                                    }
                                    ),
                }),
                customMlType: yup.string().when("mlType", {
                                      is: "others",
                                      then: yup.string().required("Must enter custom ML type")
                }),
                customAlgoType: yup.string().when("algoType", {
                                      is: "others",
                                      then: yup.string().required("Must enter custom Algo type")
                })
        });
        let datasetOptions = this.getDatasets();
        

        if(datasetOptions !== undefined && datasetOptions.length>1){
            datasetOptions = sortDict(datasetOptions)
        }

        let optionsAlgoType = this.getAlgoTypes();
        let optionsColumnType = this.state.optionsColumnType;
        let initial_values = {
            modelName: '', modelAuthored: '', version: '',
            mlStack: '', mlType: '',
            integrationId: '', algoType: '',customAlgoType: '',predictionColumn: '',
            thresholdRecall: MODEL_PERFORMANCE_THRESHOLDS.RECALL,responseColumn: '',
            thresholdPrecision: MODEL_PERFORMANCE_THRESHOLDS.PRECISION, customMlStack: '',
            thresholdScore: MODEL_PERFORMANCE_THRESHOLDS.F1_SCORE, customMlType: '',
            thresholdAccuracy: MODEL_PERFORMANCE_THRESHOLDS.ACCURACY,
            thresholdRMSE: MODEL_PERFORMANCE_THRESHOLDS.RMSE,
            thresholdSilhouette: MODEL_PERFORMANCE_THRESHOLDS.SILHOUETTE,
            predictionDelimiter: DELIMITER_OPTIONS[0],
            responseDelimiter: DELIMITER_OPTIONS[0],
            recommendationKValue: RECOMMENDATION_MODEL_DEFAULTS.K_VALUE,
            columnType: null,
            useServingAsPrediction: false,
            predictionGroupBY: '',
            predictionItemCol: '',
            responseGroupBY: '',
            responseItemCol: '',
            ratchetingRecall: '', ratchetingPrecision: '',
            ratchetingScore: '', ratchetingAccuracy: '',
            modelDeployment: '',
            accuracy: '', rawData: '',testData: '',
            prediction: '', response: '',modelData: '',
            trainingData: '', servingData: ''
        }

        let { accuracy, featuresServing,featuresTraining } = this.state;
        let edit_integration = null;
        if (this.props.exMLData !== null && this.props.exMLData !== undefined){
            edit_integration = true;
        }
        else{
            edit_integration = false;
        }
        return (
            <div className="row">
                    <Formik
                          validationSchema={modelSchema}
                          onSubmit={this.postModelData}
                          initialValues={(edit_integration === true) ? this.state.exValues_edit : initial_values}
                          enableReinitialize={true}
                    >
                    {({
                          values,
                          setFieldValue,
                          handleReset,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                        <Form name='addModel' onSubmit={handleSubmit} className="login-form" onChange={this.handleChange}>
                            <div className="vertical-stepper vertical-stepper-sm">
                                <div className="vertical-stepper__heading">
                                    <span className="vs-heading__circle">3</span>
                                    <div className="vs-heading__title">Model Metadata </div>
                                </div>
                                <div className="vertical-stepper__content">
                                    <div className="row row-sm">
                                        <Form.Group controlId="modelName" className="col-md-3">
                                            <Form.Label >Name of the model <span className="text-danger">*</span> </Form.Label>
                                            <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  name="modelName"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={errors.modelName && touched.modelName}
                                                  placeholder="Name your model"
                                                  value={values.modelName} />
                                            <Form.Control.Feedback type="invalid">
                                                        { touched.modelName && errors.modelName }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="modelAuthored" className="col-md-3">
                                            <Form.Label >Authored by <span className="text-danger">*</span> </Form.Label>
                                            <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  name="modelAuthored"
                                                  placeholder="Author"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={errors.modelAuthored && touched.modelAuthored}
                                                  value={values.modelAuthored}/>
                                            <Form.Control.Feedback type="invalid">
                                                        { touched.modelAuthored && errors.modelAuthored }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="version" className="col-md-3">
                                            <Form.Label >Model Version <span className="text-danger">*</span> </Form.Label>
                                            <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  name="version"
                                                  placeholder="Model Version"
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  isInvalid={errors.version && touched.version}
                                                  value={values.version}/>
                                            <Form.Control.Feedback type="invalid">
                                                        { touched.version && errors.version }
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group controlId="mlStack" className="col-md-3">
                                            <Form.Label>ML Tech-stack <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="mlStack"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "mlStackEdit" : "mlStackAdd"}
                                                isInvalid={errors.mlStack && touched.mlStack}
                                                onChange={selectedOption => {
                                                          handleChange("mlStack")(selectedOption.value);
                                                          this.setState({mlStack:selectedOption});
                                                }}
                                                options={this.state.optionsMLStack}
                                                value={this.state.mlStack}
                                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                                placeholder="Choose ML Tech-stack"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="mlStack" />
                                        </Form.Group>
                                        {values.mlStack === "others" ? <Form.Group controlId="customMlStack" className="col-md-3">
                                            <Form.Label >Custom ML Tech-stack <span className="text-danger">*</span> </Form.Label>
                                            <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  name="customMlStack"
                                                  placeholder="Custom ML Tech-stack"
                                                  onChange={handleChange}
                                                  isInvalid={errors.customMlStack && touched.customMlStack}
                                                  onBlur={handleBlur}
                                                  value={values.customMlStack}/>
                                                  <Form.Control.Feedback type="invalid">
                                                        { touched.customMlStack && errors.customMlStack }
                                            </Form.Control.Feedback>
                                        </Form.Group> : ""}
                                    </div>
                                </div>
                                <div className="vertical-stepper__heading">
                                    <span className="vs-heading__circle">4</span>
                                    <div className="vs-heading__title">Model Configuration</div>
                                </div>
                                <div className="vertical-stepper__content">
                                    <div className="row row-sm">
                                        <Form.Group controlId="mlType" className="col-md-3">
                                            <Form.Label>Model Type <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="mlType"
                                                filterOption={({label}, query) => label.includes(query)}
                                                options={this.state.optionsMlType }
                                                isInvalid={errors.mlType && touched.mlType}
                                                onChange={selectedOption => {
                                                          handleChange("mlType")(selectedOption.value);
                                                          setFieldValue("algoType",'');
                                                          setFieldValue("recommendationKValue", null);
                                                          this.setState({mlType:selectedOption,algoType:null});
                                                }}
                                                value={this.state.mlType}
                                                id={this.props.exMLData !== undefined ? "mlTypeEdit" : "mlTypeAdd"}
                                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                                placeholder="Choose Model"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="mlType" />
                                        </Form.Group>
                                        {values.mlType === "others" ? <Form.Group controlId="customMlType" className="col-md-3">
                                            <Form.Label >Custom Model Type<span className="text-danger">*</span> </Form.Label>
                                            <Form.Control
                                                  type="text"
                                                  className="form-control"
                                                  name="customMlType"
                                                  placeholder="Custom Model Type"
                                                  onChange={handleChange}
                                                   isInvalid={errors.customMlType && touched.customMlType}
                                                  onBlur={handleBlur}
                                                  value={values.customMlType}/>
                                                  <Form.Control.Feedback type="invalid">
                                                        { touched.customMlType && errors.customMlType }
                                                  </Form.Control.Feedback>
                                        </Form.Group> : ""}

                                        <Form.Group controlId="algoType" className="col-md-3">
                                        <Form.Label>Algo Type <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="algoType"
                                                filterOption={({label}, query) => label.includes(query)}
                                                onChange={selectedOption => {
                                                          handleChange("algoType")(selectedOption.value);
                                                          this.setState({algoType:selectedOption});
                                                }}
                                                options={optionsAlgoType}
                                                id={this.props.exMLData !== undefined ? "algoTypeEdit" : "algoTypeAdd"}
                                                value={this.state.algoType}
                                                isInvalid={errors.algoType && touched.algoType}
                                                placeholder="Choose Algo Type"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="algoType" />
                                        </Form.Group>
                                        {/*
                                            this.getKValueConfigForRecommendationModel(handleChange, values)
                                        */}
                                        {values.algoType === "others" ?
                                            <Form.Group controlId="customAlgoType"
                                                        className="col-md-3">
                                                <Form.Label>Custom Algo Type </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control"
                                                    name="customAlgoType"
                                                    placeholder="Custom Algo Type"
                                                    isInvalid={errors.customAlgoType && touched.customAlgoType}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.customAlgoType}/>
                                                <Form.Control.Feedback type="invalid">
                                                        { touched.customAlgoType && errors.customAlgoType }
                                            </Form.Control.Feedback>
                                            </Form.Group> :''
                                        }

                                        <Form.Group className="col-md-3">
                                            <Form.Label>Threshold </Form.Label>
                                            <div>
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <Form.Control
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          checked={this.state.checkedR}
                                                          onChange={this.handleCheckClick1}
                                                          name="ratcheting"
                                                          id="customCheck1" />
                                                    <Form.Label className="custom-control-label"
                                                                htmlFor="customCheck1">
                                                        Ratcheting
                                                    </Form.Label>
                                                </div>
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <Form.Control
                                                          type="checkbox"
                                                          className="custom-control-input"
                                                          name="threshold"
                                                          checked={this.state.checkedT}
                                                          onChange={this.handleCheckClick2}
                                                          id="customCheck2" />
                                                    <Form.Label className="custom-control-label"
                                                                htmlFor="customCheck2">
                                                        Threshold Test
                                                    </Form.Label>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        {(this.state.checkedT !== false) ?
                                            this.getThresholdSections(handleChange, values)
                                             : ""
                                        }
                                        {this.state.checkedR !== false ?
                                            <>
                                            <Form.Group controlId="ratchetingAccuracy" className="col-md-3">
                                                <Form.Label>Accuracy for Ratcheting</Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      className="form-control"
                                                      name="ratchetingAccuracy"
                                                      value={values.ratchetingAccuracy}
                                                      placeholder="Ratcheting value"
                                                      onChange={handleChange} />
                                            </Form.Group>
                                            <Form.Group controlId="ratchetingScore" className="col-md-3">
                                                <Form.Label>F1 Score for Ratcheting</Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      className="form-control"
                                                      name="ratchetingScore"
                                                      value={values.ratchetingScore}
                                                      onChange={handleChange}
                                                      placeholder="Ratcheting value"/>
                                            </Form.Group>
                                            <Form.Group controlId="ratchetingPrecision" className="col-md-3">
                                                <Form.Label>Precision for Ratcheting</Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      className="form-control"
                                                      name="ratchetingPrecision"
                                                      value={values.ratchetingPrecision}
                                                      onChange={handleChange}
                                                      placeholder="Ratcheting value" />
                                            </Form.Group>
                                            <Form.Group controlId="ratchetingRecall" className="col-md-3">
                                                <Form.Label>Recall for Ratcheting</Form.Label>
                                                <Form.Control
                                                      type="text"
                                                      className="form-control"
                                                      name="ratchetingRecall"
                                                      value={values.ratchetingRecall}
                                                      onChange={handleChange}
                                                      placeholder="Ratcheting value" />
                                            </Form.Group>
                                            </> : ""
                                        }
                                        <Form.Group className="col-md-8">
                                            <Form.Label>Model Deployment </Form.Label>
                                            <div>
                                                <div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id={this.props.exMLData !== undefined ? "customRadioInline1Ex":"customRadioInline1"}
                                                          checked={this.state.modelDeployment === "aws"}
                                                          name="mlDeployment" value="aws"
                                                          onChange={this.handleModelDeployment}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor={this.props.exMLData !== undefined ? "customRadioInline1Ex":"customRadioInline1"}>AWS Sagemaker</Form.Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id={this.props.exMLData !== undefined ? "customRadioInline2Ex":"customRadioInline2"}
                                                          name="mlDeployment"
                                                          value="azure"
                                                          onChange={this.handleModelDeployment}
                                                          checked={this.state.modelDeployment === "azure"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor={this.props.exMLData !== undefined ? "customRadioInline2Ex":"customRadioInline2"}>Azure ML Studio</Form.Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id={this.props.exMLData !== undefined ? "customRadioInline3Ex":"customRadioInline3"}
                                                          name="mlDeployment"
                                                          value="gcp"
                                                          onChange={this.handleModelDeployment}
                                                          checked={this.state.modelDeployment === "gcp"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor={this.props.exMLData !== undefined ? "customRadioInline3Ex":"customRadioInline3"}>Google AI Platform</Form.Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id={this.props.exMLData !== undefined ? "customRadioInline5Ex":"customRadioInline5"}
                                                          name="mlDeployment"
                                                          value="databricks"
                                                          onChange={this.handleModelDeployment}
                                                          checked={this.state.modelDeployment === "databricks"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor={this.props.exMLData !== undefined ? "customRadioInline5Ex":"customRadioInline5"}>DataBricks</Form.Label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id={this.props.exMLData !== undefined ? "customRadioInline4Ex":"customRadioInline4"}
                                                          name="mlDeployment"
                                                          value="others"
                                                          checked={this.state.modelDeployment === "others"}
                                                          onChange={this.handleModelDeployment}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor={this.props.exMLData !== undefined ? "customRadioInline4Ex":"customRadioInline4"}>Others</Form.Label>
                                                </div>
                                            </div>
                                        </Form.Group>
                                        {this.state.modelDeployment === "others" ?
                                                 <Form.Group controlId="customDeployment" className="col-md-3">
                                                       <Form.Label >Custom Deployment </Form.Label>
                                                       <Form.Control
                                                              type="text"
                                                              className="form-control"
                                                              name="customDeployment"
                                                              defaultValue={this.state.custom_deployment}
                                                              placeholder="Custom Deployment"
                                                              onChange={e=>{this.setState({custom_deployment:e.target.value})}}
                                                              />
                                                </Form.Group> : ''}
                                    </div>
                                </div>
                                <div className="vertical-stepper__heading">
                                    <span className="vs-heading__circle">5</span>
                                    <div className="vs-heading__title">Model Data Configuration</div>
                                </div>
                                <div className="vertical-stepper__content pb-0" style={{maxWidth: "1000px"}}>
                                    <div className="row row-sm">
                                        <Form.Group controlId="integrationId" className="col-md-6">
                                            <Form.Label>Datasource <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="integrationId"
                                                filterOption={({label}, query) => label.includes(query)}s
                                                id={this.props.exMLData !== undefined ? "integrationIdEdit" : "integrationIdAdd"}
                                                onChange={ selectedOption => {
                                                        handleChange("integrationId")(selectedOption.value.toString());
                                                        let datasets = this.state.datasets;
                                                        setFieldValue('accuracy', '')
                                                        setFieldValue('rawData', '')
                                                        setFieldValue('servingData', '')
                                                        setFieldValue('trainingData', '')
                                                        setFieldValue('predictionColumn', '')
                                                        setFieldValue('responseColumn', '')
                                                        setFieldValue('predictionGroupBY', '')
                                                        setFieldValue('responseGroupBY', '')
                                                        setFieldValue('predictionItemCol', '')
                                                        setFieldValue('responseItemCol', '')
                                                        setFieldValue('testData','')
                                                        setFieldValue('modelData','')
                                                        setFieldValue('prediction', '')
                                                        setFieldValue('response', '')
                                                        datasets.accuracy = null;
                                                        datasets.rawData = null;
                                                        datasets.servingData = null;
                                                        datasets.trainingData = null;
                                                        datasets.testData = null;
                                                        datasets.modelData = null;
                                                        datasets.prediction = null;
                                                        datasets.response = null;
                                                        this.setState({ integrationId: selectedOption["value"],datasets:datasets,integrationOption:selectedOption,responseColumn: [], predictionColumn: [], predictionGroupBY:[], predictionItemCol:[], responseGroupBY:[], responseItemCol:[],
                                                         featuresTraining: [], featuresServing: [], accuracy: [] });
                                                        localStorage.setItem("integrationId", selectedOption["value"]);
                                                }}
                                                value={this.state.integrationOption}
                                                options={this.props.integrationOption !== null ? this.props.integrationOption : []}
                                                placeholder="Choose a Datasource containing Model Data"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="integrationId" />
                                        </Form.Group>
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Test Dataset </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="testData"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "testDataEdit" : "testDataAdd"}
                                                options={ datasetOptions }
                                                value={this.state.datasets.testData}
                                                onChange={ selectedOption => {
                                                        handleChange("testData")(selectedOption.value.toString());
                                                        let datasets = this.state.datasets;
                                                        datasets["testData"] = selectedOption;
                                                        this.setState({datasets: datasets});
                                                }}
                                                placeholder="Choose Test Dataset inside Datasource (Optional)"
                                        />
                                        </Form.Group>
                                    </div>
                                    <div className="row row-sm">
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Raw Dataset </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="rawData"
                                                filterOption={({label}, query) => label.includes(query)}s
                                                id={this.props.exMLData !== undefined ? "rawDataEdit" : "rawDataAdd"}
                                                options={ datasetOptions }
                                                value={this.state.datasets.rawData}
                                                onChange={ selectedOption => {
                                                        handleChange("rawData")(selectedOption.value.toString());
                                                        let datasets = this.state.datasets;
                                                        datasets["rawData"] = selectedOption;
                                                        this.setState({datasets: datasets});
                                                }}
                                                placeholder="Choose Raw Dataset inside Datasource (Optional)"
                                        />
                                        </Form.Group>
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Model Runtime-Profile Dataset </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="modelData"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "modelDataEdit" : "modelDataAdd"}
                                                options={ datasetOptions }
                                                value={this.state.datasets.modelData}
                                                onChange={ selectedOption => {
                                                        handleChange("modelData")(selectedOption.value.toString());
                                                        let datasets = this.state.datasets;
                                                        datasets["modelData"] = selectedOption;
                                                        this.setState({datasets: datasets});
                                                }}
                                                placeholder="Choose Model Runtime-Profile Dataset (Optional)"
                                        />
                                        </Form.Group>
                                    </div>
                                    <div className="row row-sm">
                                        <Form.Group className="col-md-6">
                                            { this.getTrainingField()}
                                            <Select
                                                classNamePrefix='select-control'
                                                name="trainingData"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "trainingDataEdit" : "trainingDataAdd"}
                                                options={ datasetOptions }
                                                value={this.state.datasets.trainingData}
                                                onChange={ selectedOption => {
                                                            handleChange("trainingData")(selectedOption.value.toString());
                                                            let datasets = this.state.datasets;
                                                            datasets["trainingData"] = selectedOption;
                                                            this.setState({datasets: datasets,featuresTraining: []});
                                                }}
                                                placeholder="Choose Training Dataset inside Datasource"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="trainingData" />
                                        </Form.Group>
                                        <Features
                                                    name="Feature Training"
                                                    optional={optionalTraining}
                                                    removeOption={this.removeOption}
                                                    handleChange={this.handleChange}
                                                    clickedValue={featuresTraining}
                                                    attributes={this.getAttributes(values.trainingData)}
                                            />
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Optional </Form.Label>
                                            <div>
                                                <button type="button" onClick={this.addFeature} name="Feature Training" className="btn btn-outline-primary mb-1"><FontAwesomeIcon icon={faPlusCircle}/> Add Training Features Columns
                                                </button>
                                            </div>
                                        </Form.Group>
                                    </div>
                                    <div className="row row-sm">
                                        <Form.Group controlId="servingData" className="col-md-6">
                                            <Form.Label>Serving Dataset <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="servingData"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "servingDataEdit" : "servingDataAdd"}
                                                options={ datasetOptions }
                                                onChange={ selectedOption => {
                                                            handleChange("servingData")(selectedOption.value.toString());
                                                            let datasets = this.state.datasets;
                                                            datasets["servingData"] = selectedOption;

                                                            this.setState({hideUseServingAsPredictionCheckbox: false});
                                                            if (this.state.servingIntoPrediction === true){
                                                                handleChange("prediction")(selectedOption.value.toString());
                                                                datasets["prediction"] = selectedOption;
                                                                setFieldValue('predictionColumn', '');
                                                                this.setState({datasets: datasets,predictionColumn: []});
                                                            }
                                                            this.setState({datasets: datasets, featuresServing: []});

                                                }}
                                                value={this.state.datasets.servingData}
                                                placeholder="Choose Serving Dataset inside Datasource"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="servingData" />
                                        </Form.Group>
                                        <Features
                                                name="Feature Serving"
                                                optional={optionalServing}
                                                removeOption={this.removeOption}
                                                handleChange={this.handleChange}
                                                clickedValue={featuresServing}
                                                attributes={this.getAttributes(values.servingData)} />
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Optional </Form.Label>
                                            <div><button type="button" onClick={this.addFeature} name="Feature Serving" className="btn btn-outline-primary mb-1"><FontAwesomeIcon icon={faPlusCircle}/> Add Serving Features Columns </button></div>
                                        </Form.Group>
                                    </div>
                                    <div className="row row-sm">
                                        <Form.Group controlId="accuracy" className="col-md-6">
                                            <Form.Label>Accuracy Data </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="accuracy"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "accuracyDataEdit":"accuracyDataAdd"}
                                                options={ datasetOptions }
                                                onChange={ selectedOption => {
                                                            handleChange("accuracy")(selectedOption.value.toString());
                                                            let datasets = this.state.datasets;
                                                            datasets["accuracy"] = selectedOption;
                                                            this.setState({datasets: datasets,accuracy: []});
                                                }}
                                                value={this.state.datasets.accuracy}
                                                placeholder="Choose a Dataset containing Accuracy Data"
                                            />
                                        </Form.Group>
                                        <Features
                                                name="Accuracy"
                                                optional={optionalAccuracy}
                                                removeOption={this.removeOption}
                                                handleChange={this.handleChange}
                                                clickedValue={accuracy}
                                                attributes={this.getAttributes(values.accuracy)} />
                                        <Form.Group className="col-md-6">
                                            <Form.Label>Optional </Form.Label>
                                            <div><button type="button" onClick={this.addFeature} name="Accuracy" className="btn btn-outline-primary mb-1"><FontAwesomeIcon icon={faPlusCircle}/> Add Accuracy Columns </button></div>
                                        </Form.Group>
                                    </div>
                                    {
                                    (this.state.mlType.value === "recommendation") &&
                                    <div className="row row-sm">
                                    <Form.Group controlId="columnType" className="col-md-6">
                                            <label >Prediction / Response Column Type <span className="text-danger">*</span> </label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="columnType"
                                                filterOption={({label}, query) => label.includes(query)}
                                                onChange={selectedOption => {
                                                          handleChange("columnType")(selectedOption.value.toString());
                                                          this.setState({columnType:selectedOption});
                                                }}
                                                options={optionsColumnType}
                                                id={this.props.exMLData !== undefined ? "columnTypeEdit" : "columnTypeAdd"}
                                                value={this.state.columnType}
                                                placeholder="Choose Column Type"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="columnType" />
                                    </Form.Group>
                                    { this.getKValueField(handleChange, values, errors)}
                                    </div>
                                    }
                                    {/* <div className="row row-sm">
                                        <Form.Group className="col-md-12">
                                                <div>
                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                        <Form.Control
                                                            type="checkbox"
                                                            className="custom-control-input"
                                                            disabled={this.state.hideUseServingAsPredictionCheckbox}
                                                            checked={this.state.servingIntoPrediction}
                                                            onChange={ () => {
                                                            if (this.state.servingIntoPrediction === false){
                                                                handleChange("prediction")(values.servingData);
                                                            }
                                                            this.populateServingIntoPrediction()
                                                            this.setState({hidePredictionDataBox: !this.state.hidePredictionDataBox});
                                                            }}
                                                            name="PredictionInServingDataset"
                                                            id="customCheck3" />
                                                        <Form.Label className="custom-control-label"
                                                                    htmlFor="customCheck3">
                                                            Use Serving as Prediction
                                                            <OverlayTrigger placement="top"
                                                                overlay={renderUseServingAsPredictionTooltip}>
                                                                <i className="ml-2 text-muted"><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                    </div>
                                                </div>
                                        </Form.Group>
                                    </div> */}
                                    <div className="row row-sm">
                                        <Form.Group controlId="prediction" className="col-md-6">
                                            <Form.Label>Prediction Data
                                                <span className="text-danger">*</span> </Form.Label>
                                            <Select
                                                classNamePrefix='select-control'
                                                name="prediction"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "predictionDataEdit":"predictionDataAdd"}
                                                options={ datasetOptions }
                                                isDisabled={this.state.hidePredictionDataBox}

                                                onChange={ selectedOption => {
                                                            handleChange("prediction")(selectedOption.value.toString());
                                                            let datasets = this.state.datasets;
                                                            datasets["prediction"] = selectedOption;
                                                            setFieldValue('predictionColumn', '')
                                                            this.setState({datasets: datasets,predictionColumn: []});
                                                }}
                                                value={this.state.datasets.prediction}
                                                placeholder="Choose a Dataset containing Prediction Data"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="prediction" />
                                        </Form.Group>
                                        <Form.Group controlId="predictionColumn" className="col-md-6">
                                             <Form.Label>Prediction Column
                                                 <span className="text-danger">*</span> </Form.Label>
                                             <Select
                                                classNamePrefix='select-control'
                                                name="predictionColumn"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "predictionColumnEdit" : "predictionColumnAdd"}
                                                options={this.getAttributes(values.prediction)}
                                                onChange={ selectedOption => {
                                                            handleChange("predictionColumn")(selectedOption.label.toString());
                                                            this.setState({predictionColumn:selectedOption});
                                                }}
                                                value={this.state.predictionColumn}
                                                placeholder="Choose Prediction Column"
                                            />
                                            <ErrorMessage component="div" className="error-text"
                                                          name="predictionColumn" />
                                        </Form.Group>

                                        {this.getDetailsforRM(handleChange, values, true)}

                                    </div>
                                    <div className="row row-sm">
                                        <Form.Group controlId="response" className="col-md-6">
                                        { this.getResponseField()}
                                            <Select
                                                classNamePrefix='select-control'
                                                name="response"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "responseDataEdit" : "responseDataAdd"}
                                                options={ datasetOptions }
                                                onChange={ selectedOption => {
                                                            handleChange("response")(selectedOption.value.toString());
                                                            setFieldValue('responseColumn', '')
                                                            let datasets = this.state.datasets;
                                                            datasets["response"] = selectedOption;
                                                            this.setState({datasets: datasets,responseColumn: ''});
                                                }}
                                                value={this.state.datasets.response}
                                                placeholder="Choose a Dataset containing Response Data"
                                            />
                                            <ErrorMessage component="div" className="error-text" name="response" />
                                        </Form.Group>
                                        <Form.Group controlId="responseColumn"
                                                    className="col-md-6">
                                                { this.getResColumnField()}
                                             <Select
                                                classNamePrefix='select-control'
                                                name="responseColumn"
                                                filterOption={({label}, query) => label.includes(query)}
                                                id={this.props.exMLData !== undefined ? "responseColumnEdit" : "responseColumnAdd"}
                                                options={ this.getAttributes(values.response) }
                                                onChange={ selectedOption => {
                                                            handleChange("responseColumn")(selectedOption.label.toString());
                                                            this.setState({responseColumn:selectedOption});
                                                }}
                                                value={this.state.responseColumn}
                                                placeholder="Choose Response Column"
                                            />
                                        <ErrorMessage component="div"
                                                      className="error-text"
                                                      name="responseColumn" />
                                        </Form.Group>

                                        {this.getDetailsforRM(handleChange, values, false)}

                                    </div>
                                </div>
                            </div>

                            <div className="text-right">
                                <button type="button"
                                        onClick={this.cancelButton}
                                        className="btn btn-outline btn-grey btn-circle mr-2">Cancel
                                </button>
                                {this.props.exMLData !== undefined ?
                                    '' :
                                    <button type="button"
                                            onClick={() => {
                                                this.resetForm();
                                                handleReset();
                                            }}
                                            className="btn btn-outline btn-grey btn-circle mr-1">Reset
                                    </button>
                                }
                                <button type="submit"
                                        className="btn btn-primary btn-circle"
                                        disabled={this.state.hideSubmitButton}>
                                    <FontAwesomeIcon icon={faCheck}/>Submit
                                </button>
                            </div>
                        </Form>)}
                    </Formik>
            </div>
        );
    }
}

export default AddModel;