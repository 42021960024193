import React from 'react';
import Select from 'react-select';
import { faLayerGroup, faPencilAlt, faCloud, faCaretDown,faFolderOpen, faTag,faDatabase, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import  {ATTRIBUTE_RELATIONSHIP, ATTRIBUTE_VALUES} from "../../utils/constant";
import Image from '../../components/image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import BasicPortlet from '../../components/basicPortlet';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from '../../components/table';
import metadata from '../../dummydata/all_sales_data.json';
import {GovernFilterOptions} from './filteroptions'
//Get Table Description
const all_sales_data = metadata.all_sales_data;
const branch_dataset = metadata.branch;
const branch_group_master_dataset = metadata.branch_group_master;
const item_master_dataset = metadata.item_master;
const item_category_dataset = metadata.item_category;
const stock_ledger_dataset = metadata.stock_ledger;
const styles = {
  color: 'black'
}
const highlight = {
   color: 'red',
}
class MetadataGlossary extends React.Component {
constructor(props) {
        super(props);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.enableshowmodel = this.enableshowmodel.bind(this);
        this.changeMain = this.changeMain.bind(this);
        this.changeDataset = this.changeDataset.bind(this);
        this.setAttribute = this.setAttribute.bind(this);
        this.getGoldenData = this.getGoldenData.bind(this);
        this.state = {
            isFilterMode: false,
            showmodal: false,
            goldenDatasource: true,
            mainClassName: "caret caret-down",
            nestedClsName: "nested active",
            selectedDataset: "allSalesDataset",
            dataSetName: "all-sales-data",
            selectedAttribute: "Batch_No",
            datasetClsDict: {"allSalesDataset": "caret", "branchDataset": "caret",
                             "branch_group_master_Dataset":"caret","item_category_DataSet":"caret",
                             "item_master_Dataset":"caret","stock_ledger_Dataset":"caret"},
            nestedDatasetCls: {"allSalesDataset": "nested has-no-caret", "branchDataset": "nested has-no-caret",
                                "branch_group_master_Dataset": "nested has-no-caret", "item_category_DataSet": "nested has-no-caret",
                                "item_master_Dataset": "nested has-no-caret", "stock_ledger_Dataset": "nested has-no-caret"},           
            datasetDescription:"Sales related data available in this data set."
        }
 }
 owner(){
    if(this.state.dataSetName == 'all-sales-data')
        return 'Sales Management Team';
    else if (this.state.dataSetName == 'stock_ledger')
        return 'Inventory Management Team';
    else
        return 'Category Team';
 }
 getAttributeRelationship() {
    let attributeData = ATTRIBUTE_RELATIONSHIP[this.state.selectedAttribute]
    let attrList = []
    for(let i=0;i< attributeData.length; i++) {
        attrList.push(<><tr><td>{attributeData[i]["id"]}</td>
                                    <td>{attributeData[i]["sourceDataset"]}</td>
                                    <td><i className="fa fa-long-arrow-alt-right text-royalblue fs-5"></i></td>
                                    <td>{attributeData[i]["destDataset"]}</td>
                                    <td>
                                      <div className="data-progress-percentage">
                                        <ProgressBar now={attributeData[i]["percentage"]}/>
                                        <h5 className="data-progress-value">{attributeData[i]["relationship"]}</h5>
                                      </div>
                                    </td>
                                  </tr></>);
    }
    return attrList;
 }

 getGoldenData(){
     let dataSetName = this.state.dataSetName
     if(["all-sales-data", "stock_ledger"].includes(dataSetName)){
        return true
     }else{
       return false
     }

 }

 getTableData() {
    if(this.state.selectedDataset === "allSalesDataset") {
       return (                     
                  <Table data={all_sales_data}/>                        
              );

    }
    else if(this.state.selectedDataset === "branchDataset") {
      return (                     
                 <Table data={branch_dataset}/>                        
             );

   }
   else if(this.state.selectedDataset === "branch_group_master_Dataset") {
    return (                     
               <Table data={branch_group_master_dataset}/>                        
           );
    }
    else if(this.state.selectedDataset === "item_master_Dataset") {
      return (                     
                 <Table data={item_master_dataset}/>                        
             );
      }
      else if(this.state.selectedDataset === "item_category_DataSet") {
        return (                     
                   <Table data={item_category_dataset}/>                        
               );
        }
      else if(this.state.selectedDataset === "stock_ledger_Dataset") {
          return (                     
                     <Table data={stock_ledger_dataset}/>                        
                 );
          }
 }

 changeMain() {
       let clsName = this.state.mainClassName === "caret" ? "caret caret-down" : "caret";
       let nestedClsName = this.state.nestedClsName === "nested" ? "nested active" : "nested";
       this.setState({mainClassName: clsName, nestedClsName: nestedClsName});

 }
  toggleFilter() {
        this.setState({
            showmodal: false
        })
    }

 changeDataset(e) {
      this.setState({selectedDataset: e.target.id});
      if(e.target.id === "allSalesDataset") {
          let datasetClsDict = this.state.datasetClsDict;
          datasetClsDict.allSalesDataset = datasetClsDict.allSalesDataset === "caret"? "caret caret-down" : "caret";
          let nestedDatasetCls = this.state.nestedDatasetCls;
          nestedDatasetCls.allSalesDataset = nestedDatasetCls.allSalesDataset === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
          this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "all-sales-data", selectedAttribute: "Batch_No", datasetDescription:"Sales related data available in this data set."  })
      }
      else if(e.target.id === "branchDataset") {
          let datasetClsDict = this.state.datasetClsDict;
          datasetClsDict.branchDataset = datasetClsDict.branchDataset === "caret"? "caret caret-down" : "caret";
          let nestedDatasetCls = this.state.nestedDatasetCls;
          nestedDatasetCls.branchDataset = nestedDatasetCls.branchDataset === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
          this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "branch", selectedAttribute: "branch", datasetDescription :"This data set contains branch related data."});
        
      }
      else if(e.target.id === "branch_group_master_Dataset") {
        let datasetClsDict = this.state.datasetClsDict;
        datasetClsDict.branch_group_master_Dataset = datasetClsDict.branch_group_master_Dataset === "caret"? "caret caret-down" : "caret";
        let nestedDatasetCls = this.state.nestedDatasetCls;
        nestedDatasetCls.branch_group_master_Dataset = nestedDatasetCls.branch_group_master_Dataset === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
        this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "branch_group_master", selectedAttribute: "brcode", datasetDescription :"This data set contains branch group related data."});
      }
      else if(e.target.id === "item_master_Dataset") {
        let datasetClsDict = this.state.datasetClsDict;
        datasetClsDict.item_master_Dataset = datasetClsDict.item_master_Dataset === "caret"? "caret caret-down" : "caret";
        let nestedDatasetCls = this.state.nestedDatasetCls;
        nestedDatasetCls.item_master_Dataset = nestedDatasetCls.item_master_Dataset === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
        this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "item_master", selectedAttribute: "item_code", datasetDescription :"This data set contains item master related data."});
      }
      else if(e.target.id === "item_category_DataSet") {
        let datasetClsDict = this.state.datasetClsDict;
        datasetClsDict.item_category_DataSet = datasetClsDict.item_category_DataSet === "caret"? "caret caret-down" : "caret";
        let nestedDatasetCls = this.state.nestedDatasetCls;
        nestedDatasetCls.item_category_DataSet = nestedDatasetCls.item_category_DataSet === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
        this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "item_category", selectedAttribute: "Category_Code", datasetDescription :"This data set contains item category related data."});
      }
      else if(e.target.id === "stock_ledger_DataSet") {
        let datasetClsDict = this.state.datasetClsDict;
        datasetClsDict.stock_ledger_Dataset = datasetClsDict.stock_ledger_Dataset === "caret"? "caret caret-down" : "caret";
        let nestedDatasetCls = this.state.nestedDatasetCls;
        nestedDatasetCls.stock_ledger_Dataset = nestedDatasetCls.stock_ledger_Dataset === "nested has-no-caret" ? "nested has-no-caret active": "nested has-no-caret";
        this.setState({nestedDatasetCls: nestedDatasetCls, datasetClsDict: datasetClsDict, dataSetName: "stock_ledger", selectedAttribute: "brcode", datasetDescription :"This data set contains stock ledger related data."});
      }
 }

 setAttribute(e) {
    this.setState({selectedAttribute: e.target.id});
 }
 enableshowmodel(){
     this.setState({showmodal: true});
 }
 handleChange(){
      console.log("value changed")
 }
render() {
   let isChecked = this.getGoldenData();
   return(<div className="row no-gutters">
           <div className="col-md-3">
                        <div className="qd_tree-view-container">
                          <div className="form-group has-search">
                            <span className="form-control-feedback">
                              <FontAwesomeIcon icon={faSearch}/>
                            </span>
                            <input type="text" className="form-control" placeholder="Search"/>
                          </div>
                          <ul className="qd_tree-view">
                            <li><span className={this.state.mainClassName} onClick={this.changeMain}><FontAwesomeIcon icon={faDatabase}/> Main Datasources</span>
                              <ul className={this.state.nestedClsName}>
                                  {this.state.nestedClsName === "nested active" ?
                                        <><li><span className={this.state.datasetClsDict.allSalesDataset} id="allSalesDataset" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> all_sales_data</span>
                                           <ul className={this.state.nestedDatasetCls.allSalesDataset}>
                                                {                                                  
                                                all_sales_data.map((key, i)=>{
                                                    if(key.Key === "0")
                                                          return <li  id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}
                                                          </li>
                                                      else  if(key.Key === "1")
                                                          return <li  id={key.Name}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                          </li>
                                                     else 
                                                          return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                          </li>
                                                })} 
                                           </ul>
                                            </li>
                                            <li><span className={this.state.datasetClsDict.branch_group_master_Dataset} id="branch_group_master_Dataset" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> branch_group_mst</span>
                                                <ul className={this.state.nestedDatasetCls.branch_group_master_Dataset}>
                                                {
                                                    branch_group_master_dataset.map((key, i)=>{
                                                      if(key.Key === "0")
                                                          return <li onClick={this.setAttribute} id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                          </li>
                                                      else
                                                          return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                          </li>
                                                    })
                                                } 
                                                </ul>
                                            </li>
                                            <li><span className={this.state.datasetClsDict.branchDataset} id="branchDataset" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> branch</span>
                                                <ul className={this.state.nestedDatasetCls.branchDataset}>
                                                {branch_dataset.map((key, i)=>{
                                                   if(key.Key === "0")
                                                      return <li onClick={this.setAttribute} id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                  else
                                                      return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                })} 
                                                </ul>
                                            </li>
                                            <li><span className={this.state.datasetClsDict.item_master_Dataset} id="item_master_Dataset" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> item_master</span>
                                                <ul className={this.state.nestedDatasetCls.item_master_Dataset}>
                                                {item_master_dataset.map((key, i)=>{
                                                  if(key.Key === "0")
                                                      return <li onClick={this.setAttribute} id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                  else
                                                      return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                })} 
                                                </ul>
                                            </li>
                                            <li><span className={this.state.datasetClsDict.item_category_DataSet} id="item_category_DataSet" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> item_category</span>
                                                <ul className={this.state.nestedDatasetCls.item_category_DataSet}>
                                                {item_category_dataset.map((key, i)=>{
                                                  if(key.Key === "0")
                                                      return <li onClick={this.setAttribute} id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                  else
                                                      return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                })} 
                                                </ul>
                                            </li>
                                            <li><span className={this.state.datasetClsDict.stock_ledger_Dataset} id="stock_ledger_DataSet" onClick={this.changeDataset}><FontAwesomeIcon icon={faLayerGroup}/> stock_ledger</span>
                                                <ul className={this.state.nestedDatasetCls.stock_ledger_Dataset}>
                                                {stock_ledger_dataset.map((key, i)=>{
                                                  if(key.Key === "0")
                                                      return <li onClick={this.setAttribute} id={key.Name}  style={styles}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                  else
                                                      return <li onClick={this.setAttribute} id={key.Name} style={{"cursor":"pointer"}}><FontAwesomeIcon icon={faTag}/> {key.Name}                                                                                                                                                        
                                                      </li>
                                                })} 
                                                </ul>
                                            </li>
                                        </>
                                  :
                                  ""
                              }
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
           <div className="col-md-9">
                <div className="qd_tree-content-block">
                     <div className="qd_tree-info">
                          <h2 className="qd_tree-info-title">
                              <FontAwesomeIcon icon={faLayerGroup}/><span>{this.state.dataSetName}</span>
                          </h2>
                          <div className="row">
                              <div className="col-md-11">
                                <div className="mb-1 row">
                                  <label className="col-md-3">Dataset Name</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>{this.state.dataSetName}</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Owner</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>{this.owner()}</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Golden Datasource</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <div className="custom-control custom-switch">
                                                <input type="checkbox"
                                                       checked={isChecked}
                                                       onChange={this.handleChange}
                                                       className="custom-control-input"
                                                       name={this.state.dataSetName} />
                                                <label className="custom-control-label"
                                                       htmlFor={this.state.dataSetName}/>
                                            </div>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Last Processing Time</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>Wed, 22 Sep 2021 03:01:24 GMT</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Last Updated Time</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>Wed, 22 Sep 2021 13:23:32 GMT</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Last Accessed Time</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>Wed, 22 Sep 2021 17:09:06 GMT</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Allowed Users</label>
                                    <div class="qd-filtered-section">
                                      <div class="qd-filtered-body">
                                        <div class="qd-filtered_chips">
                                          <div class="chip chip-green" id="18">Mike</div>
                                          <div class="chip chip-green" id="18">Jordon</div>
                                          <div class="chip chip-green" id="18">Rob</div>
                                        </div>
                                        <div class="qd-filtered_button">
                                          <button class="btn btn-secondary text-white clickon__filter" onClick={this.enableshowmodel}>
                                            <span>Add Users</span>
                                          </button>
                                        </div>
                                      </div>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Successful Refresh</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>12</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Status</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>Completed</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Description</label>
                                  <div className="col-md-9 text-break text-muted textedit-icon">
                                    <textarea className="form-control" value={this.state.datasetDescription}>
                                    </textarea>
                                    <span className="icon-box"><FontAwesomeIcon icon={faPencilAlt}/></span>
                                  </div>
                                </div>
                              </div>
                          </div>
                          <hr className="clearfix"></hr>
                          <h2 className="qd_tree-info-title">
                             <FontAwesomeIcon icon={faCaretDown}/><span>Access History</span>
                          </h2>
                          <div className="react-bootstrap-table__header">
                              <div className="row">
                                <div className="col-6">
                                  <div className="btn-group react-table__length" role="group">
                                    <span>Show</span>
                                    <Select
                                         classNamePrefix='select-control'
                                         defaultValue={{"label": "5", "value": 5}}
                                         options={[{"label": "5", "value": 5},{"label": "10", "value": 10},{"label": "all", "value": ""}]}
                                    />
                                    <span>days</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="btn-group react-table__filter" role="group">
                                    <span>Search:</span>
                                    <div className="table-search">
                                      <input placeholder="Search" id="searchBox" className="form-control" type="text"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="react-bootstrap-table table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Users</th>
                                  <th>Privilege</th>
                                  <th>Last Accessed Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style={highlight}>Sharma</td>
                                  <td style={highlight}>Read/Write</td>
                                  <td style={highlight}>Wed, 22 Sep 2021 17:09:06 GMT</td>
                                </tr>
                                <tr>
                                  <td style={highlight}>Smith</td>
                                  <td style={highlight}>Read</td>
                                  <td style={highlight}>Tue, 21 Sep 2021 21:19:06 GMT</td>
                                </tr>
                                <tr>
                                  <td>Jordan</td>
                                  <td>Write</td>
                                  <td>Sun, 19 Sep 2021 10:09:06 GMT</td>
                                </tr>
                                <tr>
                                  <td>Mike</td>
                                  <td>Read</td>
                                  <td>Sat, 18 Sep 2021 22:09:16 GMT</td>
                                </tr>
                                <tr>
                                  <td>Rob</td>
                                  <td>Read/Write</td>
                                  <td>Sat, 18 Sep 2021 21:55:26 GMT</td>
                                </tr>
                              </tbody>
                            </table>
                            </div>
                            <div className="react-bootstrap-table__footer">
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <span className="react-table__info">Showing <b>1</b> to <b>5</b> of <b>8</b> results</span>
                                </div><div className="col-sm-12 col-md-6">
                                  <ul className="pagination float-md-right">
                                    <li className="active page-item" title="1">
                                      <a href="#!" className="page-link">1</a>
                                    </li>
                                    <li className="page-item" title="2">
                                      <a href="#!" className="page-link">2</a>
                                    </li>
                                    <li className="page-item" title="next page">
                                      <a href="#!" className="page-link">&gt;</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <hr className="clearfix"></hr>
                          <h2 className="qd_tree-info-title">
                             <FontAwesomeIcon icon={faCaretDown}/><span>Attribute</span>
                          </h2>
                          <div className="react-bootstrap-table__header">
                              <div className="row">
                                <div className="col-6">
                                  <div className="btn-group react-table__length" role="group">
                                    <span>Show</span>
                                    <Select
                                         classNamePrefix='select-control'
                                         defaultValue={{"label": "5", "value": 5}}
                                         options={[{"label": "5", "value": 5},{"label": "10", "value": 10},{"label": "all", "value": ""}]}
                                    />
                                    <span>items</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="btn-group react-table__filter" role="group">
                                    <span>Search:</span>
                                    <div className="table-search">
                                      <input placeholder="Search" id="searchBox" className="form-control" type="text"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="react-bootstrap-table table-responsive">                            
                                {this.getTableData()}
                            </div>
                            <div className="react-bootstrap-table__footer">
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <span className="react-table__info">Showing <b>1</b> to <b>5</b> of <b>8</b> results</span>
                                </div><div className="col-sm-12 col-md-6">
                                  <ul className="pagination float-md-right">
                                    <li className="active page-item" title="1">
                                      <a href="#!" className="page-link">1</a>
                                    </li>
                                    <li className="page-item" title="2">
                                      <a href="#!" className="page-link">2</a>
                                    </li>
                                    <li className="page-item" title="next page">
                                      <a href="#!" className="page-link">&gt;</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <hr className="clearfix"></hr>
                    </div>
                    <div className="qd_tree-info">
                         <h2 className="qd_tree-info-title">
                              <FontAwesomeIcon icon={faTag}/> <span>{this.state.selectedAttribute}</span>
                            </h2>
                         <div className="row">
                              <div className="col-md-11">
                                <div className="mb-1 row">
                                  <label className="col-md-3">Dataset Name</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>{this.state.dataSetName}</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Data Completeness</label>
                                  <div className="col-md-9 text-break text-muted">
                                    <span>100%</span>
                                  </div>
                                </div>
                                <div className="mb-1 row">
                                  <label className="col-md-3">Description</label>
                                  <div className="col-md-9 text-break text-muted textedit-icon">
                                    <textarea className="form-control" value={this.state.datasetDescription}>
                                    </textarea>
                                    <span className="icon-box"><FontAwesomeIcon icon={faPencilAlt}/></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr className="clearfix"></hr>
                            <h2 className="qd_tree-info-title">
                              <FontAwesomeIcon icon={faCaretDown}/> <span>Relationship</span>
                            </h2>
                            <div className="react-bootstrap-table__header">
                              <div className="row">
                                <div className="col-6">
                                  <div className="btn-group react-table__length" role="group">
                                    <span>Show</span>
                                       <Select
                                         classNamePrefix='select-control'
                                         defaultValue={{"label": "5", "value": 5}}
                                         options={[{"label": "5", "value": 5},{"label": "10", "value": 10},{"label": "all", "value": ""}]}
                                    />
                                    <span>items</span>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="btn-group react-table__filter" role="group">
                                    <span>Search:</span>
                                    <div className="table-search">
                                      <input placeholder="Search" id="searchBox" className="form-control" type="text"/>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="react-bootstrap-table table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>ID
                                      <span className="order">
                                        <span className="dropdown">
                                          <span className="caret"></span>
                                        </span>
                                        <span className="dropup">
                                          <span className="caret"></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th>Source Dataset
                                      <span className="order">
                                        <span className="dropdown">
                                          <span className="caret"></span>
                                        </span>
                                        <span className="dropup">
                                          <span className="caret"></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th></th>
                                    <th>Destination Dataset
                                      <span className="order">
                                        <span className="dropdown">
                                          <span className="caret"></span>
                                        </span>
                                        <span className="dropup">
                                          <span className="caret"></span>
                                        </span>
                                      </span>
                                    </th>
                                    <th>Relationship Score
                                      <span className="order">
                                        <span className="dropdown">
                                          <span className="caret"></span>
                                        </span>
                                        <span className="dropup">
                                          <span className="caret"></span>
                                        </span>
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.getAttributeRelationship()}
                                </tbody>
                              </table>
                            </div>
                            <div className="react-bootstrap-table__footer">
                              <div className="row">
                                <div className="col-sm-12 col-md-6">
                                  <span className="react-table__info">Showing <b>1</b> to <b>5</b> of <b>8</b> results</span>
                                </div><div className="col-sm-12 col-md-6">
                                  <ul className="pagination float-md-right">
                                    <li className="active page-item" title="1">
                                      <a href="#!" className="page-link">1</a>
                                    </li>
                                    <li className="page-item" title="2">
                                      <a href="#!" className="page-link">2</a>
                                    </li>
                                    <li className="page-item" title="next page">
                                      <a href="#!" className="page-link">&gt;</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <Row bsPrefix="form-row">
                                <Col lg={12}>
                                    <Row bsPrefix="form-row">
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                <div className="card-content">
                                                    <Card.Title>Errors Found</Card.Title>
                                                    <h1 className="card-text">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["error"]}</h1>
                                                </div>
                                                <div className="card-image">
                                                <Image
                                                className="card_line-graph"
                                                 src="linegraph"
                                                />
                                                </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                <div className="card-content">
                                                    <Card.Title>Total Space Occupied</Card.Title>
                                                    <h1 className="card-text">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["space"]}</h1>
                                                </div>
                                                <div className="card-image">
                                                <i><FontAwesomeIcon icon={faCloud} /></i>
                                                </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={4} md={6} xs={12}>
                                            <Card className="card-attribute">
                                                <Card.Body>
                                                    <div className="card-content">
                                                        <Card.Title>Number of Records</Card.Title>
                                                        <h1 className="card-text">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["record"]}</h1>
                                                    </div>
                                                    <div className="card-image">
                                                    <i><FontAwesomeIcon icon={faFolderOpen} /></i>
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row bsPrefix="form-row">
                                <Col lg={12}>
                                    <BasicPortlet
                                        video_url="profile_data"
                                        className="mb-3"
                                        bodyClassName="pb-0"
                                        title="Observation"
                                        srcOption={[]}
                                        content={
                                            <Row bsPrefix="form-row">
                                            <Col lg={3} md={6} xs={12}>
                                                <div className="dashboard-stat dashboard-stat-info">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-info">Empty values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["empty"]}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_emptyData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-success">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-success">Unique values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["unique"]}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_uniqueData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-warning">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-warning">Duplicate values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["duplicate"]}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_duplicateData" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={3} md={6} xs={12}>
                                            <div className="dashboard-stat dashboard-stat-danger">
                                                    <div className="details">
                                                        <div className="desc-header">
                                                            <div className="title text-danger">Error values</div>
                                                        </div>
                                                        <div className="desc">
                                                            <span className="desc-value">{ATTRIBUTE_VALUES[this.state.selectedAttribute]["error"]}</span>
                                                        </div>
                                                        <Image className="desc-img" src="icon_errorData" />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        }
                                    />
                                </Col>
                            </Row>
                    </div>
               </div>
           </div>
           <GovernFilterOptions
                ref={this.filterOptions}
                show={this.state.showmodal}
                closeFilterModal={this.toggleFilter}
                dataModule={this.props.dataModule}
                setFilteredValues={this.setFilteredValues}
                environments={[]}
                dataSourceOption={[]}
                dataSetOptions={[]}
                attributeOptions={[]}
                preSelectedEnvironment={[]}
                preSelectedDataSource={[]}
                preSelectedDatasets={[]}
                preSelectedAttributes={[]}
                filterTitle="User Access Filter"
                setChipData={this.setChipData}
                id="User Access"
            />
           </div>)

}

}

export default MetadataGlossary;