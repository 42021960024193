import {
    ADD_MONITOR_PREVIEW,
    ADD_PERFORMANCE_PREVIEW,
    ADD_COMPLETENESS_INFO,
    SET_MONITOR_INITIAL_VALUES,
    ADD_COMPLETENESS_ATTRIBUTE_INFO,
    ADD_TIMELINESS_INFO,
    ADD_TIMELINESS_ATTRIBUTE_INFO,
    ADD_ACCURACY_INFO,
    ADD_ACCURACY_ATTRIBUTE_INFO,
    ADD_DRIFT_INFO,
    ADD_DRIFT_ATTRIBUTE_INFO,
    ADD_CONFORMITY_INFO,
    ADD_CONFORMITY_ATTRIBUTE_INFO,
    ADD_CONSISTENCY_INFO,
    ADD_CONSISTENCY_ATTRIBUTE_INFO,
    ADD_ML_MODEL_MAPPING,
    ADD_ML_MODEL_INFO,
    ADD_ML_MODEL_ATTRIBUTE_INFO,
    ADD_ML_MODEL_PERFORMANCE,
    ADD_ML_MODEL_DETAILS, ADD_MONITOR_DQ_LIST_VIEW,
    ADD_METRICS,
    ADD_UNIQUENESS_INFO,
    ADD_UNIQUENESS_ATTRIBUTE_INFO,
    ADD_RECENCY_PREVIEW,
    ADD_FOLLOW_ATTRIBUTES,
    PREFETCH_CORRELATION,
    PREFETCH_MONITOR_STATE,
    FORM_DATASET_INFO,
    FORM_LISTVIEW_TABLE_DATA
} from "../actionTypes";
import {IS_NONE_CHECK, MONITOR_DATA_KEYS} from "../../utils/constant";

const initialState = {
    dqPreview: {},
    mlPreview: {},
    performancePreview: {},
    completenessDataSetInfo: {},
    completenessAttributeInfo: {},
    timelinessDataSetInfo: {},
    timelinessAttributeInfo: {},
    accuracyDataSetInfo: {},
    accuracyAttributeInfo: {},
    driftDataSetInfo: {},
    driftAttributeInfo: {},
    driftAttributeInfoCount: 0,
    conformityDataSetInfo: {},
    conformityAttributeInfo: {},
    consistencyDataSetInfo: {},
    consistencyAttributeInfo: {},
    uniquenessDataSetInfo: {},
    uniquenessAttributeInfo: {},
    mlModelMapping: {},
    mlModelDataSetInfo: {},
    mlModelDataSetInfo2: {},
    mlModelAttributeInfo: {},
    mlModelErrorFetch: 0,
    mlModelErrorAttributeFetch: 0,
    mlModelPerformanceInfo: {},
    modelPerformanceFetchCompleted: false,
    mlModelDetails: [],
    monitorDQListView: {"apiFetchOver": false},
    metrics: [],
    correlation_data: [],
    recencyPreview: {},
    follow_attributes: {},
    preFetchMonitorPreview: false,
    preFetchMonitorModelInfo: false,
    preFetchModelDetails: false,
    preFetchMetrics: false,
    preFetchFollowAttributes: false,
    preFetchMonitorListViewData: false,
    fetchInfo: {},
    mappedDatasetInfo : {},
    listViewTableData : []

};

export default function(state = initialState, action) {
    switch (action.type) {
        case SET_MONITOR_INITIAL_VALUES: {
            return initialState;
        }
        case ADD_ML_MODEL_MAPPING:
            return {
                ...state,
                mlModelMapping: action.payload.mlModelMapping,
                preFetchMonitorModelInfo: true
            }
        case ADD_MONITOR_PREVIEW:
//            commented lines are for dq monitor page

//            let dqPreview = action.payload.dqPreview;
//            let previousDqData = state.dqPreview;
//            let newDqData = {};
            let newMlData = {};
//
//            if (dqPreview === undefined || dqPreview === null){
//                dqPreview = {};
//            }
//            if (Object.keys(previousDqData).length === 0) {
//                newDqData = dqPreview
//            }

            let mlPreview = action.payload.mlPreview;
            let previousMlData = state.mlPreview;
            if (mlPreview === undefined || mlPreview === null){
                mlPreview = {};
            }
            if (Object.keys(previousMlData).length === 0) {
                newMlData = mlPreview
            }

//            let prev_dq_keys = Object.keys(previousDqData)
//            for (const key of prev_dq_keys) {
//                if (MONITOR_DATA_KEYS.includes(key)) {
//                    newDqData[key] =dqPreview[key]
//                } else {
//                    newDqData[key] = {
//                       ...previousDqData[key],
//                       ...dqPreview[key]
//                    }
//                }
//            }

            let prev_ml_keys = Object.keys(previousMlData)
            for (const key of prev_ml_keys) {
                if (MONITOR_DATA_KEYS.includes(key)) {
                    newMlData[key] =mlPreview[key]
                } else {
                    newMlData[key] = {
                       ...previousMlData[key],
                       ...mlPreview[key]
                    }
                }
            }
//
//            for (const current_key of Object.keys(dqPreview)) {
//                if (prev_dq_keys.includes(current_key)) {
//                    continue;
//                }
//                if (MONITOR_DATA_KEYS.includes(current_key)) {
//                    newMlData[current_key] =mlPreview[current_key]
//                } else {
//                    newDqData[current_key] = {
//                        ...dqPreview[current_key]
//                    }
//                }
//            }

            for (const current_key of Object.keys(mlPreview)) {
                if (prev_ml_keys.includes(current_key)) {
                    continue;
                }
                if (MONITOR_DATA_KEYS.includes(current_key)) {
                    newMlData[current_key] =mlPreview[current_key]
                } else {
                    newMlData[current_key] = {
                        ...mlPreview[current_key]
                    }
                }
            }
            return {
                ...state,
//                dqPreview: newDqData,
                mlPreview: newMlData
            };
        case ADD_RECENCY_PREVIEW:
            let recencyPreview = action.payload.recencyPreview;
            if (recencyPreview === undefined || recencyPreview === null){
                recencyPreview = {};
            }
            return {
                ...state,
                recencyPreview: recencyPreview,
            };
        case ADD_PERFORMANCE_PREVIEW:
            let payloadPerformancePreview = action.payload.performancePreview;
            if (payloadPerformancePreview === undefined || payloadPerformancePreview === null){
                payloadPerformancePreview = {};
            }

            return {
                ...state,
                performancePreview: payloadPerformancePreview,
                preFetchMonitorPreview: true

            }
        case PREFETCH_MONITOR_STATE:
            return {
                ...state,
                fetchInfo :{...state.fetchInfo,[action.payload.fetchState]: true}
            }
        case ADD_COMPLETENESS_ATTRIBUTE_INFO:
            let previousAttributeData = state.completenessAttributeInfo;
            let payloadCompletenessAttribute = action.payload.completenessAttributeInfo;
            if (payloadCompletenessAttribute === undefined || payloadCompletenessAttribute === null) {
                payloadCompletenessAttribute = {};
            }
            if (Object.keys(previousAttributeData).length === 0) {
                return {
                    ...state,
                    completenessAttributeInfo: payloadCompletenessAttribute
                }
            }
            let newAttributeData = {};
            let prev_completeness_attr_keys = Object.keys(previousAttributeData)
            for (const key of prev_completeness_attr_keys) {
                newAttributeData[key] = {
                   ...previousAttributeData[key],
                   ...payloadCompletenessAttribute[key]
                }
            }
            for (const current_key of Object.keys(payloadCompletenessAttribute)) {
                if (prev_completeness_attr_keys.includes(current_key)) {
                   continue;
                }

                newAttributeData[current_key] = {
                     ...payloadCompletenessAttribute[current_key]
                   }
            }
            return {
                ...state,
                completenessAttributeInfo: newAttributeData
            }
        case ADD_COMPLETENESS_INFO:
            let previousData = state.completenessDataSetInfo;
            let payloadCompletenessDatasetInfo = action.payload.completenessDataSetInfo;
            if (payloadCompletenessDatasetInfo === undefined || payloadCompletenessDatasetInfo === null) {
                payloadCompletenessDatasetInfo = {};
            }
            if (Object.keys(previousData).length === 0) {
                return {
                    ...state,
                    completenessDataSetInfo: payloadCompletenessDatasetInfo
                }
            }
            let newData = {};
            let prev_completeness_keys = Object.keys(previousData)
            for (const key of prev_completeness_keys) {
                newData[key] = {
                   ...previousData[key],
                   ...payloadCompletenessDatasetInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadCompletenessDatasetInfo)) {
                if (prev_completeness_keys.includes(current_key)) {
                    continue;
                }

                newData[current_key] = {
                        ...payloadCompletenessDatasetInfo[current_key]
                    }
            }
            return {
                ...state,
                completenessDataSetInfo: newData
            }
        case ADD_TIMELINESS_ATTRIBUTE_INFO:
            let previousTimeAttributeData = state.timelinessAttributeInfo;
            let payloadTimelinessAttribute = action.payload.timelinessAttributeInfo;
            if (payloadTimelinessAttribute === undefined || payloadTimelinessAttribute === null){
                payloadTimelinessAttribute = {};
            }
            if (Object.keys(previousTimeAttributeData).length === 0) {
                return {
                    ...state,
                    timelinessAttributeInfo: payloadTimelinessAttribute
                }
            }
            let newTimeAttributeData = {};
            let prev_timeliness_attr_keys = Object.keys(previousTimeAttributeData)
            for (const key of prev_timeliness_attr_keys) {
                newTimeAttributeData[key] = {
                   ...previousTimeAttributeData[key],
                   ...payloadTimelinessAttribute[key]
                }
            }
            for (const current_key of Object.keys(payloadTimelinessAttribute)) {
                if (prev_timeliness_attr_keys.includes(current_key)) {
                    continue;
                }

                newTimeAttributeData[current_key] = {
                       ...payloadTimelinessAttribute[current_key]
                    }
            }
            return {
                ...state,
                timelinessAttributeInfo: newTimeAttributeData
            }
        case ADD_TIMELINESS_INFO:
            let previousTimeData = state.timelinessDataSetInfo;
            let payloadTimelinessDataSetInfo = action.payload.timelinessDataSetInfo;
            if (payloadTimelinessDataSetInfo === undefined || payloadTimelinessDataSetInfo === null) {
                payloadTimelinessDataSetInfo = {};
            }
            if (Object.keys(previousTimeData).length === 0) {
                return {
                    ...state,
                    timelinessDataSetInfo: payloadTimelinessDataSetInfo
                }
            }
            let newTimeData = {};
            let prev_timeliness_keys = Object.keys(previousTimeData)
            for (const key of prev_timeliness_keys) {
                newTimeData[key] = {
                   ...previousTimeData[key],
                   ...payloadTimelinessDataSetInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadTimelinessDataSetInfo)) {
                if (prev_timeliness_keys.includes(current_key)) {
                    continue;
                }

                newTimeData[current_key] = {
                    ...payloadTimelinessDataSetInfo[current_key]
                }
            }
            return {
                ...state,
                timelinessDataSetInfo: newTimeData
            }
        case ADD_ACCURACY_ATTRIBUTE_INFO:
            let previousAccuracyAttributeData = state.accuracyAttributeInfo;
            let payloadAccuracyAttribute = action.payload.accuracyAttributeInfo;
            if (payloadAccuracyAttribute === undefined || payloadAccuracyAttribute === null) {
                payloadAccuracyAttribute = {};
            }
            if (Object.keys(previousAccuracyAttributeData).length === 0) {
                return {
                    ...state,
                    accuracyAttributeInfo: payloadAccuracyAttribute
                }
            }
            let newAttributeAccuracyData = {};
            let prev_accuracy_attr_keys = Object.keys(previousAccuracyAttributeData)
                for (const previous_key of prev_accuracy_attr_keys) {
                newAttributeAccuracyData[previous_key] = {
                   ...previousAccuracyAttributeData[previous_key],
                   ...payloadAccuracyAttribute[previous_key]
                }
            }
            for (const current_key of Object.keys(payloadAccuracyAttribute)) {
                if (prev_accuracy_attr_keys.includes(current_key)) {
                    continue;
                }

                newAttributeAccuracyData[current_key] = {
                    ...payloadAccuracyAttribute[current_key]
                }
            }
            return {
                ...state,
                accuracyAttributeInfo: newAttributeAccuracyData
            }
        case ADD_ACCURACY_INFO:
            let previousAccuracyData = state.accuracyDataSetInfo;
            let payloadAccuracyDataset = action.payload.accuracyDataSetInfo;
            if (payloadAccuracyDataset === undefined || payloadAccuracyDataset === null) {
                payloadAccuracyDataset = {};
            }
            if (Object.keys(previousAccuracyData).length === 0) {
                return {
                    ...state,
                    accuracyDataSetInfo: payloadAccuracyDataset
                }
            }
            let newAccuracyData = {};
            let prev_accuracy_keys = Object.keys(previousAccuracyData)
            for (const key of prev_accuracy_keys) {
                newAccuracyData[key] = {
                   ...previousAccuracyData[key],
                   ...payloadAccuracyDataset[key]
                }
            }
            for (const current_key of Object.keys(payloadAccuracyDataset)) {
                if (prev_accuracy_keys.includes(current_key)) {
                    continue;
                }

                newAccuracyData[current_key] = {
                    ...payloadAccuracyDataset[current_key]
                }
            }

            return {
                ...state,
                accuracyDataSetInfo: newAccuracyData
            }
        case ADD_DRIFT_ATTRIBUTE_INFO:
            // eslint-disable-next-line
            let previousDriftAttributeData = state.driftAttributeInfo;
            let previousDriftAttributeCount = state.driftAttributeInfoCount;
            // let payloadDriftAttribute = action.payload.driftAttributeInfo;
            let newAttributeDriftData = action.payload.driftAttributeInfo;
            // if (payloadDriftAttribute === undefined || payloadDriftAttribute === null) {
            //     payloadDriftAttribute = {};
            // }

            // if (Object.keys(previousDriftAttributeData).length === 0) {
            //     return {
            //         ...state,
            //         driftAttributeInfo: payloadDriftAttribute,
            //         driftAttributeInfoCount: previousDriftAttributeCount + 1
            //     }
            // }

            // let newAttributeDriftData = {};
            // for (const key of Object.keys(previousDriftAttributeData)) {
            //     newAttributeDriftData[key] = {
            //         ...previousDriftAttributeData[key],
            //         ...payloadDriftAttribute[key]
            //     }
            // }

            // let processedKeys = Object.keys(previousDriftAttributeData);
            // for (const newPayloadKey of Object.keys(payloadDriftAttribute)) {
            //     if(processedKeys.includes(newPayloadKey)){
            //         continue;
            //     }

            //     newAttributeDriftData[newPayloadKey] = {
            //         ...payloadDriftAttribute[newPayloadKey]
            //     }
            // }
            return {
                ...state,
                // driftAttributeInfo: previousDriftAttributeData,
                driftAttributeInfo: newAttributeDriftData,
                driftAttributeInfoCount: previousDriftAttributeCount + 1
            }
        case ADD_DRIFT_INFO:
            let previousDriftData = state.driftDataSetInfo;
            let payloadDriftDataset = action.payload.driftDataSetInfo;
            if (payloadDriftDataset === undefined || payloadDriftDataset === null) {
                payloadDriftDataset = {};
            }

            if (Object.keys(previousDriftData).length === 0) {
                return {
                    ...state,
                    driftDataSetInfo: payloadDriftDataset
                }
            }
            let newDriftData = {};
            for (const key of Object.keys(previousDriftData)) {
                newDriftData[key] = {
                    ...previousDriftData[key],
                    ...payloadDriftDataset[key]
                }
            }

            let processedKeysDriftDataset = Object.keys(previousDriftData);
            for (const newPayloadKey of Object.keys(payloadDriftDataset)) {
                if (processedKeysDriftDataset.includes(newPayloadKey)) {
                    continue;
                }

                newDriftData[newPayloadKey] = {
                    ...payloadDriftDataset[newPayloadKey]
                }
            }
            return {
                ...state,
                driftDataSetInfo: newDriftData
            }
        case ADD_CONFORMITY_ATTRIBUTE_INFO:
            let previousConformityAttributeData = state.conformityAttributeInfo;
            let payloadConformityAttributeInfo = action.payload.conformityAttributeInfo;
            if (payloadConformityAttributeInfo === undefined || payloadConformityAttributeInfo === null) {
                payloadConformityAttributeInfo = {};
            }
            if (Object.keys(previousConformityAttributeData).length === 0) {
                return {
                    ...state,
                    conformityAttributeInfo: payloadConformityAttributeInfo
                }
            }
            let newConformityAttributeData = {};
            let prev_conformity_attr_keys = Object.keys(previousConformityAttributeData)
            for (const key of prev_conformity_attr_keys) {
                newConformityAttributeData[key] = {
                   ...previousConformityAttributeData[key],
                   ...payloadConformityAttributeInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadConformityAttributeInfo)) {
                if (prev_conformity_attr_keys.includes(current_key)) {
                    continue;
                }

                newConformityAttributeData[current_key] = {
                    ...payloadConformityAttributeInfo[current_key]
                }
            }
            return {
                ...state,
                conformityAttributeInfo: newConformityAttributeData
            }
        case ADD_CONFORMITY_INFO:
            let previousConformityData = state.conformityDataSetInfo;
            let payloadConformityDataSetInfo = action.payload.conformityDataSetInfo;
            if (payloadConformityDataSetInfo === undefined || payloadConformityDataSetInfo === null){
                payloadConformityDataSetInfo = {};
            }
            if (Object.keys(previousConformityData).length === 0) {
                return {
                    ...state,
                    conformityDataSetInfo: payloadConformityDataSetInfo
                }
            }
            let newConformityData = {};
            let prev_conformity_keys = Object.keys(previousConformityData);
            for (const key of prev_conformity_keys) {
                newConformityData[key] = {
                    ...previousConformityData[key],
                    ...payloadConformityDataSetInfo[key]
                }
            }

            for (const current_key of Object.keys(payloadConformityDataSetInfo)) {
                if (prev_conformity_keys.includes(current_key)) {
                    continue;
                }

                newConformityData[current_key] = {
                    ...payloadConformityDataSetInfo[current_key]
                }
            }

            return {
                ...state,
                conformityDataSetInfo: newConformityData
            }
        case ADD_CONSISTENCY_ATTRIBUTE_INFO:
            let previousConsistencyAttributeData = state.consistencyAttributeInfo;
            let payloadConsistencyAttributeInfo = action.payload.consistencyAttributeInfo;
            if (payloadConsistencyAttributeInfo === undefined || payloadConsistencyAttributeInfo === null) {
                payloadConsistencyAttributeInfo = {};
            }
            if (Object.keys(previousConsistencyAttributeData).length === 0) {
                return {
                    ...state,
                    consistencyAttributeInfo: payloadConsistencyAttributeInfo
                }
            }
            let newConsistencyAttributeData = {};
            let prev_consistency_attr_keys = Object.keys(previousConsistencyAttributeData)
            for (const key of prev_consistency_attr_keys) {
                newConsistencyAttributeData[key] = {
                    ...previousConsistencyAttributeData[key],
                    ...payloadConsistencyAttributeInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadConsistencyAttributeInfo)) {
                if (prev_consistency_attr_keys.includes(current_key)) {
                    continue;
                }

                newConsistencyAttributeData[current_key] = {
                       ...payloadConsistencyAttributeInfo[current_key]
                    }
            }
            return {
                ...state,
                consistencyAttributeInfo: newConsistencyAttributeData
            }
        case ADD_CONSISTENCY_INFO:
            let previousConsistencyData = state.consistencyDataSetInfo;
            let payloadConsistencyDataSetInfo = action.payload.consistencyDataSetInfo;
            if (payloadConsistencyDataSetInfo === undefined || payloadConsistencyDataSetInfo === null) {
                payloadConsistencyDataSetInfo = {};
            }
            if (Object.keys(previousConsistencyData).length === 0) {
                return {
                    ...state,
                    consistencyDataSetInfo: payloadConsistencyDataSetInfo
                }
            }
            let newConsistencyData = {};
            let prev_consistency_keys = Object.keys(previousConsistencyData)
            for (const key of prev_consistency_keys) {
                newConsistencyData[key] = {
                   ...previousConsistencyData[key],
                   ...payloadConsistencyDataSetInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadConsistencyDataSetInfo)) {
                if (prev_consistency_keys.includes(current_key)) {
                    continue;
                }

                newConsistencyData[current_key] = {
                       ...payloadConsistencyDataSetInfo[current_key]
                    }
            }
            return {
                ...state,
                consistencyDataSetInfo: newConsistencyData
            }
        case ADD_ML_MODEL_ATTRIBUTE_INFO:
            const incomingNewData = action.payload.mlModelAttributeInfo;
            if (incomingNewData === undefined || incomingNewData === null){
                return state;
            }

            let previousMLAttributeData = state.mlModelAttributeInfo;
            if (Object.keys(previousMLAttributeData).length === 0){
                return {
                    ...state,
                    mlModelErrorAttributeFetch: state.mlModelErrorAttributeFetch + 1,
                    mlModelAttributeInfo: incomingNewData,
                };
            }

            let newMLAttributeData = {...previousMLAttributeData};

            for (const modelKey of Object.keys(incomingNewData)) {
                let previousModelData = previousMLAttributeData[modelKey];
                let modelLevelData = incomingNewData[modelKey];

                if (previousModelData === undefined) {
                    newMLAttributeData[modelKey] = modelLevelData;
                }
                else{
                    const updatedModelData = {...previousModelData};
                    newMLAttributeData[modelKey] = updatedModelData

                    for (const metricKey of Object.keys(modelLevelData)) {
                        let metricData = updatedModelData[metricKey];
                        const incomingMetricData = modelLevelData[metricKey];
                        if (metricData === undefined) {
                            updatedModelData[metricKey] = incomingMetricData;
                            continue;
                        }

                        updatedModelData[metricKey] = {...metricData, ...incomingMetricData};
                    }
                }
            }

            return {
                ...state,
                mlModelErrorAttributeFetch: state.mlModelErrorAttributeFetch + 1,
                mlModelAttributeInfo: newMLAttributeData
            }
        case ADD_ML_MODEL_INFO:
            const incomingNewDataDS = action.payload.mlModelDataSetInfo;
            if (incomingNewDataDS === undefined || incomingNewDataDS === null){
                return state;
            }

            let previousMLData = state.mlModelDataSetInfo2;
            if (Object.keys(previousMLData).length === 0){
                return {
                    ...state,
                    mlModelErrorFetch: state.mlModelErrorFetch + 1,
                    mlModelDataSetInfo2: action.payload.mlModelDataSetInfo,
                    mlModelDataSetInfo: action.payload.mlModelDataSetInfo
                };
            }

            let newMLDatasetData = {...previousMLData};

            for (const modelKey of Object.keys(incomingNewDataDS)) {
                let previousModelData = previousMLData[modelKey];
                let modelLevelData = incomingNewDataDS[modelKey];

                if (previousModelData === undefined) {
                    newMLDatasetData[modelKey] = modelLevelData;
                }
                else{
                    const updatedModelData = {...previousModelData};
                    newMLDatasetData[modelKey] = updatedModelData

                    for (const metricKey of Object.keys(modelLevelData)) {
                        let metricData = updatedModelData[metricKey];
                        const incomingMetricData = modelLevelData[metricKey];
                        if (metricData === undefined) {
                            updatedModelData[metricKey] = incomingMetricData;
                            continue;
                        }

                        updatedModelData[metricKey] = {...metricData, ...incomingMetricData};
                    }
                }
            }

            return {
                ...state,
                mlModelErrorFetch: state.mlModelErrorFetch + 1,
                mlModelDataSetInfo2: newMLDatasetData,
                mlModelDataSetInfo: newMLDatasetData
            }
        case ADD_ML_MODEL_PERFORMANCE:
            let previousPerformanceResults = state.mlModelPerformanceInfo;
            if (previousPerformanceResults === undefined){
                previousPerformanceResults = {};
            }

            let payloadMlModelPerformanceInfo = action.payload.mlModelPerformanceInfo;
            if (payloadMlModelPerformanceInfo === undefined || payloadMlModelPerformanceInfo === null){
                payloadMlModelPerformanceInfo = {};
            }

            if (Object.keys(previousPerformanceResults).length === 0) {
                return {
                    ...state,
                    modelPerformanceFetchCompleted: true,
                    mlModelPerformanceInfo: payloadMlModelPerformanceInfo
                }
            }

            let newPerformanceResult = {};
            for (const key of Object.keys(previousPerformanceResults)) {
                newPerformanceResult[key] = {
                    ...previousPerformanceResults[key],
                    ...payloadMlModelPerformanceInfo[key]
                }
            }
            return {
                ...state,
                modelPerformanceFetchCompleted: true,
                mlModelPerformanceInfo: newPerformanceResult
            }
        case ADD_ML_MODEL_DETAILS:
            let payloadMLModelDetails = action.payload.mlModelDetails;
            if (payloadMLModelDetails === undefined || payloadMLModelDetails === null){
                payloadMLModelDetails = [];
            }

            let newModelDetails = payloadMLModelDetails;
            return {
                ...state,
                mlModelDetails: newModelDetails,
                preFetchModelDetails: true
            }
        case ADD_MONITOR_DQ_LIST_VIEW:
            let previousDQListView = state.monitorDQListView;
            let currentDQListView = action.payload.monitorDQListView;

            if (currentDQListView === undefined || currentDQListView === null){
                return state;
            }

            let newDQListView = {...previousDQListView};
            if (currentDQListView.isRecency === true) {
                delete currentDQListView.isRecency;
                for (let _key of Object.keys(currentDQListView)) {
                        let currValue = currentDQListView[_key];
                        if (IS_NONE_CHECK.includes(newDQListView["dataset_level_data"])){
                            continue;
                        }

                        if (newDQListView["dataset_level_data"][_key] !== undefined && newDQListView["dataset_level_data"][_key] !== null) {
                            for (let _currKey of Object.keys(currValue)) {
                                newDQListView["dataset_level_data"][_key][_currKey] = currValue[_currKey];
                            }
                        } else {
                            newDQListView["dataset_level_data"][_key] = currValue;
                        }
                    }
            } else {
                for (const listKey of Object.keys(currentDQListView)) {
                    let previousListData = previousDQListView[listKey];
                    let currentListData = currentDQListView[listKey];
                    if (listKey === "ordered_data_set_ids") {
                        if (previousListData !== null && previousListData !== undefined) {
                            newDQListView[listKey] = previousListData.concat(currentListData);
                        } else {
                            newDQListView[listKey] = currentListData
                        }
                    } else {
                        if (previousListData !== null && previousListData !== undefined) {
                            newDQListView[listKey] = {...previousListData, ...currentListData}
                        } else {
                            newDQListView[listKey] = currentListData
                        }
                    }
                }
            }
            newDQListView["apiFetchOver"] = true;
            return {
                ...state,
                monitorDQListView: newDQListView,
                preFetchMonitorListViewData: true
            }

        case ADD_METRICS:
             let metrics = action.payload.metrics;
             if (metrics === undefined || metrics === null){
                metrics = [];
            }
            return {
                ...state,
                metrics: metrics,
                preFetchMetrics: true
            }

        case ADD_FOLLOW_ATTRIBUTES:
             let follow_attributes = action.payload.follow_attributes;
             if (follow_attributes === undefined || follow_attributes === null){
                follow_attributes = {};
            }

            return {
                ...state,
                follow_attributes: follow_attributes,
                preFetchFollowAttributes: true
            }

        case ADD_UNIQUENESS_ATTRIBUTE_INFO:
            let previousUniquenessAttributeData = state.uniquenessAttributeInfo;
            let payloadUniquenessAttributeInfo = action.payload.uniquenessAttributeInfo
            if (payloadUniquenessAttributeInfo === undefined || payloadUniquenessAttributeInfo === null) {
                payloadUniquenessAttributeInfo = {};
            }
            if (Object.keys(previousUniquenessAttributeData).length === 0) {
                return {
                    ...state,
                    uniquenessAttributeInfo: payloadUniquenessAttributeInfo
                }
            }
            let newUniquenessAttributeData = {};
            let prev_uniqueness_attr_keys = Object.keys(previousUniquenessAttributeData)
            for (const key of prev_uniqueness_attr_keys) {
                newUniquenessAttributeData[key] = {
                    ...previousUniquenessAttributeData[key],
                    ...payloadUniquenessAttributeInfo[key]
                }
            }
            for (const current_key of Object.keys(payloadUniquenessAttributeInfo)) {
                if (prev_uniqueness_attr_keys.includes(current_key)) {
                    continue;
                }

                newUniquenessAttributeData[current_key] = {
                       ...payloadUniquenessAttributeInfo[current_key]
                    }
            }
            return {
                ...state,
                uniquenessAttributeInfo: newUniquenessAttributeData
            }

        case PREFETCH_CORRELATION:
             let correlation_data = action.payload.correlation_data;
             if (correlation_data === undefined || correlation_data === null){
                correlation_data = [];
            }
            return {
                ...state,
                correlation_data: correlation_data,
            }

        case ADD_UNIQUENESS_INFO:
            let previousUniquenessData = state.uniquenessDataSetInfo;
            let payUniquenessDataSetInfo = action.payload.uniquenessDataSetInfo;
            if (payUniquenessDataSetInfo === undefined || payUniquenessDataSetInfo === null) {
                payUniquenessDataSetInfo = {};
            }
            if (Object.keys(previousUniquenessData).length === 0) {
                return {
                    ...state,
                    uniquenessDataSetInfo: payUniquenessDataSetInfo
                }
            }
            let newUniquenessData = {};
            let prev_uniqueness_keys = Object.keys(previousUniquenessData)
            for (const key of prev_uniqueness_keys) {
                newUniquenessData[key] = {
                   ...previousUniquenessData[key],
                   ...payUniquenessDataSetInfo[key]
                }
            }
            for (const current_key of Object.keys(payUniquenessDataSetInfo)) {
                if (prev_uniqueness_keys.includes(current_key)) {
                    continue;
                }

                newUniquenessData[current_key] = {
                       ...payUniquenessDataSetInfo[current_key]
                    }
            }
            return {
                ...state,
                uniquenessDataSetInfo: newUniquenessData
            }
        case FORM_DATASET_INFO:
            return {
                ...state,
                mappedDatasetInfo: action.payload.data,
        }
        case FORM_LISTVIEW_TABLE_DATA:
            return {
                ...state,
                listViewTableData: action.payload.data,
            }
        default:
            return state;
        }
}
