import React from 'react';
import {postDatasource}  from "../../../../utils/event_handling";
import { userValidation } from '../../../../utils/common_utils';
import Form from 'react-bootstrap/Form';
import * as yup from 'yup';
import {TEST_CONNECTION_MESSAGES, FILE_TYPE_OPTIONS} from "../../../../utils/constant";
import {  faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import Select from 'react-select';
import { Formik, ErrorMessage } from 'formik';


class DatabricksSqlForm extends React.Component {
    constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postAzureDeltaTable = this.postAzureDeltaTable.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangePasswordIcon = this.ChangePasswordIcon.bind(this);
        this.state = {
            integration_data: this.props.integration_data,
            integration_data_edit: "",
            isChecked: true,
            hideSubmitButton: false,
            passwordIcon:faEye,
            fileType: null,
            selectOptionKey:"emptyFileType",
            passwordType:'password',
        };
    }

    componentDidMount() {
        this.fill_details();
    }

    toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
    }

    fill_details() {
        if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined && this.props.ex_integration_details.integration_config  !== undefined) {
            let ex_integration_details = this.props.ex_integration_details;
            let integration_data = this.state.integration_data;
            const exIntegrationConfig = ex_integration_details.integration_config;
            integration_data.host = exIntegrationConfig.host;
            integration_data.http_path = exIntegrationConfig.http_path;
            integration_data.schema = exIntegrationConfig.schema;
            integration_data.error_storage = exIntegrationConfig.error_storage;
            integration_data.table = exIntegrationConfig.table;
            let fileType = this.props.ex_integration_details.integration_config.filetype;
            integration_data.fileType = fileType
            integration_data.catalog = exIntegrationConfig.catalog;
            integration_data.created_time = exIntegrationConfig.created_time;
            integration_data.secret_key = ex_integration_details.auth_config.secret_key;
            integration_data.integration_name = ex_integration_details.integration_name;
            integration_data.integration_id = this.props.ex_integration_details["integration_id"];
            integration_data.integration_type = "azure_databricks_table";

           let defaultErrorStorage = exIntegrationConfig.default_error_storage;
           if (defaultErrorStorage === undefined) {
                defaultErrorStorage = true;
           }

            this.setState({isChecked: defaultErrorStorage,
                selectOptionKey: "updatedFileType",
                fileType:{"label": fileType, "value": fileType},
                integration_data_edit:integration_data
            });

        } else {
            let integration_data = this.state.integration_data;
            integration_data.filetype= "json";
            this.setState({integration_data_edit:integration_data});
        }
    }

    disableButton() {
            this.setState({hideSubmitButton: true});
    }

    postAzureDeltaTable(event){
        this.disableButton();
        const {
            integration_name, host, http_path, catalog, schema, table,fileType,
            error_storage, secret_key,
            created_time
        } = event;

        let azureDeltaTable = {"integration_name":integration_name,
                             "data_store_type":"database",
                             "integration_id":this.state.integration_data.integration_id,
                             "integration_type":"azure_databricks_sql",
                             "host": host,
                             "secret_key":secret_key,
                             "http_path": http_path,
                             "catalog": catalog,
                             "schema": schema,
                             "table": table,
                             "error_storage": error_storage,
                             "filetype": fileType,
                             "created_time": created_time,
                             "isFirstIntegration": this.props.isFirstIntegration,
                             "env_id":this.props.env_id
                             }
        let default_message = TEST_CONNECTION_MESSAGES["azure_databricks_table"];
        this.props.closeTestConnection();
        this.props.setSubmitButton(true);
        this.props.setData(azureDeltaTable, default_message);
        this.postDatasource(azureDeltaTable);

    }

    ChangePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
            this.setState({passwordIcon: faEyeSlash});
            this.setState({passwordType: "text"})
          } else {
            this.setState({passwordIcon: faEye});
            this.setState({passwordType: "password"})
          }
    }

    render() {
        let azureDeltaTableSchema = '';
        if(this.state.isChecked === false) {
            azureDeltaTableSchema =  yup.object({
                integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                    'DatasourceName test',
                    'invalid Datasource name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                host: yup.string().required('Databricks URL is a required field'),
                http_path: yup.string().required('Databricks HTTP Path is a required field'),
                catalog: yup.string().required('Catalog is a required field'),
                schema: yup.string().required('Schema is a required field'),
                fileType: yup.string().required('File type is a required field'),
                secret_key: yup.string().required('Databricks Access token is a required field'),
                error_storage: yup.string().required('Error Storage is a required field'),
            });
        }else {
            azureDeltaTableSchema =  yup.object({
                integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                    'DatasourceName test',
                    'invalid Datasource name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                host: yup.string().required('Databricks URL is a required field'),
                http_path: yup.string().required('Databricks HTTP Path is a required field'),
                catalog: yup.string().required('Catalog is a required field'),
                schema: yup.string().required('Schema is a required field'),
                fileType: yup.string().required('File type is a required field'),
                secret_key: yup.string().required('Databricks Access token is a required field'),
            });
        }

        let initial_values = {
            integration_name: '', host: '', http_path: '', catalog: '', schema: '', table: '', fileType:'', secret_key: '', error_storage: '',
            created_time: ''
        }
        let edit_integration = null;
        if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
            edit_integration = true;
        }
        else {
            edit_integration = false;
        }
        return (
                <>
                    <Formik
                          validationSchema={azureDeltaTableSchema}
                          onSubmit={this.postAzureDeltaTable}
                          initialValues={(edit_integration === true) ? this.state.integration_data_edit : initial_values}
                          enableReinitialize={true}                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <Form.Group controlId="integration_name" className="col-md-6">
                            <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="integration_name"
                                  value={values.integration_name === undefined ? "" : values.integration_name}
                                  onChange={handleChange}
                                  placeholder="Datasource Name"
                                  onBlur={handleBlur}
                                  isInvalid={errors.integration_name && touched.integration_name}/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.integration_name && errors.integration_name }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="host" className="col-md-6">
                            <Form.Label>Server Hostname<span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="host"
                                  value={values.host === undefined ? "" : values.host}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={errors.host && touched.host}
                                  placeholder="Databricks URL"/>
                            <Form.Control.Feedback type="invalid">
                                  { touched.host && errors.host }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="secret_key" className="col-md-6">
                            <Form.Label>Databricks Access token <span className="text-danger">*</span></Form.Label>
                            <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faLock}/>
                                                    </i>
                                <Form.Control
                                    type={this.state.passwordType}
                                    className="form-control"
                                    placeholder="Databricks Access Token"
                                    value={values.secret_key === undefined ? "" : values.secret_key}
                                    onBlur={handleBlur}
                                    isInvalid={errors.secret_key && touched.secret_key}
                                    name="secret_key"
                                    onChange={handleChange}
                                    autoComplete={this.props.autocomplete}/>
                                <Form.Control.Feedback type="invalid">
                                        { touched.secret_key && errors.secret_key }
                                </Form.Control.Feedback>
                            <i className="security-eye">
                                                    <FontAwesomeIcon onClick={this.ChangePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                                </i>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="http_path" className="col-md-6">
                            <Form.Label>HTTP Path <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={values.http_path === undefined ? "" : values.http_path}
                                  placeholder="HTTP Path"
                                  onBlur={handleBlur}
                                  isInvalid={errors.http_path && touched.http_path}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  name="http_path"/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.http_path && errors.http_path }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="catalog" className="col-md-6">
                            <Form.Label>Catalog <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="Catalog"
                                  value={values.catalog === undefined ? "" : values.catalog}
                                  onBlur={handleBlur}
                                  name="catalog"
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  isInvalid={errors.catalog && touched.catalog}
                                  />
                            <Form.Control.Feedback type="invalid">
                                       { touched.catalog && errors.catalog }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="schema" className="col-md-6">
                            <Form.Label>Schema <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={values.schema === undefined ? "" : values.schema}
                                  placeholder="Schema"
                                  onBlur={handleBlur}
                                  isInvalid={errors.schema && touched.schema}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  name="schema"/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.schema && errors.schema }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="table" className="col-md-6">
                            <Form.Label>Table name </Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="Table name"
                                  value={values.table === undefined ? "" : values.table}
                                  onBlur={handleBlur}
                                  name="table"
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">
                                       { touched.table && errors.table }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="fileType" className="col-md-6">
                            <Form.Label>Format <span className="text-danger">*</span></Form.Label>
                                <Select name="fileType"
                                        filterOption={({label}, query) => label.includes(query)}
                                        onChange={selectedOption => {
                                                    handleChange("fileType")(selectedOption.value);
                                        }}
                                        isInvalid={errors.fileType && touched.fileType}
                                        id={(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) ? "blobFileTypeEdit" : "blobFileTypeAdd"}
                                        key={this.state.selectOptionKey}
                                        defaultValue={this.state.fileType}
                                        options={FILE_TYPE_OPTIONS}
                                        placeholder="Choose file type"
                                        classNamePrefix='select-control'
                                    />
                                <ErrorMessage component="div" className="error-text" name="fileType" />
                        </Form.Group>
                        <Form.Group controlId="created_time" className="col-md-6">
                            <Form.Label>Incremental Data Identifier </Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="created_time"
                                  placeholder="Incremental Data Identifier"
                                  value={values.created_time === undefined ? "" : values.created_time}
                                  onBlur={handleBlur}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="checkbox col-md-12" style={{"padding": "10px 5px"}}>
                             <Form.Label>
                                 <Form.Control
                                       type="checkbox"
                                       name="errorColumn"
                                       onChange={this.toggleChange}
                                       checked={this.state.isChecked}
                                       />
                                 <span className="checkbox-decorator">
                                        <span className="check">
                                        </span>
                                 </span>Store Data Issues and Errors in Qualdo™
                             </Form.Label>
                        </div>
                        {this.state.isChecked === false ?
                        <Form.Group controlId="error_storage" className="col-md-6">
                            <Form.Label>Error Table Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="error_storage"
                                  placeholder="Error Table Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={errors.error_storage && touched.error_storage}
                                  value={values.error_storage === undefined ? "" : values.error_storage}/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.error_storage && errors.error_storage }
                            </Form.Control.Feedback>
                         </Form.Group> : ''}
                    </div>
                    <hr className="clearfix"/>
                    <div className="text-right">
                         {this.props.showCancel ?
                             <button type="button"
                                     onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)}
                                     className="btn btn-outline btn-grey btn-circle mr-2">
                                 Cancel
                             </button> : ''
                         }
                         <button type="submit"
                                 disabled={this.state.hideSubmitButton}
                                 className="btn btn-primary btn-circle mr-2">
                             Test Connection
                         </button>
                    </div>
                    </Form>)}
                    </Formik>
                </>

        );
    }
}
export default DatabricksSqlForm;