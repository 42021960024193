import React from 'react';
import Image from '../../components/image';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar,faCheck, faSearch} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import BasicPortlet from '../../components/basicPortlet';
import ChartBody from '../../components/chartBody';
class Lineage extends React.Component {
    constructor(props) {
        super(props);  
        let startDate = moment().subtract(10, 'days');
        let endDate =  moment().utc().endOf('day');  
        this.createModal = this.createModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);  
        this.state = {
          startDate: startDate,
          endDate: endDate,
          openModal: false
        }
    }  

    cancelModal() {
      this.setState({openModal: false});
   }

   createModal() {
      this.setState({openModal: true});
   }

    render() { 
      let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
   };
        return (
          <>
          {/* Modal START */}
          <Modal show={this.state.openModal}  onHide={this.cancelModal} style={{"top":"15%"}}>
          <Modal.Header closeButton className="border-0 justify-content-center d-block">
              <h5 className="modal-title">
                  Data Relationship Filter</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Environment <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Environment" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "Stage", "value":1}
                                  ]}
                        defaultValue={{ "label": "Stage", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Data Source <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select DataSource" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "retail-data", "value":1}
                                  ]}
                         defaultValue={{ "label": "retail-data", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Dataset <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Schemas" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "branch", "value":1},
                                    {"label": "branch_group_mst", "value":2},
                                    {"label": "item_category", "value":3},
                                    {"label": "item_master", "value":4}, 
                                    {"label": "sales_dataset", "value":5},
                                    {"label": "stock_ledger", "value":6}
                                  ]}
                         defaultValue={{ "label": "branch", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Column <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Column" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "Column 1", "value":1},                                  
                                    {"label": "Column 2", "value":2},
                                    {"label": "Column 3", "value":3},
                                    {"label": "Column 4", "value":4},
                                    {"label": "Column 5", "value":5},
                                    {"label": "Column 6", "value":6}
                                  ]}
                      />
              </div>             
            </div>

            <div className="form-group mb-0 mt-2">
                            <span className="switch switch-sm switch-info">
                                <label className="m-0">
                                    Include PII
                                    <input className="form-check-input" disabled checked={true} type="checkbox"/>
                                    <span className="m-0 mx-2"></span>
                                </label>
                            </span>
                        </div>
          </Modal.Body>
          <Modal.Footer className=" border-0 justify-content-right">
              <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.cancelModal}>Cancel</button>
              <button type="button" onClick={this.cancelModal}
                  className="btn btn-primary btn-circle">
                     <i> <FontAwesomeIcon icon={faCheck} /></i>
                    Apply</button>
          </Modal.Footer>
          </Modal>
  {/* Modal END */}

            <div className="tab-pane active" id="page-nav-tab8__content" role="tabpanel" aria-labelledby="page-nav-tab8">
                <div className="qd-filtered-section">
                  <div className="qd-filtered-body">
                    <div className="qd-filtered_chips">
                      <div className="chip chip-blue">
                        Datasources
                        <i className="close"></i>
                      </div>
                      <div className="chip chip-blue">
                        Table
                        <i className="close"></i>
                      </div>
                      <div className="chip chip-blue">
                        Environment
                        <i className="close"></i>
                      </div>
                    </div>
                    <div className="qd-filtered_button">                  
                      <button className="btn btn-secondary text-white" onClick={this.createModal} data-toggle="modal" data-target="#modal__track-issue">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" viewBox="0 0 24 24" width="24">
                          <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg> <span>Filters</span>
                      </button>
                    </div>
                  </div>
                </div>
{/* Widgets START */}
                <div className="form-row mt-3">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-info">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-info">Attributes with 0-25% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">10</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartinfo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-success">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-success">Attributes with 25%-50% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">10</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartsuccess" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-warning">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-warning">Attributes with 50%-75% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">5</span>
                        </div>
                        <div className="chart">
                          <Image src="areachartwarning" />
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-danger">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-danger">Attributes with > 75% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">2</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartdanger" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  {/* Widgets END */}
                <div className="portlet">
                  <div className="portlet-title">
                    <div className="caption">
                      <span className="text-uppercase font-weight-bold">Data visualization</span>
                    </div>
                    <div className="actions">
                     <div style={{"marginRight":"10px"}}>
                    <Select
                        placeholder="Select DataSource" classNamePrefix='select-control'
                        options={[                                  
                                    {"label": "retail_data", "value":1}
                                  ]}
                        defaultValue={{ "label": "retail_data", "value":1 }}
                      />
                      </div>
                      <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                             startDate={this.state.startDate}
                                             onApply={this.handleTimeFilter}
                                             endDate={this.state.endDate}
                                             ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span className="ml-2 d-none d-md-inline-block">{this.state.startDate.format('YYYY-MM-DD')} - {this.state.endDate.format('YYYY-MM-DD')}</span>
                            </DateRangePicker>
                    
                      <a href="#!" className="help-icon" data-toggle="modal" data-target="#model__help"><i className="fa fa-question-circle"></i></a>
                    </div>
                  </div>
              {/* Chart START */}
                  <div className="portlet-body">                   
                    <div className="portlet-chart-title">                     
                      <div className="text-center">
                        <div>
                          <BasicPortlet
                                            video_url="profile_data"
                                            className="pb-0"
                                            title=""
                                            srcOption={{}}
                                            bodyClassName="pb-0"
                                            id="lineagechord"
                                            content={
                                              <ChartBody
                                              classValue="chart"
                                              id="chordProfileData"
                                              page="profile"
                                              chartType="chordDependency"
                                              style={{height: "250px"}}

                                              />
                                            }
                          />
                         
                        </div>
                      </div>
                    </div>
                  </div>
  {/* Chart END */}



                </div>

                </div>
                </>
        );
     }
}

export default (Lineage);