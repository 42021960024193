import React from 'react';
import Image from '../../components/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faExclamationTriangle, faHourglassStart, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {handleSubmit, getQualdoIps}  from "../../utils/event_handling";
import Load from '../../components/loadAction';
// import {get_document_link} from "../../utils/common_utils";
import {TEST_CONNECTION_MESSAGES} from "../../utils/constant";




class TestConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            integration_data:this.props.integration_data,
            submit_button:true,
            disableFinishSetup: false,
        };
        this.getQualdoIps = getQualdoIps.bind(this);

    }

    renderTestSteps() {
        const children = [];
        let stages = null;
        if (this.props.testConnection_data !== null && this.props.testConnection_data !== undefined) {
            if (this.props.testConnection_data.code === 403){
                stages = [{"stage" : 1,
                          "name" : this.props.testConnection_data.error,
                          "status" : false
                          }];
            }
            else if (this.props.integration_data !== undefined && this.props.integration_data.integration_type === 'clickhouse'){
                stages = TEST_CONNECTION_MESSAGES.clickhouse.stages;

            }

            else{
            stages = this.props.testConnection_data.stages;
            }

            if (stages === undefined || stages === null ) {
                return children;
            }

            if (this.props.integration_data !== undefined && this.props.integration_data.integration_type === 'clickhouse'){

                for (let s = 0; s < stages.length; s++){
                    let d = stages[s];
                    children.push(<p key={d["stage"]}><strong>Step {d["stage"]}: </strong>{d['name']} - <span className="text-success"><FontAwesomeIcon icon={faCheckCircle}/> Done</span></p>);

                }

                return children;


            }
            else{

            for (let s = 0; s < stages.length; s++) {
                let d = stages[s];
                if (d['status'] === null || d['status'] === false ) {
                    children.push(<p key={d["stage"]}><strong>Step {d["stage"]}: </strong>{d['name']} - <span className="text-danger"><FontAwesomeIcon icon={faTimesCircle}/> Failed</span></p>);
                } else if(d['status'] === true || d['status'] === "True") {
                    children.push(<p key={d["stage"]}><strong>Step {d["stage"]}: </strong>{d['name']} - <span className="text-success"><FontAwesomeIcon icon={faCheckCircle}/> Done</span></p>);
                } else if(d['status'] === "in-progress") {
                    children.push(<p key={d["stage"]}><strong>Step {d["stage"]}: </strong>{d['name']} - <span className="text-info"><FontAwesomeIcon icon={faHourglassStart}/> In-progress </span></p>);
                }
            }
            return children;
            }
        }else {
           return (<Load/>);
        }

    }

    render() {
        return (
            <div
                className="alert alert-light shadow-sm alert__testing-connection col-md-8 offset-md-2 col-smd-offset-2 text-center test-connection my-3"
                role="alert">
                <h4 className="alert-heading">
                    <Image className="brand-logo" src="blob"/>
                     Connecting using the configured parameters
                </h4>
                <hr/>

                {this.renderTestSteps(this.props.testConnection_data)}
                {this.props.testConnection !== undefined ? '' :
                    <>
                        { this.props.testConnection_data.status_code === 405 ?
                            <div className="alert alert-danger d-inline-flex align-items-center m-0">
                                <h5 className="mr-2 mr-sm-3 mb-0 text-danger"><FontAwesomeIcon icon={faExclamationTriangle} /></h5>
                                <span>Make sure Qualdo's IP : <strong>{this.props.ip_address}</strong> is added to your authorised network(s).</span>
                            </div>
                        :
                        (this.props.testConnection_data.status_code === 200 ? '':
                            <div className="alert alert-info d-inline-flex align-items-center m-0">
                                <h5 className="mr-2 mr-sm-3 mb-0 text-info"><FontAwesomeIcon icon={faInfoCircle} /></h5>
                                <span>Make sure Qualdo's IP : <strong>{this.props.ip_address}</strong> is added to your authorised network(s).</span>
                            </div>)
                        }
                        <hr/>   
                        <button type="button" onClick={this.props.closeTestConnection}
                                className="btn btn-outline btn-grey btn-circle">
                                Close
                        </button>
                        {
                           this.props.integration_data!==undefined && this.props.integration_data.integration_type === 'clickhouse'?
                            <button type="submit" name="save_integration"
                                                            className="btn btn-primary btn-circle action__to-show"
                                                            onClick={handleSubmit.bind(this)}
                                                            disabled={this.state.disableFinishSetup}>
                                                        <FontAwesomeIcon icon={faCheckCircle}/> Finish setup
                            </button>
                            :
                                ''
                        }
                        {this.props.submit_button ?
                                '' 
                            :
                            <button type="submit" name="save_integration"
                                    className="btn btn-primary btn-circle action__to-show"
                                    onClick={handleSubmit.bind(this)}
                                    disabled={this.state.disableFinishSetup}>
                                <FontAwesomeIcon icon={faCheckCircle}/> Finish setup
                            </button>
                        }
                    </>
                }
                <hr/>

                {/* {this.props.testConnection_data !== null && this.props.testConnection_data.status_code !== 200  ?
                 <><p>Please refer to the
                     <a href={get_document_link()} rel="noopener noreferrer" target="_blank"> document </a>
                     for detailed information on setting up necessary permissions and fetching the expected inputs.</p>
                  <hr/></>
                  :
                  ''
                } */}


            </div>
        );
    }

}

export default TestConnection;