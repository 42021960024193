import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Select from 'react-select';
import {CopyRightsFooter} from '../components/copyrights';
import Modal from 'react-bootstrap/Modal';
import { faEdit, faBorderAll, faList,faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const probStyle = {
  border: "1px solid #ccc",
  padding:"2%",
  lineHeight: "170%"

}
class Govern extends React.Component {

    constructor(props) {
        super(props);
        this.handleTab = this.handleTab.bind(this);
        this.createIssue = this.createIssue.bind(this);
        this.cancelCreateIssue = this.cancelCreateIssue.bind(this);
        this.openViewDetails = this.openViewDetails.bind(this);
        this.cancelViewDetails = this.cancelViewDetails.bind(this);
        this.state={
            activeKey: "myIssues",
            openCreateIssue: false,
            openViewDetails:false,
            ViewDetailsDescription:""
        }
    }

    cancelCreateIssue() {
       this.setState({openCreateIssue: false});
    }

    createIssue() {
       this.setState({openCreateIssue: true});
    }
    openViewDetails= value => () => {   
      this.setState({openViewDetails: true, ViewDetailsDescription:value});
    }
	 cancelViewDetails() {
      this.setState({openViewDetails: false});
    }
    handleTab(key,tab) {
        this.setState ({activeKey: key});
    }
    render() {
        return (
                <>
                <Modal show={this.state.openCreateIssue}  centered onHide={this.cancelCreateIssue}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title">
                        Track this issue</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label className="form-label">Assign to :</label>
                      <Select
                         placeholder=""
                         classNamePrefix='select-control'
                         options={[{"label": "Mark", "value":1},{"label": "Alex", "value":2},{"label": "Sam", "value":3}, {"label": "Nathan", "value":4}]}
                      />
                    </div>
                    <div className="form-group col-md-12">
                      <label className="form-label">Description or Problem :</label>
                      <textarea className="form-control"></textarea>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className=" border-0 justify-content-center" centered>
                    <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.cancelCreateIssue}>Cancel</button>
                    <button type="button" onClick={this.cancelCreateIssue}
                        className="btn btn-primary btn-circle">Submit</button>
                </Modal.Footer>
                </Modal>

                {/* Modal for View Details  START*/}

                <Modal show={this.state.openViewDetails}  centered onHide={this.cancelViewDetails}>
                                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                      <h5 className="modal-title font-weight-bold">
                          View Details</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row" style={{"marginBottom":"10%"}}>
                      <div className="form-group col-md-12">
                        <label className="form-label">Description or Problem </label>
                        <div style={probStyle} className="text-muted"> 
                            {this.state.ViewDetailsDescription}
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
          {/* Modal for View Details  END*/}


                <NavigationHeader page="govern"/>
                <main>
                    <BreadCrumb icon='clipboardCheck' title='Issue Tracker' />
                    <Tab.Container defaultActiveKey="no" activeKey={this.state.activeKey} onSelect={this.handleTab}>
                        <div className="qd-tab__header">
                            <div className="container-fluid">
                                <div className="qd-tab__header-wrapper">
                                    <div className="qd-tab__header-menu">
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="myIssues">
                                                    <span className="qd-menu__link-text">My Issues</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="resolvedIssues">
                                                    <span className="qd-menu__link-text">Resolved Issues</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="watchingIssues">
                                                    <span className="qd-menu__link-text">Watching Issues</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qd-container">
                            <div className="qd-body">
                                <div className="qd-tab__content" id={this.state.activeKey + "Tab"}>
                                    <Tab.Content>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="myIssues">
                                              <div className="tab-pane active" id="page-nav-tab11__content" role="tabpanel" aria-labelledby="page-nav-tab1">
                                                <div className="qd-tab__content-header">
                                                <h2>Your Issues</h2>
                                                <button className="btn btn-circle btn-md btn-outline-gray" onClick={this.createIssue} data-toggle="modal" data-target="#modal__track-issue"><FontAwesomeIcon icon={faEdit}/>Create Issue</button>                                                </div>
                                                <div id="triggers-alert-tab-content">
                                                    <div className="triggers-alert-tab-dq-content">
                                                      <div className="tabbable-line mb-3">
                                                        <ul className="nav nav-tabs" id="page-nav-tabs" role="tablist">
                                                          <li className="nav-item">
                                                            <a className="nav-link active" href="#!" id="page-nav-subtab1" data-toggle="tab" role="tab" aria-controls="page-nav-subtab1__content" aria-selected="true">
                                                              <span className="qd-menu__link-text"><FontAwesomeIcon icon={faBorderAll}/> Grid View</span>
                                                            </a>
                                                          </li>
                                                          <li className="nav-item">
                                                            <a className="nav-link" href="#!" id="page-nav-subtab2" data-toggle="tab" role="tab" aria-controls="page-nav-subtab2__content" aria-selected="false">
                                                              <span className="qd-menu__link-text"><FontAwesomeIcon icon={faList}/> List View</span>
                                                            </a>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div className="tab-content">
                                                            <div className="tab-pane active" id="page-nav-subtab11__content" role="tabpanel" aria-labelledby="page-nav-subtab1">
                                                              <div className="row no-gutters">
                                                                  <div className="col-md-6">
                                                                      <div className="card card_govern-issue mb-3">
                                                                          <div className="card-header">
                                                                            <div className="card_issue-info">
                                                                                <p>Retail Data/all_sales_data</p>
                                                                                <h5>Data Drift error detected in gender attribute</h5>
                                                                            </div>
                                                                            <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("Starting from 2-April, there is a new error getting reported as data drift error. Kindly investigate this.")} 
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                          </div>
                                                                          <div className="card-body">
                                                                            <div className="issue-track-bar has-25p bg-danger" role="progressbar">
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <div className="track-bar"></div>
                                                                              <div className="track-circle"></div>
                                                                            </div>
                                                                            <h6 className="issue-track-status text-danger">To Do</h6>
                                                                          </div>
                                                                          <div className="card-footer">
                                                                              <div className="row no-gutters">
                                                                                  <div className="col-4">
                                                                                      <div className="card_gover-footer-text">
                                                                                        <h6>Type</h6>
                                                                                        <h5>Drift Error</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Milestone</h6>
                                                                                        <h5>Mission_Launch</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Due On</h6>
                                                                                        <h5>20/04/2021</h5>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                      <div className="card card_govern-issue mb-3">
                                                                          <div className="card-header">
                                                                            <div className="card_issue-info">
                                                                                <p>Retail Data/all_sales_data</p>
                                                                                <h5>User privilege of Sharma changed </h5>
                                                                            </div>
                                                                            <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("The user access privilege of Sharma changed from Read to Read/Write. Kindly investigate this.")}
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                          </div>
                                                                          <div className="card-body">
                                                                            <div className="issue-track-bar has-25p bg-danger" role="progressbar">
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <div className="track-bar"></div>
                                                                              <div className="track-circle"></div>
                                                                            </div>
                                                                            <h6 className="issue-track-status text-danger">To Do</h6>
                                                                          </div>
                                                                          <div className="card-footer">
                                                                              <div className="row no-gutters">
                                                                                  <div className="col-4">
                                                                                      <div className="card_gover-footer-text">
                                                                                        <h6>Type</h6>
                                                                                        <h5>Access Error</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Milestone</h6>
                                                                                        <h5>Mission_Launch</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Due On</h6>
                                                                                        <h5>23/09/2021</h5>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                      <div className="card card_govern-issue mb-3">
                                                                          <div className="card-header">
                                                                            <div className="card_issue-info">
                                                                                <p>Retail Data/all_sales_data</p>
                                                                                <h5>Removal of User Smith</h5>
                                                                            </div>
                                                                            <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("User Smith doesn't exist, Need to be removed.")}
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                          </div>
                                                                          <div className="card-body">
                                                                            <div className="issue-track-bar has-25p bg-danger" role="progressbar">
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <span></span>
                                                                              <div className="track-bar"></div>
                                                                              <div className="track-circle"></div>
                                                                            </div>
                                                                            <h6 className="issue-track-status text-danger">To Do</h6>
                                                                          </div>
                                                                          <div className="card-footer">
                                                                              <div className="row no-gutters">
                                                                                  <div className="col-4">
                                                                                      <div className="card_gover-footer-text">
                                                                                        <h6>Type</h6>
                                                                                        <h5>Access Error</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Milestone</h6>
                                                                                        <h5>Mission_Launch</h5>
                                                                                      </div>
                                                                                  </div>
                                                                                  <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                        <h6>Due On</h6>
                                                                                        <h5>22/09/2021</h5>
                                                                                      </div>
                                                                                  </div>
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                                  <div className="col-md-6">
                                                                    <div className="card card_govern-issue mb-3">
                                                                        <div className="card-header">
                                                                          <div className="card_issue-info">
                                                                            <p>Retail Data/stock_ledger</p>
                                                                            <h5>Reference Date is not filled in Inventory data</h5>
                                                                          </div>
                                                                          <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("Starting from 2-April, there is a new error getting reported as data drift error. Kindly investigate this.")} 
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                          <div className="issue-track-bar has-50p bg-warning" role="progressbar">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <div className="track-bar"></div>
                                                                            <div className="track-circle"></div>
                                                                          </div>
                                                                          <h6 className="issue-track-status text-warning">In Progress</h6>
                                                                        </div>
                                                                        <div className="card-footer">
                                                                            <div className="row no-gutters">
                                                                                <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                      <h6>Type</h6>
                                                                                      <h5>Completeness Error</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Milestone</h6>
                                                                                      <h5>Mission_Launch</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Due On</h6>
                                                                                      <h5>06/04/2021</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="card card_govern-issue">
                                                                        <div className="card-header">
                                                                          <div className="card_issue-info">
                                                                            <p>Retail Data/all_sales_data</p>
                                                                            <h5>Transaction Data has an outlier detected</h5>
                                                                          </div>
                                                                          <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("Starting from 2-April, there is a new error getting reported as data drift error. Kindly investigate this.")} 
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                          <div className="issue-track-bar has-75p bg-info" role="progressbar">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <div className="track-bar"></div>
                                                                            <div className="track-circle"></div>
                                                                          </div>
                                                                          <h6 className="issue-track-status text-info">Team Review</h6>
                                                                        </div>
                                                                        <div className="card-footer">
                                                                            <div className="row no-gutters">
                                                                                <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                      <h6>Type</h6>
                                                                                      <h5>Accuracy Error</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Milestone</h6>
                                                                                      <h5>Mission_Launch</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Due On</h6>
                                                                                      <h5>04/04/2021</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="card card_govern-issue">
                                                                        <div className="card-header">
                                                                          <div className="card_issue-info">
                                                                            <p>Retail Data/all_sales_data</p>
                                                                            <h5>Conformity Issues reported in Transaction data</h5>
                                                                          </div>
                                                                          <div className="col-md-4 pull-right">
                                                                                <button className="btn btn-circle btn-success" onClick={this.openViewDetails("Starting from 2-April, there is a new error getting reported as data drift error. Kindly investigate this.")} 
                                                                                data-toggle="modal" data-target="#modal__view-issue"><FontAwesomeIcon icon={faEye}/>View Details</button>
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-body">
                                                                          <div className="issue-track-bar has-100p bg-success" role="progressbar">
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <span></span>
                                                                            <div className="track-bar"></div>
                                                                            <div className="track-circle"></div>
                                                                          </div>
                                                                          <h6 className="issue-track-status text-success">Completed</h6>
                                                                        </div>
                                                                        <div className="card-footer">
                                                                            <div className="row no-gutters">
                                                                                <div className="col-4">
                                                                                    <div className="card_gover-footer-text">
                                                                                      <h6>Type</h6>
                                                                                      <h5>Data Conformity Error</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Milestone</h6>
                                                                                      <h5>Mission_Launch</h5>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-4">
                                                                                  <div className="card_gover-footer-text">
                                                                                      <h6>Due On</h6>
                                                                                      <h5>02/04/2021</h5>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10 offset-md-1 text-center">
                                                                    <button type="button" className="btn btn-circle my-5 btn-lg btn-gray">Load More</button>
                                                                </div>
                                                              </div>
                                                            </div>

                                                          </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="resolvedIssues">
                                             <div className="tab-pane active" id="page-nav-tab11__content" role="tabpanel" aria-labelledby="page-nav-tab1">
                                                <div className="qd-tab__content-header">
                                                </div>
                                                <div className="portlet mb-0" data-select2-id="19">
                                                  <div className="portlet-title">
                                                    <div className="caption">
                                                      <span className="text-uppercase font-dark">Resolved issues</span>
                                                    </div>
                                                  </div>
                                                  <div className="portlet-body" data-select2-id="18">
                                                    <div className="react-bootstrap-table__header" data-select2-id="17">
                                                      <div className="row" data-select2-id="16">
                                                        <div className="col-6" data-select2-id="15">
                                                          <div className="btn-group react-table__length" role="group" data-select2-id="14">
                                                            <span>Show</span>
                                                            <Select
                                                                    classNamePrefix='select-control'
                                                                    defaultValue={{"label": "5", "value": 5}}
                                                                    options={[{"label": "5", "value": 5},{"label": "10", "value": 10},{"label": "all", "value": ""}]}
                                                                />
                                                            <span>items</span>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="btn-group react-table__filter" role="group">
                                                            <span>Search:</span>
                                                            <div className="table-search">
                                                            <input
                                                                placeholder={"Search"}
                                                                id={"searchBox"}
                                                                className="form-control"
                                                                style={{backgroundColor: 'white', align: 'right'}}
                                                                type="text"
                                                            />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="react-bootstrap-table table-responsive action-sticky-cell">
                                                      <table className="table">
                                                            <thead>
                                                              <tr>
                                                                <th>S. No.
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Title
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Status
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Milestone
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Assignee
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Created
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                                <th>Updated
                                                                  <span className="order">
                                                                    <span className="dropdown">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                    <span className="dropup">
                                                                      <span className="caret"></span>
                                                                    </span>
                                                                  </span>
                                                                </th>
                                                              </tr>
                                                            </thead>
                                                            <tbody>
                                                              <tr>
                                                                <td>1</td>
                                                                <td>Data Accuracy error reported in transaction data</td>
                                                                <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                                <td>Mission_Launch</td>
                                                                <td>Mark</td>
                                                                <td>2021-03-02</td>
                                                                <td>4 days ago</td>
                                                              </tr>
                                                              <tr>
                                                                <td>2</td>
                                                                <td>Data is not refreshed for 2 days</td>
                                                                <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                                <td>Mission_Launch</td>
                                                                <td>Mark</td>
                                                                <td>2021-02-22</td>
                                                                <td>3 days ago</td>
                                                              </tr>
                                                              <tr>
                                                                <td>3</td>
                                                                <td>Data consistency issue coming up in the migrated data</td>
                                                                <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                                <td>Mission_Launch</td>
                                                                <td>Mark</td>
                                                                <td>2021-02-12</td>
                                                                <td>4 days ago</td>
                                                              </tr>
                                                              <tr>
                                                                <td>4</td>
                                                                <td>Invalid value is showing up in the transaction type column in the data</td>
                                                                <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                                <td>Mission_Launch</td>
                                                                <td>Mark</td>
                                                                <td>2021-03-02</td>
                                                                <td>8 days ago</td>
                                                              </tr>
                                                              <tr>
                                                                <td>5</td>
                                                                <td>Category code is not matching in the transaction data</td>
                                                                <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                                <td>Mission_Launch</td>
                                                                <td>Mark</td>
                                                                <td>2021-01-21</td>
                                                                <td>1 days ago</td>
                                                              </tr>
                                                            </tbody>
                                                          </table>
                                                    </div>
                                                    <div className="react-bootstrap-table__footer">
                                                      <div className="row">
                                                        <div className="col-sm-12 col-md-6">
                                                          <span className="react-table__info">Showing <b>1</b> to <b>5</b> of <b>8</b> results</span>
                                                        </div><div className="col-sm-12 col-md-6">
                                                          <ul className="pagination float-md-right">
                                                            <li className="active page-item" title="1">
                                                              <a className="page-link" href="#!">1</a>
                                                            </li>
                                                            <li className="page-item" title="2">
                                                              <a className="page-link" href="#!">2</a>
                                                            </li>
                                                            <li className="page-item" title="next page">
                                                              <a className="page-link" href="#!">&gt;</a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                             </div>
                                        </Tab.Pane>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="watchingIssues">
                                                <div className="tab-pane" id="page-nav-tab13__content" role="tabpanel" aria-labelledby="page-nav-tab3" data-select2-id="page-nav-tab13__content">
                                                <div className="portlet mb-0" data-select2-id="19">
                                                  <div className="portlet-title">
                                                    <div className="caption">
                                                      <span className="text-uppercase font-dark">List of Monitoring issues</span>
                                                    </div>
                                                  </div>
                                                  <div className="portlet-body" data-select2-id="18">

                                                    <div className="react-bootstrap-table__header" data-select2-id="17">
                                                      <div className="row" data-select2-id="16">
                                                        <div className="col-6" data-select2-id="15">
                                                          <div className="btn-group react-table__length" role="group" data-select2-id="14">
                                                            <span>Show</span>
                                                            <Select
                                                                    classNamePrefix='select-control'
                                                                    defaultValue={{"label": "7", "value": 7}}
                                                                    options={[{"label": "7", "value": 7},{"label": "10", "value": 10},{"label": "all", "value": ""}]}
                                                                />
                                                            <span>items</span>
                                                          </div>
                                                        </div>
                                                        <div className="col-6">
                                                          <div className="btn-group react-table__filter" role="group">
                                                            <span>Search:</span>
                                                            <div className="table-search">
                                                            <input
                                                                placeholder={"Search"}
                                                                id={"searchBox"}
                                                                className="form-control"
                                                                style={{backgroundColor: 'white', align: 'right'}}
                                                                type="text"
                                                            />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div className="react-bootstrap-table table-responsive action-sticky-cell">
                                                      <table className="table">
                                                        <thead>
                                                          <tr>
                                                            <th>S. No.
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Title
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Status
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Milestone
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Assignee
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Created
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                            <th>Updated
                                                              <span className="order">
                                                                <span className="dropdown">
                                                                  <span className="caret"></span>
                                                                </span>
                                                                <span className="dropup">
                                                                  <span className="caret"></span>
                                                                </span>
                                                              </span>
                                                            </th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          <tr>
                                                            <td>1</td>
                                                            <td>Data Accuracy error reported in transaction data</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-03-02</td>
                                                            <td>4 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>2</td>
                                                            <td>Data is not refreshed for 2 days</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-02-22</td>
                                                            <td>3 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>3</td>
                                                            <td>Data consistency issue coming up in the migrated data</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-02-12</td>
                                                            <td>4 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>4</td>
                                                            <td>Invalid value is showing up in the transaction type column in the data</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-03-02</td>
                                                            <td>8 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>5</td>
                                                            <td>Category code is not matching in the transaction data</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Resolved</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-01-21</td>
                                                            <td>1 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>6</td>
                                                            <td>User privilege of Sharma changed</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Pending</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-09-23</td>
                                                            <td>1 days ago</td>
                                                          </tr>
                                                          <tr>
                                                            <td>7</td>
                                                            <td>Removal of User Smith</td>
                                                            <td><button type="button" className="btn btn-sm btn-outline-warning">Pending</button></td>
                                                            <td>Mission_Launch</td>
                                                            <td>Mark</td>
                                                            <td>2021-09-22</td>
                                                            <td>1 days ago</td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                    <div className="react-bootstrap-table__footer">
                                                      <div className="row">
                                                        <div className="col-sm-12 col-md-6">
                                                          <span className="react-table__info">Showing <b>1</b> to <b>7</b> of <b>8</b> results</span>
                                                        </div><div className="col-sm-12 col-md-6">
                                                          <ul className="pagination float-md-right">
                                                            <li className="active page-item" title="1">
                                                              <a className="page-link" href="#!">1</a>
                                                            </li>
                                                            <li className="page-item" title="2">
                                                              <a className="page-link" href="#!">2</a>
                                                            </li>
                                                            <li className="page-item" title="next page">
                                                              <a className="page-link" href="#!">&gt;</a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>
                </>
        );
    }
}

export default Govern;