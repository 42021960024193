import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postmicrosoftTeamsData }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils';

class MSTeamsForm extends React.Component {

    constructor(props) {
        super(props);
        this.postmicrosoftTeamsData = postmicrosoftTeamsData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            hideSubmitButton: false,
            microsoftTeamData: {
                consumeName: '',
                url: ''
            }
        };
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    componentDidMount() {
        if (this.props.exConsumeDetails === undefined) {
            return;
        }
        let microsoftTeamData = this.state.microsoftTeamData;
        const exConsumeItem = this.props.exConsumeDetails.consume_item;

        microsoftTeamData.consumeName = exConsumeItem.consume_group_name;

        const config_details = exConsumeItem.config_details;
        microsoftTeamData.url = config_details.url;
        this.setConsumeData(microsoftTeamData);

    }
    setConsumeData(data) {
        this.setState({
            microsoftTeamData: data
        });
    }

    requiresConsumeUpdate(microsoftTeamsPostData) {
        const exConsumeItem = this.props.exConsumeDetails.consume_item;
        if (microsoftTeamsPostData.consume_group_name !== exConsumeItem.consume_group_name) {
            return true;
        }
        const currentConfiguration = microsoftTeamsPostData.config_details;
        const exConfiguration = exConsumeItem.config_details;
        if (currentConfiguration.url !== exConfiguration.url) {
           return true;
        }
    }

    render() {
        const microsoftTeamValidationSchema = yup.object({
            consumeName: yup.string().min(1,"Name must be at least 1 characters").required("Notification name is a required field").test(
                'Notification name test',
                'invalid Notification name',
                function test(value) {
                   let val = userValidation(value,"input");
                   return val;
            }
            ),
            url: yup.string().url("Enter a valid URL").required("URL is a required field")
        });

        return (
                <>
                    <Formik
                          validationSchema={microsoftTeamValidationSchema}
                          onSubmit={this.postmicrosoftTeamsData}
                          initialValues={this.state.microsoftTeamData}
                          enableReinitialize={true}
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                        <Form className="form-label-right-md" onSubmit={handleSubmit}>
                            <Form.Group controlId="consumeName" className="row">
                                <Form.Label className="col-md-4 col-form-label">Notification Name <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                      <Form.Control
                                            type="text"
                                            name="consumeName"
                                            value={values.consumeName}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={errors.consumeName && touched.consumeName}
                                            placeholder="Microsoft Teams Integration Name"/>
                                      <Form.Control.Feedback type="invalid">
                                            { touched.consumeName && errors.consumeName }
                                      </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="url" className="row">
                                <Form.Label className="col-md-4 col-form-label">Microsoft Teams Url<span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                      <Form.Control
                                            type="text"
                                            name="url"
                                            value={values.url}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={errors.url && touched.url}
                                            placeholder="Microsoft Teams Url"/>
                                      <Form.Control.Feedback type="invalid">
                                            { touched.url && errors.url }
                                      </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group className="row">
                                <div className="col-md-5 offset-sm-4">
                                    <button type="button" onClick={() => this.props.exConsumeDetails ? this.props.closeModal() : this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                    </button>
                                </div>
                            </Form.Group>
                        </Form>
                        )}
                    </Formik>
                </>
            );

    }
}

export default MSTeamsForm;
