import {
  call_post_api,
  call_get_api } from "./event_handling";
import { ui_service_ip } from "./ip_details";
// import moment from 'moment';
// import { CONFORMITY_METRIC_KEYS} from "./constant";
import moment from 'moment';
// import {
//   attributeIDFromMappedValue,
//   attributeNameFromMappedValue,
//   datasetIDFromMappedValue,
// } from "./attribute_name_utils";
// import { getMonitorTabDQPListViewTableData } from "../utils/common_utils"
// import { normalizeChildDatasetName } from "../utils/attribute_name_utils"
 import {
    IS_NONE_CHECK
} from "../utils/constant";

export function getDateObject(timeStr) {
  let dateObj;
  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
      dateObj = new Date(timeStr.replace(/\s/, 'T'));
  } else {
      dateObj = new Date(timeStr);
  }
  return dateObj;
}


//export function preFetchMlMonitorPreview(tenantData, setMlPreview, store) {
//    // Not in use
//    call_post_api(ui_service_ip, "/monitor/info/preview", tenantData).then(
//        (result) => {
//            if (result !== undefined && result !== null) {
//                    setMlPreview(result["ml_model"]);
//            }
//        }
//    );
//}

/**Optimization Changes*/
 export function preFetchMonitorInfo(
   tenantData, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
   addTimelinessDataSetInfo, addTimelinessAttributeInfo, addAccuracyDataSetInfo,
   addAccuracyAttributeInfo, addConformityDataSetInfo, addConformityAttributeInfo,
   addConsistencyDataSetInfo, addConsistencyAttributeInfo,
   addDriftDataSetInfo, addDriftAttributeInfo, addMLDataSetInfo,
   addMLAttributeInfo, addMonitorPreview, addUniquenessDataSetInfo,
   addUniquenessAttributeInfo, addRecencyPreview, prefetchMonitorState, setDataSource, integrationId = null, store, retries = 3

 ) {
   let apiReqData = tenantData;
   if (integrationId !== null) {
     apiReqData["integration_id"] = integrationId;
   }

   let monitorModuleProps = {}
   prefetchRecencyInfo(tenantData, store, addTimelinessDataSetInfo, addRecencyPreview);
   call_post_api(ui_service_ip, `monitor/info`, apiReqData).then(
     (result) => {
       if (result !== undefined && result !== null) {
         if (result.results !== undefined) {

           let integrationId = result.results[0].integration_id
           monitorModuleProps = persistInfoData(result.results, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
             addTimelinessDataSetInfo, addTimelinessAttributeInfo, addAccuracyDataSetInfo,
             addAccuracyAttributeInfo, addConformityDataSetInfo, addConformityAttributeInfo,
             addConsistencyDataSetInfo, addConsistencyAttributeInfo,
             addDriftDataSetInfo, addDriftAttributeInfo, addMLDataSetInfo,
             addMLAttributeInfo, addMonitorPreview,
             addUniquenessDataSetInfo, addUniquenessAttributeInfo, prefetchMonitorState,
             store, integrationId
           );

           const integrationIds = result.integration_ids;
                   if (integrationIds !== undefined) {
                    for (let integrationId of integrationIds) {
                        apiReqData["integration_id"] = integrationId;
                        call_post_api(ui_service_ip, `monitor/info`, apiReqData).then(
                              (result) => {
                                  if (result !== null && result !== undefined && result.results !== undefined) {
                                      persistInfoData(result.results, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
                                      addTimelinessDataSetInfo, addTimelinessAttributeInfo, addAccuracyDataSetInfo,
                                      addAccuracyAttributeInfo, addConformityDataSetInfo, addConformityAttributeInfo,
                                      addConsistencyDataSetInfo, addConsistencyAttributeInfo,
                                      addDriftDataSetInfo, addDriftAttributeInfo, addMLDataSetInfo,
                                      addMLAttributeInfo,addMonitorPreview, addUniquenessDataSetInfo,
                                      addUniquenessAttributeInfo,prefetchMonitorState,store,integrationId
                                  );
                                  }
                              }
                        );
                    }
                  }
//           let integration_name = this.props.filterData.datasourceOptions.filter((data)=>data.value ===Number(integrationId))
           const tenantId = localStorage.getItem('team_id');
           let endPoint = "metrics_store_payload_created_time";
           let data = { "tenant_id": tenantId, "integration_id": integrationId}
           let last_processed_time=null;
           call_get_api(ui_service_ip, endPoint, data).then((response) => {
               if (response !== undefined && response.code === 200) {
                   let results = response["results"];
                   last_processed_time = results[0].last_processed_time
                   // let maxLastProfilingTime = results.reduce((a, b) => Number(getDateObject(results[a])) > Number(getDateObject(results[b])) ? a : b);
                   this.setState({
                     monitorModuleProps: monitorModuleProps,
//                     selectedDataSrc:{'value':String(integrationId), 'label':integration_name[0].label},
                     showLoader: false,
                     noIntegration: false,
                     last_processed_time:last_processed_time
                   })
                 }
           }
           )

           // setTimeout(() => {
           // }, 1000);

         }
         else if(result.integration_ids !== undefined && result.integration_ids.length > 0){
           let integrationId = result.integration_ids[result.integration_ids.length-1]
           this.preFetchMonitorInfo(
             tenantData, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
             addTimelinessDataSetInfo, addTimelinessAttributeInfo, addAccuracyDataSetInfo,
             addAccuracyAttributeInfo, addConformityDataSetInfo, addConformityAttributeInfo,
             addConsistencyDataSetInfo, addConsistencyAttributeInfo,
             addDriftDataSetInfo, addDriftAttributeInfo, addMLDataSetInfo,
             addMLAttributeInfo, addMonitorPreview, addUniquenessDataSetInfo,
             addUniquenessAttributeInfo, addRecencyPreview, prefetchMonitorState, setDataSource, integrationId
           )
         }
         else if (result.message === 'Result not found') {
           this.setState({
                           showLoader: false,
                           noIntegration: true
                         })
         }
       }
     }
   );
 }


 function persistInfoData(data, addCompletenessDataSetInfo, addCompletenessAttributeInfo,
   addTimelinessDataSetInfo, addTimelinessAttributeInfo, addAccuracyDataSetInfo,
   addAccuracyAttributeInfo, addConformityDataSetInfo, addConformityAttributeInfo,
   addConsistencyDataSetInfo, addConsistencyAttributeInfo,
   addDriftDataSetInfo, addDriftAttributeInfo, addMLDataSetInfo,
   addMLAttributeInfo, addMonitorPreview, addUniquenessDataSetInfo, addUniquenessAttributeInfo, prefetchMonitorState, store, integrationId = 'none'
 ) {
//    commented lines are for dq monitor page
//    let chartData = {}
   let metricList = [
//     'drift_dq_attribute',
//     'drift_dq_dataset',
//
//     'completeness_dq_attribute',
//     'completeness_dq_dataset',
//
//     'uniqueness_dq_attribute',
//     'uniqueness_dq_dataset',
//
//     'accuracy_dq_attribute',
//     'accuracy_dq_dataset',
//
//     'consistency_dq_dataset',
//     'consistency_dq_attribute',
//
//     'conformity_dq_dataset',
//     'conformity_dq_attribute',

     'ml_attribute',
     'ml_dataset',
     'preview',

   ]

//   let completenessDataSetInfo = {}
//   let completenessAttributeInfo = {}
//
//   let conformityDataSetInfo = {}
//   let conformityAttributeInfo = {}
//
//   let accuracyDataSetInfo = {}
//   let accuracyAttributeInfo = {}
//
//   let uniquenessDataSetInfo = {}
//   let uniquenessAttributeInfo = {}
//
//   let driftDataSetInfo = {}
//   let driftAttributeInfo = {}
//
//   let consistencyDataSetInfo = {}
//   let consistencyAttributeInfo = {}

   let monitorPreview = {}
   let mlDatasetInfo = {}
   let mlAttributeInfo = {}

   let filterPreviewData = data.filter((data) => data.type === 'preview' && data.payload !== undefined && Object.keys(data.payload).length !== 0)
//   monitorPreview['dq_metrics'] = filterPreviewData[0]['payload']['dq_metrics']
//   monitorPreview['dqpreview'] = filterPreviewData[0]['payload']['dq_metrics']
   monitorPreview['ml_model'] = filterPreviewData[0]['payload']['ml_model']
   monitorPreview['mlPreview'] = filterPreviewData[0]['payload']['ml_model']
     if(store !== undefined) {
        store.dispatch(monitorPreview['mlPreview'])
      }else {
        addMonitorPreview(monitorPreview['mlPreview'])
      }

   // Filter out Null data
   let filterData = data.filter((data) => metricList.includes(data.type) && data.payload.data !== undefined && Object.keys(data.payload.data).length !== 0)
   for (let metric of metricList) {

     filterData.filter((data) => data.type === metric && data.payload.data !== undefined && Object.keys(data.payload.data).length !== 0 &&
       // eslint-disable-next-line
       Object.keys(data.payload.data).map((key) => {
         // eslint-disable-next-line
         Object.keys(data.payload.data[key]).map((value) => {
//           if (metric === 'drift_dq_dataset') {
//             if (driftDataSetInfo[key] === undefined) {
//               driftDataSetInfo[key] = {}
//               driftDataSetInfo[key][value] = data.payload.data[key][value]
//
//             }
//             else {
//               driftDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'drift_dq_attribute') {
//             if (driftAttributeInfo[key] === undefined) {
//               driftAttributeInfo[key] = {}
//               driftAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               driftAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           if (metric === 'uniqueness_dq_dataset') {
//             if (uniquenessDataSetInfo[key] === undefined) {
//               uniquenessDataSetInfo[key] = {}
//               uniquenessDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               uniquenessDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'uniqueness_dq_attribute') {
//             if (uniquenessAttributeInfo[key] === undefined) {
//               uniquenessAttributeInfo[key] = {}
//               uniquenessAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               uniquenessAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'completeness_dq_attribute') {
//             if (completenessAttributeInfo[key] === undefined) {
//               completenessAttributeInfo[key] = {}
//               completenessAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               completenessAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'completeness_dq_dataset') {
//             if (completenessDataSetInfo[key] === undefined) {
//               completenessDataSetInfo[key] = {}
//               completenessDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               completenessDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'accuracy_dq_dataset') {
//             if (accuracyDataSetInfo[key] === undefined) {
//               accuracyDataSetInfo[key] = {}
//               accuracyDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               accuracyDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           if (metric === 'accuracy_dq_attribute') {
//             if (accuracyAttributeInfo[key] === undefined) {
//               accuracyAttributeInfo[key] = {}
//               accuracyAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               accuracyAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'conformity_dq_dataset') {
//             if (conformityDataSetInfo[key] === undefined) {
//               conformityDataSetInfo[key] = {}
//               conformityDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               conformityDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'conformity_dq_attribute') {
//             if (conformityAttributeInfo[key] === undefined) {
//               conformityAttributeInfo[key] = {}
//               conformityAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               conformityAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'consistency_dq_dataset') {
//             if (consistencyDataSetInfo[key] === undefined) {
//               consistencyDataSetInfo[key] = {}
//               consistencyDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               consistencyDataSetInfo[key][value] = data.payload.data[key][value]
//             }
//           }
//           else if (metric === 'consistency_dq_attribute') {
//             if (consistencyAttributeInfo[key] === undefined) {
//               consistencyAttributeInfo[key] = {}
//               consistencyAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//             else {
//               consistencyAttributeInfo[key][value] = data.payload.data[key][value]
//             }
//           }
           if (metric === 'ml_dataset') {
             if (mlDatasetInfo[key] === undefined) {
               mlDatasetInfo[key] = {}
               mlDatasetInfo[key][value] = data.payload.data[key][value]
             }
             else {
               mlDatasetInfo[key][value] = data.payload.data[key][value]
             }
             if(store !== undefined) {
                store.dispatch(addMLDataSetInfo(mlDatasetInfo))
              }else {
                addMLDataSetInfo(mlDatasetInfo)
              }
           }
           else if (metric === 'ml_attribute') {
             if (mlAttributeInfo[key] === undefined) {
               mlAttributeInfo[key] = {}
               mlAttributeInfo[key][value] = data.payload.data[key][value]
             }
             else {
               mlAttributeInfo[key][value] = data.payload.data[key][value]
             }
             if(store !== undefined) {
                store.dispatch(addMLAttributeInfo(mlAttributeInfo))
              }else {
                addMLAttributeInfo(mlAttributeInfo)
              }
           }
           else if (metric === 'preview') {
             if (monitorPreview[key] === undefined) {
               monitorPreview[key] = {}
               monitorPreview[key][value] = data.payload.data[key][value]
             }
             else {
               monitorPreview[key][value] = data.payload.data[key][value]
             }
             if(store !== undefined) {
                store.dispatch(addMonitorPreview(monitorPreview))
              }else {
                addMonitorPreview(monitorPreview)
              }
           }
         })
       }
       ))
   }

   if (integrationId !== 'none') {
     prefetchMonitorState(integrationId)
   }

   let monitorModuleProps = {
//     "accuracyAttributeInfo": accuracyAttributeInfo,
//     "accuracyDataSetInfo": accuracyDataSetInfo,
//     "consistencyDataSetInfo": consistencyDataSetInfo,
//     "consistencyAttributeInfo": consistencyAttributeInfo,
//     "conformityDataSetInfo": conformityDataSetInfo,
//     "conformityAttributeInfo": conformityAttributeInfo,
//     "completenessAttributeInfo": completenessAttributeInfo,
//     "completenessDataSetInfo": completenessDataSetInfo,
//     "driftAttributeInfo": driftAttributeInfo,
//     "driftDataSetInfo": driftDataSetInfo,
     "mlAttributeInfo": mlAttributeInfo,
     "mlDataSetInfo": mlDatasetInfo,
     "monitorPreview": monitorPreview,
//     "uniquenessAttributeInfo": uniquenessAttributeInfo,
//     "uniquenessDataSetInfo": uniquenessDataSetInfo,
//     "dqPreview": monitorPreview,
//     "MonitorPreview": monitorPreview
   }
   return monitorModuleProps
 }


 export function preFetchMonitorListViewData(tenantData, addMonitorDQListViewData, integrationId = null, store, retries = 3) {
   let endPoint = "monitor_list_view";
   let data = { "tenant_id": tenantData, "res_type": "dq" }
   if (integrationId !== null) {
     data["integration_id"] = integrationId;
   }
   // prefetchRecencyInfo(tenantData, store, addTimelinessDataSetInfo, addRecencyPreview);

   call_get_api(ui_service_ip, endPoint, data).then(
     (result) => {
       if (result !== undefined && result !== null && result.list_view_data !== null) {
         if (store !== undefined) {
               store.dispatch(addMonitorDQListViewData(result.list_view_data))
             } else {
               addMonitorDQListViewData(result.list_view_data)
             }
         let endPoint = "recency/all";
         let reData = { "tenant_id": tenantData, "type": "monitor_list" };
         call_get_api(ui_service_ip, endPoint, reData).then(
           (recencyResult) => {
             if (recencyResult !== undefined && recencyResult.message === "Successfully fetched") {
               let recencyProfileResult = recencyResult.monitor_list_view;
               recencyProfileResult["isRecency"] = true;
               if (store !== undefined) {
                 store.dispatch(addMonitorDQListViewData(recencyProfileResult));
               } else {
                 addMonitorDQListViewData(recencyProfileResult);
               }
             }
           }
         );
         let integrationIds = result.integration_ids;
         if (integrationIds !== undefined && integrationIds.length > 0) {
           for (let integrationId of integrationIds) {
             let endPoint = "monitor_list_view";
             data["integration_id"] = integrationId;
             call_get_api(ui_service_ip, endPoint, data).then(
               (curr_result) => {
                 if (curr_result !== undefined) {
                   integrationIds.pop(integrationId)
                   let curr_list_view_data = curr_result.list_view_data;
                   if (IS_NONE_CHECK.includes(curr_list_view_data)) {
                     curr_list_view_data = {};
                   }
                   curr_list_view_data["apiFetchOver"] = true;
                   if (result !== undefined && result !== null) {
                     if (store !== undefined) {
                       store.dispatch(addMonitorDQListViewData(curr_list_view_data));
                     } else {
                       addMonitorDQListViewData(curr_list_view_data);
                     }
                   }
                   if (integrationIds.length === 0) {
                     let endPoint = "recency/all";
                     let reData = { "tenant_id": tenantData, "type": "monitor_list" };
                     call_get_api(ui_service_ip, endPoint, reData).then(
                       (recencyResult) => {
                         if (recencyResult !== undefined && recencyResult.message === "Successfully fetched") {
                           let recencyProfileResult = recencyResult.monitor_list_view;
                           recencyProfileResult["isRecency"] = true;
                           if (store !== undefined) {
                             store.dispatch(addMonitorDQListViewData(recencyProfileResult));
                           } else {
                             addMonitorDQListViewData(recencyProfileResult);
                           }
                         }
                       }
                     );
                   }
                 }
               }
             );
           }
         }

         let list_view_data = result.list_view_data;
         list_view_data["apiFetchOver"] = true;
//         let integrationId = result.integration_id
//
//         if (integrationId === undefined || integrationId === null) {
//           this.setState({ showLoader: false, noIntegration: true })
//         }
//         else {
//           this.setState({ noIntegration: false })
//           let filterdatasouce = [];
//           if(this.props.filterData !== undefined && this.props.filterData !==""){
//             if(this.props.filterData.datasourceOptions !== undefined && this.props.filterData.datasourceOptions !==null){
//                 filterdatasouce = this.props.filterData.datasourceOptions.filter((data)=>
//                   data.value === integrationId
//                 )
//             }
//           }
//           let datasets = []
//           integrationId = String(integrationId)
//           if (this.props.mappedDatasetInfo["idDatasetDetail"] !== undefined){
//           for (const [key, value] of Object.entries(this.props.mappedDatasetInfo["idDatasetDetail"])) {
//               if (value["dataSourceId"] === integrationId) {
//                   datasets.push({ "label": normalizeChildDatasetName(value["datasetName"]), "value": key });
//               }
//           }
//           }
//
//
//
//           let defaultDataSourceOption = {"value":integrationId,"label":this.props.dataModule.integrations[integrationId]}
//           const reqTableData = getMonitorTabDQPListViewTableData(this.state.mappedDatasetInfo, list_view_data, this.props.monitorModule.follow_attributes, this.props.timelinessData);
//           const listViewTableData = reqTableData.tableData;
//           this.setState({
//             keyData: `dqListView_${listViewTableData.length}`,
//             monitorDQListView: list_view_data,
//             currListViewTableData: listViewTableData, completeTableData: listViewTableData, listViewTableData: listViewTableData ,
//             defaultDatasourceInside:defaultDataSourceOption,
//             selectedDataSource:defaultDataSourceOption,
//             selectedDataSourceID: integrationId,
//             selectedDataSourceName:filterdatasouce.length > 0 ? filterdatasouce[0]["label"] : "",
//             datasets: datasets,
//           })
//             this.setState({showLoader:false})
//
//         }
       }
       else if(result.list_view_data === undefined || result.list_view_data === null) {
         if(result.integration_ids !== undefined && result.integration_ids.length > 0){
           let integrationId = result.integration_ids[result.integration_ids.length-1];
             this.preFetchMonitorListViewData(tenantData, addMonitorDQListViewData,integrationId)
         }
         else{
           this.setState({ noIntegration: true, showLoader: false })
         }
       }
       else {
         if (retries > 0) {
           preFetchMonitorListViewData(tenantData, addMonitorDQListViewData, store, retries - 1)
         }
       }
     }
   );
 }


// export function preFetchFollowAttributes(tenantData, addFollowAttributes, store, retries = 3) {
//   let endPoint = "follow_attributes";

//   call_get_api(ui_service_ip, endPoint, { "tenant_id": tenantData.tenant_id }).then(
//     (result) => {
//       if (result !== undefined && result !== null) {
//         if (store !== undefined) {
//           store.dispatch(addFollowAttributes(result.follow_attributes));
//         } else {
//           addFollowAttributes(result.follow_attributes);
//         }
//       }
//       else {
//         if (retries > 0) {
//           preFetchFollowAttributes(tenantData, addFollowAttributes, store, retries - 1)
//         }
//       }
//     }
//   );
// }


 function prefetchRecencyInfo(tenantData, store, addTimelinessDataSetInfo, addRecencyPreview) {
   let endPoint = "recency/all";
   let reData = { "tenant_id": tenantData.tenant_id, "type": "monitor_chart" };
   call_get_api(ui_service_ip, endPoint, reData).then(
     (recencyResult) => {
       if (recencyResult !== undefined && recencyResult !== null) {
         if (recencyResult.code === 200) {
           let recencyData = recencyResult.monitor_others;
           let recencyPreviewData = recencyResult.preview_res;
           if (recencyData !== undefined && recencyData !== null) {
             let chartData = recencyData.data;
             if (store !== undefined) {
               store.dispatch(addTimelinessDataSetInfo(chartData))
               store.dispatch(addRecencyPreview(recencyPreviewData))
             } else {
               addTimelinessDataSetInfo(chartData)
               addRecencyPreview(recencyPreviewData)
             }
           }
         }
       }
     }
   );
 }






// export function preFetchMonitorMetaData(
//   tenantData, addMetaData, setDataSet, setAttribute, store, retries = 3
// ) {

//   call_get_api(ui_service_ip, "metadata/mapping/latest", { "tenant_id": tenantData["tenant_id"] }).then(
//     (result) => {
//       if (result !== undefined && result !== null) {
//         for (const [key, value] of Object.entries(result.meta_data)) {
//           let dataSetId = datasetIDFromMappedValue(key);
//           let attributeId = attributeIDFromMappedValue(key);
//           let attributeName = attributeNameFromMappedValue(value);
//           if (store !== undefined) {
//             store.dispatch(addMetaData(result.meta_data));
//             store.dispatch(setDataSet(dataSetId));
//             store.dispatch(setAttribute(attributeId, attributeName));
//           } else {
//             addMetaData(result.meta_data);
//             setDataSet(dataSetId);
//             setAttribute(attributeId, attributeName);
//           }
//           break;
//         }
//       }
//       else {
//         if (retries > 0) {
//           preFetchMonitorMetaData(
//             tenantData, addMetaData, setDataSet, setAttribute, store, retries - 1)
//         }
//       }
//     }
//   );
// }

// export function fromMonitorData(mappedDatasetInfo, listViewTableData, data_mappedDatasetInfo, data_listViewTableData, store) {
//   if (data_mappedDatasetInfo !== undefined && data_listViewTableData !== undefined) {
//     if (store !== undefined) {
//       // mappedDatasetInfo(data_mappedDatasetInfo);
//       // listViewTableData(data_listViewTableData);
//       store.dispatch(mappedDatasetInfo(data_mappedDatasetInfo));
//       store.dispatch(listViewTableData(data_listViewTableData));
//     }
//     else {
//       mappedDatasetInfo(data_mappedDatasetInfo);
//       listViewTableData(data_listViewTableData);
//     }
//   }
// }


 export function preFetchMonitorPreview(tenantData, addMonitorPreview, addPerformancePreview, addModelPerformanceResults, store, retries = 3) {
   tenantData.tenant_id = tenantData.tenant_id.toString();
   call_post_api(ui_service_ip, "/model_performance/results/info", tenantData).then(
     (result) => {
       if (result !== undefined && result !== null) {
         let data = result.data;
         let dateDetails = result.date_details;
         if (dateDetails !== undefined && dateDetails !== null) {
           data["filterStartDate"] = dateDetails.filterStartDate;
           data["filterEndDate"] = dateDetails.filterEndDate;
           data["dataFrom"] = dateDetails.dataFrom;
           data["dataTo"] = dateDetails.dataTo;
         }

         if (store !== undefined) {
           store.dispatch(addPerformancePreview(data));
           store.dispatch(addModelPerformanceResults(result.results));
         } else {
           addPerformancePreview(data);
           addModelPerformanceResults(result.results);
         }
       }
       else {
         if (retries > 0) {
           preFetchMonitorPreview(tenantData, addMonitorPreview, addPerformancePreview, addModelPerformanceResults, store, retries - 1)
         }
       }
     }
   );
 }


// export function preFetchDqMonitorPreview(tenantData, setDqPreview, store) {
//   // Not in use
//   call_post_api(ui_service_ip, "/monitor/info/preview", tenantData).then(
//     (result) => {
//       if (result !== undefined && result !== null) {
//         if (store !== undefined) {
//           store.dispatch(setDqPreview(result["dq_metrics"]));
//         } else {
//           setDqPreview(result["dq_metrics"]);
//         }
//       }
//     }
//   );
// }

/**Optimization Changes End */

export function preFetchMonitorModelInfo(tenantData, addMLModelMapping, addMLDataSet, addMLAttribute, addMLModel, setMLModel, store, retries = 3) {
  let data = { "tenant_id": tenantData }
  call_get_api(ui_service_ip, "model/map", data).then(
    (result) => {
      if (result !== undefined && result !== null) {
        if (store !== undefined) {
          store.dispatch(addMLModelMapping(result.model_map));
          store.dispatch(setMLModel(Object.keys(result.model_map)[0]));
        } else {
          addMLModelMapping(result.model_map);
          setMLModel(Object.keys(result.model_map)[0]);
        }
        //getMonitorModelInfo(tenantData, "dataset", addMLDataSet, store);
        //getMonitorModelInfo(tenantData, "attribute", addMLAttribute, store);
        //                Object.keys(result.model_map).forEach((mlModelId) => {
        //                    getProfileMLChart(tenantData, mlModelId, addMLModel, store);
        //                });
      }
      else {
        if (retries > 0) {
          preFetchMonitorModelInfo(tenantData, addMLModelMapping, addMLDataSet, addMLAttribute, addMLModel, setMLModel, store, retries - 1)
        }
      }
    }
  );
}

export function preFetchModelDetails(tenantData, addModelDetails, store, retries = 3) {
  let data = { tenant_id: tenantData, isDataset: 1 }
  call_get_api(ui_service_ip, "ml-models", data).then(
    (result) => {
      if (result !== undefined && result !== null) {
        if (store !== undefined) {
          store.dispatch(addModelDetails(result.ml_model));
        } else {
          addModelDetails(result.ml_model)
        }
      }
      else {
        if (retries > 0) {
          preFetchModelDetails(tenantData, addModelDetails, store, retries = retries - 1)
        }
      }

    }
  );
}

//export function getMonitorModelInfo(tenantData, level, persistData, store) {
//    // Not in use
//    let postData = {};
//    postData["tenant_id"] = tenantData;
//    postData["type"] = level;
//    call_post_api(ui_service_ip, `/monitor/info/ml?page=1&count=${MAX_CHARTS_PER_API}`, postData).then((result) => {
//        if (result !== null && result !== undefined) {
//            if (store !== undefined) {
//                store.dispatch(persistData(result.data));
//            } else {
//                persistData(result.data);
//            }
//
//            let page = 1;
//            let totalPage = Math.ceil(result.count/MAX_CHARTS_PER_API);
//
//            while (page < totalPage ) {
//                page = page + 1;
//                call_post_api(ui_service_ip, `/monitor/info/ml?page=${page}&count=${MAX_CHARTS_PER_API}`, postData).then(
//                    (pageResult) => {
//                        if (pageResult !== null && pageResult !== undefined) {
//                            if (store !== undefined) {
//                                store.dispatch(persistData(pageResult.data, store));
//                            } else {
//                                persistData(pageResult.data, store);
//                            }
//                        }
//                    }
//                );
//            }
//        }
//
//    });
//}



//     call_get_api(ui_service_ip, "metadata/mapping/latest",{"tenant_id":tenantData["tenant_id"]}).then(

// For first time we will send only tenant id and res_type. API will return anyone of integration details and also
// return other integration ids. We will call the same api with integration id. API will return data associated with
// this integration id


// This function was introduced for issue_1905.
// function addConformityNoErrorData(conformityData) {
//   const errorMetricKeys = Object.keys(conformityData);
//   for (let key in CONFORMITY_METRIC_KEYS) {
//     if (errorMetricKeys.includes(CONFORMITY_METRIC_KEYS[key])) {
//       continue
//     } else {
//       conformityData[CONFORMITY_METRIC_KEYS[key]] = {}
//     }
//   }
//   return conformityData
// }


export function getLastProfilingTime(tenantData, addLastProfilingTime, setStartDate, setEndDate, store) {
  let endPoint = "metrics_store_payload_created_time";
  call_get_api(ui_service_ip, endPoint, tenantData).then((response) => {
    if (response !== undefined && response.code === 200) {
      let results = response["results"];
      // let maxLastProfilingTime = Object.keys(results).reduce((a, b) => Number(new Date(results[a])) > Number(new Date(results[b])) ? a : b);
      if (results !== null && results.length > 0) {
        for (let index = 0; index < results.length; index++) {
          let integrationId = results[index]["integration_id"]
          if (store !== undefined) {
            store.dispatch(addLastProfilingTime(integrationId, results[index]["last_processed_time"]));
          } else {
            addLastProfilingTime(integrationId, results[index]["last_processed_time"]);
          }
          if (store !== undefined) {
            store.dispatch(setStartDate(moment(results[index]["last_processed_time"])))
            store.dispatch(setEndDate(moment(results[index]["last_processed_time"])))
          } else {
            setStartDate(moment(results[index]["last_processed_time"]))
            setEndDate(moment(results[index]["last_processed_time"]))
          }
        }

      }

    }
  }
  );
}


export function preFetchMetrics(tenantData, addMetrics, store, retries = 3) {
  let endPoint = "metrics/monitor";
  let data = { "tenant_id": tenantData }
  call_get_api(ui_service_ip, endPoint, data).then(
    (result) => {
      if (result !== undefined && result !== null) {
        if (store !== undefined) {
          store.dispatch(addMetrics(result.metrics));
        } else {
          addMetrics(result.metrics);
        }
      }
      else {
        if (retries > 0) {
          preFetchMetrics(tenantData, addMetrics, store, retries - 1)
        }
      }
    }
  );
}

export function preFetchCorrelationData(tenantData, correlationStoreFunction, store, retries = 3) {
    let data = {"tenant_id": tenantData}
    call_get_api(ui_service_ip, "correlation_data", data).then(
        (result) => {
            if (result === undefined && retries > 0) {
                preFetchCorrelationData(tenantData, correlationStoreFunction, store, retries - 1)
            }
            if (result === undefined) {
                return
            }

            if (result.code !== 200) {
                return;
            }

            let payload_data = result.data
            let correlation_data = payload_data.correlation_data;

            if (store !== undefined) {
                store.dispatch(correlationStoreFunction(correlation_data))
            } else {
                correlationStoreFunction(correlation_data)
            }
        }
    )
}