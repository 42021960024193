import React from 'react';
import Image from './image';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import WarningMessageHeader from './warningMessageHeader';
import Dropdown from 'react-bootstrap/Dropdown';
import {handleClick}  from "../utils/event_handling";
import HelpZendesk from "./help.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import {browserHistory} from 'react-router';

import {
    faBell, faUser, faChartPie, faHistory, faUserPlus, faServer,
    faUserCog, faUserFriends, faSignOutAlt, faBezierCurve, faDatabase,
    faShoppingBag, faExclamationTriangle, faArrowRight, faHdd, faQuestion,
    faHeadset, faBook, faDollarSign, faRocket, faUserMinus, faSearch
} from '@fortawesome/free-solid-svg-icons';
import { getApi } from "../utils/event_handling";
import $ from "jquery";
import { Link } from 'react-router';
// import OfflineComponent from './offlineComponent'
// import { Offline } from "react-detect-offline";
import {get_document_link, getTrimmedNotificationMessage, getFeatureAccess, decryptForLocalUsage} from "../utils/common_utils";
import {IS_NONE_CHECK, REQUIRES_EXCLAMATION_TRIANGLE_ICON,
        LOCAL_STORE_KEY, QUALDO_DOCUMENTATION, PERMISSIONS_LOCAL_STORE_KEY, LOGGEDIN_USER_ID, LOGGEDIN_USER_EMAIL} from "../utils/constant";
// import InternetStatusAlert from './internetStatusAlert';
import NetworkStatusAlert from './NetworkStatusAlert';


const userToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    href=""
    ref={ref}
    className="qd-header__topbar-icon dropdown-toggle"
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <FontAwesomeIcon color="#fff" icon={faUser}/>
    {children}
  </span>
));

const helpToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div 
    className="qd-header__topbar-icon"
    onClick={(e) => {
        e.preventDefault();
        onClick(e);
    }}
    >
        <span className="nav-link">
            <FontAwesomeIcon color="#fff" icon={faQuestion}/>
            {children}
        </span>
    </div>
  ));

const customLink = React.forwardRef(({link, tab, children, onClick}, ref) => (
    <Link to={link} className={link.includes(tab) ? "dropdown-item active" : "dropdown-item"}>
        {children}
    </Link>
));

const goToNotifications = () => {
    window.location.href = "/notification";
}
const bellToggle = React.forwardRef(({ children, onClick, unReadMsg }, ref) => {

  if (unReadMsg !=null && unReadMsg.length > 0)
  {
      return ( <span
                ref={ref}
                className="qd-header__topbar-icon"
                onClick={e => {
                  e.preventDefault();
                  onClick(e);
                }}
               >
                <FontAwesomeIcon color="#fff" icon={faBell}/>
                {children}
              </span>
        );
    } else {
        return ( <span
                ref={ref}
                className="qd-header__topbar-icon"
                 onClick={e => {
                  e.preventDefault();
                  goToNotifications();
                }}
               >
                <FontAwesomeIcon color="#fff" icon={faBell}/>
                {children}
              </span>
        );
    }
  },
);


let activeItems = {
    'configure': 'nav-item',
    'profile': 'nav-item',
    'monitor': 'nav-item',
    'metrics': 'nav-item',
    'alerts': 'nav-item',
    'discover': 'nav-item',
    'report': 'nav-item',
    'govern': 'nav-item',
    'comply': 'nav-item'
};
let SUB_BRAND = process.env.REACT_APP_CUSTOM_SUB_BRAND
let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO
class NavigationHeader extends React.Component {
     constructor(props) {
            super(props);
            this.getApi = getApi.bind(this);
            this.handleHelp = this.handleHelp.bind(this);
            this.onKeyDownHandler = this.onKeyDownHandler.bind(this);
            this.check_access_billing = this.check_access_billing.bind(this);
            this.closeWarning = this.closeWarning.bind(this);

            let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
            let warning_message = decrypt_data['warning_message']
            // let warning_message = localStorage.getItem("warning_message");

            let showWarning = false;
            this.input = null;
            if (warning_message !== undefined && warning_message !== null && warning_message !== '' && warning_message !== 'undefined') {
                showWarning = true;
            }
            this.state = {
                unreadNotifications: null,
                featureAccess: getFeatureAccess(),
                selectedView : null,
                notificationLoaded: false,
                notificationType: null,
                selectedNotification: null,
                zendeskShow: false,
                showWarning: showWarning,
                warningMessage: warning_message,
                isComplyShow:( LOGGEDIN_USER_EMAIL.includes(localStorage.getItem('emailId')))? true : false,
                prefetch_load: this.props.prefetch_load === true ? this.props.prefetch_load : false,
                isReportShow: ( LOGGEDIN_USER_ID.includes(localStorage.getItem('user_id')))? true : false
            }

            this.getNotificationHeaderData();
     }
     
     pageRefresh(internetstatus){
        if(internetstatus === true){
            window.location.reload(false);
        }
     }

     onKeyDownHandler = e => {
        if (e.keyCode === 13) {
          e.preventDefault();
          if(this.input.value.trim()){
              let data = {"search_text":this.input.value }
              browserHistory.push({
                  pathname: '/discover',
                    state: data // your data array of objects
                })
          }

        }
      };

     componentDidMount() {
        let header = $(".qd-header");
        let body = $("body");
        let shrinkIt = header.height();
        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll >= shrinkIt) {
                header.addClass("sticky-header");
                body.addClass("has-sticky-header");
            } else {
                header.removeClass("sticky-header");
                body.removeClass("has-sticky-header");
            }
        });
        $("#navBarToggler").click(function() {
            if ($("#navBarToggler").hasClass("collapsed")) {
                $("#navBarToggler").removeClass("collapsed");
                $("#navbarCollapse").addClass("show");
            } else {
                $("#navBarToggler").addClass("collapsed");
                $("#navbarCollapse").removeClass("show");
            }
        });
     }

    static getDerivedStateFromProps(props, state) {
        const userNotificationDataFromProps = props.dataModule.userNotificationData;

        const currStateUnreads = state.unreadNotifications;
        const propsUnreadsCount = userNotificationDataFromProps.unreadNotifications.length;
        const unreadCountMisMatch = (currStateUnreads !== null) && (currStateUnreads.length !== propsUnreadsCount);

        const firstTimeLoading = state.notificationLoaded === false && userNotificationDataFromProps.loaded === true;

        if (firstTimeLoading || unreadCountMisMatch) {
            return {
                unreadNotifications: userNotificationDataFromProps.unreadNotifications,
                notificationLoaded: true
            };
        }

        return null;
    }

     goToTeamMate() {
        window.location.href = "/teammate";
     }

    getNotificationHeaderData() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" ||
            sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }

    }


     handleHelp() {
         //this.setState({zendeskShow:true});
         window.zE(function() {
            window.zE.show();
            window.zE.identify({
                name: localStorage.getItem('user_name'),
                email: localStorage.getItem('emailId'),
            });
        });
        window.zE.activate({hideOnClose: false});
     }

     getErrorIcon(notification_type){
        if (notification_type === "Data Quality Error"){
           return faServer;
        }else if(notification_type === "Model Error"){
           return faHdd;
        }else if(notification_type === "Limit Exceeded Alert"){
           return faExclamationTriangle;
        }else if(notification_type === "Payment Failure Alert"){
           return faDollarSign;
        } else if (notification_type === "Missing Dataset"){
           return faServer;
        } else if (notification_type === "Schema Drift"){
           return faServer;
        } else {
            if (REQUIRES_EXCLAMATION_TRIANGLE_ICON.includes(notification_type)) {
                return faExclamationTriangle;
            }
        }
     }

     getNotificationSendTimeInMinutes(createdTimeString){
        let minutes = null;
        let hours = null;
        let days = null;
        var currentTime = new Date();
        var createdTime = new Date(createdTimeString);
        minutes = parseInt((currentTime-createdTime)/(60*1000));
        hours = parseInt((currentTime-createdTime)/(3600*1000));
        days = parseInt((currentTime-createdTime)/(24*3600*1000));
        if(minutes != null && minutes <= 60){
            return minutes + " Mins ago";

        }else if(hours != null && hours <= 24){
            return hours + " Hours ago";

        }else{
            return days + " days ago"
        }
    }

    setView(evt, notificationType, selectedView) {
        window.location.href = "/notification?notification_id=" + evt;
    }


    renderNotifications() {
        let requests = [];
        if (this.state.unreadNotifications === undefined || this.state.unreadNotifications === null) {
            return requests;
        }


        requests = this.state.unreadNotifications.map(val => val.notification_type === "request" || val.notification_type === "request_auto_deleted" ?
            <Dropdown.Item key={val["notification_id"]}
                           className="list-group-item settings-notification"
                           eventKey={val["notification_id"]}
                           onSelect={evt => {this.setView(evt, val.notification_type, val)}}>
                {
                val.notification_type === "request"  ?
                <>
                <div className="notification-icon">
                    <span>
                        <i><FontAwesomeIcon color="#fff" icon={faUserPlus}/></i>
                    </span>
                </div>
                <div className="notification-content">
                    <div className="list-group-item-content">
                        <h5 id={val["notification_id"]}>
                          Invitation Request
                        </h5>
                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                    </div>
                    <p> {val["username"]} would like to join your team on Qualdo.</p>
                </div>
                </>
                :
                <>
                <div className="notification-icon">
                    <span>
                        <i><FontAwesomeIcon color="#fff" icon={faUserMinus}/></i>
                    </span>
                </div>
                <div className="notification-content">
                    <div className="list-group-item-content">
                        <h5 id={val["notification_id"]}>
                          Request Auto Deleted
                        </h5>
                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                    </div>
                    <p> {val["message"]}</p>
                </div>
                </>
                }
            </Dropdown.Item>
            :
            <Dropdown.Item className="list-group-item app-notification"
                           key={val["notification_id"]}
                           eventKey={val["notification_id"]}
                           onSelect={evt => {
                               this.setView(evt, val.notification_type, val)
                           }}>
                <div className="notification-icon">
                    <span>
                        <i>
                            <FontAwesomeIcon color="#fff"
                                            icon={this.getErrorIcon(val.notification_type)}/>
                        </i>
                    </span>
                </div>
                <div className="notification-content">
                    <div className="list-group-item-content">
                        <h5 id={val["notification_id"]}> {val["notification_type"]} </h5>
                        <small>{this.getNotificationSendTimeInMinutes(val["created_time"])}</small>
                    </div>
                    <p> {getTrimmedNotificationMessage(val)} </p>
                </div>
            </Dropdown.Item>
        );

        return requests;
    }

    closeWarning(){
        this.setState({showWarning: false, warningMessage: null});
    }


    check_access_billing(isDrxFreeTrailExpired) {
        let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
        let permissionsList = [];
        if (permissions !== undefined && permissions !== null) {
            permissionsList = permissions;
        }
        let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
        let isPlanExpired = ((decrypt_data['isPlanExpired'] === true && decrypt_data['isInBufferPeriod'] === false) ? true : false);
        // let isPlanExpired = ((localStorage.getItem("isPlanExpired") === "true" && localStorage.getItem("isInBufferPeriod") === "false") ? true : false);

        return (
            <>
                {
                    (this.state.featureAccess !== null && this.state.featureAccess["usage_payment"] === false &&
                        permissionsList.includes('BillingAccess') &&!isDrxFreeTrailExpired) ?
                        <>
                            <Dropdown.Item as={Link} to={isPlanExpired ? "/plan" : "/teammate"}
                                activeClassName="active">
                                <i><FontAwesomeIcon icon={faUserFriends} /></i>
                                Team
                            </Dropdown.Item>
                        </>
                        :
                        permissionsList.includes('BillingAccess') && !isDrxFreeTrailExpired ?

                            <>
                                <Dropdown.Item to={this.state.prefetch_load !== true ? "/plan" :""}
                                    as={Link}
                                    activeClassName="active" >
                                    <i><FontAwesomeIcon icon={faChartPie} /></i>
                                    Plan & Usage
                                </Dropdown.Item>
                                <Dropdown.Item to={this.state.prefetch_load !== true ? "/choose-plan" :""}
                                    as={Link}
                                    activeClassName="active">
                                    <i><FontAwesomeIcon icon={faShoppingBag} /></i>
                                    Upgrade Plan
                                </Dropdown.Item>
                                <Dropdown.Item to={this.state.prefetch_load !== true ? "/billing" :""} 
                                    as={Link}
                                    activeClassName="active">
                                    <i><FontAwesomeIcon icon={faHistory} /></i>
                                    Billing & Payment
                                </Dropdown.Item>
                                <Dropdown.Item as={Link} to={this.state.prefetch_load !== true ? isPlanExpired ? "/plan" : "/teammate" : ""}
                                    activeClassName="active">
                                    <i><FontAwesomeIcon icon={faUserFriends} /></i>
                                    Team
                                </Dropdown.Item>
                            </> : ''
                }
            </>
        );
    }
    shouldComponentUpdate(){
        if(this.state.page === "configure") {
            if(this.state.prefetch_load === true) {
                return false;
            }else {
                return true;
            }
        }else {
            return true;
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.page === "configure") {
            if (prevProps.prefetch_load !== this.props.prefetch_load) {
                this.setState({prefetch_load:this.props.prefetch_load});
            }
        }
    }

    render() {
        // let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
        let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
        let isPlanExpired = ((decrypt_data['isPlanExpired'] === true && decrypt_data['isInBufferPeriod'] === false) ? true : false);
        // let isPlanExpired = ((localStorage.getItem("isPlanExpired") === "true" && localStorage.getItem("isInBufferPeriod") === "false") ? true : false);
        let hideCloseButton = false
        let isDrxFreeTrailExpired = false
        let isDrxFreeTrail = decrypt_data['is_drx_free_trail']
        let DrxFreeTrailStatus = decrypt_data['drx_free_trail_status']
        if (isDrxFreeTrail !== undefined && isDrxFreeTrail !== null && isDrxFreeTrail === true){
            if(DrxFreeTrailStatus !== undefined && DrxFreeTrailStatus !== null && DrxFreeTrailStatus === "Expired"){
                hideCloseButton = true
                isDrxFreeTrailExpired = true
            }
        }   

        for (let [key] of Object.entries(activeItems)) {
            activeItems[key] = "nav-item";
        }
        activeItems[this.props.page] = 'nav-item active'

        let warning_message;
        if (this.state.warningMessage !== null) {
            warning_message = this.state.warningMessage;
        }

        let notificationAlertClassName = "";
        let notificationCircleCount = "";

        const currUnreadNotifications = this.state.unreadNotifications;
        const unreadCount = IS_NONE_CHECK.includes(currUnreadNotifications) ? '' : currUnreadNotifications.length;

        if (unreadCount > 0) {
            notificationAlertClassName = "badge alert-badge";
            notificationCircleCount = unreadCount;
        }

        let hideProfileModelTab = true;
        let enableDiscoveryTab = false;
        let enablePowerBiTab = false;
        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const profileModel = this.state.featureAccess["models"];
            const discoverTab = this.state.featureAccess["discover"];
            hideProfileModelTab = profileModel === false;
            enableDiscoveryTab = discoverTab === true;
            const powerbiTab = this.state.featureAccess["powerbi"];
            enablePowerBiTab = powerbiTab === true;
        }

        if (this.props.dataModule.tenantId == 110) {
            hideProfileModelTab = true;
        }


        const pathname = this.props;
        var profileactive = "";
        var planactive = "";
        var upgradeactive = "";
        var billpaymentactive = "";
        var teamactive = "";
        var videohelpactive = "";
        if(pathname.hasOwnProperty("location")) {
           if(pathname.location.pathname === "/settings"){
             profileactive = "active";
           }
          else if(pathname.location.pathname === "/plan"){
            planactive = "active";
          }
          else if(pathname.location.pathname === "/choose_plan"){
            upgradeactive = "active";
          }
          else if(pathname.location.pathname === "/billing"){
            billpaymentactive = "active";
          }
          else if(pathname.location.pathname === "/teamMate"){
            teamactive = "active";
          }
          else if(pathname.location.pathname === "/quick_start_video"){
            videohelpactive = "active";
          }
        }
        return (
            <>
                {
                    this.state.showWarning === true ?
                        <WarningMessageHeader warning_message={warning_message} closeWarning={this.closeWarning} hideCloseButton={hideCloseButton}/>
                    : ''
                }

                <header className="qd-header">
                    <div className="qd-header-container navbar navbar-expand-md">
                        {(SUB_BRAND !== null && SUB_BRAND !== undefined) ?
                            <dt className="title-text"><small >{SUB_BRAND}</small></dt>
                            :
                            ''
                        }
                    <Navbar expand="lg">
                        <Navbar.Brand href={isDrxFreeTrailExpired ? "/discover" : isPlanExpired ? "/plan" : "/configure"}>
                        <div className="qd-header__brand">
                                <Image
                                    className="qd-header__brand-logo-default"
                                    src={BRAND_LOGO}
                                />
                        </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Nav.Item className={activeItems['configure']}>
                                <Link to={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/configure")} className={`nav-link${isPlanExpired  || isDrxFreeTrailExpired ? " disabled" : ""}`}>
                                        Configure
                                    </Link>
                                </Nav.Item>
                                {this.state.prefetch_load === true ?
                                <Nav.Item className={activeItems['monitor']}>
                                <Link className="nav-link cursor-progress">
                                        Profile
                                </Link>
                                </Nav.Item>
                                    :
                                    <NavDropdown title="Profile" className={activeItems['profile']}
                                             id="basic-nav-dropdown">
                                    <NavDropdown.Item className={activeItems['data_profile']}
                                                      as={customLink}
                                                      link={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/data-profile")}
                                                      tab={this.props.tab}>
                                        <i><FontAwesomeIcon icon={faDatabase}/></i> Data
                                    </NavDropdown.Item>
                                    {hideProfileModelTab ? "" : <NavDropdown.Item className={activeItems['ml-profile']}
                                                      as={customLink}
                                                      link={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/ml-profile")}
                                                      tab={this.props.tab}>
                                        <i><FontAwesomeIcon icon={faBezierCurve}/></i>Models
                                    </NavDropdown.Item>
                                    }
                                </NavDropdown> }
                                <Nav.Item className={activeItems['monitor']}>
                                    {this.state.prefetch_load === true ?
                                    <Link className="nav-link cursor-progress">
                                        Monitor
                                    </Link>
                                     :     
                                     <Link to={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/monitor")} className={`nav-link${isPlanExpired  || isDrxFreeTrailExpired ? " disabled" : ""}`}>
                                        Monitor
                                    </Link>
                                }
                                </Nav.Item>
                                <Nav.Item className={activeItems['metrics']}>
                                {this.state.prefetch_load === true ? 
                                <Link disabled className="nav-link cursor-progress">
                                        Metrics
                                    </Link>
                                    :
                                    <Link to={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/metrics")} className={`nav-link${isPlanExpired  || isDrxFreeTrailExpired ? " disabled" : ""}`}>
                                        Metrics
                                    </Link>
                                }
                                </Nav.Item>
                                <Nav.Item className={activeItems['alerts']}>
                                {this.state.prefetch_load === true ?
                                    <Link className="nav-link cursor-progress">
                                        Alerts
                                    </Link>
                                    :
                                    <Link to={isPlanExpired ? "/plan" : (isDrxFreeTrailExpired ? "/discover": "/alerts")} className={`nav-link${isPlanExpired  || isDrxFreeTrailExpired ? " disabled" : ""}`}>
                                        Alerts
                                     </Link>
                                }
                                </Nav.Item>

                                {(this.state.isReportShow === true)?
                                <Nav.Item className={activeItems['report']}>
                                    <Link to={isPlanExpired ? "/plan" :"/reports"} className="nav-link">
                                        Reports
                                    </Link>
                                </Nav.Item>
                                :""}

                                { enableDiscoveryTab?
                                <Nav.Item className={activeItems['discover']}>
                                    {this.state.prefetch_load === true ?
                                    <Link className="nav-link cursor-progress">
                                        Discover
                                    </Link>
                                    :
                                    <Link to={isPlanExpired ? "/plan" :"/discover"}
                                          className="nav-link">
                                        Discover
                                    </Link> }
                                </Nav.Item>
                                :
                                ''
                                }

                              {(this.state.isComplyShow === true)?
                                <Nav.Item className={activeItems['govern']}>
                                    <Link to={isPlanExpired ? "/plan" :"/govern"} className="nav-link">
                                        Govern
                                    </Link>
                                </Nav.Item>
                                  :""}
                                {(this.state.isComplyShow === true)?
                                <Nav.Item className={activeItems['comply']}>
                                    <Link to={isPlanExpired ? "/plan" :"/comply"} className="nav-link">
                                        Comply
                                    </Link>
                                </Nav.Item>
                                :""}

                                {(this.state.isShow === true)?
                                <Nav.Item className={activeItems['comply']}>
                                    <Link to={isPlanExpired ? "/plan" :"/comply"} className="nav-link">
                                        Comply
                                    </Link>
                                </Nav.Item>
                                :""}

                                 { enablePowerBiTab
                                ?
                <NavDropdown title="Power BI" className={activeItems['powerbi']}
                                             id="basic-nav-dropdown">
                                    <NavDropdown.Item className={activeItems['pbi-setup']}
                                                      as={customLink}
                                                      link={isPlanExpired ? "/plan" :"/pbi-setup"}
                                                      tab={this.props.tab}>
                                        <i className='icon_powerbi'></i>Power BI Setup
                                    </NavDropdown.Item>
                                     <NavDropdown.Item className={activeItems['pbi-report']}
                                                      as={customLink}
                                                      link={isPlanExpired ? "/plan" :"/pbi-report"}
                                                      tab={this.props.tab}>
                                        <i className='icon_powerbi-error'></i>Power BI Error
                                    </NavDropdown.Item>
                                    {/* JIRA ISSUE QPS-2153 <NavDropdown.Item className={activeItems['pbi-mapping']}
                                                      as={customLink}
                                                      link={isPlanExpired ? "/plan" :"/pbi-mapping"}
                                                      tab={this.props.tab}>
                                        <i className='icon_powerbi-mapping'></i>Power BI Mapping
                                    </NavDropdown.Item> */}
                                </NavDropdown>
                                :"" }
                            </Nav>
                            {this.props.page !== "discover" && enableDiscoveryTab ? <div className="qd-header__topbar-item qd-quick-search d-none d-xl-inline-block">
                                <form className="qd-quick-search__form" onKeyDown={this.onKeyDownHandler}>
                                  <div className="input-group">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text"><i><FontAwesomeIcon icon={faSearch}/></i></span>
                                    </div>
                                    <input type="text" ref={n => this.input = n} className="form-control qd-quick-search__input" placeholder="Search..."/>
                                  </div>
                                </form>
                            </div> : ""}
                            <div className="qd-header__topbar-item dropdown d-lg-inline-block qd-header_notification">
                            <div className="qd-header__topbar-wrapper dropdown"
                                 id = "dropdown__user-notification"
                                 key={`user_notification_${notificationCircleCount}`}
                            >

                                <Dropdown>
                                    <Dropdown.Toggle unReadMsg={currUnreadNotifications}
                                                     as={bellToggle}/>
                                    <span className={notificationAlertClassName}
                                          key={`notificationCircle${notificationCircleCount}`}>
                                        {notificationCircleCount}
                                    </span>
                                    { this.state.prefetch_load !== true && currUnreadNotifications !== null && (isPlanExpired !== true && isDrxFreeTrailExpired !== true)?
                                        <Dropdown.Menu className="dropdown-menu__user-notification has-caret">
                                            <Dropdown.Item className="dropdown-menu_user-notification__head">
                                                <span>
                                                    {currUnreadNotifications.length} New Qualdo Notifications
                                                </span>
                                            </Dropdown.Item>

                                            <div className="user-notification__list">
                                                {this.renderNotifications()}
                                            </div>

                                            <Dropdown.Item className="dropdown-menu_user-notification__footer"
                                                           name='view all'
                                                           href='/notification'>
                                                View All Notifications
                                                <i>
                                                    <FontAwesomeIcon icon={faArrowRight}/>
                                                </i>
                                            </Dropdown.Item>
                                        </Dropdown.Menu> : ""
                                    }
                                </Dropdown>


                            </div>
                        </div>
                            <div className="qd-header__topbar-item d-md-none d-lg-inline-block">
                                <div className="qd-header__topbar-wrapper">
                                    <Dropdown alignRight>
                                        <Dropdown.Toggle as={ helpToggle}></Dropdown.Toggle>
                                        <Dropdown.Menu>
                                           <Dropdown.Item href="#!" onClick={this.state.prefetch_load !== true ? this.handleHelp :""}>
                                                <i><FontAwesomeIcon  icon={faHeadset}/></i>
                                                Help Desk & Support
                                            </Dropdown.Item>
                                            <Dropdown.Item to={isDrxFreeTrailExpired === true ? "" : this.state.prefetch_load!== true ? "/quick-start-video" : ""}  as={Link} activeClassName="active" >
                                                <i><FontAwesomeIcon  icon={faRocket}/></i>
                                                Quick Start
                                            </Dropdown.Item>
                                            <Dropdown.Item href={isDrxFreeTrailExpired === true ? "#" : get_document_link("index")}  target="_blank">
                                                <i><FontAwesomeIcon icon={faBook}/></i>
                                                {QUALDO_DOCUMENTATION}
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                {this.state.zendeskShow === true ? <HelpZendesk/> : ""}
                            </div>
                        <div className="qd-header__topbar-item qd-header__topbar-item--user">
                            <div className="qd-header__topbar-wrapper dropdown">
                                <span className="qd-header__topbar-welcome">Hi,</span>
                                <span className="qd-header__topbar-username">
                                    {localStorage.getItem('user_name')}
                                </span>
                                <Dropdown alignRight>
                                    <Dropdown.Toggle as={userToggle}>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                           <Dropdown.Item onClick={this.state.prefetch_load !== true ? this.onItemClick :""}  to={isDrxFreeTrailExpired === true ? "" :this.state.prefetch_load!== true ? "/settings" :""}  as={Link} activeClassName="active"><i><FontAwesomeIcon
                                            icon={faUserCog}/></i>Profile</Dropdown.Item>
                                        {this.check_access_billing(isDrxFreeTrailExpired)}
                                        <Dropdown.Item name='logout'
                                                       onClick={handleClick.bind(this)}>
                                            <i><FontAwesomeIcon icon={faSignOutAlt}/></i>
                                            Log Out
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        </Navbar.Collapse>
                    </Navbar>


                </div>
                <NetworkStatusAlert />
            </header>
            </>
        );
    }
}


const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, null) (NavigationHeader);


export function SimpleHeader() {
    let DataSetupStatus = localStorage.getItem("DataSetupStatus");
    const canShowQuickStart = DataSetupStatus !== "false";
    let BRAND_LOGO = process.env.REACT_APP_CUSTOM_BRAND_LOGO

    let decrypt_data = decryptForLocalUsage(localStorage.getItem(LOCAL_STORE_KEY))
    let warning_message = decrypt_data['warning_message']
    // let warning_message = localStorage.getItem("warning_message");

    let showWarning = false;
    if (warning_message !== undefined && warning_message !== null && warning_message !== '' && warning_message !== 'undefined') {
        showWarning = true;
    }

    return (
        <>
        {
                    showWarning === true ?
                        <WarningMessageHeader warning_message={warning_message} quick_start={true} />
                    : ''
                }
        
        <header className="qd-header">
            <div className="qd-header-container navbar navbar-expand-md">
                {(SUB_BRAND !== null && SUB_BRAND !== undefined) ?
                    <dt className="title-text"><small >{SUB_BRAND}</small></dt>
                    :
                    ''
                }
                <div className="qd-header__brand">
                    <a href="#!">
                        <Image
                            className="qd-header__brand-logo-default"
                            src={BRAND_LOGO}
                        />
                    </a>
                </div>
                <button className="navbar-toggler collapsed" type="button"
                    data-toggle="collapse" data-target="#navbarCollapse"
                    aria-controls="navbarCollapse" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="navbar-collapse collapse flex-row-reverse" id="navbarCollapse">
                    <div className="qd-header__topbar-item qd-header__topbar-item--user">
                        <div className="qd-header__topbar-wrapper dropdown">
                            <span className="qd-header__topbar-welcome">Hi,</span>
                            <span className="qd-header__topbar-username">
                                {localStorage.getItem('user_name')}
                            </span>
                            <Dropdown alignRight>
                                <Dropdown.Toggle as={userToggle}>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="/settings">
                                        <i><FontAwesomeIcon icon={faUserCog} /></i>Profile
                                    </Dropdown.Item>

                                        {
                                            canShowQuickStart ?
                                            <Dropdown.Item href="/quick-start"><i>
                                                <FontAwesomeIcon icon={faRocket}/></i>Quick Start
                                            </Dropdown.Item>
                                            :
                                            ''
                                        }

                                        <Dropdown.Item href={get_document_link("index")} target="_blank">
                                            <i><FontAwesomeIcon icon={faBook}/></i>{QUALDO_DOCUMENTATION}
                                        </Dropdown.Item>
                                        <Dropdown.Item name='logout'
                                                       onClick={handleClick.bind(this)}>
                                            <i><FontAwesomeIcon icon={faSignOutAlt}/></i>
                                            Log Out
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            {/* <Offline polling={false} interval={1000} onChange={this.pageRefresh}>
                <OfflineComponent>
                </OfflineComponent>
            </Offline> */}
            </header>
            </>
        );

}
