import React from 'react';
import Select from 'react-select';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postKubeflow, validateKubeflowUrl }  from "../../utils/event_handling";
import {userValidation} from "../../utils/common_utils"


class KubeflowForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateKubeflowUrl = validateKubeflowUrl.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.postKubeflow = postKubeflow.bind(this);
        this.ChangeAccessTokenIcon = this.ChangeAccessTokenIcon.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.changePasswordIcon = this.changePasswordIcon.bind(this);
        this.state = {
            check: false,
            options: [],
            pipeline: {},
            hideSubmitButton: false,
            kubeflow:{
                kubeflowName: '',
                pipelineId: '',
                pipelineName: '',
                experiment: '',
                payload: '',
                username: '',
                password: '',
                url: ''
            },
            errors: {
            },
            passwordIcon: faEye,
            passwordType: "password"
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleCancel() {
        this.setState({check:false});
    }

    changePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
          this.setState({passwordIcon: faEyeSlash});
          this.setState({passwordType: "text"})
        } else {
          this.setState({passwordIcon: faEye});
          this.setState({passwordType: "password"})
        }
    }

    componentDidMount() {
        const exKubeflowDetails = this.props.ex_details;
        if (exKubeflowDetails === undefined) {
            return;
        }
        let kubeflow = this.state.kubeflow;
        kubeflow.kubeflowName = exKubeflowDetails.pipeline_trigger_name;
        const exConfigurationDetails = exKubeflowDetails.configuration;
        kubeflow.pipelineId = exConfigurationDetails.pipeline_id;
        kubeflow.pipelineName = exConfigurationDetails.pipeline_name;
        kubeflow.url = exConfigurationDetails.url;
        kubeflow.username = exConfigurationDetails.username;
        kubeflow.password = exConfigurationDetails.password;
        kubeflow.payload = exConfigurationDetails.payload;
        kubeflow.experiment = exConfigurationDetails.experiment;
        this.setState({kubeflow_data:kubeflow});
        this.setState({pipeline: {"label": exConfigurationDetails.pipeline_id, "value": exConfigurationDetails.pipeline_id}});
    }


    requires_pipeline_update(kubeflowData){
        const exKubeflowDetails = this.props.ex_details;
        if (kubeflowData.pipeline_trigger_name !== exKubeflowDetails.pipeline_trigger_name) {
            return true;
        }
        const exConfiguration = exKubeflowDetails.configuration;
        const currentConfiguration = kubeflowData.configuration;
        if (currentConfiguration.url !== exConfiguration.url) {
            return true;
        }
        if (currentConfiguration.username !== exConfiguration.username) {
            return true;
        }
        if (currentConfiguration.password !== exConfiguration.password) {
            return true;
        }
        if (currentConfiguration.pipeline_id !== exConfiguration.pipeline_id) {
            return true;
        }
        if (currentConfiguration.pipeline_name !== exConfiguration.pipeline_name) {
            return true;
        }
        if (currentConfiguration.payload !== exConfiguration.payload) {
            return true;
        }
        if (currentConfiguration.experiment !== exConfiguration.experiment) {
            return true;
        }
        return false;
    }

    ChangeAccessTokenIcon() {
        let state = this.state.accessTokenIcon;
        if (state.iconName === "eye") {
            this.setState({accessTokenIcon: faEyeSlash});
            this.setState({accessTokenType: "text"})
          } else {
            this.setState({accessTokenIcon: faEye});
            this.setState({accessTokenType: "password"})
          }
    }

    render() {
        const kubeflowSchema = yup.object({
            pipelineId: yup.string().required("Pipeline Name is a required field"),
            experiment: yup.string().required("Experiment Id is a required field"),
        });
        const kubeflowValidate = yup.object({
            kubeflowName: yup.string().min(1,"Name must be at least 1 characters").required("Name is a required field").test(
                'kubeflow name test',
                'invalid kubeflow name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            url: yup.string().url("Enter a valid URL").required("URL is a required field"),
            username: yup.string().required("Username is a required field"),
            password: yup.string().required("Password is a required field"),
        });

        if (this.state.check === true) {
            return (
                    <>
                            <Formik
                                validationSchema={kubeflowSchema}
                                onSubmit={this.postKubeflow}
                                initialValues={this.state.kubeflow_data}
                                enableReinitialize={true}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                      <Form.Group controlId="pipelineId" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Select Pipeline<span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                <Select
                                                    name="pipelineId"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    id="KubeflowPipeline"
                                                    options={this.state.options}
                                                    defaultValue={this.state.pipeline}
                                                    isInvalid={errors.pipelineId && touched.pipelineId}
                                                    onChange={selectedOption => {
                                                        handleChange("pipelineId")(selectedOption.value.toString());
                                                    }}
                                                    classNamePrefix='select-control'
                                                    placeholder="Select Pipeline"
                                                />
                                                <ErrorMessage component="div" className="error-text" name="pipelineId" />
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="experiment"  className="row">
                                            <Form.Label className="col-md-4 col-form-label">Experiment Id :</Form.Label>
                                            <div className="col-md-5">
                                                <Form.Control
                                                      type="text"
                                                      name="experiment"
                                                      value={values.experiment}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className="form-control"
                                                      placeholder="Experiment Id"/>
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="payload"  className="row">
                                            <Form.Label className="col-md-4 col-form-label">Trigger Payload :</Form.Label>
                                            <div className="col-md-5">
                                                <Form.Control
                                                      type="text"
                                                      name="payload"
                                                      value={values.payload}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className="form-control"
                                                      placeholder="Trigger Payload"/>
                                            </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                            <div className="col-md-5 offset-sm-4">
                                              <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                                </button>
                                            </div>
                                      </Form.Group>
                                </Form>
                                )}
                            </Formik>
                    </>
            );
        } else {
                return (
                             <>
                                    <Formik
                                        validationSchema={kubeflowValidate}
                                        onSubmit={this.validateKubeflowUrl}
                                        initialValues={this.state.kubeflow}
                                    >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                          <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                            <Form.Group controlId="kubeflowName" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Kubeflow Name <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                        type="text"
                                                        name="kubeflowName"
                                                        value={values.kubeflowName}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.kubeflowName && touched.kubeflowName}
                                                        placeholder="Kubeflow Name"/>
                                                    <Form.Control.Feedback type="invalid">
                                                            { touched.kubeflowName && errors.kubeflowName }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="url" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Kubeflow URL <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          onChange={handleChange}
                                                          name="url"
                                                          value={values.url}
                                                          className="form-control"
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.url && touched.url}
                                                          placeholder="Kubeflow URL" />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.url && errors.url }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="username"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">Kubeflow Username <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          name="username"
                                                          onChange={handleChange}
                                                          value={values.username}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.username && touched.username}
                                                          className="form-control"
                                                          placeholder="Kubeflow Username"/>
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.username && errors.username }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="password"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">Kubeflow Password <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <InputGroup bsPrefix="icon-input-group">
                                                        <i>
                                                            <FontAwesomeIcon icon={faLock}/>
                                                        </i>
                                                        <Form.Control
                                                              name="password"
                                                              type={this.state.passwordType}
                                                              onChange={handleChange}
                                                              value={values.password}
                                                              onBlur={handleBlur}
                                                              autoComplete={this.props.autocomplete}
                                                              isInvalid={errors.password && touched.password}
                                                              className="form-control"
                                                              placeholder="Kubeflow Password"/>
                                                        <Form.Control.Feedback type="invalid">
                                                               { touched.password && errors.password }
                                                        </Form.Control.Feedback>
                                                        <i className="security-eye">
                                                            <FontAwesomeIcon onClick={this.changePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                                        </i>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <div className="col-md-5 offset-sm-4">
                                                    <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                    <button type="submit" disabled={this.state.hideSubmitButton} onClick={this.handleClick} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                                    </button>
                                                </div>
                                            </Form.Group>
                                         </Form>
                                    )}
                                    </Formik>
                             </>
                );
        }
    }

}


export default KubeflowForm;

