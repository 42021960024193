import React from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import {postDatasource}  from "../../../../utils/event_handling";
import {userValidation} from "../../../../utils/common_utils"
import {TEST_CONNECTION_MESSAGES, VERSION_FORMATS, FILE_TYPE_FORMATS} from "../../../../utils/constant";
import {  faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';




class TableauForm extends React.Component {
        constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.postS3Data = this.postS3Data.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeAccessKeyIcon = this.ChangeAccessKeyIcon.bind(this);
        this.state = {
            formType_option:null,
            isChecked: true,
            hideSubmitButton: false,
            fileType: null,
            selectOptionKey:"emptyFileType",
            integration_data: this.props.integration_data,
            integration_data_edit: "",
            refreshType: {"label":"File Replace", "value":"file_replace"},
            versionFormat: null,
            versionOccurence: {"label":"prefix", "value":"prefix"},
            accessKeyIcon:faEye,
            accessKeyType:'password',
        };
        }

        componentDidMount() {
        this.fill_details();
        }

        toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
        }

        fill_details() {
            if (this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) {
                let integration_data =  this.state.integration_data;
                integration_data.integration_name = this.props.ex_integration_details["integration_name"];
                integration_data.integration_id = this.props.ex_integration_details["integration_id"];
                integration_data.bucket_name = this.props.ex_integration_details.integration_config.bucket;
                integration_data.sub_path = this.props.ex_integration_details.integration_config.sub_path;
                integration_data.error_storage = this.props.ex_integration_details.integration_config.error_storage;
                let fileType = this.props.ex_integration_details.integration_config.filetype;
                integration_data.fileType = fileType
                let refreshType = this.props.ex_integration_details.integration_config.refresh_type;
                integration_data.refreshType = refreshType;
                let versionType = this.props.ex_integration_details.integration_config.version_type;
                let versionFormat = this.props.ex_integration_details.integration_config.version_format;
                let versionOccurence = this.props.ex_integration_details.integration_config.version_occurence;
                if (versionType !== "custom") {
                    integration_data.versionFormat = versionFormat;
                    this.setState({versionFormat: {"label": versionType, "value": versionFormat}});
                } else {
                    integration_data.versionFormat = versionType;
                    integration_data.versionFormatRegex = versionFormat;
                    this.setState({versionFormat: {"label": versionType, "value": versionType}});
                }
                let defaultErrorStorage = this.props.ex_integration_details.integration_config.default_error_storage;
                if (defaultErrorStorage === undefined) {
                    defaultErrorStorage = true;
                }
                this.setState({isChecked: defaultErrorStorage,
                            selectOptionKey: "updatedFileType",
                            fileType: {"label": fileType, "value": fileType},
                            versionOccurence: {"label": versionOccurence, "value": versionOccurence},
                            refreshType: {"label": FILE_TYPE_FORMATS[refreshType], "value": refreshType}});

                if (this.props.formType === "s3"){
                    integration_data.accessKey = this.props.ex_integration_details.auth_config.access_key;
                    integration_data.secretAccessKey = this.props.ex_integration_details.auth_config.secret_access_key;
                }

                this.setState({
                            integration_type: this.props.formType,
                            integration_data_edit: integration_data
                            });
            }

        }

        postS3Data(event) {
            this.disableButton();
            const {integration_name,error_storage, accessKey, secretAccessKey,sub_path,bucket_name,
            fileType, versionFormat, versionFormatRegex} = event;
            let version_format;
            let version_type;
            if (versionFormat === "custom") {
                version_format = versionFormatRegex;
                version_type = "custom";
            } else {
                version_format = VERSION_FORMATS[versionFormat];
                version_type = versionFormat;
            }
            let s3Data = {"integration_name":integration_name,
                                    "integration_id":this.state.integration_data.integration_id,
                                    "data_store_type":"filesystem",
                                    "error_storage": error_storage,
                                    "bucket_name": bucket_name,
                                    "sub_path": sub_path,
                                    "filetype": fileType,
                                    "refresh_type": this.state.refreshType.value,
                                    "version_format": version_format,
                                    "version_type": version_type,
                                    "version_occurence": this.state.versionOccurence.value,
                                    "env_id":this.props.env_id
                            }
                s3Data["integration_type"] = "s3"
                s3Data["access_key"] = accessKey;
                s3Data["secret_access_key"] = secretAccessKey;
                s3Data["isFirstIntegration"] = this.props.isFirstIntegration;

            let default_message = TEST_CONNECTION_MESSAGES["s3"];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(s3Data, default_message);
            this.postDatasource(s3Data);
        }

        getSchema() {
            const subPath = RegExp(/^(?!\/)/);
            let s3Schema = yup.object({
                integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                    'DatasourceName test',
                    'invalid Datasource name',
                    function test(value) {
                        let val = userValidation(value,"input");
                        return val;
                })});
            return s3Schema;
        }

        disableButton() {
            this.setState({hideSubmitButton: true});
        }

        ChangeAccessKeyIcon() {
            let state = this.state.accessKeyIcon;
            if (state.iconName === "eye") {
                this.setState({accessKeyIcon: faEyeSlash});
                this.setState({accessKeyType: "text"})
                } else {
                this.setState({accessKeyIcon: faEye});
                this.setState({accessKeyType: "password"})
                }
        }

        render() {
            let s3Schema = this.getSchema();
            let initial_values = '';

            initial_values = {integration_name:'',error_storage: '',accessKey: '', secretAccessKey: '',sub_path: '',
            bucket_name: '',fileType: '', refreshType: '', versionFormat: '', versionOccurence: ''}

            let edit_integration = null;
            if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
                edit_integration = true;
            }
            else{
                edit_integration = false;
            }
            return (
                    <>
                        <Formik
                                validationSchema={s3Schema}
                                onSubmit={this.postS3Data}
                                validateOnBlur={false}
                                initialValues={(edit_integration === true) ? this.state.integration_data_edit : initial_values}
                                enableReinitialize={true}                        >
                        {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                        }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group controlId="integration_name" className="form-group col-md-6">
                                <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="integration_name"
                                        value={values.integration_name === undefined ? "" : values.integration_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.integration_name && touched.integration_name}
                                        placeholder="Datasource Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                            { touched.integration_name && errors.integration_name }
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="app_ver" className="col-md-6">
                                            <Form.Label>App Version <span className="text-danger">*</span></Form.Label>
                                            <Form.Control
                                                type="text"
                                                className="form-control"
                                                name="app_ver"
                                                value={values.bucket_name === undefined ? "" : values.bucket_name}
                                                disabled={edit_integration ? true : false}
                                                onChange={handleChange}
                                                placeholder="App Version"
                                                onBlur={handleBlur}
                                                isInvalid={errors.bucket_name && touched.bucket_name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                            { touched.bucket_name && errors.bucket_name }
                                            </Form.Control.Feedback>
                                    </Form.Group>
                            <Form.Group controlId="app_server_url" className="col-md-6">
                                    <Form.Label>App Server URL  </Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            placeholder="App Server URL"
                                            value={values.sub_path === undefined ? "" : values.sub_path}
                                            disabled={edit_integration ? true : false}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            name="app_server_url"
                                            isInvalid={errors.sub_path}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                        {errors.sub_path }
                                        </Form.Control.Feedback>
                                </Form.Group>
 
                                <Form.Group controlId="token_name" className="col-md-6">
                                    <Form.Label>Token Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            name="token_name"
                                            value={values.accessKey === undefined ? "" : values.accessKey}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder='Token Name'
                                            isInvalid={errors.accessKey && touched.accessKey}
                                    />
                                        <Form.Control.Feedback type="invalid">
                                        { touched.accessKey && errors.accessKey }
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group controlId="token_value" className="col-md-6">
                                    <Form.Label>Token Value <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            name="token_value"
                                            value={values.accessKey === undefined ? "" : values.accessKey}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder='Token Value'
                                            isInvalid={errors.accessKey && touched.accessKey}
                                    />
                                        <Form.Control.Feedback type="invalid">
                                        { touched.accessKey && errors.accessKey }
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group controlId="site_name" className="col-md-6">
                                    <Form.Label>Site Name <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                            type="text"
                                            className="form-control"
                                            name="site_name"
                                            value={values.accessKey === undefined ? "" : values.accessKey}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            placeholder='Site Name'
                                            isInvalid={errors.accessKey && touched.accessKey}
                                    />
                                        <Form.Control.Feedback type="invalid">
                                        { touched.accessKey && errors.accessKey }
                                    </Form.Control.Feedback>
                                </Form.Group>

                            <div className="checkbox col-md-12" style={{"padding": "10px 5px"}}>
                                    <Form.Label>
                                        <Form.Control
                                            type="checkbox"
                                            name="errorColumn"
                                            onChange={this.toggleChange}
                                            checked={this.state.isChecked}/>
                                        <span className="checkbox-decorator">
                                            <span className="check">
                                            </span>
                                        </span>Store Data Issues and Errors in Qualdo™
                                    </Form.Label>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error File Name <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="error_storage"
                                        placeholder="Error file Name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={errors.error_storage && touched.error_storage}
                                        value={values.error_storage === undefined ? "" : values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                            { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                                </Form.Group> : ''}
                        </div>
                        <hr className="clearfix"/>
                        <div className="text-right">
                                {this.props.showCancel ?
                                    <button type="button"
                                            onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)}
                                            className="btn btn-outline btn-grey btn-circle mr-2">
                                        Cancel
                                    </button> : ''
                                }
                                <button type="submit"
                                        disabled={this.state.hideSubmitButton}
                                        className="btn btn-primary btn-circle mr-2">
                                    Test Connection
                                </button>
                        </div>
                        </Form>)}
                        </Formik>
                </>
            );
        }
}
export default TableauForm;