import React from 'react';
import Image from '../components/image';
import Load from '../components/loadAction';
import BasicPortlet from '../components/basicPortlet';
import EmailForm from './components/EmailForm';
import SlackForm  from './components/slackForm';
import PagerdutyForm  from './components/PagerdutyForm';
import QualdoDataTable from '../components/bootstrapTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import {postMixpanel} from "../mixpanel";
import MSTeamsForm from './components/msTeamsForm';
import { checkPermissions } from '../utils/common_utils';

class Notification extends React.Component {

    constructor(props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleRadioButton = this.handleRadioButton.bind(this);
        this.renderNotificationForm = this.renderNotificationForm.bind(this);
        this.state={
            options: [{"label": "Email", "value": "Email"}, {"label": "Slack", "value": "Slack"},
            {"label": "Pagerduty", "value": "Pagerduty"}, {"label": "MicrosoftTeams", "value": "MicrosoftTeams"}],
            selectedOption: null,
            option: "",
            isClicked: false,
            notificationType: "email",
            title: "Add Email"
        }
    }

    handleCancel() {
        let value = this.state.isClicked ? false : true;
        this.setState ({isClicked: value});
    }

    handleRadioButton = e =>{
       this.setState({notificationType:e.target.value});
       if(e.target.value === "email") {
          this.setState({title:"Add Email"});
       }else{
          this.setState({title:"Add Slack"});
       }
    }

    handleButton() {
      postMixpanel("clicked Add Notification Channel in Alerts-> notification tab")
      let value = this.state.isClicked ? false : true;
      this.setState ({isClicked: value});
    }

    renderNotificationForm(notificationType){
      switch(notificationType){
        case "email":
            return(
                <EmailForm 
                    showToast={this.props.showToast} 
                    handleCancel={this.handleCancel}
                />
            );
        case "slack":
            return(
                <SlackForm 
                    showToast={this.props.showToast} 
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                    />
            );
        case "pagerduty":
            return(
                <PagerdutyForm 
                    showToast={this.props.showToast} 
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        case "MicrosoftTeams":
            return(
                <MSTeamsForm showToast={this.props.showToast} handleCancel={this.handleCancel}/>
            );
        default:
            break;
      }
    }

    render() {
        let disableAlertAction = checkPermissions('Alerts')
        let notificationType = this.state.notificationType;
        return (
            <>
            <div className="col-md-8 offset-md-2 text-center p-3">
                <i className="d-inline-block mb-3">
                    <Image src="notification"/>
                </i>
                <h4 className="mb-3">Add a Notification Channel</h4>
                <p>Notification Channels help you to receive Qualdo generated errors and custom metric alerts.</p>
                <div className="d-flex justify-content-center">
                    <form className="form-inline">
                        <div className="form-group mx-sm-3 mb-2 text-left">
                        </div>
                    </form>
                    <button disabled={disableAlertAction} type="button" onClick={this.handleButton} className="btn btn-primary mb-2 navto_dq-add-alert-metrics"><FontAwesomeIcon icon={faBell}/>  Add a Notification Channel</button>
                </div>
            </div>
            {this.state.isClicked ? <BasicPortlet
                        className="pb-0" title={"Add a Notification Channel"} bodyClassName="pb-0" id="addNotificationChannel"
                        content={
                                 <div className="row">
                                      <div className="col-lg-10 col-md-12 pt-4">
                                           <div className="form-label-right-md">
                                                <Form.Group className="row">
                                                      <Form.Label className="col-md-5 col-lg-4 col-form-label">Notification :</Form.Label>
                                                      <div className="col-md-7 col-lg-5">
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="NotificationType"
                                                                  id="email"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.notificationType === "email"}
                                                                  value="email"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="email">
                                                                Email
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="NotificationType"
                                                                  id="slack"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.notificationType === "slack"}
                                                                  value="slack"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="slack">
                                                                Slack
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="NotificationType"
                                                                  id="pagerduty"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.notificationType === "pagerduty"}
                                                                  value="pagerduty"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="pagerduty">
                                                                Pagerduty
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="NotificationType"
                                                                  id="MicrosoftTeams"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.notificationType === "MicrosoftTeams"}
                                                                  value="MicrosoftTeams"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="MicrosoftTeams">
                                                                Microsoft Teams
                                                            </Form.Label>
                                                        </div>
                                                      </div>
                                                </Form.Group>
                                           </div>
                                           {this.renderNotificationForm(notificationType)}
                                      </div>
                                 </div>
                        }
                    /> : ''
            }
            {this.props.data !== null ? <BasicPortlet
                        className="mb-0 pb-0"
                        id="notificationChannelsTable"
                        title="NOTIFICATION CHANNELS"
                        content={<QualdoDataTable
                                    data={this.props.data}
                                    showToast={this.props.showToast}
                                    component_name='consume'
                                 />}
                    /> : <BasicPortlet
                        className="mb-0 pb-0"
                        id="notificationChannelsTableLoading"
                        title="NOTIFICATION CHANNELS"
                        content={
                                    <Load />
                                }
                   />}
            </>
        );
    }
}

export default Notification;