import React from 'react';
import Select from 'react-select';

class AddMetadata extends React.Component {

render() {
   return(<div className="row">
                      <div className="col-md-12">
                        <div className="portlet-body">

                          <form className="form-label-right-md">
                            <div className="row">
                              <div className="form-group col-md-3">
                                <label className="form-label">Metadata Source Name :</label>
                                <input
                                    placeholder={"Type Metadata Source"}
                                    id={"metadatasource"}
                                    className="form-control"
                                    type="text"
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">Metadata Tool :</label>
                                <Select
                                    placeholder="Choose Metadata Tool"
                                    classNamePrefix='select-control'
                                    options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">Metadata Connector Type :</label>
                                <Select
                                    placeholder="Choose Metadata Connector Type"
                                    classNamePrefix='select-control'
                                    options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">Bucket Name :</label>
                                <Select
                                    placeholder="Choose Bucket Name"
                                    classNamePrefix='select-control'
                                    options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                />
                                </div>
                              <div className="form-group col-md-3">
                                <label className="form-label" for="sub_path">Sub Path :</label>
                                <input type="text" className="form-control" placeholder="Type Sub Path"/>
                              </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">File Type :</label>
                                <Select
                                    placeholder="Choose File Type"
                                    classNamePrefix='select-control'
                                    options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                />
                              </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">Access key :</label>
                                   <Select
                                    placeholder="Choose Access key"
                                    classNamePrefix='select-control'
                                    options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                />
                                </div>
                              <div className="form-group col-md-3">
                                <label className="form-label">Secret Access key :</label>
                                    <Select
                                        placeholder="Choose Secret Access key"
                                        classNamePrefix='select-control'
                                        options={[{"label": "Azure Blob", "value":1},{"label": "Azure Data Lake", "value":2},{"label": "Snowflake", "value":3}]}
                                    />
                                </div>
                            </div>
                            <div className="custom-control custom-checkbox custom-control-inline pt-2">
                              <input name="defaulErrorCheck" type="checkbox" id="customCheck2" className="custom-control-input form-control"/>
                                <label className="custom-control-label form-label" for="customCheck2">Store Metadata glossary in Qualdo™</label>
                              </div>
                            <div className="text-right mt-4">
                              <button type="button" className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                              <button type="button" className="btn btn-primary btn-circle mr-2"><i className="fa fa-check"></i>Submit</button>
                            </div>
                          </form>

                        </div>
                      </div>
                    </div>);

}
}

export default AddMetadata;