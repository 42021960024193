import React from 'react';
import {postDatasource}  from "../../../../utils/event_handling";
import { userValidation } from "../../../../utils/common_utils"
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import {TEST_CONNECTION_MESSAGES} from "../../../../utils/constant";
import { faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';


class DODSForm extends React.Component {
    constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postMySqlData = this.postMySqlData.bind(this);
        this.postDatasource = postDatasource.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangePasswordIcon = this.ChangePasswordIcon.bind(this);
        this.state = {
            integration_data: this.props.integration_data,
            integration_data_edit: "",
            isChecked: true,
            hideSubmitButton: false,
            passwordIcon:faEye,
            passwordType:'password',
        };
    }

    componentDidMount() {
        this.fill_details();
    }

    toggleChange = () => {
            this.setState({isChecked: !this.state.isChecked});
    }

    fill_details() {
        if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined && this.props.ex_integration_details.integration_config  !== undefined) {
            let ex_integration_details = this.props.ex_integration_details;
            let integration_data = this.state.integration_data;
            const exIntegrationConfig = ex_integration_details.integration_config;
            integration_data.host = exIntegrationConfig.host;
            integration_data.database = exIntegrationConfig.database;
            integration_data.error_storage = exIntegrationConfig.error_storage;
            integration_data.table_name = exIntegrationConfig.table_name;
            integration_data.created_time = exIntegrationConfig.created_time;
            integration_data.user = ex_integration_details.auth_config.user;
            integration_data.password = ex_integration_details.auth_config.password;
            integration_data.integration_name = ex_integration_details.integration_name;
            integration_data.integration_id = this.props.ex_integration_details["integration_id"];
            integration_data.integration_type = "mysql";

           let defaultErrorStorage = exIntegrationConfig.default_error_storage;
           if (defaultErrorStorage === undefined) {
                defaultErrorStorage = true;
           }

            this.setState({isChecked: defaultErrorStorage});
            this.setState({integration_data_edit:integration_data});
        } else {
            let integration_data = this.state.integration_data;
            integration_data.filetype= "json";
            this.setState({integration_data_edit:integration_data});
        }
    }

    disableButton() {
            this.setState({hideSubmitButton: true});
    }

    postMySqlData(event){
        this.disableButton();
        const {
            integration_name, host, database, table_name,
            user, error_storage, password,
            created_time
        } = event;

        let mySqlData = {"integration_name":integration_name,
                             "data_store_type":"database",
                             "integration_id":this.state.integration_data.integration_id,
                             "integration_type":"mysql",
                             "host": host,
                             "database": database,
                             "error_storage": error_storage,
                             "user": user,
                             "password": password,
                             "table_name": table_name,
                             "created_time": created_time,
                             "isFirstIntegration": this.props.isFirstIntegration,
                             "env_id":this.props.env_id
                             }
        let default_message = TEST_CONNECTION_MESSAGES["mysql"];
        this.props.closeTestConnection();
        this.props.setSubmitButton(true);
        this.props.setData(mySqlData, default_message);
        this.postDatasource(mySqlData);

    }

    ChangePasswordIcon() {
        let state = this.state.passwordIcon;
        if (state.iconName === "eye") {
            this.setState({passwordIcon: faEyeSlash});
            this.setState({passwordType: "text"})
          } else {
            this.setState({passwordIcon: faEye});
            this.setState({passwordType: "password"})
          }
    }

    render() {
        let mySqlSchema = '';
        if(this.state.isChecked === false) {
            mySqlSchema =  yup.object({
                integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                    'DatasourceName test',
                    'invalid Datasource name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                host: yup.string().required('Host is a required field'),
                database: yup.string().required('Database is a required field'),
                user: yup.string().required('Username is a required field'),
                password: yup.string().required('Password is a required field'),
                error_storage: yup.string().required('Error Storage is a required field'),
            });
        }else {
            mySqlSchema =  yup.object({
                integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Datasource Name is a required field").test(
                    'DatasourceName test',
                    'invalid Datasource name',
                    function test(value) {
                       let val = userValidation(value,"input");
                       return val;
                }
                ),
                host: yup.string().required('Host is a required field'),
                database: yup.string().required('Database is a required field'),
                user: yup.string().required('Username is a required field'),
                password: yup.string().required('Password is a required field'),
            });
        }

        let initial_values = {
            integration_name: '', host: '', database: '', table_name: '', user: '', password: '', error_storage: '',
            created_time: ''
        }

        let edit_integration = null;
        if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
            edit_integration = true;
        }
        else{
            edit_integration = false;
        }

        return (
                <>
                    <Formik
                          validationSchema={mySqlSchema}
                          onSubmit={this.postMySqlData}
                          initialValues={(edit_integration === true) ? this.state.integration_data_edit : initial_values}
                          enableReinitialize={true}                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                    <Form onSubmit={handleSubmit}>
                    <div className="form-row">
                        <Form.Group controlId="integration_name" className="col-md-6">
                            <Form.Label>Datasource Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="integration_name"
                                  value={values.integration_name === undefined ? "" : values.integration_name}
                                  onChange={handleChange}
                                  placeholder="Datasource Name"
                                  onBlur={handleBlur}
                                  isInvalid={errors.integration_name && touched.integration_name}/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.integration_name && errors.integration_name }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="host" className="col-md-6">
                            <Form.Label>Host <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="host"
                                  value={values.host === undefined ? "" : values.host}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={errors.host && touched.host}
                                  placeholder="Host"/>
                            <Form.Control.Feedback type="invalid">
                                  { touched.host && errors.host }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="database" className="col-md-6">
                            <Form.Label>Database <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  value={values.database === undefined ? "" : values.database}
                                  placeholder="Database"
                                  onBlur={handleBlur}
                                  isInvalid={errors.database && touched.database}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                                  name="database"/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.database && errors.database }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="table_name" className="col-md-6">
                            <Form.Label>Table name </Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  placeholder="Table name"
                                  value={values.table_name === undefined ? "" : values.table_name}
                                  onBlur={handleBlur}
                                  name="table_name"
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange} />
                            <Form.Control.Feedback type="invalid">
                                       { touched.table_name && errors.table_name }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="user" className="col-md-6">
                            <Form.Label>Username <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="user"
                                  placeholder="Username"
                                  value={values.user === undefined ? "" : values.user}
                                  onBlur={handleBlur}
                                  isInvalid={errors.user && touched.user}
                                  onChange={handleChange}  />
                            <Form.Control.Feedback type="invalid">
                                       { touched.user && errors.user }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="password" className="col-md-6">
                            <Form.Label>Password <span className="text-danger">*</span></Form.Label>
                            <InputGroup bsPrefix="icon-input-group">
                                <i>
                                    <FontAwesomeIcon icon={faLock}/>
                                </i>
                                <Form.Control
                                    type={this.state.passwordType}
                                    className="form-control"
                                    placeholder="Password"
                                    value={values.password === undefined ? "" : values.password}
                                    onBlur={handleBlur}
                                    isInvalid={errors.password && touched.password}
                                    name="password"
                                    onChange={handleChange}  />
                                <Form.Control.Feedback type="invalid">
                                        { touched.password && errors.password }
                                </Form.Control.Feedback>
                                <i className="security-eye">
                                    <FontAwesomeIcon onClick={this.ChangePasswordIcon} icon={this.state.passwordIcon} className="security-eye"/>
                                </i>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="created_time" className="col-md-6">
                            <Form.Label>Incremental Data Identifier </Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="created_time"
                                  placeholder="Incremental Data Identifier"
                                  value={values.created_time === undefined ? "" : values.created_time}
                                  onBlur={handleBlur}
                                  disabled={edit_integration ? true : false}
                                  onChange={handleChange}
                            />
                        </Form.Group>
                        <div className="checkbox col-md-12" style={{"padding": "10px 5px"}}>
                             <Form.Label>
                                 <Form.Control
                                       type="checkbox"
                                       name="errorColumn"
                                       onChange={this.toggleChange}
                                       checked={this.state.isChecked}
                                       disabled/>
                                 <span className="checkbox-decorator">
                                        <span className="check">
                                        </span>
                                 </span>Store Data Issues and Errors in Qualdo™
                             </Form.Label>
                        </div>
                        {this.state.isChecked === false ?
                        <Form.Group controlId="error_storage" className="col-md-6">
                            <Form.Label>Error Table Name <span className="text-danger">*</span></Form.Label>
                            <Form.Control
                                  type="text"
                                  className="form-control"
                                  name="error_storage"
                                  placeholder="Error Table Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isInvalid={errors.error_storage && touched.error_storage}
                                  value={values.error_storage === undefined ? "" : values.error_storage}/>
                            <Form.Control.Feedback type="invalid">
                                       { touched.error_storage && errors.error_storage }
                            </Form.Control.Feedback>
                         </Form.Group> : ''}
                    </div>
                    <hr className="clearfix"/>
                    <div className="text-right">
                         {this.props.showCancel ?
                             <button type="button"
                                     onClick={() => this.props.ex_integration_details ? this.props.closeModal() : this.props.setPage(false)}
                                     className="btn btn-outline btn-grey btn-circle mr-2">
                                 Cancel
                             </button> : ''}
                         <button type="submit"
                                 disabled={this.state.hideSubmitButton}
                                 className="btn btn-primary btn-circle mr-2">
                             Test Connection
                         </button>
                    </div>
                    </Form>)}
                    </Formik>
                </>

        );
    }
}
export default DODSForm;