import React from 'react';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import BasicPortlet from '../components/basicPortlet';
import Nav from 'react-bootstrap/Nav';
import {CopyRightsFooter} from '../components/copyrights';

import DSEnvironment from './dataSources/dsEnvironment';
import ErrorHandler from '../components/error_500';
import {AB_MODELS_TABLE_TITLE, IS_NONE_CHECK} from "../utils/constant";
import {postMixpanel} from '../mixpanel';

class Reports extends React.Component {
    constructor(props) {
        super(props);
        let tab = "ds"
        if(this.props.route.navKey !== undefined && this.props.route.navKey !== null) {
          tab=this.props.route.navKey
        }

        this.handleTab = this.handleTab.bind(this);

        // State variable => model_ab_data contains data for table rendering,
        // State variable => currModelABList contains AB data that is fetched from API
        this.state={
            errorOccurred: false,
            toastStatus: false,
            current_tab:tab,

            data:null,
            integration_details:null,
            integration_option:null,
            mlDetails:null,
            mlData:null,
            mlLimitExceed: null,
            dataLimitExceed: null,
            isAddModel: false,
            isModelVersion: false,
            isAddDS: false,
            planName: null,
            environment:null,
            datasets:[],
            attributes: [],
            maxModel: null,
            datasource_updates: null,
            model_ab_data: null,
            currModelABList: [],
            model_ab_updated: false,
            model_updates: null,
            show: false,
            change_request_exists: false,
        }
     }




    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

   componentDidMount() {
      this.sectionNavigation();

   }
    handleTab(key) {
        postMixpanel("Configure -> " + key +"tab Page")
        this.setState ({current_tab: key});
    }

    sectionNavigation() {
        document.querySelectorAll('.breadcrumb-direct-section li a[href*="#"]').forEach(anchor => {
            anchor.addEventListener('click', function (e) {
                e.preventDefault();
                var topOfElement = document.querySelector(this.getAttribute('href')).offsetTop;
                window.scroll({ top: topOfElement-80, behavior: "smooth" });
            });
        });
    }

    render() {
        let hideConfPageModelTab = true;


        if (!IS_NONE_CHECK.includes(this.state.featureAccess)) {
            const confPageData = this.state.featureAccess["models"];
            hideConfPageModelTab = confPageData === false;
        }

        return (

            this.state.errorOccurred ?
                <ErrorHandler/>
            :
            <>

                <NavigationHeader page="report"/>
                <main>
                    <BreadCrumb icon='faSearchEngine' title='Reports' />
                    <Tab.Container defaultActiveKey={this.state.current_tab} onSelect={this.handleTab}>
                        <div className="qd-tab__header">
                            <div className="container-fluid">
                                <div className="qd-tab__header-wrapper">
                                    <div className="qd-tab__header-menu">
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="ds" >
                                                    <span className="qd-menu__link-text">Quality Reports</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qd-container">
                            <div className="qd-body">
                                <div className="qd-tab__content">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="ds">
                                        <>
                                          <div id="jump-to_datasource piperr-iframe" className="mb-15">
                                              <DSEnvironment
                                                    id={"frmExecutiveView"}
                                                    width={"100%"}
                                                    height={"2000px"}
                                                            url={"http://20.25.251.239:8080/superset/dashboard/1/?standalone=true"}
                                                    />
                                            </div>
                                            </>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>

            </>
        );
    }
}

//export default Configure;

const mapStateToProps = state => {
    return state;
}

export default (Reports);
