import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../../components/navigationHeader';
import BreadCrumb from '../../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import MlModel from './ml_model'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ErrorHandler from '../../components/error_500';
import {CopyRightsFooter} from '../../components/copyrights';
import { postUIlogs } from "../../utils/event_handling";


import { connect } from "react-redux";

import {
    preFetchModelDetails
} from "../../utils/modelFetch";

import Load from '../../components/loadAction';

import {
    addMLModelMapping,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    setMLModel,
    addModelDetails
} from "../../redux/actions";


class MLProfile extends React.Component {

    constructor(props) {
        super(props);
        this.postUIlogs = postUIlogs.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.preFetch = this.preFetch.bind(this)
        this.preFetchModelDetails = preFetchModelDetails.bind(this)
        this.state = {
            errorOccurred: false,
            toastStatus: false,
            closeLastProfiledTime: false,
            showLoader:true,
        }
    }


    componentDidMount(){
        this.preFetch();
    }

    preFetch() {
        let teamID = localStorage.getItem('team_id')   
        // // Monitor Model Prefetch : model/map
        // // store the value in redux
        if (!this.props.monitorModule.preFetchModelDetails) {
            this.preFetchModelDetails(
                teamID, this.props.addMLModelMapping,
                this.props.addMLDataSetInfo, this.props.addMLAttributeInfo,
                this.props.addMLModel, this.props.setMLModel, this.props.addModelDetails
            );
        }
        else{
            this.setState({showLoader:false})
        }
    }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error, info);
    }

    closeToast() {
        this.setState({toastStatus: false});
    }



    render() {
        let sessionToken = localStorage.getItem("sessionToken");
        if (sessionToken === "undefined" || sessionToken === "null" || sessionToken === null || sessionToken === undefined) {
            window.location = '/';
            return;
        }
        return (
            this.state.errorOccurred ?
                <ErrorHandler/>
            :
            <>
                <div id="toastMsg">
                  <Toast className={this.state.toastClass}  onClose={() =>  this.closeToast()}  show={this.state.toastStatus} delay={3000} autohide>
                    <div className="d-flex align-items-center">
                       <i className="toast-icon">
                           { this.state.toastIcon ?
                                <FontAwesomeIcon icon={ this.state.toastIcon }/> :
                                ""
                            }
                       </i>
                       <Toast.Body>
                          <strong className="toast-title">{this.state.toastHeading}</strong>
                          <p>{ this.state.toastMsg }</p>
                       </Toast.Body>
                       <button type="button" onClick={this.closeToast} className="close" data-dismiss="toast" aria-label="Close"/>
                    </div>
                  </Toast>
                </div>
                <NavigationHeader page="profile" tab="ml"/>
                <main>
                    <BreadCrumb icon='userCircle' title='Profile' separator={true} separatorData="Models"/>
                    <Tab.Container defaultActiveKey="ml">
                        <div className="qd-tab__header">
                            <div className="container-fluid">
                                <div className="qd-tab__header-wrapper">
                                    <div className="qd-tab__header-menu">
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="ml">
                                                    <span className="qd-menu__link-text">Models</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qd-container">
                            <div className="qd-body">
                                <div className="qd-tab__content" id="modelProfile">
                                    <Tab.Content>
                                        <Tab.Pane mountOnEnter={true} unmountOnExit={true} eventKey="ml">
                                            {this.state.showLoader === true ? <Load/> : <MlModel/>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>
            </>
        );
    }

}


const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
    addMLModelMapping,
    setMLModel,
    addMLDataSetInfo,
    addMLAttributeInfo,
    addMLModel,
    addModelDetails,
}
)(MLProfile);