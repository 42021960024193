import React from 'react';
import Iframe from 'react-iframe'
import $ from 'jquery';
import ReactDOM from 'react-dom'
class DSEnvironment extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
       return(
            <>
                        <Iframe url={this.props.url}
                        frameBorder={0}
                       height={this.props.height}
                        width={this.props.width}
                        id={this.props.id}
                        className="myClassname"
                        position="relative"
                         />

            </>
        );
    }
}

export default DSEnvironment;