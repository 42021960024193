import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postWebhookData }  from "../../utils/event_handling";
import {userValidation} from '../../utils/common_utils'


class WebhookForm extends React.Component {

    constructor(props) {
        super(props);
        this.postWebhookData = postWebhookData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            hideSubmitButton: false,
            webhook:{
                webhookName: '',
                url: '',
                header: '',
                dataFormat: 'json',
                methods: 'post',
                parameter: ''
            },
            errors: {
            }
        }
        this.fill_details();
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    fill_details() {
        const exWebhook = this.props.ex_details;
        if (exWebhook === undefined) {
            return;
        }
        let webhook = this.state.webhook;
        webhook.webhookName = exWebhook.pipeline_trigger_name;
        const exConfigurationDetails = exWebhook.configuration;
        

        webhook.url = exConfigurationDetails.url;
        webhook.header = exConfigurationDetails.header;
        webhook.parameter = exConfigurationDetails.payload;
        webhook.dataFormat = exConfigurationDetails.data_format;
        webhook.methods = exConfigurationDetails.http_method;
        this.setState({
            webhook:webhook
        });

    }


    requires_pipeline_update(webhook_data){
        const ex_web_hook_details = this.props.ex_details;
        if (webhook_data.pipeline_trigger_name !== ex_web_hook_details.pipeline_trigger_name) {
            return true;
        }

        const ex_configuration_details = ex_web_hook_details.configuration;
        const curr_configuration_details = webhook_data.configuration;
        if (curr_configuration_details.url !== ex_configuration_details.url) {
            return true;
        }
        if (curr_configuration_details.header !== ex_configuration_details.header) {
            return true;
        }
        if (curr_configuration_details.payload !== ex_configuration_details.payload) {
            return true;
        }

        if (curr_configuration_details.http_method !== ex_configuration_details.http_method) {
            return true;
        }

        if (curr_configuration_details.data_format !== ex_configuration_details.data_format) {
            return true;
        }
        return false;
    }

     render() {
        const webhookSchema = yup.object({
            webhookName: yup.string().min(1,"Name must be at least 1 characters").required("webhook Name is a required field").test(
                'Webhook name test',
                'invalid Webhook name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            url: yup.string().url("Enter a valid URL").required("URL is a required field")
            //,header: yup.string().required("Header is a required field"),
        });
        return (
            <>
                    <Formik
                        validationSchema={webhookSchema}
                        onSubmit={this.postWebhookData}
                        initialValues={this.state.webhook}
                        enableReinitialize={true}
                    >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        isValid,
                    }) => (
                    <Form className="form-label-right-md" onSubmit={handleSubmit}
                        ref={form => this.form = form}  name="pipeline_trigger" method="post">
                        <Form.Group controlId="webhookName" className="row">
                            <Form.Label className="col-md-4 col-form-label">Webhook Name <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-5">
                                <Form.Control
                                      type="text"
                                      name="webhookName"
                                      value={values.webhookName}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      className="form-control"
                                      isInvalid={errors.webhookName && touched.webhookName}
                                      placeholder="Webhook Name"/>
                                <Form.Control.Feedback type="invalid">
                                       { touched.webhookName && errors.webhookName }
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group controlId="url" className="row">
                            <Form.Label className="col-md-4 col-form-label">URL <span className="text-danger">*</span> :</Form.Label>
                            <div className="col-md-5">
                                <Form.Control
                                       type="text"
                                       name="url"
                                       className="form-control"
                                       value={values.url}
                                       onChange={handleChange}
                                       onBlur={handleBlur}
                                       isInvalid={errors.url && touched.url}
                                       placeholder="Webhook URL" />
                                <Form.Control.Feedback type="invalid">
                                       { touched.url && errors.url }
                                </Form.Control.Feedback>
                            </div>
                        </Form.Group>
                        <Form.Group className="row">
                            <Form.Label className="col-md-4 col-form-label">Send data as :</Form.Label>
                            <div className="col-md-5" >
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                         type="radio"
                                         name="dataFormat"
                                         id={this.props.ex_details !== undefined ?"customRadioInline1Ex" : "customRadioInline1"}
                                         value="json"
                                         defaultChecked={values.dataFormat === "json"}
                                         onChange={handleChange}
                                         className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.props.ex_details !== undefined ?"customRadioInline1Ex" : "customRadioInline1"}>
                                        Json
                                    </Form.Label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                          type="radio"
                                          name="dataFormat"
                                          id={this.props.ex_details !== undefined ?"customRadioInline2Ex" : "customRadioInline2"}
                                          value="parameters"
                                          defaultChecked={values.dataFormat === "parameters"}
                                          onChange={handleChange}
                                          className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.props.ex_details !== undefined ?"customRadioInline2Ex" : "customRadioInline2"}>
                                        Parameters
                                    </Form.Label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                          type="radio"
                                          name="dataFormat"
                                          id={this.props.ex_details !== undefined ?"customRadioInline3Ex" : "customRadioInline3"}
                                          value="form_encode"
                                          defaultChecked={values.dataFormat === "form_encode"}
                                          onChange={handleChange}
                                          className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.props.ex_details !== undefined ?"customRadioInline3Ex" : "customRadioInline3"}>
                                        Form encode
                                    </Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group className="row">
                            <Form.Label className="col-md-4 col-form-label">Http method :</Form.Label>
                            <div className="col-md-5" >
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                         type="radio"
                                         name="methods"
                                         id={this.props.ex_details !== undefined ?"customRadioInline4Ex" : "customRadioInline4"}
                                         value="get"
                                         defaultChecked={values.methods === "get"}
                                         onChange={handleChange}
                                         className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.props.ex_details !== undefined ?"customRadioInline4Ex" : "customRadioInline4"}>GET</Form.Label>
                                </div>
                                <div className="custom-control custom-radio custom-control-inline pt-2">
                                    <Form.Control
                                          type="radio"
                                          name="methods"
                                          id={this.props.ex_details !== undefined ?"customRadioInline5Ex" : "customRadioInline5"}
                                          value="post"
                                          defaultChecked={values.methods === "post"}
                                          onChange={handleChange}
                                          className="custom-control-input"/>
                                    <Form.Label className="custom-control-label" htmlFor={this.props.ex_details !== undefined ?"customRadioInline5Ex" : "customRadioInline5"}>POST</Form.Label>
                                </div>
                            </div>
                        </Form.Group>
                        <Form.Group  controlId="parameter" className="row">
                            <Form.Label className="col-md-4 col-form-label">Add Parameter :</Form.Label>
                            <div className="col-md-5">
                                <Form.Control
                                      type="text"
                                      name="parameter"
                                      className="form-control"
                                      value={values.parameter}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                   />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="header" className="row">
                            <Form.Label className="col-md-4 col-form-label">Add header :</Form.Label>
                            <div className="col-md-5">
                                <Form.Control
                                      type="text"
                                      name="header"
                                      className="form-control"
                                      value={values.header}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      //isInvalid={errors.header && touched.header}
                                      />
                                {/*<Form.Control.Feedback type="invalid">
                                       { touched.header && errors.header }
                                   </Form.Control.Feedback> */}
                            </div>
                        </Form.Group>
                        <Form.Group className="row">
                            <div className="col-md-5 offset-sm-4">
                                <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-circle btn-primary">
                                    <FontAwesomeIcon icon={faCheck}/>Submit
                                </button>
                            </div>
                        </Form.Group>
                    </Form>
                    )}
                    </Formik>
            </>
        );

    }
}


export default WebhookForm;

