import React from 'react';
// import Filter from '../components/filter';
import ChartBody from '../../components/chartBody';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import Load from '../../components/loadAction';
import {
    CONSISTENCY_METRIC,
    ENVIRONMENT,
    DATA_SOURCE,
    DATA_SET,
    ATTRIBUTE,
    CONSISTENCY_METRIC_COLOR,
    STATIC_CARDS,
} from '../../utils/constant';
// import { FilterOptions } from '../components/filterOptions';
import _ from 'lodash';
import { connect } from "react-redux";
import { setDataSource, setDataSet, setAttribute, setChipData, setFilteredValues, setTimeFilter, setStartDate, setEndDate } from "../../redux/actions";
import moment from 'moment';
import * as d3 from 'd3';
// import CardHelpContent from '../../components/cardHelpContent';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
// import RcTooltip from 'rc-tooltip';
import ProgressStatus from '../../components/progressStatus';
import { getApi } from "../../utils/event_handling";
import EnableConsistency from '../../components/enableConsistency';
import { getDatsetIdForSelectedDatasource, getAttributeNameIdForSelectedDatasource } from '../../utils/common_utils';
import {
    normalizeAttributeName,
    METADATA_MAP_VALUE_SEPARATOR,
    normalizeChildDatasetName,
} from '../../utils/attribute_name_utils';
import { renderTableComponents } from "./dq_render_utils";
//import AdvanceFilter from "./advancedFilter"
import ProfileCardData from "./profileCardData"
import { getDatasForTab } from "../../utils/dataProfileEventHandling"
import {  profileTabPrefetch, formTableData } from "../../utils/dataProfileEventHandling"
import TreeFilter from "../data/treeFilter"


// const renderTooltip = (props) => (
//     <span {...props}>
//         Need Help ?
//     </span>
// );


class Standardisation extends React.Component {

    constructor(props) {
        super(props);
        this.setDataSource = this.setDataSource.bind(this);
        this.formCardData = this.formCardData.bind(this);
        this.formTableData = this.formTableData.bind(this);
        this.closeLastProfiledTime = this.closeLastProfiledTime.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.setFilteredValues = this.setFilteredValues.bind(this);
        this.setDefaultValuesInFilter = this.setDefaultValuesInFilter.bind(this);
        this.setChipData = this.setChipData.bind(this);
        this.removeChipData = this.removeChipData.bind(this);
        this.filterInfoData = this.filterInfoData.bind(this);
        this.filterOptions = React.createRef();
        this.setDataSetAndAttribute = this.setDataSetAndAttribute.bind(this);
        // this.renderCards = this.renderCards.bind(this);
        this.filterTimeData = this.filterTimeData.bind(this);
        this.changeTime = this.changeTime.bind(this);
        this.getTimeFilteredData = this.getTimeFilteredData.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getApi = getApi.bind(this);
        this.renderTableComponents = renderTableComponents.bind(this);
        this.skloader = this.skloader.bind(this)

        this.profileTabPrefetch = profileTabPrefetch.bind(this);


        this.loadDataSourceRender = this.loadDataSourceRender.bind(this);
        this.getDatasForTab = getDatasForTab.bind(this);

        this.allowPartition = this.allowPartition.bind(this)
        this.allowAttributes = this.allowAttributes.bind(this)
        this.clearAllFilterData = this.clearAllFilterData.bind(this)
        this.handleFilteredData = this.handleFilteredData.bind(this);

        let startDate = moment().subtract(30, 'days').set({ "hour": 0, "minute": 0, "seconds": 0 });
        let endDate = moment().endOf('day').set({ "hour": 23, "minute": 59, "seconds": 59 });

        startDate = moment(startDate).format("Y/MM/D");
        endDate = moment(endDate).format("Y/MM/D");

        this.state = {
            hideLastProfiledTime: false,
            last_processed_time:"",
            isFilterMode: true,
            dqmData: "",
            showModal: false,
            cardName: "",
            cardData: [],
            tableData: {
                "headers": ["DataSource", "DataSet", "Attribute Name", "Metric", "Metric Value"],
                "data": []
            },
            startDate: startDate,
            endDate: endDate,
            selectedDate: [],
            initial_load: 1,
            hideComponent: 'd-none',
            isConsistencyEnabled: null,
            chartTitle:"Data Consistency Dashboard",
            gridTitle:"Data Consistency metric values for selected datasets / attributes",

            environmentOptions:[],
            datasourceOptions:[],
            datasetsOptions:[],
            attributesOptions:[],
            showLoader:this.props.showLoader,
            relationShipchartData:[],
            newCardData:[],
            chartDatas:[],
            filterData: this.props.filterData,
            seletedDatasourceId: "",
            seletedDatasourceName: "",
            checkedPartition:false,
            checkedAttributes:false,
            datasetLevelChart:[],
            attributeLevelChart:[],
            existingList: this.props.existingList,
            chartErrors:{},
            clearAllFilter:false,
            profileData:{},
            filteredDatas:{},
            filteredValues: this.props.filteredValues,
        }
    }


    allowPartition(){
        this.setState({checkedPartition:!this.state.checkedPartition})
    }

    allowAttributes(){
        this.setState({checkedAttributes:!this.state.checkedAttributes})
    }
    loadDataSourceRender(params, filteredDatas=null,  dateFilter=null) {
        if(dateFilter === true){
            this.setState({showLoader:true})
            if(Object.keys(this.state.filteredValues).length > 0){
                this.setState({filteredDatas:this.state.filteredValues})
                filteredDatas = this.state.filteredValues
            }
            else{
                filteredDatas = this.state.filteredDatas
            }
            this.setState({ clearAllFilter: false,
                showLoader: true,
                seletedDatasourceId: params.integration_id,
                seletedDatasourceName: params.integration_name ,
                checkedAttributes:false,
                checkedPartition:false
            })
            this.getDatasForTab(params,filteredDatas,  dateFilter)

        }
        else{
            this.setState({ clearAllFilter: false,
                showLoader: true,
                seletedDatasourceId: params.integration_id,
                seletedDatasourceName: params.integration_name ,
                checkedAttributes:false,
                checkedPartition:false
            })
            this.getDatasForTab(params)

        }
    }

    clearAllFilterData(){
        this.setState({ filterData: this.props.filterData,clearAllFilter:true})
    }

    handleFilteredData(data) {
        this.props.handleFilteredData(data)
        this.setState({ filteredValues: data,checkedAttributes:false,checkedPartition:false,allowRelationData:false,allowRelationsFilter:"All" })
        let filterData = formTableData(this.state.profileData['totalDatas'], 6, data)
        if(filterData !== undefined){
            this.setState({newCardData:filterData['cardData'], tableData:filterData['tableData']})
        }
    }


    componentDidMount() {
        if(this.props.startDate !== null){
            let loadparams = this.props.loadparams
            loadparams['tab_type'] = 6
            this.setState({showLoader:true})
            this.getDatasForTab(loadparams,this.state.filteredValues, true)
        }
        else{
            if(this.props.noDataFound !== true && this.props.inProgress !== true){
                this.setState({showLoader:true})
                this.profileTabPrefetch(this.state.filteredValues, 6);
            }             }

        this.formCardData(this.props.dataModule.filteredValues);
        this.formTableData(this.props.dataModule.filteredValues);
        this.getSelectedTime();
        this.setDefaultValuesInFilter();
        this.getData();

    }

    getData() {
        this.getApi("checkConsistency");
    }
    getSelectedTime() {
        let timeList = [];
        let tempStartDate = moment().subtract(29, 'days').format('YYYY-MM-DD 00:00:00');
        let tempEndDate = moment().format('YYYY-MM-DD 00:00:00');
        while (moment(tempStartDate).isSameOrBefore(moment(tempEndDate))) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }
        this.setState({ selectedDate: timeList });

    }



    // componentDidUpdate(prevProps) {
    //     // if(this.state.showLoader === false && this.state.tableData.data.length === 0){
    //     //     this.formTableData(this.props.dataModule.filteredValues);
    //     // }
    //     if (prevProps.dataModule.selectedDataSource === -1) {
    //         if (prevProps.dataModule.selectedDataSource !== this.props.dataModule.selectedDataSource) {
    //             this.formCardData(this.props.dataModule.filteredValues);
    //             this.formTableData(this.props.dataModule.filteredValues);
    //             this.setDefaultValuesInFilter();
    //         }
    //     }
    //     if (prevProps.dataModule.filteredValues !== this.props.dataModule.filteredValues) {
    //         if (this.props.dataModule.filteredValues.length > 0) {
    //             this.setFilteredValues(this.props.dataModule.filteredValues);
    //             this.formCardData(this.props.dataModule.filteredValues);
    //             this.formTableData(this.props.dataModule.filteredValues);
    //         }
    //     }
    // }

    componentWillUnmount() {
        d3.selectAll(".d3-tip").remove();
    }

    setDefaultValuesInFilter() {
        let environment = -1;
        let dataSource = -1;
        if (this.props.dataModule !== undefined && this.props.dataModule.selectedDataSource !== undefined && this.props.dataModule.filteredValues !== undefined && this.props.dataModule.filteredValues.length === 0) {
            for (const [key, value] of Object.entries(this.props.dataModule.integrations)) {
                if (key === this.props.dataModule.selectedDataSource.toString()) {
                    dataSource = { "label": value, "value": key };
                }
            }
            for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
                if (value.includes(this.props.dataModule.selectedDataSource.toString())) {
                    environment = { "label": this.props.dataModule.environments[key], "value": key };
                }
            }
        }
        this.setState({ prefillEnvironment: environment, prefillDataSource: dataSource });
    }

    changeTime(selectedValue, startDate, endDate) {
        this.props.setTimeFilter(true);
        endDate = moment(endDate).format('YYYY-MM-DD 23:59:59');
        this.formCardData(this.props.dataModule.filteredValues, -1, startDate, endDate);
        this.formTableData(this.props.dataModule.filteredValues, -1, startDate, endDate);
        this.setState({ startDate: startDate, endDate: endDate });
    }

    getTimeFilteredData(allMetricInfo, startDate, endDate, metricInfo, key) {
        let timeList = allMetricInfo[metricInfo][`${key}_created_time`];
        let startIndex = -1;
        let endIndex = -1;
        timeList.forEach((time) => {
            if (moment(time).isSameOrBefore(moment(endDate)) && moment(time).isSameOrAfter(moment(startDate))) {
                let index = timeList.indexOf(time);
                if (startIndex === -1 || startIndex > index) {
                    startIndex = index;
                }
                if (endIndex === -1 || endIndex < index) {
                    endIndex = index;
                }
            }
        })
        if (startIndex !== -1 && endIndex !== -1) {
            let tempMetricValueList = allMetricInfo[metricInfo][`${key}_time_value`].slice(startIndex, endIndex + 1);
            tempMetricValueList = tempMetricValueList.filter(x => x !== "NA");
            return tempMetricValueList;
        }
        return [];
    }


    setChipData(environment, dataSource, dataSets, attributes) {
        let chipData = [];
        if (environment !== -1) {
            chipData.push({ "key": environment.value, "name": environment.label, "type": ENVIRONMENT, "value": environment.value, "label": environment.label });
        }
        if (dataSource !== -1) {
            chipData.push({ "key": dataSource.value, "name": dataSource.label, "type": DATA_SOURCE, "value": dataSource.value, "label": dataSource.label });
        }
        if (dataSets !== -1) {
            dataSets.forEach((dataSet) => {
                chipData.push({ "key": dataSet.value, "name": dataSet.label, "type": DATA_SET, "value": dataSet.value, "label": dataSet.label });
            });
        }
        if (attributes !== -1) {
            attributes.forEach((attribute) => {
                chipData.push({ "key": attribute.value, "name": attribute.label, "type": ATTRIBUTE, "value": attribute.value, "label": attribute.label });
            });
        }
        this.props.setChipData(chipData);
        this.setState({ prefillEnvironment: environment, prefillDataSource: dataSource });
    }

    removeChipData(key) {
        // Chipdata will have list of all the environment, datasource, dataset and attribute level key, value and type
        // [{ key: "2270", value: "postgres check", type: 1 }]
        let chipData = this.props.dataModule.chipData;
        let chipDataAttribute;
        let checkdataSetAttributeMapping = this.props.dataModule.dataSetAttributeMapping;

        // Initially the selected key will be romoved from the chipData
        // Then in furter will reomve the keys with depends on the removing key
        // ex : if user remove dataset here will remove the dataset key alone in futher will remove the dependent attribute key for that dataset
        chipData = chipData.filter((item) => item.key !== key && (item.type === ENVIRONMENT || item.type === DATA_SOURCE || item.type === DATA_SET || (item.type === ATTRIBUTE && item.key !== "*")));

        if (checkdataSetAttributeMapping[key] !== undefined) {
            // While removing the dataset level key we have to remove the corresponding attributes key
            // Filtering the attribute keys for the particular dataset
            chipDataAttribute = chipData.filter((item) => checkdataSetAttributeMapping[key].indexOf(item.key) > -1 && item.type === ATTRIBUTE);
            for (let i of chipDataAttribute) {
                // Here will exclude the attribute from chipData
                chipData = chipData.filter((item) => item.key !== i.key);
            }
        }
        let filteredValues = [];
        let environment = chipData.filter((item) => item.type === ENVIRONMENT);
        let dataSource = chipData.filter((item) => item.type === DATA_SOURCE);
        if (environment.length === 0 || dataSource.length === 0) {
            chipData = [{
                "key": this.props.dataModule.selectedDataSource,
                "name": this.props.dataModule.integrations[this.props.dataModule.selectedDataSource],
                "type": DATA_SOURCE
            }];
            // this.filterOptions.current.setDefaultValuesFromParent(this.state.prefillEnvironment,this.state.prefillDataSource,-1,-1);
        } else {
            let prefix = `${environment[0].key}.${dataSource[0].key}`;

            //
            let dataSets = chipData.filter((item) => item.type === DATA_SET);
            let attributes = chipData.filter((item) => item.type === ATTRIBUTE);
            // After removing keys from chipData
            // Will form lables and store list of values in filteredValues
            if (dataSets.length !== 0) {
                dataSets.forEach((dataSet) => {
                    if (dataSet.key !== '*') {
                        if (attributes.length !== 0 && attributes.key !== "*") {
                            attributes.forEach((attribute) => {
                                if (checkdataSetAttributeMapping[dataSet.key].filter((item) => item.key !== attribute.key)) {

                                    // chipDataAttribute = chipData.filter((item) => checkdataSetAttributeMapping[dataSet.key].indexOf(item.key) > -1 && item.type === ATTRIBUTE);
                                    // for(let i of chipDataAttribute){
                                    //     // Here will exclude the attribute from chipData
                                    //     chipData = chipData.filter((item)=> item.key === i.key && (item.type === ENVIRONMENT || item.type === DATA_SOURCE || item.type === DATA_SET || item.type === ATTRIBUTE));
                                    // }

                                    filteredValues.push(`${prefix}.${dataSet.key}.${attribute.key}`);
                                } else {

                                    filteredValues.push(`${prefix}.${dataSet.key}`);
                                }
                            });
                        } else if (attributes.key === '*') {
                            // To handle the All option in Attribute field inside the filter
                            // All option will be remove by excluding the * from the chipData list
                            chipData = chipData.filter((item) => item.type !== ATTRIBUTE && item.key !== "*");
                            filteredValues.push(`${prefix}.${dataSet.key}`);
                            // this.filterOptions.current.setDefaultValuesFromParent(null, null, null, -1);
                        }
                    }
                    else if (dataSets.length > 1 && dataSet.key === '*') {
                        chipData = chipData.filter((item) => item.key !== dataSet.key && item.key !== "*");
                        filteredValues.push(`${prefix}.${dataSet.key}`);
                        // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    }
                    else if (dataSets.length === 1 && dataSet.key === '*') {
                        // To handle the All option in Dataset field inside the filter
                        // All option will be remove by excluding the * from the chipData list
                        chipData = chipData.filter((item) => item.type !== DATA_SET && item.key !== "*");
                        filteredValues.push(`${prefix}`);
                        // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    }
                    // else{
                    //     // To handle the All option in Dataset field inside the filter
                    //     // All option will be remove by excluding the * from the chipData list
                    //     chipData = chipData.filter((item)=> item.type !== DATA_SET && item.key !=="*");
                    //     // filteredValues.push(prefix);
                    //     filteredValues.push(`${prefix}.${dataSet.key}`);
                    //     this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
                    // }
                });
            } else {
                // Will Removing the environment or datasource level key
                // We'll clear all datasource and attribute keys from the chipData
                chipData = chipData.filter((item) => item.type !== ATTRIBUTE && item.type !== DATA_SET);
                filteredValues.push(prefix);
                // this.filterOptions.current.setDefaultValuesFromParent(null, null, -1, null);
            }
        }
        this.props.setFilteredValues(filteredValues);
        this.props.setChipData(chipData);
        this.formCardData(filteredValues, this.props.dataModule.selectedDataSource, this.state.startDate, this.state.endDate);
        this.formTableData(filteredValues, this.props.dataModule.selectedDataSource, this.state.startDate, this.state.endDate);
    }



    setDataSource(dataSourceId) {
        let startDate = moment(this.props.dataModule.lastProfilingTime[dataSourceId]).format('YYYY-MM-DD 00:00:00');
        let endDate = moment(this.props.dataModule.lastProfilingTime[dataSourceId]).format('YYYY-MM-DD 23:59:59');
        let datasetId = getDatsetIdForSelectedDatasource(this.props.dataModule, dataSourceId);
        let attributeData = getAttributeNameIdForSelectedDatasource(this.props.dataModule, dataSourceId, datasetId);
        let attributeName = attributeData.attributeName;
        let attributeId = attributeData.attributeId;
        this.props.setDataSource(dataSourceId);
        this.props.setAttribute(attributeId, attributeName);
        this.props.setDataSet(datasetId);
        let environment = -1;
        for (const [key, value] of Object.entries(this.props.dataModule.environmentDataSourceMapping)) {
            if (value.includes(dataSourceId.toString())) {
                environment = { "label": this.props.dataModule.environments[key], "value": key };
            }
        }
        let dataSource = { "value": dataSourceId, "label": this.props.dataModule.integrations[dataSourceId] };
        this.props.setFilteredValues([]);
        this.setChipData(environment, dataSource, -1, -1);
        this.props.setStartDate(startDate);
        this.props.setEndDate(endDate);
        this.setState({ startDate: startDate, endDate: endDate })
        this.formCardData([], dataSourceId, startDate, endDate);
        this.formTableData([], dataSourceId, startDate, endDate);
    }


    closeLastProfiledTime() {
        this.setState({
            hideLastProfiledTime: true,
        });
    }

    toggleFilter() {
        let filterMode = this.state.isFilterMode ? false : true;
        this.setState({
            isFilterMode: filterMode
        })
    }

    resetFilter() {
        this.setState({ formResetFlag: 1 });
    }

    toggleMainFilterApplied() {
        let isMainFilterApplied = this.state.isMainFilterApplied ? false : true;
        this.setState({
            isMainFilterApplied: isMainFilterApplied
        })
    }

    setFilteredValues(filteredValues) {
        this.props.setFilteredValues(filteredValues);
        let dataSourceId = filteredValues[0].split(".")[1];
        this.formCardData(filteredValues, dataSourceId, this.state.startDate, this.state.endDate);
        this.formTableData(filteredValues, dataSourceId, this.state.startDate, this.state.endDate);
        this.props.setDataSource(dataSourceId);
    }

    setDataSetAndAttribute(dataSet, attribute) {
        this.props.setDataSet(dataSet);
        this.props.setAttribute("", attribute);
    }

    filterInfoData(filteredValues, infoData) {
        let updatedInfoData = {}
        filteredValues.forEach((filteredValue) => {
            const filtered = Object.keys(infoData)
                .filter(key => key.startsWith(filteredValue))
                .reduce((obj, key) => {
                    obj[key] = infoData[key];
                    return obj;
                }, {});
            updatedInfoData = { ...updatedInfoData, ...filtered }
        });
        return updatedInfoData;
    }

    formTableData(filteredValues, changedDataSource = -1, startDate = this.state.startDate, endDate = this.state.endDate) {
        if (this.props.dataModule.selectedDataSource !== -1 && this.props.dataModule.selectedDataSource !== undefined) {
            let tableInfo = [];
            let allMetricInfo;
            if (changedDataSource === -1) {
                allMetricInfo = _.cloneDeep(this.props.dataModule.info[this.props.dataModule.selectedDataSource]);
            } else {
                allMetricInfo = _.cloneDeep(this.props.dataModule.info[changedDataSource]);
            }
            let default_data = allMetricInfo;
            let restrictchilddata = [];
            // if((this.props.dataModule.filteredValues.length === 0) || (this.props.dataModule.filteredValues.length === 1 && this.props.dataModule.filteredValues[0].split(".").length === 2)){
            //     for (var key in default_data) {
            //         if (default_data.hasOwnProperty(key)) {
            //             let metaData = this.props.dataModule.metaData[key];
            //             if(!datasetNameFromMappedValue(metaData).includes(QUALDO_CHILD_DATASET_SEPARATOR)){
            //                restrictchilddata[key] =  default_data[key]
            //             }
            //         }
            //     }
            // }else {
            //     restrictchilddata = default_data;
            // }
            restrictchilddata = default_data;
            if (filteredValues.length !== 0) {
                restrictchilddata = this.filterInfoData(filteredValues, restrictchilddata);
            }
            for (const [key, value] of Object.entries(restrictchilddata)) {
                let metaData = this.props.dataModule.metaData[key];
                if (metaData !== undefined) {
                    let reqSplitArrVals = metaData.split(METADATA_MAP_VALUE_SEPARATOR);
                    let dataSource = reqSplitArrVals[1];
                    let dataSet = reqSplitArrVals[2];
                    let attribute = reqSplitArrVals[reqSplitArrVals.length - 1];
                    for (const [metricId, metricValue] of Object.entries(value)) {
                        if (!metricId.endsWith("drift") && metricId in CONSISTENCY_METRIC) {
                            let tableData = [];
                            tableData.push({ "value": dataSource, "type": "td" });
                            let reqDatasetName = normalizeChildDatasetName(dataSet)
                            tableData.push({ "value": reqDatasetName, "type": "td" });
                            let normalizedAttributeName = normalizeAttributeName(attribute);
                            tableData.push({ "value": normalizedAttributeName, "type": "td" });
                            tableData.push({ "value": CONSISTENCY_METRIC[metricId], "type": "td" });
                            let tempMetricValue = metricValue;
                            if (this.props.dataModule.timeFilterApplied) {
                                let timeFilteredData = this.getTimeFilteredData(allMetricInfo, startDate, endDate, key, metricId);
                                if (timeFilteredData.length > 0) {
                                    tempMetricValue = `${Math.max.apply(null, timeFilteredData)}`;
                                } else {
                                    continue;
                                }
                            }
                            tableData.push({ "value": `${tempMetricValue} %`, "type": "td" });
                            tableInfo.push(tableData);
                        }
                    }
                }

            }
            let tableHeaders = this.state.tableData.headers;
            let tableData = { "headers": tableHeaders, data: tableInfo };
            this.setState({ tableData: tableData });
        }
        // let tableData = this.state.tableData

        // this.setState({ tableData: tableData });
    }

    filterTimeData(key) {
        let tempStartDate = this.state.startDate;
        let timeList = [];
        for (let i = 0; i < 8; i++) {
            timeList.push(tempStartDate);
            tempStartDate = moment(tempStartDate).add(1, 'days').format('YYYY-MM-DD 00:00:00');
        }

        return { "time": timeList, "values": STATIC_CARDS[key] }
    }

    formCardData(filteredValues, changedDataSource = -1, startDate = this.state.startDate, endDate = this.state.endDate) {

        let cardInfo = [];
        let allMetricInfo;
        if (changedDataSource === -1) {
            allMetricInfo = _.cloneDeep(this.props.dataModule.info[this.props.dataModule.selectedDataSource]);
        } else {
            allMetricInfo = _.cloneDeep(this.props.dataModule.info[changedDataSource]);
        }

        if (filteredValues.length !== 0) {
            allMetricInfo = this.filterInfoData(filteredValues, allMetricInfo);
        }
        let commonId = 0;
        for (const [key] of Object.entries(CONSISTENCY_METRIC)) {
            let attributeMetricList = [];
            for (const metricInfo in allMetricInfo) {
                let metaData = this.props.dataModule.metaData[metricInfo];
                if (metaData !== undefined) {
                    if (key in allMetricInfo[metricInfo]) {
                        if (this.props.dataModule.timeFilterApplied) {
                            let timeFilteredData = this.getTimeFilteredData(allMetricInfo, startDate, endDate, metricInfo, key);
                            if (timeFilteredData.length > 0) {
                                attributeMetricList.push(Math.max.apply(null, timeFilteredData));
                            }
                        } else {
                            attributeMetricList.push(allMetricInfo[metricInfo][key]);
                        }
                    }
                }
            }
            let tempInfo = {};
            tempInfo["metric_name"] = CONSISTENCY_METRIC[key];
            if (attributeMetricList.length !== 0) {
                tempInfo["metric_value"] = `${(attributeMetricList.reduce((a, b) => a + b) / attributeMetricList.length).toFixed(2)}`;
            } else {
                tempInfo["metric_value"] = "NA";
            }
            tempInfo["hide_edit"] = true;
            tempInfo["cardType"] = "chart";
            tempInfo["background"] = CONSISTENCY_METRIC_COLOR[key].cardBackground;
            tempInfo["textColor"] = CONSISTENCY_METRIC_COLOR[key].textColor;
            tempInfo["idValue"] = `conformity${commonId}`;
            tempInfo["chartData"] = this.filterTimeData(commonId);
            commonId = commonId + 1;
            cardInfo.push(tempInfo);

        }
        this.setState({
            cardData: cardInfo
        });
    }

    handleClick(data) {
        this.setState({ showModal: true, cardName: data.metric_name });
    }

    hideModal = () => {
        this.setState({ showModal: false });
    }

    // renderCards(cardData) {
    //     return (
    //         <div className="form-row">
    //             {
    //                 this.state.showModal ?
    //                     <CardHelpContent
    //                         show={this.state.showModal}
    //                         onHide={this.hideModal}
    //                         name={this.state.cardName}
    //                         page={"data"}
    //                     /> : ""
    //             }
    //             {
    //                 cardData.map((data) => {
    //                     let cardBackground = `dashboard-stat ${data.background}`;
    //                     let textColor = `title ${data.textColor}`;
    //                     return (
    //                         <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
    //                             key={data.idValue}
    //                         >
    //                             <div className={cardBackground}>
    //                                 <div className="details">
    //                                     <div className="desc-header">
    //                                         <div className={textColor}>
    //                                             {data.metric_name}
    //                                         </div>
    //                                         <span>
    //                                             <RcTooltip placement="top" overlay={renderTooltip} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}>
    //                                                 <div className="help-icon" onClick={this.handleClick.bind(this, data)} data-toggle="modal" data-target="#model__help"><i><FontAwesomeIcon icon={faQuestionCircle} /></i></div>
    //                                             </RcTooltip>
    //                                         </span>
    //                                     </div>
    //                                     <div className="desc">
    //                                         <span className="desc-value">{data.metric_value}</span>
    //                                         {data.metric_value !== "NA" ? <span className="desc-percentage">%</span> : ""}
    //                                     </div>
    //                                     <ChartBody
    //                                         classValue="chart"
    //                                         id={data.idValue}
    //                                         chartType="cardArea"
    //                                         style={{ height: "250px" }}
    //                                         chart_data={data.chartData}
    //                                         colorType={data.textColor}
    //                                     />
    //                                 </div>
    //                             </div>
    //                         </div>
    //                     );
    //                 })
    //             }
    //         </div>
    //     );
    // }

    skloader(key) {
        let count = this.state.initial_load + key
        this.setState({ initial_load: count })
    }

    render() {
        const lastProfilingTimeElement = this.state.last_processed_time
        let lastProfilingTime = lastProfilingTimeElement ?
            `Your data last profiled at ${lastProfilingTimeElement}` : "--";


        /**  TODO */
        // Below entire chart logic needs to be moved into a function : handleChartData()

        let chartData = []

        let initialFilteredValues =  this.state.filteredValues
        let initialFilteredDatasets = []
        let initialFilteredAttributes = []

        if (Object.keys(initialFilteredValues).length > 0 && initialFilteredValues['fiteredDatseset'].length > 0) {
            initialFilteredDatasets = initialFilteredValues['fiteredDatseset']
            initialFilteredAttributes = initialFilteredValues['fitlerdAttr']
        }



        let datasetLevelError = []
        let attributeLevelError = []

        if (Object.keys(this.state.chartErrors).length > 0) {
            datasetLevelError = this.state.chartErrors['dataset_level']
            attributeLevelError = this.state.chartErrors['attr_level']
        }


        /**Separate Filter Existing Dataset Level Chart Data */
        let datasetLevelChart = []

        this.state.datasetLevelChart.map((data) => {
            data['name'] = normalizeChildDatasetName(data['name'])
            if (this.state.existingList.includes(Number(data.dataSetId))) {
            let error_metrics = []
            if(initialFilteredDatasets.length > 0){
                if (datasetLevelError[data.dataSetId] !== undefined && initialFilteredDatasets.includes(Number(data.dataSetId))) {
                    datasetLevelError[data.dataSetId].map((data) => {
                        if (data.error === true) {
                            error_metrics = error_metrics.concat(data)
                        }
                        return null
                    })
                    data['metrics'] = error_metrics
                    datasetLevelChart = datasetLevelChart.concat(data)
                }
                else if(initialFilteredDatasets.includes(Number(data.dataSetId))){
                    datasetLevelChart = datasetLevelChart.concat(data)
                }
                // }
            }
            else{
                if (datasetLevelError[data.dataSetId] !== undefined) {
                    datasetLevelError[data.dataSetId].map((data) => {
                        if (data.error === true) {
                            error_metrics = error_metrics.concat(data)
                        }
                        return null
                    })
                    data['metrics'] = error_metrics

                }

                datasetLevelChart = datasetLevelChart.concat(data)
            }

            }

            return null;
        })


        /**Separate Existing Attribute Level Chart Data */
        let attributeLevelChart = []

        this.state.attributeLevelChart.map((data) => {
            if (this.state.existingList.includes(Number(data.attribute_id))) {
            let error_metrics = []
            if(initialFilteredDatasets.length > 0){
                if (attributeLevelError[data.attribute_id] !== undefined && initialFilteredDatasets.includes(Number(data.dataSetId))) {
                    attributeLevelError[data.attribute_id].map((data) => {
                        if (data.error === true) {
                            error_metrics = error_metrics.concat(data)
                        }
                        return null
                    })
                    data['metrics'] = error_metrics
                    attributeLevelChart = attributeLevelChart.concat(data)

                }
                else if(initialFilteredAttributes.includes(Number(data.attribute_id))){
                    attributeLevelChart = attributeLevelChart.concat(data)

                }
                else if(initialFilteredAttributes.length === 0 && initialFilteredDatasets.includes(Number(data.dataSetId))){
                    attributeLevelChart = attributeLevelChart.concat(data)
                }
            }
            else{
                if (attributeLevelError[data.attribute_id] !== undefined) {
                    attributeLevelError[data.attribute_id].map((data) => {
                        if (data.error === true) {
                            error_metrics = error_metrics.concat(data)
                        }
                        return null
                    })
                    data['metrics'] = error_metrics
                }
                    attributeLevelChart = attributeLevelChart.concat(data)
            }
            }

            return null;
        })


        /**Separate Partition and child datasets */
        let parentDatasetIds = []
        let childDatasetIds = []

        datasetLevelChart.map((data) => {
            if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
                childDatasetIds = childDatasetIds.concat(Number(data.dataSetId))
            }
            else {
                parentDatasetIds = parentDatasetIds.concat(Number(data.dataSetId))
            }
            return null
        })



        /**Separate Partition Dataset Attributes and Child Dataset Attributes  */
        let parentAttributeIds = []
        let childAttributeIds = []

        attributeLevelChart.map((data) => {
            if (parentDatasetIds.includes(Number(data.dataSetId))) {
                parentAttributeIds = parentAttributeIds.concat(data.dataSetId)
            }
            else {
                childAttributeIds = childAttributeIds.concat(data.attribute_id)
            }
            return null
        })



        /**Handle Dataset level chart Data based on the chart check box filter */
        let attributeList = []

        datasetLevelChart.map((data) => {
            if (this.state.checkedPartition) {
                chartData = chartData.concat(data)
                attributeList = childAttributeIds.concat(parentAttributeIds)
            }
            else if (this.state.filterData['childDatasets'].includes(Number(data.dataSetId))) {
                /*** Skip Pushing Child Dataset initially */
            }
            else {
                attributeList = parentAttributeIds
                chartData = chartData.concat(data)
            }
            return null
        })

        /**Handle Attribute level chart Data based on the chart check box filter */
        if (this.state.checkedAttributes) {
            chartData = []
            attributeLevelChart.map((data) => {
                if(this.state.checkedPartition === false){
                    if(parentDatasetIds.includes(Number(data.dataSetId))){
                        chartData = chartData.concat(data)
                    }
                }
                else if(childDatasetIds.includes(Number(data.dataSetId))){
                        chartData = chartData.concat(data)
                }
                else{
                    chartData = chartData.concat(data)
                }
                return chartData
            })
        }
        // if(this.state.checkedAttributes === true){
        //     chartData=[]
        //     attributeLevelChart.map((data) => {
        //     chartData = chartData.concat(data)
        //     return null
        //     })
        // }

        /**  TODO */
        // Above entire chart logic needs to be moved into a function : handleChartData()


        return (
            (
                this.state.isConsistencyEnabled !== null ?
                    (
                        this.state.isConsistencyEnabled === false ?
                                <EnableConsistency
                                    message={"Please enable Consistency to view the chart."} />
                            :
                            <>
                                {
                                this.state.showLoader === false && this.props.inProgress === false
                                ?
                                    (
                                        <>
                                            <div className="qd-tab__content-action">
                                                {
                                                    this.state.hideLastProfiledTime === false ?
                                                        <div className="caption">
                                                            <div className="alert moniker-alert" role="alert">
                                                                <p className="mb-0">
                                                                    <strong> {lastProfilingTime}</strong>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                                <div className="actions flex-shrink-0">
                                                    <button onClick={this.toggleFilter} className="btn btn-light">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#484848" height="24" className="mr-1"
                                                            viewBox="0 0 24 24" width="24">
                                                            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                            <path d="M0 0h24v24H0z" fill="none" />
                                                        </svg>
                                                        <span className="dot-symbol"></span>
                                                        <span>Filters</span>
                                                    </button>
                                                </div>
                                            </div>
                                            {this.state.isFilterMode === true &&
                                                <TreeFilter
                                                    tab={"consistency"}
                                                    startDate={this.props.startDate !== null ? this.props.startDate : this.state.startDate}
                                                    endDate={this.props.endDate !== null ? this.props.endDate : this.state.endDate}
                                                    seletedDatasourceId={this.state.seletedDatasourceId}
                                                    seletedDatasourceName={this.state.seletedDatasourceName}
                                                    data={this.state.filterData}
                                                    clearAllFilterData={this.clearAllFilterData}
                                                    clearAllFilter={this.state.clearAllFilter}
                                                    loadDataSourceRender={this.loadDataSourceRender}
                                                    handleFilteredData={this.handleFilteredData}
                                                    handleDateFiltered={this.props.handleDateFiltered}

                                                />
                                            }
                                                <ProfileCardData
                                                newCardData={this.state.newCardData}
                                                cardData={this.state.cardData}
                                                cardName={this.state.cardName}
                                                showModal={this.state.showModal}
                                                hideModal={this.hideModal}
                                                skloader={this.skloader}

                                            />
                                            <BasicPortlet
                                                video_url="profile_data"
                                                className="pb-0"
                                                title={this.state.chartTitle}
                                                bodyClassName="pb-0"
                                                showFilter={false}
                                                changeSource={this.setDataSource}
                                                showtimeFilter={false}
                                                disableTimeFilter={true}
                                                id="standardisation"
                                                changeTime={this.changeTime}
                                                content={
                                                        <ChartBody
                                                            id="chartStandardisation"
                                                            chartType="hierarchicalEdgeBundlingV6"
                                                            title={this.state.chartTitle}
                                                            changeTab={this.props.changeTab}
                                                            setDataSetAndAttribute={this.setDataSetAndAttribute}
                                                            childDatasets={this.state.filterData['childDatasets']}
                                                            page="profile"
                                                            video_url="profile_data"
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            showParititionFilter={true}
                                                            showAttributeFilter={true}
                                                            showZoom={true}
                                                            attributes={[]}

                                                            // ToDo Rename variable
                                                            checkedAttributes={this.state.checkedAttributes}
                                                            checkedPartition={this.state.checkedPartition}
                                                            chart_data={chartData}
                                                            datasetLevelChart={childDatasetIds}
                                                            attributeLevelChart={attributeList}
                                                            allowPartition={this.allowPartition}
                                                            allowAttributes={this.allowAttributes}
                                                        />
                                                }
                                            />
                                            <BasicPortlet
                                                video_url="profile_data"
                                                className="mb-0 pb-0" id="standardisationTableData" title={this.state.gridTitle} bodyClassName="pb-0"
                                                content={
                                                    <QualdoDataTable
                                                        customGetActionComponent={this.renderTableComponents}
                                                        data={this.state.tableData}
                                                        component_name="profile_data"
                                                    />
                                                }
                                            />
                                        </>
                                    )
                                :
                                    (
                                        this.state.showLoader === false && this.props.inProgress === true
                                        ?
                                                <BasicPortlet
                                                    video_url="profile_data"
                                                    className="pb-0"
                                                    title={this.state.chartTitle}
                                                    bodyClassName="pb-0"
                                                    id="standardisation"
                                                    content={
                                                        <>
                                                            <ProgressStatus
                                                                progressStatus={this.props.progressStatus}
                                                            />
                                                        </>
                                                    }
                                            />
                                        :
                                            <BasicPortlet
                                            video_url="profile_data"
                                            className="pb-0" bodyClassName="pb-0"
                                            content={(this.state.filterData.datasetsOptions.length > 0)?
                                            <Load />: <EnableConsistency
                                            message={"Please enable Consistency to view the chart."} />}
                                        />
                                    )
                                }
                            </>
                    )
                    :
                    <BasicPortlet
                        video_url="profile_data"
                        className="pb-0" bodyClassName="pb-0"
                        content={<Load />}         
                    />
            )
        );
    }
}

const mapStateToProps = state => {
    return state;
}

export default connect(mapStateToProps, { setDataSource, setDataSet, setAttribute, setChipData, setFilteredValues, setTimeFilter, setStartDate, setEndDate })(Standardisation);