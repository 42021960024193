import React from 'react';
import Select from 'react-select';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { postDatabricks, validateDatabricksUrl }  from "../../utils/event_handling";
import{userValidation } from '../../utils/common_utils'



class DatabricksForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateDatabricksUrl = validateDatabricksUrl.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.postDatabricks = postDatabricks.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeAccessTokenIcon = this.ChangeAccessTokenIcon.bind(this);
        this.state = {
            check: false,
            options: [],
            job: {},
            accessTokenIcon:faEye,
            accessTokenType:'password',
            hideSubmitButton: false,
            dataBricks:{
                databricksName: '',
                jobId: '',
                params: '',
                token: '',
                url: '',
                type: ''
            },
            errors: {
            }
        }
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    handleCancel() {
        this.setState({check:false});
    }

    componentDidMount() {
        const exDatabricksDetails = this.props.ex_details;
        if (exDatabricksDetails === undefined) {
            return;
        }
        let dataBricks = this.state.dataBricks;
        dataBricks.databricksName = exDatabricksDetails.pipeline_trigger_name;
        const exConfigurationDetails = exDatabricksDetails.configuration;
        dataBricks.jobId = exConfigurationDetails.job_id;
        dataBricks.url = exConfigurationDetails.url;
        dataBricks.token = exConfigurationDetails.token;
        dataBricks.params = exConfigurationDetails.params;
        this.setState({dataBricks_edit:dataBricks});

    }


    requires_pipeline_update(databricksData){
        const exDatabricksDetails = this.props.ex_details;
        if (databricksData.pipeline_trigger_name !== exDatabricksDetails.pipeline_trigger_name) {
            return true;
        }
        const exConfiguration = exDatabricksDetails.configuration;
        const currentConfiguration = databricksData.configuration;
        if (currentConfiguration.url !== exConfiguration.url) {
            return true;
        }
        if (currentConfiguration.token !== exConfiguration.token) {
            return true;
        }
        if (currentConfiguration.job_id !== exConfiguration.job_id) {
            return true;
        }
        if (currentConfiguration.params !== exConfiguration.params) {
            return true;
        }
        return false;
    }

    ChangeAccessTokenIcon() {
        let state = this.state.accessTokenIcon;
        if (state.iconName === "eye") {
            this.setState({accessTokenIcon: faEyeSlash});
            this.setState({accessTokenType: "text"})
          } else {
            this.setState({accessTokenIcon: faEye});
            this.setState({accessTokenType: "password"})
          }
    }

    render() {
        const databrickSchema = yup.object({
            jobId: yup.number().required("Job Name is a required field")
        });
        const databricksValidate = yup.object({
            databricksName:  yup.string().min(1,"Name must be at least 1 characters").required("Databricks Name is a required field").test(
                'Databricks name test',
                'invalid Databricks name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            url: yup.string().url("Enter a valid URL").required("URL is a required field"),
            token: yup.string().required("DataBricks Token is a required field"),
        });

        if (this.state.check === true) {
            return (
                    <>
                            <Formik
                                validationSchema={databrickSchema}
                                onSubmit={this.postDatabricks}
                                initialValues={this.state.dataBricks_edit}
                                enableReinitialize={true}
                            >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                isValid,
                            }) => (
                                <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                      <Form.Group controlId="jobId" className="row">
                                            <Form.Label className="col-md-4 col-form-label">Select Job<span className="text-danger">*</span> :</Form.Label>
                                            <div className="col-md-5">
                                                <Select
                                                    name="jobId"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    id="DatabricksJob"
                                                    options={this.state.options}
                                                    defaultValue={this.state.job}
                                                    isInvalid={errors.jobId && touched.jobId}
                                                    onChange={selectedOption => {
                                                        handleChange("jobId")(selectedOption.value.toString());
                                                        handleChange("type")(selectedOption.type.toString());
                                                    }}
                                                    classNamePrefix='select-control'
                                                    placeholder="Select Job"
                                                />
                                                <ErrorMessage component="div" className="error-text" name="jobId" />
                                            </div>
                                      </Form.Group>
                                      <Form.Group controlId="params"  className="row">
                                            <Form.Label className="col-md-4 col-form-label">Trigger Params :</Form.Label>
                                            <div className="col-md-5">
                                                <Form.Control
                                                      type="text"
                                                      name="params"
                                                      value={values.params}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      className="form-control"
                                                      placeholder="Trigger Params"/>
                                            </div>
                                      </Form.Group>
                                      <Form.Group className="row">
                                            <div className="col-md-5 offset-sm-4">
                                              <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                                </button>
                                            </div>
                                      </Form.Group>
                                </Form>
                                )}
                            </Formik>
                    </>
            );
        } else {
                return (
                             <>
                                    <Formik
                                        validationSchema={databricksValidate}
                                        onSubmit={this.validateDatabricksUrl}
                                        initialValues={this.state.dataBricks}
                                    >
                                    {({
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                          <Form className="form-label-right-md" onSubmit={handleSubmit}  name="pipeline_trigger" method="post">
                                            <Form.Group controlId="databricksName" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Name <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                        type="text"
                                                        name="databricksName"
                                                        value={values.databricksName}
                                                        className="form-control"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        isInvalid={errors.databricksName && touched.databricksName}
                                                        placeholder="Databricks Name"/>
                                                    <Form.Control.Feedback type="invalid">
                                                            { touched.databricksName && errors.databricksName }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>

                                            <Form.Group controlId="url" className="row">
                                                <Form.Label className="col-md-4 col-form-label">Databricks URL <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                    <Form.Control
                                                          type="text"
                                                          onChange={handleChange}
                                                          name="url"
                                                          value={values.url}
                                                          className="form-control"
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.url && touched.url}
                                                          placeholder="Databricks URL" />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.url && errors.url }
                                                    </Form.Control.Feedback>
                                                </div>
                                            </Form.Group>
                                            <Form.Group controlId="token"  className="row">
                                                <Form.Label className="col-md-4 col-form-label">Token <span className="text-danger">*</span> :</Form.Label>
                                                <div className="col-md-5">
                                                <InputGroup bsPrefix="icon-input-group">
                                                    <i>
                                                        <FontAwesomeIcon icon={faLock}/>
                                                    </i>
                                                    <Form.Control
                                                          type={this.state.accessTokenType}
                                                          name="token"
                                                          onChange={handleChange}
                                                          value={values.token}
                                                          onBlur={handleBlur}
                                                          isInvalid={errors.token && touched.token}
                                                          className="form-control"
                                                          placeholder="Databricks Token"
                                                          autoComplete={this.props.autocomplete}
                                                          />
                                                    <Form.Control.Feedback type="invalid">
                                                           { touched.token && errors.token }
                                                    </Form.Control.Feedback>
                                                    <i className="security-eye">
                                                        <FontAwesomeIcon onClick={this.ChangeAccessTokenIcon} icon={this.state.accessTokenIcon} className="security-eye"/>
                                                    </i>
                                                </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group className="row">
                                                <div className="col-md-5 offset-sm-4">
                                                    <button type="button" onClick={() => this.props.ex_details ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                                    <button type="submit" disabled={this.state.hideSubmitButton} onClick={this.handleClick} className="btn btn-primary btn-circle">
                                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                                    </button>
                                                </div>
                                            </Form.Group>
                                         </Form>
                                    )}
                                    </Formik>
                             </>
                );
        }
    }

}


export default DatabricksForm;

