import React from 'react';
import BasicPortlet from '../components/basicPortlet';
import QualdoDataTable from '../components/bootstrapTable';
import CardGroup from "../components/cardGroup";
import {handleMetricFollow} from "../utils/event_handling";
import {getMetricsTableData, formMLMetricsCardData, decryptForLocalUsage} from "../utils/common_utils";
import Image from "../components/image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloudUploadAlt,faArrowRight} from "@fortawesome/free-solid-svg-icons";
import ModelQualityMetricsForm from "./components/ModelQualityMetricsForm";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Load from '../components/loadAction';
import Modal from "react-bootstrap/Modal";
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import {faCube, faCubes} from "@fortawesome/free-solid-svg-icons";
import RefreshesTable from "../components/refreshesTable";
import { PERMISSIONS_LOCAL_STORE_KEY } from '../utils/constant';

class ModelMetrics extends React.Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            check: false,
            activeTabKey: "ml_metrics_default",
            plan_details: localStorage.getItem("plan_details"),
            title: "Model Metrics",
            text: "Here, you can either edit qualdo-generated default metrics or create a user-defined model metric."
        }
    }

    handleClick() {
        if (this.state.check === true) {
            this.setState({check:false});
        }else {
            this.setState({check:true});
        }
    }

    renderCard(){
        let showToast = this.props.showToast;
        const mlmData = this.props.mlmData;
        let additionalDetails = {
            "data": mlmData,
            "datasetInfo": this.props.datasetInfo
        }

        if (mlmData !== "") {
            return mlmData.map(function (row, index) {
                let defaultMetricCardData = [];
                let cardData = formMLMetricsCardData(row.metrics);
                cardData.forEach(element => {
                    if(element.metric_type === "default"){
                        defaultMetricCardData.push(element);
                    }
                });
                return(<BasicPortlet className="pb-0"
                                 title={"Qualdo Generated - Model Metrics"}
                                 bodyClassName="pb-0"
                                 id="modelMetricsCards"
                                 key={`portlet_${row.mas_name}`}
                                 content={
                                     <CardGroup
                                         rowNo= {index}
                                         key={row.mas_name}
                                         data={defaultMetricCardData}
                                         showFollow={true}
                                         handleCardFollow={handleMetricFollow}
                                         showToast={showToast}
                                         additionalDetails={additionalDetails}
                                         component_name="metrics"/>
                                 }
                />);
            });
        }else {
            return(<BasicPortlet className="pb-0"
                                 bodyClassName="pb-0"
                                 id="modelMetricsCardsLoading"
                                 content={
                                     <Load/>
                                 }
            />);
        }
    }

    renderCustomProcess(tab) {
        if(tab === "ml_metrics_custom"){
            let tableData = this.props.customMetricMlmData;
            let emptyTable = false;
            let isData = false;
            if (tableData !== undefined && tableData !== null) {
                if (tableData.data !== undefined && tableData.data !== null) {
                    emptyTable = (tableData.data.length === 0 && this.props.dqmData !== "");
                    if (tableData.data.length > 0) {
                        isData = true;
                    }
                }
            }
            if (emptyTable || isData) {
                return(<BasicPortlet
                     className="mt-3" id="datasourceRefreshes"
                     title="PROCESSING ERRORS & DATA REFRESH STATUS"
                     content={
                         this.state.datasource_updates !== null ?
                         <RefreshesTable componentName="metrics"
                                         data={tableData}
                                         page={"custom_metrics"}/>
                                         :
                         <Load/>

                     }
                />);
            }else {
                return(<BasicPortlet className="pb-0"
                                 id="dqMetricsTableLoading"
                                 title={"PROCESSING ERRORS & DATA REFRESH STATUS"}
                                 content={<Load/>}
                />);
            }
        }
    }

    renderTable(){
        let tableData = getMetricsTableData(this.props.mlmData);
        let dataSet = this.props.datasetInfo.data_set;
        const allDataAvailable = this.props.mlmData !== "" &&
            dataSet !== undefined &&
            this.props.datasetInfo.attributes !== undefined && dataSet.length > 0 &&
            this.props.modelOptions !== null;

        const isEmptyTable = tableData.data.length === 0 && this.props.mlmData !== "";

        if (isEmptyTable || allDataAvailable) {
            return <BasicPortlet className="pb-0"
                             id="modelMetricsTable"
                             title={"CUSTOM - USER DEFINED MODEL METRICS"}
                             content={<QualdoDataTable 
                                        id="modelMetricsTable"    
                                        key={this.state.title}
                                        data={tableData}
                                        paginationSize={3}
                                        modelOptions={this.props.modelOptions}
                                        modalTitle={"Custom - Model Metric"}
                                        showToast={this.props.showToast}
                                        additional_details={this.props.mlmData}
                                        datasetInfo={this.props.datasetInfo}
                                        component_name="metrics"/>}
        />
        }else {
            return <BasicPortlet className="pb-0"
                             id="modelMetricsTableLoading"
                             title={"CUSTOM - USER DEFINED MODEL METRICS"}
                             content={<Load/>}
        />
        }
    }


   goToChoosePlan = () => {
      window.location.href = "/choose-plan";
   }




    render() {
        let permissions = decryptForLocalUsage(localStorage.getItem(PERMISSIONS_LOCAL_STORE_KEY))
        let permissionsList = [];
        if (permissions !== undefined && permissions !== null) {
            permissionsList = permissions;
        }

        let hide_choose_plan_button = true;
        if (permissionsList.includes('BillingAccess')) {
            hide_choose_plan_button = false;
        } else {
            hide_choose_plan_button = true;
        }

        let show_custom_metrics = false;
        if (this.state.plan_details !== null && this.state.plan_details !== undefined) {
            let details_json = JSON.parse(this.state.plan_details);
            if (details_json !== null) {
                let details = details_json["details"]["supported_features"];
                if (details !== null) {
                    for (const [key, val] of Object.entries(details)) {
                        if (key !== "custom_metrics") {
                            continue;
                        } else {
                            let status = val["support"];
                            show_custom_metrics = status;
                        }
                    }
                }
            }

        }
        let show_custom_metrics_modal = (this.state.check === true  && show_custom_metrics === false);
        return (
            <>


<Tab.Container defaultActiveKey={this.state.activeTabKey} onSelect={this.changeTab}>
        <div className="tabbable-line mb-3">
            <Nav variant="tabs" id="page-nav-tabs">
                <Nav.Item>
                    <Nav.Link eventKey="ml_metrics_default">
                                <span className="qd-menu__link-text">
                                <i>
                                    <FontAwesomeIcon icon={faCube}/>
                                </i>  Default Metrics</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="ml_metrics_custom">
                                <span className="qd-menu__link-text"><i>
                                    <FontAwesomeIcon icon={faCubes}/>
                                </i>   Custom Metrics</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
                    <div className="tab-content">
                        <Tab.Content>
                            <Tab.Pane eventKey="ml_metrics_default">
                                {this.renderCard()}
                            </Tab.Pane>
                            <Tab.Pane eventKey="ml_metrics_custom">

                <Modal show={show_custom_metrics_modal} className="modal__upgrade text-center" onHide={this.handleClick}  centered >
                    <Modal.Header closeButton className="border-0 justify-content-center d-block">
                        <h5 className="modal-title" id="updagrade_plan">
                            You are currently on a Basic Plan (Free)</h5>
                            <p className="text-muted">Upgrade now to use this feature</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                    <Modal.Body>
                        <Image src="upgradePlanIcon"/>
                    </Modal.Body>
                    <Modal.Footer className=" border-0 justify-content-center" centered>
                        <button type="button" onClick={this.goToChoosePlan}
                            className="btn btn-primary btn-circle" disabled={hide_choose_plan_button}>Choose another plan <FontAwesomeIcon className="mr-0 ml-2" icon={faArrowRight}/></button>
                    </Modal.Footer>
                </Modal>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-7 d-flex align-items-stretch">
                                <i className="d-inline-flex p-2 mr-3">
                                    <Image src="dataQuality"/>
                                </i>
                                <div className="d-flex flex-row align-items-center">
                                    <div>
                                        <h4 className="h5 mb-2">{this.state.title}</h4>
                                        <p className="mb-0">{this.state.text}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 mt-4 mt-md-0 text-md-right">
                                <button type="button" onClick={() => this.handleClick()}
                                        className="btn btn-outline-primary btn-circle mr-2">
                                    <FontAwesomeIcon icon={faCloudUploadAlt} />
                                    {"Add Custom Model Metrics"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                { this.state.check === false || show_custom_metrics === false ? "" :
                            <div className="add-dataquality-content">
                                <BasicPortlet title={"Add a Custom Model Metric"} bodyClassName="pb-0"
                                    id="addModelMetrics"
                                    content={<ModelQualityMetricsForm showToast={this.props.showToast}
                                                         details={this.props.datasetInfo}
                                                         type={"ml"}
                                                         modelOptions={this.props.modelOptions}
                                                         modelDetails={this.props.modelDetails}
                                                         mas_data={this.props.mlmData}
                                                         handleClick={this.handleClick}
                                    />}
                                />
                            </div>
                         
                }
                                 {this.renderTable()}
                                 {this.renderCustomProcess("ml_metrics_custom")}
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
    </Tab.Container>

               

            </>
        );
    }
}


export default ModelMetrics;