import React from 'react';
import Toast from 'react-bootstrap/Toast';
import NavigationHeader from '../components/navigationHeader';
import BreadCrumb from '../components/breadCrumb';
import Tab from 'react-bootstrap/Tab';
import BasicPortlet from '../components/basicPortlet';
import { getApi, postUIlogs } from "../utils/event_handling";
import Nav from 'react-bootstrap/Nav';
import {CopyRightsFooter} from '../components/copyrights';
import DSEnvironment from './dataSources/dsEnvironment';
import AddDataSources from './dataSources/addDataSources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit,  faTrash, faTrashAlt, faCheck} from '@fortawesome/free-solid-svg-icons';
import {toastObj} from "../utils/common_utils";
import MetadataGlossary from '../configure/metadata/metadataGlossary';
import ErrorHandler from '../components/error_500';
import { connect } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import {
    addMLModelMapping,
    setMLModel
} from "../redux/actions";

class Comply extends React.Component {
    constructor(props) {
        super(props);
        let tab = "piiglossary"
        if(this.props.route.navKey !== undefined && this.props.route.navKey !== null) {
          tab=this.props.route.navKey
        }
        this.getApi = getApi.bind(this);
        this.setAddModel = this.setAddModel.bind(this);
        this.setAddDS = this.setAddDS.bind(this);
        this.showToast = this.showToast.bind(this);
        this.closeToast = this.closeToast.bind(this);
        this.handleTab = this.handleTab.bind(this);
        this.setDataLimit = this.setDataLimit.bind(this);
        this.updateTable = this.updateTable.bind(this);
        this.postUIlogs = postUIlogs.bind(this);
        this.updateEnvironmentList = this.updateEnvironmentList.bind(this);
        this.openViewDetails = this.openViewDetails.bind(this);
        this.cancelViewDetails = this.cancelViewDetails.bind(this);
        this.openEditDetails = this.openEditDetails.bind(this);
        this.cancelEditDetails = this.cancelEditDetails.bind(this);
        this.openDeleteDetails = this.openDeleteDetails.bind(this);
        this.cancelDeleteDetails = this.cancelDeleteDetails.bind(this);
        this.showFilter = this.showFilter.bind(this);
        
        this.state={
            errorOccurred: false,
            toastStatus: false,
            current_tab:tab,
            data:null,
            integration_details:null,
            integration_option:null,
            mlDetails:null,
            mlData:null,
            mlLimitExceed: null,
            dataLimitExceed: null,
            isEdit:null,
            isAddModel: false,
            isAddDS: false,
            isMetadataAdd: false,
            planName: null,
            environment:null,
            datasets:[],
            attributes: [],
            maxModel: null,
            datasource_updates: null,
            model_updates: null,
            show: false,
            openViewDetails:false,
            openEditDetails:false,
            openDeleteDetails:false,
            shouldHide:false
        }
     }

    componentDidCatch(error, info) {
        this.setState({ errorOccurred: true });
        this.postUIlogs(error);
    }

   componentDidMount() {
      this.getData();
   }

   setDataLimit() {
     this.getApi("increaseDataLimit");
   }

   getData() {
        this.getApi("accountDetails");
        this.getApi("environments");
        this.getApi("integrations");
        this.getApi("mlmodels");
        this.getApi("dataset");
        this.getApi("model_updates");
        this.getApi("datasource_updates");
   }

    updateEnvironmentList(newEnvironmentData) {
        //Adds the newly added environment information to existing list
        const existingEnvironmentDetail = this.state.environment;
        if (existingEnvironmentDetail === undefined || existingEnvironmentDetail === null) {
            return;
        }

        if (newEnvironmentData === undefined || newEnvironmentData === null) {
            return;
        }

        let data = {"label": newEnvironmentData.env_name, "value": newEnvironmentData.env_id};
        existingEnvironmentDetail.push(data);
        this.setState({environment: existingEnvironmentDetail});
    }

   setAddModel(value) {
        this.setState ({isAddModel: value});
   }

   setAddDS(value,value1) {
        this.setState ({isAddDS: value, isEdit: value1});
   }
  
   closeToast() {
        this.setState({toastStatus:false});
   }

   updateTable() {
        this.setState({updateStatus:false}, this.getApi("integrations"));
   }

   showToast(status, msg, link, bulk) {
       this.getData();
       this.setState({
          toastStatus: true,
          toastMsg: msg,
          toastClass: toastObj[status]["className"],
          toastIcon: toastObj[status]["icon"],
          toastLink: link,
          toastHeading: toastObj[status]["heading"],
          toastBulk: bulk
       });
   }

    handleTab(key) {
        this.setState ({current_tab: key});
    }
    openViewDetails() {   
        this.setState({openViewDetails: true});
      }
      cancelViewDetails() {
        this.setState({openViewDetails: false});
      }
      openEditDetails() {   
        this.setState({openEditDetails: true});
      }
      cancelEditDetails() {
        this.setState({openEditDetails: false});
      }
      openDeleteDetails() {   
        this.setState({openDeleteDetails: true});
      }
      cancelDeleteDetails() {
        this.setState({openDeleteDetails: false});
      }
      showFilter(){
        this.setState({shouldHide: true});
        
    }
    render() {
        return (
            this.state.errorOccurred ?
                <ErrorHandler/>
            :
            <>
                    
            {/* Modal for View Details  START*/}

            <Modal show={this.state.openViewDetails} size="lg" centered onHide={this.cancelViewDetails}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title font-weight-bold">
                    View Datasource Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>
                        <div class="row py-3">
                            
                            <div class="col-md-10 offset-md-1">
                            <div class="form-group row">
                                <div class="col-md-2"></div>
                                <label class="col-md-4 text-right">PII Name</label>
                                <label class="col-md-1">:</label>
                                <div class="col-md-3 text-muted text-break">
                                    <span>Demo</span>
                                </div>
                            </div>
                            </div>
                            <div class="col-md-10 offset-md-1">
                            <div class="form-group row">
                                <div class="col-md-2"></div>
                                <label class="col-md-4 text-right">PII Regular Expression</label>
                                <label class="col-md-1">:</label>
                                <div class="col-md-3 text-muted text-break">
                                <span>Experiment</span>
                                </div>
                            </div>
                            </div>
                    </div>
                </Modal.Body>
                </Modal>
          {/* Modal for View Details  END*/}


            {/* Modal for Edit Details  START*/}

            <Modal show={this.state.openEditDetails} size="lg" centered onHide={this.cancelEditDetails}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title font-weight-bold">
                    Edit Datasource Details</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>

                    <div class="form-row">
                            <div class="form-group col-md-6" data-select2-id="44">
                            <label>PII Name :</label>
                            <Select
                                classNamePrefix='select-control'
                                defaultValue={[{"label": "Demo", "value": 1}]}
                                options={[{"label": "Demo", "value": 1},{"label": "Test 1", "value": 2}]}
                             />
                                                               
                            </div>
                            <div class="form-group col-md-6" data-select2-id="47">
                            <label>PII Regular Expression :</label>
                            <Select
                                classNamePrefix='select-control'
                                defaultValue={[{"label": "Experiment", "value": 1}]}
                                options={[{"label": "Experiment", "value": 1},{"label": "AWS", "value": 2}]}
                             />
                           </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className=" border-0">                  
                    <button type="button" className="btn btn-primary btn-circle"><FontAwesomeIcon icon={faCheck}></FontAwesomeIcon>Update</button>
                </Modal.Footer>
                </Modal>
          {/* Modal for Edit Details  END*/}

            {/* Modal for Delete Details  START*/}

            <Modal show={this.state.openDeleteDetails} size="lg" centered onHide={this.cancelDeleteDetails}>
                <Modal.Header closeButton className="border-0 justify-content-center d-block">
                    <h5 className="modal-title font-weight-bold">
                    Delete Datasource</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
                </Modal.Header>
                <Modal.Body>

                    <div class="modal__alert">
                        <div class="modal_alert-icon text-danger">
                            <FontAwesomeIcon icon={faTrashAlt} size="3x"></FontAwesomeIcon>                       
                        </div>
                        <div class="modal_alert-text">
                        <h4>You are about to delete a Datasource</h4>
                        <p>This will delete your Datasource</p>
                        </div>
                        <div class="modal_alert-btn-group">
                        <button class="btn btn-outline btn-grey btn-circle mr-2" type="button" data-dismiss="modal">Cancel</button>
                        <button class="btn btn-primary btn-circle mr-2" data-dismiss="modal" type="button">Delete</button>
                        </div>
                    </div>
                </Modal.Body>
                </Modal>
          {/* Modal for Edit Details  END*/}
            <div id="toastMsg">
            <Toast className={this.state.toastClass}  onClose={() =>  this.closeToast()}  show={this.state.toastStatus} delay={3000} autohide>
                <div className="d-flex align-items-center">
                    <i className="toast-icon">
                         { this.state.toastIcon ?
                                <FontAwesomeIcon icon={ this.state.toastIcon }/> :
                                ""
                         }
                    </i>
                    <Toast.Body>
                        <strong className="toast-title">{this.state.toastHeading}</strong>
                        { this.state.toastBulk === "bulk" ?
                             this.state.toastMsg.map(function (row) {
                                        return <p>{row.message}</p>;
                             }) : <p>{ this.state.toastMsg }</p>
                        }
                    </Toast.Body>
                    <button type="button" className="close" data-dismiss="toast" aria-label="Close"/>
                </div>
            </Toast>
            </div>
                <NavigationHeader page="comply"/>
                <main>
                    <BreadCrumb icon='cog' title='comply' />
                    <Tab.Container defaultActiveKey={this.state.current_tab} onSelect={this.handleTab}>
                        <div className="qd-tab__header">
                            <div className="container-fluid">
                                <div className="qd-tab__header-wrapper">
                                    <div className="qd-tab__header-menu">
                                        <Nav variant="tabs">
                                            <Nav.Item>
                                                <Nav.Link eventKey="piiglossary" >
                                                    <span className="qd-menu__link-text">PII Glossary</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="piisearch">
                                                    <span className="qd-menu__link-text">PII Search</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="piirules">
                                                    <span className="qd-menu__link-text">PII Rules</span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="qd-container">
                            <div className="qd-body">
                                <div className="qd-tab__content">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="piirules">
                                            <BasicPortlet
                                                className="pb-0" title="PII RULES"
                                                id="datasourceIndex"
                                                content={
                                                    <DSEnvironment environment={this.state.environment}
                                                                   updateEnvironmentList={this.updateEnvironmentList}
                                                                   setDataLimit={this.setDataLimit}
                                                                   planName={this.state.planName}
                                                                   showToast={this.showToast}
                                                                   router={this.props.router}
                                                                   setPage={this.setAddDS}
                                                                   dataLimitExceed={this.state.dataLimitExceed}  
                                                                   isEdit =    {this.state.isEdit}                                     
                                                                  />
                                                }
                                            />
                                            {
                                                this.state.isAddDS ?
                                                    <AddDataSources props={this.props} showToast={this.showToast} showCancel={true} setPage={this.setAddDS} isEdit = {this.state.isEdit}/>
                                                     : ''
                                            }

                                                <div class="portlet mb-0">
                                                <div class="portlet-title">
                                                    <div class="caption">
                                                    <span class="text-uppercase font-dark">List of Datasources</span>
                                                    </div>
                                                </div>
                                                <div class="portlet-body">
                                                <div class="react-bootstrap-table__header">
                                                    <div class="row">
                                                        <div class="col-6">
                                                        <div class="btn-group react-table__length" role="group">
                                                            <span>Show</span>
                                                            <Select
                                                                classNamePrefix='select-control'
                                                                defaultValue={[{"label": "5", "value": 1}]}
                                                                options={[{"label": "5", "value": 1},{"label": "10", "value": 2},{"label": "all", "value": 3}]}
                                                            />                                                   
                                                        </div>
                                                        </div>                       
                                                    </div>
                                                    </div>
                                                    <div class="react-bootstrap-table table-responsive action-sticky-cell">
                                                    <table class="table">
                                                        <thead>
                                                        <tr>
                                                            <th>ID
                                                            <span class="order">
                                                                <span class="dropdown">
                                                                <span class="caret"></span>
                                                                </span>
                                                                <span class="dropup">
                                                                <span class="caret"></span>
                                                                </span>
                                                            </span>
                                                            </th>
                                                            <th>PII Name
                                                            <span class="order">
                                                                <span class="dropdown">
                                                                <span class="caret"></span>
                                                                </span>
                                                                <span class="dropup">
                                                                <span class="caret"></span>
                                                                </span>
                                                            </span>
                                                            </th>
                                                            <th>PII Regular Expression
                                                            <span class="order">
                                                                <span class="dropdown">
                                                                <span class="caret"></span>
                                                                </span>
                                                                <span class="dropup">
                                                                <span class="caret"></span>
                                                                </span>
                                                            </span>
                                                            </th>
                                                            <th>Actions
                                                            <span class="order">
                                                                <span class="dropdown">
                                                                <span class="caret"></span>
                                                                </span>
                                                                <span class="dropup">
                                                                <span class="caret"></span>
                                                                </span>
                                                            </span>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>First Name</td>
                                                            <td>County</td>
                                                            <td>
                                                            <button className="btn btn-sm btn-outline-success" onClick={this.openViewDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-view"> <FontAwesomeIcon icon={faEye}/>View</button>
                                                            <button className="btn btn-sm btn-outline-warning" onClick={this.openEditDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-edit"> <FontAwesomeIcon icon={faEdit}/>Edit</button>
                                                            <button className="btn btn-sm btn-outline-danger" onClick={this.openDeleteDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-delete"> <FontAwesomeIcon icon={faTrash}/>Delete</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>Last Name</td>
                                                            <td>State/Province</td>
                                                            <td>
                                                            <button className="btn btn-sm btn-outline-success" onClick={this.openViewDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-view"> <FontAwesomeIcon icon={faEye}/>View</button>
                                                            <button className="btn btn-sm btn-outline-warning" onClick={this.openEditDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-edit"> <FontAwesomeIcon icon={faEdit}/>Edit</button>
                                                            <button className="btn btn-sm btn-outline-danger" onClick={this.openDeleteDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-delete"> <FontAwesomeIcon icon={faTrash}/>Delete</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>Company</td>
                                                            <td>Phone 1</td>
                                                            <td>
                                                            <button className="btn btn-sm btn-outline-success" onClick={this.openViewDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-view"> <FontAwesomeIcon icon={faEye}/>View</button>
                                                            <button className="btn btn-sm btn-outline-warning" onClick={this.openEditDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-edit"> <FontAwesomeIcon icon={faEdit}/>Edit</button>
                                                            <button className="btn btn-sm btn-outline-danger" onClick={this.openDeleteDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-delete"> <FontAwesomeIcon icon={faTrash}/>Delete</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>Address</td>
                                                            <td>Email</td>
                                                            <td>
                                                            <button className="btn btn-sm btn-outline-success" onClick={this.openViewDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-view"> <FontAwesomeIcon icon={faEye}/>View</button>
                                                            <button className="btn btn-sm btn-outline-warning" onClick={this.openEditDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-edit"> <FontAwesomeIcon icon={faEdit}/>Edit</button>
                                                            <button className="btn btn-sm btn-outline-danger" onClick={this.openDeleteDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-delete"> <FontAwesomeIcon icon={faTrash}/>Delete</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>City</td>
                                                            <td>Web</td>
                                                            <td>
 
                                                            <button className="btn btn-sm btn-outline-success" onClick={this.openViewDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-view"> <FontAwesomeIcon icon={faEye}/>View</button>
                                                            <button className="btn btn-sm btn-outline-warning" onClick={this.openEditDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-edit"> <FontAwesomeIcon icon={faEdit}/>Edit</button>
                                                            <button className="btn btn-sm btn-outline-danger" onClick={this.openDeleteDetails} 
                                                             data-toggle="modal" data-target="#modal__PIIRule-delete"> <FontAwesomeIcon icon={faTrash}/>Delete</button>
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                    <div class="react-bootstrap-table__footer">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6"> 
                                                        <span class="react-table__info">Showing <b>1</b> to <b>5</b> of <b>8</b> results</span> 
                                                        </div>
                                                        {/*<div class="col-sm-12 col-md-6">
                                                         <ul class="pagination float-md-right">
                                                            <li class="active page-item" title="1">
                                                            <a href="#" class="page-link">1</a>
                                                            </li>
                                                            <li class="page-item" title="2">
                                                            <a href="#" class="page-link">2</a>
                                                            </li>
                                                            <li class="page-item" title="next page">
                                                            <a href="#" class="page-link">&gt;</a>
                                                            </li>
                                                        </ul> 
                                                        </div>*/}
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="piiglossary">
                                            <BasicPortlet
                                                   className="pb-3" title="PII GLOSSARY" id="PIIGlossary" bodyClassName="pb-0"
                                                   content={<MetadataGlossary/>}
                                                    />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="piisearch">
                                            <div className="qd_search-container">
                                                <div className="qd_search-box my-4">
                                                
                                                    <input className="form-control" type="search" 
                                                        placeholder="Search for any thing! Just start typing..." 
                                                        value="Religion" aria-label="Search" /> 
                                                    <div className="qd_search-bar-divider">
                                                        <span>or</span>
                                                    </div>
                                                    <div className="qd-filtered_button">
                                                        <button id="btn-filter" className="btn btn-outline-secondary text-white" onClick={this.showFilter}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#0021a5" height="24" viewBox="0 0 24 24" width="24">
                                                            <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                                                            <path d="M0 0h24v24H0z" fill="none"></path>
                                                            </svg> <span>Filters</span>
                                                        </button>
                                                    </div>
                                                </div>
                                                { this.state.shouldHide ?
                                                <div  data-select2-id="49">
                                                    <form className="form-label-right-md pt-2" data-select2-id="48">
                                                    <div className="row">
                                                        <div className="form-group col-md-3" data-select2-id="47">
                                                        <label className="form-label">Environment :</label>
                                                        <Select
                                                                placeholder="Choose Environment"
                                                                classNamePrefix='select-control'
                                                              
                                                                options={[{"label": "IT Employee", "value": 1},
                                                                            {"label": "Government Employee", "value": 2},
                                                                            {"label": "Personal Information", "value": 3}]}
                                                            />  
                                                       </div>
                                                        <div className="form-group col-md-3" data-select2-id="54">
                                                        <label className="form-label">Datasource :</label>
                                                        <Select
                                                                placeholder="Choose Datasource"
                                                                classNamePrefix='select-control'
                                                                
                                                                options={[{"label": "Employee Datasource", "value": 1},
                                                                            {"label": "Employer Datasource", "value": 2},
                                                                            {"label": "Employment Datasource", "value": 3}]}
                                                            />
                                                       </div>
                                                        <div className="form-group col-md-3" data-select2-id="59">
                                                        <label className="form-label">Dataset :</label>
                                                        <Select
                                                                placeholder="Choose Dataset"
                                                                classNamePrefix='select-control'
                                                                options={[{"label": "Employment Information", "value": 1},
                                                                            {"label": "Government Employee", "value": 2},
                                                                            {"label": "IT Employee", "value": 3}]}
                                                            />
                                                        </div>
                                                        <div className="form-group col-md-3" data-select2-id="64">
                                                        <label className="form-label">Column :</label>
                                                        <Select
                                                                placeholder="Choose Column"
                                                                classNamePrefix='select-control'
                                                                options={[{"label": "Date of Birth", "value": 1},
                                                                            {"label": "Place of Birth", "value": 2},
                                                                            {"label": "Religion", "value": 3},
                                                                            {"label": "Weight", "value": 4},
                                                                            {"label": "Activities", "value": 5},
                                                                            {"label": "Geographical Location", "value": 6},
                                                                            {"label": "Employment Information", "value": 7},
                                                                            {"label": "Medical information", "value": 8},
                                                                            {"label": "Education information", "value": 9},
                                                                            {"label": "Financial information", "value": 10},
                                                                            {"label": "Family members", "value": 11},
                                                                        ]}
                                                            />
                                                       </div>
                                                    </div>
                                                
                                                    </form>
                                                    <div className="row">
                                                    <div className="col-md-8 offset-md-2">
                                                        <div className="p-4 text-center">
                                                        <h3 className="mb-1">Discover PIIs</h3>
                                                        <p className="text-muted">10+ search results in Profile related</p>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                                                              
                                                :"" }
                                            </div>

                                            <BasicPortlet
                                                   className="pb-3" title="PII GLOSSARY" id="PIISearch" bodyClassName="pb-0"
                                                   content={<MetadataGlossary/>}
                                                    />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </div>
                    </Tab.Container>
                </main>
                <CopyRightsFooter/>

            </>
        );
    }
  
}

//export default Configure;

const mapStateToProps = state => {
    return state;
}

export default connect(
    mapStateToProps, {
        addMLModelMapping,
        setMLModel
    }
) (Comply);
