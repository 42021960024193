import React from 'react';
import Integration from "../components/integration";

class AddDataSources extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {
            }
        }
    }

    render() {
        return(            
            <Integration src_page={this.props.src_page} showCancel={this.props.showCancel} setPage={this.props.setPage} showToast={this.props.showToast}  isEdit={this.props.isEdit}/>
        );
    }
}

export default AddDataSources;