import React from 'react';
import Select from 'react-select';
import { faPlusCircle, faEdit} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';

class DSEnvironment extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.addPIIRule= this.addPIIRule.bind(this);
        this.editPIIRule= this.editPIIRule.bind(this);        
        this.showButtonAction = this.showButtonAction.bind(this);
        this.addEnvironment = this.addEnvironment.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.state = {
            errors: {
            },
            env_id:null,
            show: false,
            actionType: '',
            showButton: false,
            clickAction: false,
            hideSubmitButton: false
        }
    }

    disableButton() {
            this.setState({hideSubmitButton: true});
    }

    goToChoosePlan = () => {
        this.props.router.push("/choose_plan");
    }
    addPIIRule() {
        let env = this.state.env_id;
        this.setState({clickAction:true});
        localStorage.setItem("env_id",env);
        if(env !== null) {
            this.props.setPage(true,false);
        }
    }

    editPIIRule() {
        let env = this.state.env_id;
        this.setState({clickAction:true});
        localStorage.setItem("env_id",env);
        if(env !== null) {
            this.props.setPage(true,true);
        }
    }
 
    handleChange = (selectedOption) => {
        this.setState({ env_id:selectedOption["value"]});
        localStorage.setItem("env_id",selectedOption["value"]);
    }

    addEnvironment(event) {
        // let {environment} = event;
        // let session = localStorage.getItem("sessionToken");
        // const env_data = {env_name: environment, tenant_id: localStorage.getItem('team_id')};
        // call_post_api(console_ip, 'environment', env_data, session).then((response) => {
        //     if (response.code === 200) {
        //         this.props.showToast("alert-success", "Environment added successfully", '')
        //         let tenant_id = localStorage.getItem('team_id')
        //         let key = `environments_${tenant_id}`
        //         sessionStorage.removeItem(key);
        //         let env_data = response.env_data;
        //         this.setState({showButton: false});
        //         this.props.updateEnvironmentList(env_data);
        //     } else {
        //         this.props.showToast("alert-danger", response.message, '')
        //     }
        // });
    }

	showButtonAction () {
	    this.setState({showButton: true});
	}


	goToChoosePlan = () => {
        this.props.router.push("/choose_plan");
    }

   
    render() {
       const envSchema = yup.object({
            environment: yup.string().required("Environment is a required field"),
       });

       return(
            <>
                <div className="row">
                        <div className="vertical-stepper vertical-stepper-sm">
                            <div className="vertical-stepper__heading">
                            <span className="vs-heading__circle">1</span>
                            <div className="vs-heading__title">Add or Choose Environment</div>
                            </div>
                            <div className="vertical-stepper__content">
                              <p>Environment is used to organize related datasources (Eg. Dev, Staging, Production). Add or select an existing environment.</p>
                              <Formik
                                 initialValues={{ environment: '' }}
                                 validationSchema={envSchema}
                                 onSubmit={this.addEnvironment}
                              >
                              {({
                                   values,
                                   errors,
                                   touched,
                                   handleChange,
                                   handleSubmit,
                                   isSubmitting,
                                   isValid,
                              }) => (
                                  <Form className="needs-validation" noValidate method="post" ref={form => this.form = form}  name='add_environment' onSubmit={handleSubmit}>
                                    <div className="qd-get-configure">
                                        <div className="form-row mb-1">
                                            <div className="col-lg-5">
                                                <div className={(this.state.env_id !== null || this.state.clickAction === false) ? "card" : "card has-error"}>
                                                    <Form.Label>Choose Environment</Form.Label>
                                                    <Form.Group className="mb-1">
                                                    <Select name="env" onChange={this.handleChange}
                                                        placeholder="Select Environment" classNamePrefix='select-control'
                                                        options={[                                  
                                                                    {"label": "Production", "value":1},
                                                                    {"label": "Staging", "value":2}
                                                                ]}
                                                        id="choose_environment_add_datasource"
                                                    />
                                                    </Form.Group>
                                                    <Form.Text className="text-muted font-italic">Please select at-least one Environment</Form.Text>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                                <h4 className="text-muted">OR</h4>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="card">
                                                        <Form.Label>Add Environment</Form.Label>
                                                        { this.state.showButton !== false ?
                                                           <>
                                                                <div className="form-inline d-flex">
                                                                    <Form.Control type="text" className="form-control-solid placeholder-no-fix mb-1 flex-grow-1"
                                                                    autoComplete="off" placeholder=" " name="environment" value={values.environment} onChange={handleChange} isInvalid={!!errors.environment}/>
                                                                    <button type="submit" className="btn btn-outline-primary mb-1">
                                                                        <FontAwesomeIcon icon={faPlusCircle}/>
                                                                        Add Environment
                                                                    </button>
                                                                    <Form.Control.Feedback type="invalid">
                                                                         { errors.environment }
                                                                    </Form.Control.Feedback>
                                                                </div>
                                                                {errors.environment ? '' : <Form.Text className="text-muted font-italic">Please add Environment</Form.Text>}
                                                           </>:
                                                           <>
                                                                <div className="form-inline d-flex">
                                                                    <button type="button"   className="btn btn-outline-primary mb-1"  onClick={() => this.showButtonAction()}>
                                                                        <FontAwesomeIcon icon={faPlusCircle}/>
                                                                        Add Environment
                                                                    </button>
                                                                </div>
                                                                <Form.Text className="text-muted font-italic">Please add Environment</Form.Text>
                                                           </>
                                                        }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                  </Form>)}
                              </Formik>
                            </div>
                            <div className="vertical-stepper__heading">
                                <span className="vs-heading__circle">2</span>
                            <div className="vs-heading__title">Add or Edit PII Rule </div>
                            </div>
                            <div className="vertical-stepper__content pb-0">
                                <button type="submit" onClick={this.addPIIRule} className="btn btn-outline-primary btn-circle mr-2 mb-1">
                                    <FontAwesomeIcon icon={faPlusCircle}/> Add a PII Rules
                                </button>
                                <button type="submit" onClick={this.editPIIRule} className="btn btn-primary btn-circle action__to-show mb-1">
                                    <FontAwesomeIcon icon={faEdit}/> Edit a PII Rules
                                </button>
                            </div>
                        </div>
                </div></>
        );
    }
}

export default DSEnvironment;