import React from 'react';
import Select from 'react-select';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';




class MetadataEnvironment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {
            },
            env_id:null,
            show: false,
            uploadClick:false,
            file:[],
            actionType: '',
            acceptCondition: false,
            showButton: false,
            clickAction: false,
            show_add_model_modal: null,
            hideSubmitButton: false
        }
    }

    render() {
       return(<div className="row">
                      <div className="vertical-stepper vertical-stepper-sm" data-select2-id="128">
                        <div className="vertical-stepper__heading">
                          <span className="vs-heading__circle">
                            1
                          </span>
                          <div className="vs-heading__title">
                            Add or Choose Environment
                          </div>
                        </div>
                        <div className="vertical-stepper__content" data-select2-id="127">
                          <p>
                            Environment is used to organize related datasources (Eg. Dev, Staging, Production). Add or select an existing environment.
                          </p>
                          <form className="col-12 mt-2 needs-validation" novalidate="" method="post" name="add_environment" data-select2-id="126">
                            <div className="qd-get-configure" data-select2-id="125">
                              <div className="form-row mb-1" data-select2-id="124">
                                <div className="col-lg-5" data-select2-id="123">
                                  <div className="card">
                                    <label className="form-label">
                                      Choose Environment :
                                    </label>
                                    <div className="mb-1 form-group" data-select2-id="122">
                                         <Select name="env"
                                                            options={[{"label": "Production", "value": 1},{"label": "Staging", "value": 2}]}
                                                            placeholder="Choose Environment"
                                                            classNamePrefix='select-control'
                                                            id="choose_environment_add_meta_data"
                                         />
                                    </div>
                                    <small className="text-muted font-italic form-text">Please select at-least one
                                      Environment</small>
                                  </div>
                                </div>
                                <div className="col-lg-1 d-flex align-items-center justify-content-center">
                                  <h4 className="text-muted">OR</h4>
                                </div>
                                <div className="col-lg-6">
                                  <div className="card">
                                    <label className="form-label">Add Environment</label>
                                    <div className="form-inline d-flex">

                                      <button className="btn btn-outline-primary toggle mb-1" type="button"><FontAwesomeIcon icon={faPlusCircle}/> Add Environment</button>
                                    </div>
                                    <small className="text-muted font-italic form-text">Please add Environment</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="vertical-stepper__heading">
                          <span className="vs-heading__circle">
                            2
                          </span>
                          <div className="vs-heading__title">
                            Add a Metadata Source
                          </div>
                        </div>
                        <div className="vertical-stepper__content pb-0">
                          <p>Metadata sources contain both the business glossary and data relationship constraints</p>
                          <button type="submit" onClick={this.props.setAddMetadata} className="btn btn-primary btn-circle action__to-show mb-1"><FontAwesomeIcon icon={faPlusCircle}/> Add
                            Metadata Sources</button>
                        </div>
                      </div>
                    </div>)
    }
}

export default MetadataEnvironment;