import React from 'react';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import {postPipelinesource}  from "../../../../utils/event_handling";
import {userValidation} from "../../../../utils/common_utils"
import {PIPELINE_TEST_CONNECTION_MESSAGES} from "../../../../utils/constant";
import { faEye, faLock, faEyeSlash,faPlusCircle,faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputGroup from 'react-bootstrap/InputGroup';
import {faTimesCircle} from '@fortawesome/fontawesome-free-regular';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Image from '../../../../components/image.js';
import Alert from 'react-bootstrap/Alert';


//#SpecificPipeline Code
const SpecificPipeline = (props) => {
      if (props.clickedValue === undefined){
          return '';
      }
      let tablelist;
      tablelist = props.clickedValue.map(function(elem){ 
          return elem.pipeline_name; }).join(",");
      tablelist = tablelist.split(",");

  
      return props.clickedValue.map((value,idx) => {
          let name = props.name;
  
          let checked = props.clickedValue[idx]["is_exact_match"] === true ? "checked" : "";
          return (
      <div className="dataset-partition-card">
                                {(props.pipelineToBeProcessed === "specifiedPipeline" && props.clickedValue.length > 1) ?( <i onClick={props.removeSpecificPipeline.bind(null,idx,props.name,props.clickedValue[idx][name])} className="icon-close text-danger"><FontAwesomeIcon icon={faTimesCircle} /></i>):""}
                                           <div className="dp-card__content">
                                            { props.pipelineToBeProcessed === "allPipeline" ?
                                                  <span className="dp-card__label">Add Incremental Data Identification (Optional)</span>
                                                  :
                                                  <span className="dp-card__label">Pipeline {idx+1} to be processed</span>}
                                                  <div className="form-row">
                                                        <Form.Group className="col-md-6 col-lg-4">
                                                              <Form.Label>Pipeline Name 
                                                                  {props.pipelineToBeProcessed === "specifiedPipeline" ?
                                                                  <span class="text-danger">*</span> : "" }
                                                                  {/* <OverlayTrigger
                                                                      placement="top"
                                                                      overlay={
                                                                          <Tooltip>This Dataset will get partition.</Tooltip>
                                                                      }
                                                                      >
                                                                      <i><Image className="ml-1" width="16px" src="icon_info_circle" /></i>
                                                                  </OverlayTrigger> */}
                                                              </Form.Label>
                                                              <Form.Control
                                                                    type="text"
                                                                    className={(props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined  || (props.clickedValue[idx]["pipeline_name"]!=="" && props.clickedValue[idx]["pipeline_name"]!==undefined && tablelist.filter(x => x===props.clickedValue[idx]["pipeline_name"]).length > 1))   ? "form-control is-invalid" : "form-control"}
                                                                    placeholder="Pipeline Name"
                                                                    name={"specific_pipeline_name_"+(idx+1)}
                                                                    id={"specific_pipeline_name_"+(idx+1)}
                                                                    data-index={(idx)}
                                                                    data-key={"pipeline_name"}
                                                                    value={((props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined)) ? "" : props.clickedValue[idx]["pipeline_name"]}
                                                                    onChange={(e) => {props.changePipelineText(e)}}
                                                              />
                          {
                          (props.clickedValue[idx]["pipeline_name"]==="" || props.clickedValue[idx]["pipeline_name"]===undefined)  ? <div class="error-text">Pipeline Name is required field</div> : ""
                        }
                         {
                          (props.clickedValue[idx]["pipeline_name"]!=="" && props.clickedValue[idx]["pipeline_name"]!==undefined &&  tablelist.filter(x => x===props.clickedValue[idx]["pipeline_name"]).length > 1) ? <div class="error-text">Pipeline Name  already added</div> : ""
                        }               
                                                                  {checked !=="" ?
                                    
                                                              <Form.Text className="text-muted">
                                                                  <Form.Check
                                                                      custom
                                                                      type="checkbox"
                                                                      label="Exact match only"
                                                                      id={"exact-match_"+(idx+1)}
                                                                      data-index={(idx)}
                                                                      data-key={"is_exact_match"}
                                                                      name={"specic_exact_match_"+(idx+1)}
                                                                      defaultChecked={(true)}
                                                                      onChange={(e) => {props.handleExactMatch(e)}}
                                                                  />
                                                              </Form.Text>
                                                              :
                                                              <Form.Text className="text-muted">
                                                              <Form.Check
                                                                  custom
                                                                  type="checkbox"
                                                                  label="Exact match only"
                                                                  id={"exact-match_"+(idx+1)}
                                                                  data-index={(idx)}
                                                                  data-key={"is_exact_match"}
                                                                  name={"specic_exact_match_"+(idx+1)}
                                                                  onChange={(e) => {props.handleExactMatch(e)}}
                                                              />
                                                          </Form.Text>    
                                                          }
                                                        </Form.Group>
                                                  </div>
                                            </div>
      </div>)
    //}
      });
  }
//#SpecificPipeline Code

class AzureForm extends React.Component {
        constructor(props) {
        super(props);
        this.fill_details = this.fill_details.bind(this);
        this.postPipelinesource = postPipelinesource.bind(this);
        this.postAzureData = this.postAzureData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangesecretKeyIcon = this.ChangesecretKeyIcon.bind(this);
        //#SpecificPipeline Code
        this.handlePipelineChange = this.handlePipelineChange.bind(this);
        this.addSpecificPipeline = this.addSpecificPipeline.bind(this);
        this.removeSpecificPipeline = this.removeSpecificPipeline.bind(this);
        this.handleExactMatch = this.handleExactMatch.bind(this)
        this.changePipelineText  = this.changePipelineText.bind(this);
        this.state = {
            formType_option:null,
            isChecked: true,
            hideSubmitButton: false,
            fileType: null,
            selectOptionKey:"emptyFileType",
            integration_data: this.props.integration_data,
            integration_data_edit:"",
            refreshType: {"label":"File Replace", "value":"file_replace"},
            versionFormat: null,
            versionOccurence: {"label":"prefix", "value":"prefix"},
            secretKeyIcon:faEye,
            secretKeyType:'password',
            workspace_url:'',
            pipeline_name:'',
            //#SpecificPipeline Code
            pipelineToBeProcessed:"allPipeline",
            specificPipeline:[{"pipeline_name":"","is_exact_match":"False"}],
            dataset_processed_edit:true,
            updateData:false
        };
       }

       componentDidMount() {
            this.fill_details();

            //   console.log(this.props.ex_integration_details);
                // return;

            //#SpecificPipeline Code
            if(this.props.ex_integration_details!==null){

                
            if(this.props.ex_integration_details[0]['pipeline_config']['pipeline_name']!==undefined){
                for(let loop=0;loop<this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'].length;loop++){
                    let selectobj = {};
                    selectobj.label= this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'][loop]["pipeline_name"];
                    selectobj.value= this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'][loop]["pipeline_name"];
               }
            }
        this.setState({
                pipelineToBeProcessed:this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined ? "allPipeline" : this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'],
                dataset_processed_edit : this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined ? true : false,
                specificPipeline:this.props.ex_integration_details[0]['pipeline_config']['pipeline_name'],
        });
        if(this.props.ex_integration_details[0]['pipeline_config']['pipelineToBeProcessed'] === undefined){
            this.setState((prevState) => ({
                specificPipeline:[]
            }))
        }
        }
        //#SpecificPipeline Code
        }
//#SpecificPipeline Code
  changePipelineText(e){
      let specificPipeline = [...this.state.specificPipeline];
      
      if(e.currentTarget.getAttribute("data-key")==="pipeline_name"){
          let selectobj = {};
          selectobj.label= e.currentTarget.value;
          selectobj.value= e.currentTarget.value;
      }
      if (this.state.pipelineToBeProcessed === "specifiedPipeline"){
      specificPipeline[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.currentTarget.value;
      }  
      this.setState({specificPipeline:specificPipeline, updateData:true});
  }

  handleExactMatch(e){
      let specificPipeline = [...this.state.specificPipeline];
      specificPipeline[e.currentTarget.getAttribute("data-index")][e.currentTarget.getAttribute("data-key")] = e.target.checked === true ? "True" : "False";
      this.setState({specificPipeline:specificPipeline});
  }
  handlePipelineChange(e){
      if(e.target.value==="specifiedPipeline"){
              this.setState((prevState) => ({
                  specificPipeline: [{"pipeline_name":"","is_exact_match":"False"}]
              }))
          }
          this.setState({
            pipelineToBeProcessed: e.target.value
          })
  }
  addSpecificPipeline(e){
      e.preventDefault();
      let checkpartitionvalidation = true;
      let specificPipeline = this.state.specificPipeline;
      if (this.state.pipelineToBeProcessed === "specifiedPipeline"){
          for(let loop=0;loop<specificPipeline.length;loop++){
              if(specificPipeline[loop]['pipeline_name']==="" ||
              specificPipeline[loop]['pipeline_name']=== undefined
              ){
                  checkpartitionvalidation = false;
                  break;
              }
          }
      }
      if(checkpartitionvalidation===true){
      let tempData = null
      // tempData = Object.assign({});
      tempData ={"pipeline_name":"","is_exact_match":"False"}
      // idx = this.state.specificPipeline.length-1;
          this.setState((prevState) => ({
              specificPipeline: [...prevState.specificPipeline, tempData]
          }));
      }
  }
  removeSpecificPipeline  = (idx,name) => {
    this.state.specificPipeline.splice(idx,1)
    this.setState({specificPipeline:this.state.specificPipeline, updateData:true});
}
//#SpecificPipeline Code

toggleChange = () => {
    this.setState({isChecked: !this.state.isChecked});
}

       fill_details() {


        //    alert(JSON.stringify(this.props));
            if (this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null) {
               
                  
               let integration_data =  this.state.integration_data;
               integration_data.integration_name = this.props.ex_integration_details[0]["pipeline_integration_name"];
               integration_data.integration_id = this.props.ex_integration_details[0]["pipeline_integration_id"];
              
              
               integration_data.client_id = this.props.ex_integration_details[0]["auth_config"]["client_id"];
               integration_data.client_secret = this.props.ex_integration_details[0]["auth_config"]["client_secret"];
               integration_data.tenant_id = this.props.ex_integration_details[0]["auth_config"]["tenant_id"];


               integration_data.workspace_url = this.props.ex_integration_details[0]["pipeline_config"]["workspace_url"];
               integration_data.pipeline_name = this.props.ex_integration_details[0]["pipeline_config"]["pipeline_name"];

               this.setState({
                            integration_type: this.props.formType,
                            integration_data_edit: integration_data
                            });
            }

       }

       postAzureData(event) {
           let pipelineToBeProcessed= this.state.pipelineToBeProcessed;
           let specificPipeline = this.state.specificPipeline;
           let checkpartitionvalidation = true;

            let pipeline_name_filter = specificPipeline.map(function(elem){ 
            return elem.pipeline_name; }).join(",");
            if(pipeline_name_filter!==""){
                pipeline_name_filter = pipeline_name_filter.split(",");   
            }

            if(pipelineToBeProcessed === "specifiedPipeline" && specificPipeline.length > 0 && pipeline_name_filter!==""){
                specificPipeline.forEach( input => {
                    if(input['pipeline_name']==="" ||
                    input['pipeline_name']=== undefined
                        ){
                            checkpartitionvalidation = false;
                            return;
                        }
                        else {
                        if(pipeline_name_filter.filter(x => x===input["pipeline_name"]).length > 1) {
                            checkpartitionvalidation = false;
                            return;
                        }
                    }
                })     
                    
                // for(let loop=0;loop<specificPipeline.length;loop++){
                //     if(specificPipeline[loop]['pipeline_name']==="" ||
                //         specificPipeline[loop]['pipeline_name']=== undefined
                //         ){
                //             checkpartitionvalidation = false;
                //             break;
                //         }
                //         else {
                //         if(pipeline_name_filter.filter(x => x===specificPipeline[loop]["pipeline_name"]).length > 1) {
                //             checkpartitionvalidation = false;
                //             break;
                //         }
                //     }
                // }
                
            }else{
            specificPipeline=[];
           }
           if(checkpartitionvalidation===false){
            return;
           }
            this.disableButton();
            // secret_key,storage_name,
            const {integration_name,client_secret,client_id,
            tenant_id,workspace_url} = event;
            let azureData = {"pipeline_integration_name":integration_name,
                                 "integration_id":this.state.integration_data.integration_id,
                                 "env_id":this.props.env_id,
                                 "auth_config": {
                                    "client_id": client_id,
                                    "client_secret": client_secret,
                                    "tenant_id": tenant_id
                                    // "client_id": "096f9fbc-d31d-493e-b567-01a5ce105867",
                                    // "client_secret": "KFy8Q~II4aSF8xmVZjg5V5NvwFHuiiwTVOmAFdwJ",
                                    // "tenant_id": "2b127c7d-1a0b-4e98-8e9e-d333a2c78ba7"
                                  },
                                //   "pipelineToBeProcessed":this.state.pipelineToBeProcessed,
                                  "pipeline_config": {
                                    "pipelineToBeProcessed":this.state.pipelineToBeProcessed,
                                   // "workspace_url": "synapwse.dev.azuresynapse.net",
                                    "workspace_url":workspace_url,
                                    "pipeline_name": specificPipeline,
                                  },
                                  "tenant_id": localStorage.getItem('team_id'),
                                  "pipeline_type": "synapse",
                            }
                          
            let default_message = PIPELINE_TEST_CONNECTION_MESSAGES[this.props.formType];
            this.props.closeTestConnection();
            this.props.setSubmitButton(true);
            this.props.setData(azureData, default_message);
            this.postPipelinesource(azureData);

        }

        getSchema() {
           let azureSchema = '';
           if(this.props.formType === "synapse" && this.state.isChecked === true) {
                  azureSchema = yup.object({
                        integration_name: yup.string().min(1,"Name must be at least 1 characters").required("Pipelinesource Name is a required field").test(
                              'Pipelinesource name test',
                              'invalid Pipelinesource name',        
                              function test(value) {        
                                 let val = userValidation(value,"input");       
                                 return val;
                          }
                          ),
                        workspace_url: yup.string().required('Workspace URL is a required field'),
                        pipeline_name: yup.string().optional('Pipeline Name is a required field'),
                        tenant_id: yup.string().required('Tenant Id is a required field'),
                        client_id: yup.string().required('Client Id is a required field'),
                        client_secret: yup.string().required('Client Secret is a required field'),
                  });
           }
           return azureSchema;
        }

        disableButton() {
            this.setState({hideSubmitButton: true});
        }

        ChangesecretKeyIcon() {
            let state = this.state.secretKeyIcon;
            if (state.iconName === "eye") {
                this.setState({secretKeyIcon: faEyeSlash});
                this.setState({secretKeyType: "text"})
              } else {
                this.setState({secretKeyIcon: faEye});
                this.setState({secretKeyType: "password"})
              }
        }

        render() {
            let azureSchema = this.getSchema();
            let initial_values = {
                integration_name: '', error_storage: '', secret_key: '', storage_name: '',
                client_secret: '', client_id: '',tenant_id: '', pipeline_name: '',workspace_url:'',
                refreshType: this.state.refreshType, versionFormat: '',
                versionOccurence: this.state.versionOccurence
            };

            // let edit_integration = null;
            // if(this.props.ex_integration_details !== undefined && this.props.ex_integration_details !== null){
            //     edit_integration = true;
            // }
            // else{
            //      edit_integration = false;
            // }
            let edit_integration = null;
            if (this.props.ex_integration_details !== null && this.props.ex_integration_details !== undefined){
                edit_integration = true;
            }
            else {
                edit_integration = false;
            }
            return (
                  <>
                        <Formik
                              validationSchema={azureSchema}
                              onSubmit={this.postAzureData}
                              validateOnBlur={false}
                              initialValues={ edit_integration === true ? this.state.integration_data_edit : initial_values}
                              enableReinitialize={true}
                        >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              isValid,
                        }) => (
                        <Form onSubmit={handleSubmit}>
                        <div className="form-row">
                            <Form.Group controlId="integration_name" className="form-group col-md-6">
                                <Form.Label>Pipeline Source Name <span className="text-danger">*</span></Form.Label>

                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="integration_name"
                                      value={values.integration_name === undefined ? '' : values.integration_name}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.integration_name && touched.integration_name}
                                      placeholder="Pipeline Source Name"
                                />
                                <Form.Control.Feedback type="invalid">
                                           { touched.integration_name && errors.integration_name }
                                </Form.Control.Feedback>
                            </Form.Group>
                                  <>


                                  <Form.Group controlId="workspace_url" className="col-md-6">
                                         <Form.Label>Workspace URL <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="workspace_url"
                                               value={values.workspace_url === undefined ? "" : values.workspace_url}
                                               onChange={handleChange}
                                               placeholder="Workspace URL"
                                               onBlur={handleBlur}
                                               isInvalid={errors.workspace_url && touched.workspace_url}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.workspace_url && errors.workspace_url }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    {/* <Form.Group controlId="pipeline_name" className="col-md-6">
                                         <Form.Label>Pipeline Name </Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="pipeline_name"
                                               value={values.pipeline_name === undefined ? "" : values.pipeline_name}
                                               onChange={handleChange}
                                               placeholder="Pipeline Name"
                                               onBlur={handleBlur}
                                               isInvalid={errors.pipeline_name && touched.pipeline_name}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.pipeline_name && errors.pipeline_name }
                                         </Form.Control.Feedback>
                                    </Form.Group> */}


                                    <Form.Group controlId="tenant_id" className="col-md-6">
                                         <Form.Label>Tenant Id <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="tenant_id"
                                               value={values.tenant_id === undefined ? "" : values.tenant_id}
                                               onChange={handleChange}
                                               placeholder="Tenant Id"
                                               onBlur={handleBlur}
                                               isInvalid={errors.tenant_id && touched.tenant_id}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.tenant_id && errors.tenant_id }
                                         </Form.Control.Feedback>
                                    </Form.Group>


                                    <Form.Group controlId="client_id" className="col-md-6">
                                         <Form.Label>Client Id <span className="text-danger">*</span></Form.Label>
                                         <Form.Control
                                               type="text"
                                               className="form-control"
                                               name="client_id"
                                               value={values.client_id === undefined ? "" : values.client_id}
                                               onChange={handleChange}
                                               placeholder="Client Id"
                                               onBlur={handleBlur}
                                               isInvalid={errors.client_id && touched.client_id}
                                         />
                                         <Form.Control.Feedback type="invalid">
                                           { touched.client_id && errors.client_id }
                                         </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="client_secret" className="col-md-6">
                                         <Form.Label>Client Secret <span className="text-danger">*</span></Form.Label>
                                         <InputGroup bsPrefix="icon-input-group">
                                          <i>
                                                <FontAwesomeIcon icon={faLock}/>
                                          </i>
                                                <Form.Control
                                                      type={this.state.secretKeyType}
                                                      className="form-control"
                                                      name="client_secret"
                                                      value={values.client_secret === undefined ? "" : values.client_secret}
                                                      onChange={handleChange}
                                                      placeholder="Client Secret"
                                                      onBlur={handleBlur}
                                                      isInvalid={errors.client_secret && touched.client_secret}
                                                      autoComplete={this.props.autocomplete}
                                                />
                                            <Form.Control.Feedback type="invalid">
                                           { touched.client_secret && errors.client_secret }
                                         </Form.Control.Feedback>
                                          <i className="security-eye">
                                                <FontAwesomeIcon onClick={this.ChangesecretKeyIcon} icon={this.state.secretKeyIcon} className="security-eye"/>
                                          </i>
                                          </InputGroup>
                                    </Form.Group>




<Form.Group className="col-md-6">
<Form.Label>Pipeline to be processed</Form.Label>
<div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id="allPipeline"
                                                          name="allPipeline"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          checked={this.state.pipelineToBeProcessed === "allPipeline"}
                                                          value="allPipeline"
                                                          onChange={this.handlePipelineChange}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="allPipeline">All Pipelines</Form.Label>
</div>
<div className="custom-control custom-radio custom-control-inline mt-1">
                                                    <Form.Control
                                                          type="radio"
                                                          id="specifiedPipeline"
                                                          name="specifiedPipeline"
                                                          disabled={edit_integration === true  && this.state.dataset_processed_edit === false  ? true : false}
                                                          value="specifiedPipeline"
                                                          onChange={this.handlePipelineChange}
                                                          checked={this.state.pipelineToBeProcessed === "specifiedPipeline"}
                                                          className="custom-control-input" />
                                                    <Form.Label className="custom-control-label" htmlFor="specifiedPipeline">Specified Pipelines</Form.Label>
</div>
</div>
</Form.Group>
{edit_integration === true?
<div className="col-12  partition_alert_info">
    <Alert variant="warning">
        <p className="mb-0 alert-warning-text"><i className="mr-2"><FontAwesomeIcon icon={faInfoCircle} /></i>Current Metrics and all related information will be lost on modifying existing specified pipelines</p>
    </Alert>
</div>
    :""}
{this.state.pipelineToBeProcessed === "specifiedPipeline" ? 
       <>
       <SpecificPipeline
           clickedValue={this.state.specificPipeline}
           pipelineToBeProcessed={this.state.pipelineToBeProcessed}
           partitiondatasetlist={this.state.partitiondatasetlist}
           changePipelineText={this.changePipelineText}
           removeSpecificPipeline={this.removeSpecificPipeline}
           handleExactMatch={this.handleExactMatch}
       />
       
       <a href="!#" onClick={this.addSpecificPipeline}>
       {
        this.state.pipelineToBeProcessed === "specifiedPipeline" ? 
        <><i className="mr-1"><FontAwesomeIcon icon={faPlusCircle} /></i>Add Another Pipeline</>
        :
        ""
       }
        </a>
       <hr className="clearfix w-100" />
       </>
      : ""
    }
    
{/* <div className="col-md-6 mb-3">
                                          <button type="button" onClick={this.addPartition} className="btn btn-outline-primary">
                                                <i><FontAwesomeIcon icon={faPlusCircle} /></i>Add Partition
                                          </button>
                                          <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                      <Tooltip>
                                                            Partioning Dataset.
                                                      </Tooltip>
                                                }
                                          >
                                                <sup className="ml-1"><Image width="16px" src="icon_info_circle" /></sup>
                                          </OverlayTrigger>
                                    </div> */}

                                  </>
                            <div className="checkbox col-md-12" style={{"padding": "10px 5px"}}>
                                 <Form.Label>
                                     <Form.Control
                                           type="checkbox"
                                           name="errorColumn"
                                           onChange={this.toggleChange}
                                           checked={this.state.isChecked}/>
                                     <span className="checkbox-decorator">
                                            <span className="check">
                                            </span>
                                     </span>Store Pipeline Issues and Errors in Qualdo™
                                 </Form.Label>
                            </div>
                            {this.state.isChecked === false ?
                            <Form.Group controlId="error_storage" className="col-md-6">
                                <Form.Label>Error Storage Sub Path <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                      type="text"
                                      className="form-control"
                                      name="error_storage"
                                      placeholder="Error Storage Sub Path"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      isInvalid={errors.error_storage && touched.error_storage}
                                      value={values.error_storage}/>
                                <Form.Control.Feedback type="invalid">
                                           { touched.error_storage && errors.error_storage }
                                </Form.Control.Feedback>
                             </Form.Group> : ''}
                        </div>
                        {edit_integration === true  && this.state.updateData=== true?
                        <Alert variant="danger" className='partition_alert_check'>
                        <div className="custom-control custom-checkbox custom-checkbox-lg checkbox-valign-middle">
                            <Form.Control
                                type="checkbox"
                                className="custom-control-input"
                                name="threshold"
                                id="partion-checkbox" 
                            />
                            <Form.Label className="custom-control-label pl-2" htmlFor="partion-checkbox">
                                <div className="d-sm-flex align-items-center">
                                    <Image src="icon_layer_partition" className="mr-2" />
                                    <p className="mb-0 alert-danger-text">Current Metrics and all related information will be lost on modifying existing specified pipelines. <strong>Are you sure you want to go ahead?</strong></p>
                                </div>
                            </Form.Label>
                        </div>
                    </Alert>:""}
                    <hr className="clearfix"/>
                        <div className="text-right">
                            {this.props.showCancel ?
                                <button type="button"
                                        onClick={() => this.props.ex_integration_details ?
                                            this.props.closeModal() :
                                            this.props.setPage(false)}
                                        className="btn btn-outline btn-grey btn-circle mr-2">
                                    Cancel
                                </button> : ''
                            }
                             <button type="submit"
                                     disabled={this.state.hideSubmitButton}
                                     className="btn btn-primary btn-circle mr-2">
                                 Test Connection
                             </button>
                        </div>
                        </Form>)}
                        </Formik>
                </>
            );
        }
}
export default AzureForm;