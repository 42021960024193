import React from 'react';
import Select from 'react-select';
import AzureForm from './integration_forms_pipeline/Azure/azure';
import AirflowForm from './integration_forms_pipeline/Airflow/airflow';
import AdfForm from './integration_forms_pipeline/Azure/adf';
import Composer from './integration_forms_pipeline/Google Cloud/composer'
import PipelineTestConnection from './PipelineTestConnection';
import {helpContentPipelineSources,get_document_link, getSupportedPipelineOptions} from "../../utils/common_utils";
import {getPipelineIntegrationDetail, getQualdoIps} from "../../utils/event_handling"
//import {getQualdoIps} from "../../utils/event_handling"
import Load from '../../components/loadAction';
import $ from 'jquery';
import {QUALDO_DOCUMENTATION} from "../../utils/constant";
import Glue from './integration_forms_pipeline/AWS/glue';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = data => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);


class PipelineIntegration extends React.Component {
    constructor(props) {
        super(props);
        this.setForm = this.setForm.bind(this);
        this.closeTestConnection = this.closeTestConnection.bind(this);
        this.setIntegrationData = this.setIntegrationData.bind(this);
        this.setSubmitButton = this.setSubmitButton.bind(this);
        this.setData = this.setData.bind(this);
        this.fill_details= this.fill_details.bind(this);
        this.changeHelpContent = this.changeHelpContent.bind(this);
        this.getIntegrationDetail = getPipelineIntegrationDetail.bind(this);
        //this.getIntegrationDetail = this.getIntegrationDetail.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.getQualdoIps = getQualdoIps.bind(this);

        

        const supportedPipelinesourceOptions = getSupportedPipelineOptions();

        let formType = "";
        if (supportedPipelinesourceOptions.length > 0) {
            formType = supportedPipelinesourceOptions[0].options[0].value;
        }

        this.state = {
            submit_button:false,
            testConnection:null,
            data_source_options: supportedPipelinesourceOptions,
            formType:formType,
            formType_option:{"label":"Synapse", "value":'synapse'},
            helpContentKey: formType,
            integration_data:{
                integration_type:formType,
                data_store_type: "filesystem"
            },
            ex_edit_integration_details: null,
            ip_address: null
        };
    }

    componentDidMount() {
        this.getQualdoIps();
        this.fill_details();
    }
    fill_details() {
        // Removed defaultValues as we given default value directly inside the state variable
        if (this.props.ex_integration_details !== undefined) {
            let values = this.FillFormDetails(this.props.ex_integration_details);
            //eslint-disable-next-line
            this.state.formType = values[1];
            //eslint-disable-next-line
            this.state.formType_option = values[0];
        }
    }

    setForm = (selectedOption) => {
        this.setState ({
            formType: selectedOption["value"],
            helpContentKey: selectedOption["value"]
        });
        this.closeTestConnection();
    }

    closeTestConnection() {
        this.setState ({
            testConnection: null
        });
    }

    setIntegrationData(data) {
        this.setState ({
            integration_data: data
        });
    }

    setData(integration_data,testConnection_data) {
       this.setState({testConnection:testConnection_data,
                      integration_data:integration_data,
       });
    }

    setSubmitButton(value) {
        this.setState({submit_button:value});
    }
    

    renderForm(formType) {
        if (formType === "synapse") {
            return (<AzureForm
                        integration_data={this.state.integration_data}
                        ex_integration_details={this.state.ex_edit_integration_details}
                        setData={this.setData}
                        formType={formType}
                        showCancel={this.props.showCancel}
                        showToast={this.props.showToast}
                        closeModal={this.props.closeModal}
                        setPage={this.props.setPage}
                        closeTestConnection={this.closeTestConnection}
                        setSubmitButton={this.setSubmitButton}
                        setEnvData={this.props.setEnvData}
                        env_id={this.props.env_id} 
                        autocomplete = "new-password"
                        />);
        } 
        else if(formType === "airflow"){
            return (<AirflowForm
                integration_data={this.state.integration_data}
                ex_integration_details={this.state.ex_edit_integration_details}
                setData={this.setData}
                formType={formType}
                showCancel={this.props.showCancel}
                showToast={this.props.showToast}
                closeModal={this.props.closeModal}
                setPage={this.props.setPage}
                closeTestConnection={this.closeTestConnection}
                setSubmitButton={this.setSubmitButton}
                setEnvData={this.props.setEnvData}
                env_id={this.props.env_id} 
                autocomplete = "new-password"
                />);
        }
        else if(formType === "adf"){
            return (<AdfForm
                integration_data={this.state.integration_data}
                ex_integration_details={this.state.ex_edit_integration_details}
                setData={this.setData}
                formType={formType}
                showCancel={this.props.showCancel}
                showToast={this.props.showToast}
                closeModal={this.props.closeModal}
                setPage={this.props.setPage}
                closeTestConnection={this.closeTestConnection}
                setSubmitButton={this.setSubmitButton}
                setEnvData={this.props.setEnvData}
                env_id={this.props.env_id} 
                autocomplete = "new-password"
                />);
        }
        else if(formType === "glue"){
            return (<Glue
                integration_data={this.state.integration_data}
                ex_integration_details={this.state.ex_edit_integration_details}
                setData={this.setData}
                formType={formType}
                showCancel={this.props.showCancel}
                showToast={this.props.showToast}
                closeModal={this.props.closeModal}
                setPage={this.props.setPage}
                closeTestConnection={this.closeTestConnection}
                setSubmitButton={this.setSubmitButton}
                setEnvData={this.props.setEnvData}
                env_id={this.props.env_id}
                autocomplete = "new-password"
                />);
        }
        else if(formType === "composer"){
            return (<Composer
                integration_data={this.state.integration_data}
                ex_integration_details={this.state.ex_edit_integration_details}
                setData={this.setData}
                formType={formType}
                showCancel={this.props.showCancel}
                showToast={this.props.showToast}
                closeModal={this.props.closeModal}
                setPage={this.props.setPage}
                closeTestConnection={this.closeTestConnection}
                setSubmitButton={this.setSubmitButton}
                setEnvData={this.props.setEnvData}
                env_id={this.props.env_id}
                autocomplete = "new-password"
                />);
        }
    }

    renderTest() {
        if (this.state.testConnection != null) {
            return <PipelineTestConnection integration_data={this.state.integration_data}
                                   testConnection_data={this.state.testConnection}
                                   submit_button={this.state.submit_button}
                                   closeTestConnection={this.closeTestConnection}
                                   src_page={this.props.src_page}
                                   setPage={this.props.setPage}
                                   showToast={this.props.showToast}
                                   closeModal={this.props.closeModal}
                                   existingIntegrations={this.props.existingIntegrations}
                                   setEnvData={this.props.setEnvData}
                                   env_id={this.props.env_id}
                                   ip_address={this.state.ip_address}
                />;
        }
    }


    FillFormDetails(integration_details) {
        let defaultValue, formType = null;
        const integration_type = integration_details["pipeline_type"];
        if (integration_type === undefined){
            return [null, null];
        }
        if(integration_type === "synapse") {
            formType = "synapse";
            defaultValue ={"label":"Synapse", "value":'synapse'};

        }
        if(integration_type === "airflow") {
            formType = "airflow";
            defaultValue = {"label":"Airflow", "value":'airflow'};
        }
        if(integration_type === "adf") {
            formType = "adf";
            defaultValue = {"label":"Azure Data Factory", "value":'adf'};
        }

        if(integration_type === "glue") {
            formType = "glue";
            defaultValue = {"label":"Glue", "value":'glue'};
        }

        if(integration_type === "composer") {
            formType = "composer";
            defaultValue = {"label":"Composer", "value":'composer'};
        }
        //     defaultValue = {"label":"Azure Blob", "value":'azure_blob'};
        // } else if (integration_type === "azure_data_lake") {
        //      formType = "azure_data_lake";
        //      defaultValue = {"label":'Azure Data Lake', "value":'azure_data_lake'};
        // } else if (integration_type.includes("snow_flake")) {
        //      formType = "snowflake";
        //      defaultValue = {"label":'Snowflake', "value":'snowflake'};
        // }  else if (integration_type.includes("s3")) {
        //      formType = "s3";
        //      defaultValue = {"label":'AWS S3', "value":'s3'};
        // } else if (integration_type.includes("azure_sql")) {
        //      formType = "azure_sql";
        //      defaultValue = {"label":"Azure SQLServer", "value":'azure_sql'};
        // }else if (integration_type.includes("gcp_sql")) {
        //      formType = "gcp_sql";
        //      defaultValue = {"label":"GCP SQLServer", "value":'gcp_sql'};
        // }else if (integration_type.includes("big_query")) {
        //      formType = "big_query";
        //      defaultValue = {"label":"Google BigQuery", "value":'big_query'};
        // } else if (integration_type.includes("postgresql")) {
        //      formType = "postgresql";
        //      defaultValue = {"label":"Postgres", "value":'postgresql'};
        // } else if (integration_type.includes("mysql")) {
        //      formType = "mysql";
        //      defaultValue = {"label":"MySQL", "value":'mysql'};
        // }else if (integration_type.includes("gcs")) {
        //     formType = "gcs";
        //     defaultValue = {"label":"GCS", "value":'gcs'};
        // }  else if (integration_type.includes("redshift")) {
        //     formType = "redshift";
        //     defaultValue = {"label":'AWS redshift', "value":'redshift'};
        // }else if (integration_type.includes(POWER_BI_CONNECTOR)) {
        //     formType = POWER_BI_CONNECTOR;
        //     defaultValue = {"label":'Power Bi', "value":POWER_BI_CONNECTOR};
        // }else {
        //      formType = "AWS";
        //      defaultValue = {"label":'AWS', "value":'AWS'};
        // }
        return [defaultValue, formType];
    }

    changeHelpContent(key) {
        this.setState({helpContentKey: key});
    }

    renderHelpContent() {
        let content = [];
        let info = [];

        // alert(this.state.formType);

        if(this.state.formType === "synapse" ) {
            info = helpContentPipelineSources["synapse"];
        } 
        if(this.state.formType === "airflow" ) {
            info = helpContentPipelineSources["airflow"];
        }
        if(this.state.formType === "adf" ) {
            info = helpContentPipelineSources["adf"];
        }
        if(this.state.formType === "glue" ) {
            info = helpContentPipelineSources["glue"];
        }
        else {
            info = helpContentPipelineSources["synapse"];
        }
        let k=0;
        for (k=0; k < info.length; k++) {
           /* content.push(<pre key={k+1}><code className="language-js" data-lang="js"><span className="s1">{k+1}. </span>
                   <span className="kd">
                   {info[k]}
                   {k=== (info.length-1) ? <>Refer to the <a href={get_document_link("data_source")} rel="noopener noreferrer" target="_blank"><br/>{QUALDO_DOCUMENTATION}</a> for more details.
                    </>: ""}
                   </span></code></pre>);*/
                   if(k=== (info.length-1)){
                    // content.push(<li>Make sure Qualdo's IP : <strong> {this.state.ip_address} </strong> is added to your authorised network(s).</li>);
                    content.push(<li>{info[k]} Refer to the <a href={get_document_link("data_source")} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a> for more details.</li>);
                    }else {
                        let formatstr = info[k].replace(/(?:\r\n|\r|\n)/g, '<br/>');
                        content.push(<li style={{"white-space":"pre-line"}}>{formatstr}</li>);
                    }
        }   

        if($( window ).width() > 768){
            let addheight = $('#add-datasource').height();
            $('#add-datasource_help').height(addheight - 26);
        }    
        return content;
    }

    getAddIntegrationContent(data_source_option){
        
        return (
            <>
                <div className = "portlet portlet-2col">
                    <div className="portlet-title">
                        <div className="caption">
                            <span className="text-uppercase font-dark">{this.props.src_page === "setup" ? "Add your first Pipeline Sources" : "Add Pipeline Sources"}</span>
                        </div>
                        <div className="actions"></div>
                    </div>
                    <div className = "portlet-body" >
                        <div className="row">
                            <div className={this.props.ex_integration_details !== undefined ? "col-md-12" : "col-md-8"}>
                                <div className="portlet-body"id="add-datasource">
                                    <div className="form-row">
                                        <div className="form-group col-md-6 hello">
                                            <label>Choose a Pipeline source type </label>
                                            <Select
                                                id="choose_pipelinesource_type_add"
                                                filterOption={({label}, query) => label.includes(query)}
                                                onChange={this.setForm}
                                                defaultValue={this.state.formType_option}
                                                options={data_source_option}
                                                placeholder="Choose Pipeline Sources"
                                                classNamePrefix='select-control'
                                                formatGroupLabel={formatGroupLabel}
                                            />
                                        </div>
                                    </div>
                                    { this.renderForm(this.state.formType) }
                                </div>
                            </div>
                                <div className="col-md-4">
                                    <div className="portlet border-0 mb-0">
                                        <div className="portlet-title">
                                            <div className="caption">
                                                <span className="text-uppercase">Help</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="portlet-body p-0">
                                        {/* <h4 id="tooltipupdate">
                                            <div>
                                                <code className="highlighter-rouge">Steps to enable access from your storage to Qualdo™: </code>
                                                <a className="anchorjs-link " href="#tooltipupdate"
                                                    aria-label="Anchor" data-anchorjs-icon="#" style={{paddingLeft: "0.375em"}}>
                                                </a>
                                            </div>
                                        </h4> */}
                                        {/* <figure className="highlight" style={{"height": "250px","overflow-y": "auto"}}>
                                            {this.renderHelpContent()}
                                        </figure> */}
                                        <div id="add-pipelinesource_help" class="qd-helpGuide">
                                        <div class="qd-helpGuide__content">

                                        {/* if(this.state.formType === "synapse" ) {
            info = helpContentPipelineSources["synapse"];
        } 
        if(this.state.formType === "airflow" ) {
      */}
                                             <h4 class="qd-helpGuide__title">
                                                { (this.state.formType === "synapse" ) ?
                                                 "Steps to enable access to your Azure Synapse Workspace from Qualdo™:"
                                                 :""
                                                }
                                                { (this.state.formType === "airflow" ) ?
                                                 "Steps to enable access to your Airflow Workspace from Qualdo™:"
                                                 :""
                                                }
                                                { (this.state.formType === "adf" ) ?
                                                 "Steps to enable access to your Azure Data Factory Workspace from Qualdo™:"
                                                 :""
                                                }
                                                                                                
                                                { (this.state.formType === "glue" ) ?
                                                 "Steps to enable access to your AWS Glue Workspace from Qualdo™:"
                                                 :""
                                                }
                                                </h4>
                                             <ol class="qd-helpGuide__info">
                                             {this.renderHelpContent()}
                                             </ol>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                { this.renderTest() }
             </>
        );
    }

    getEditIntegrationContent(data_source_option){

        //alert(this.state.formType_option);

        return (
            <div>
                <div className = "portlet-body">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="portlet-body">
                                <div className="portlet-body">
                                        <div className="form-row">
                                            <div className="form-group col-md-6 hello">
                                                <label>Choose a Pipeline sources type</label>
                                                <Select
                                                    id="choose_datasource_type_edit"
                                                    filterOption={({label}, query) => label.includes(query)}
                                                    onChange={this.setForm}
                                                    defaultValue={this.state.formType_option}
                                                    options={data_source_option}
                                                    placeholder="Choose Pipeline Sources Type"
                                                    formatGroupLabel={formatGroupLabel}
                                                    classNamePrefix='select-control'
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                        { this.renderForm(this.state.formType) }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                { this.renderTest() }
            </div>
        );
    }

    render() {
        const data_source_option = this.state.data_source_options;
        let editLoad = false;
        if (this.props.src_page === "edit_integration" && this.state.ex_edit_integration_details === null) {
            editLoad = true;
            this.getIntegrationDetail(this.props.ex_integration_details["pipeline_integration_id"], "synapse");
        }
        return (
            editLoad ?
                <Load/>
                :
                <>
                    {
                        this.props.ex_integration_details !== undefined ?
                            this.getEditIntegrationContent(data_source_option) :
                            this.getAddIntegrationContent(data_source_option)
                    }
                </>
        );
    }
}
export default PipelineIntegration;