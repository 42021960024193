import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCog } from '@fortawesome/free-solid-svg-icons';
import RcTooltip from 'rc-tooltip';
import VideoContent from './videoContent';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { connect } from "react-redux";
import { setStartDate, setEndDate } from "../redux/actions";
import {DISPLAY_DATE_FORMAT} from "../utils/constant";
import { checkPermissions } from '../utils/common_utils';

const renderTooltip = (props) => (
    <span {...props}>
      Need Help ?
    </span>
  );

  const renderTitleToolTip = (props) => (
    <span {...props}>
      {props}
    </span>
  );

class BasicPortlet extends React.Component {
    constructor(props) {
        super(props);
        this.changeSource = this.changeSource.bind(this);
        this.changeModel = this.changeModel.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
        this.handleTimeFilter = this.handleTimeFilter.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.state = {
            showModal: false
        }
      }

    handleClick() {
        this.setState({showModal:true});
    }

    hideModal = () => {
        this.setState({ showModal: false });
    };


    changeSource(event, obj) {
        let ChartFilter=true
        this.props.changeSource(event.value,ChartFilter);
    }

    changeModel(event, obj)
    {
        this.props.handleLoad(true);
        this.props.changeModel(event, this.props.id, this.props.title);
    }

    handleTimeFilter(event, picker) {
         let startDate = picker.startDate;
         let endDate = picker.endDate;
         let selectedValue = {"label":"", value:null};
         if (this.props.selectedDataSrc['data_source'] !== undefined && this.props.selectedDataSrc['data_source'] !== null ) {
            selectedValue = this.props.selectedDataSrc["data_source"];
//            this.props.handleLoad(true);
         } else if(this.props.selectedDataSrc['mlmodels'] !== undefined && this.props.selectedDataSrc['mlmodels'] !== null) {
            selectedValue = this.props.selectedDataSrc['mlmodels'];
         }
         this.props.setStartDate(startDate);
         this.props.setEndDate(endDate);
         this.props.changeTime(selectedValue,startDate.format('YYYY-MM-DD 00:00:00'),endDate.format('YYYY-MM-DD 23:59:59') )
    }

    renderTitle() {
        let titleToolTip = this.props.titleTooltip;
        
        if (titleToolTip === undefined || titleToolTip === null) {
            return (
                <span className="text-uppercase font-dark">{this.props.title}</span>
            );
        }

        return (
            <>
                {
                    this.props.titlePrefix !== undefined ?
                        <span className="text-uppercase font-dark">{this.props.titlePrefix}</span> :
                        ''
                }

                <RcTooltip 
                    placement="top"
                    overlay={renderTitleToolTip(titleToolTip)}
                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                >
                    <span>{this.props.toolTipHandle}</span>
                </RcTooltip>

                {this.props.shortTitle}

            </>

        );
    }

    render() {
        let ranges = {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        let disableMetricsAction = checkPermissions('Metrics')

        let srcOption = [];
        if (this.props.srcOption !== null && this.props.srcOption !== undefined) {
            srcOption = this.props.srcOption;
        }

        let startDate = this.props.disableTimeFilter ? moment().subtract(0, 'days') : moment(this.props.dataModule.startDate);
        let endDate = this.props.disableTimeFilter ? moment().subtract(0, 'days') : moment(this.props.dataModule.endDate);
        let startDateText = startDate.format(DISPLAY_DATE_FORMAT);
        let endDateText = endDate.format(DISPLAY_DATE_FORMAT);
        let defaultDataSrc = {"label":"", "value":""};
        if (this.props.selectedDataSrc !== null && this.props.selectedDataSrc !== undefined) {
            defaultDataSrc = this.props.selectedDataSrc;
        }

        /*if(this.props.selectedDataSrc !== null && this.props.selectedDataSrc !== undefined && this.props.selectedDataSrc.details !== undefined ) {
            startDateText = this.props.selectedDataSrc.details.startDate.split(" ")[0];
            endDateText = this.props.selectedDataSrc.details.endDate.split(" ")[0];
        }*/

        let defaultMLModel = {"label":"", "value":""};

        /*if (this.props.selectedDataSrc !== null && this.props.selectedDataSrc !== undefined) {
            if (this.props.selectedDataSrc.details["dropDownDataSrc"] !== null) {
                defaultDataSrc = this.props.selectedDataSrc.details["dropDownDataSrc"];
            }
            if (this.props.selectedDataSrc.details["dropDownMLModel"] !== null) {
                defaultMLModel = this.props.selectedDataSrc.details["dropDownMLModel"];
            }
        }*/
        return(
            <div className={`portlet ${this.props.className}`}>
                <div className="portlet-title">
                    <div className="caption">
                        {this.props.id === "discoverSearch" && this.props.searchText !== "" &&  this.props.searchText !== undefined ?
                        <><strong className="font-dark">{ '"' + this.props.searchText + '" '}</strong><span className="text-muted">Search results</span></>:
                        this.renderTitle()
                        }
                        {
                        this.props.mas_name === "Data Consistency" ?
                        <button type="button"
                                className="btn btn-md btn-circle btn-outline-secondary ml-2 font-weight-bold"
                                data-toggle="modal"
                                onClick={evt => this.props.showConfigureConsistency(null)}
                                disabled={disableMetricsAction}
                                >
                                <i>
                                <FontAwesomeIcon icon={faCog} />
                                </i>
                                Configure</button>
                        : ''
                        }
                    </div>
                    <div className='actions d-flex'>
                        {
                            this.props.showFilter ?
                                    <Select
                                        name="data_sources"
                                        filterOption={({label}, query) => label.includes(query)}
                                        className="mr-2"
                                        classNamePrefix='select-control'
                                        options={srcOption}
                                        id={this.props.id !== undefined ? this.props.id + "ChooseDatasource" : "ChooseDatasource"}
                                        value = {defaultDataSrc}
                                        onChange={this.changeSource}
                                        placeholder="Choose Datasource"
                                    />
                            : ''
                        }
                        {
                            this.props.showModelFilter ?
                                    <Select
                                        name="ml_model"
                                        filterOption={({label}, query) => label.includes(query)}
                                        classNamePrefix='select-control'
                                        options={srcOption}
                                        value = {defaultMLModel}
                                        onChange={this.changeModel}
                                        placeholder="Choose Model"
                                    />
                            : ''
                        }
                        {
                            this.props.showtimeFilter ?
                               (this.props.disableTimeFilter ?
                                 <div className="btn btn-datapicker reportrange mr-2">
                                            <i>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </i>
                                            <span className="ml-2 d-none d-md-inline-block">{startDateText} - {endDateText}</span>
                                 </div>
                                 :
                                 <>
                                     {/* <div className="qd-cw-col"> */}
                                        <DateRangePicker containerClass="btn btn-datapicker reportrange mr-2"
                                                         startDate={startDate}
                                                         onApply={this.handleTimeFilter}
                                                         endDate={endDate}
                                                         ranges={ranges}>
                                            <i>
                                                <FontAwesomeIcon icon={faCalendar} />
                                            </i>
                                            <span className="ml-2 d-none d-md-inline-block">{startDateText} - {endDateText}</span>
                                        </DateRangePicker>
                                     {/* </div> */}
                                 </>)
                                  : ''
                        }
                        {this.props.showFilter || this.props.showModelFilter || this.props.title === "Attribute Drifts" || this.props.showHelpSection ?
                            <div onClick={this.handleClick}>
                                <RcTooltip 
                                    placement="top" 
                                    overlay={renderTooltip}
                                    arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                >
                                    <span className="help-icon" onClick={this.handleClick} data-toggle="modal" data-target="#model__help"><i><FontAwesomeIcon icon={faQuestionCircle}/></i></span>
                                </RcTooltip>
                            </div> : ''
                        }
                        {
                            this.state.showModal ?
                            <VideoContent
                                show={this.state.showModal}
                                onHide={this.hideModal}
                                video_url={this.props.video_url}
                                name={this.props.id === "discoverSearch" ? "Discover" : this.props.title}
                            /> : ""
                        }
                    </div>
                </div>
                <div className={this.props.bodyClassName !== undefined ? `portlet-body ${this.props.bodyClassName}`:"portlet-body"} id={this.props.id}>
                    {this.props.content}
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return state;
}


export default connect(mapStateToProps, { setStartDate, setEndDate })(BasicPortlet);