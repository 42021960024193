import React from 'react';
import Image from '../../components/image';
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar,faCheck, faSearch} from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
// import BasicPortlet from '../../components/basicPortlet';
// import ChartBody from '../../components/chartBody';
import $ from 'jquery';
import * as d3Module from 'd3';
import d3Tip from 'd3-tip';
import {event as currentEvent} from 'd3-selection';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const d3 = {
  ...d3Module,
  tip: d3Tip
};



class LineageV1 extends React.Component {
    constructor(props) {
        super(props);  
        let startDate = moment().subtract(10, 'days');
        let endDate =  moment().utc().endOf('day');  
        this.createModal = this.createModal.bind(this);
        this.cancelModal = this.cancelModal.bind(this);  
        this.state = {
          startDate: startDate,
          endDate: endDate,
          openModal: false
        }
    }  

    cancelModal() {
      this.setState({openModal: false});
   }

   createModal() {
      this.setState({openModal: true});
   }

   reset() {
    var svg = d3.select("#graphWrapper");
    let zoom = d3.zoom()
      .on("zoom", function (d, i) {
        let g =d3.select("#graphWrapper g")
         g.attr("transform", currentEvent.transform)
    });
    zoom(svg);

       d3.select('svg')
        .transition()
        .call(zoom.scaleTo, 1);
      
   }

   showGraph(){
    $(".lg-tooltip").hide(); // On very first line of scripts.js file


var icon_job = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<g id="icon_job" transform="translate(-8449 726)">
<rect id="Rectangle_1919" data-name="Rectangle 1919" width="24" height="24" transform="translate(8449 -726)" fill="none"/>
<path id="icon_tree" d="M8.359,8.383h1.8v1.785H5.564a1.025,1.025,0,0,0-1.032,1.011v1.937H2.789A1.032,1.032,0,0,0,1.75,14.137v4.591A1.031,1.031,0,0,0,2.789,19.75H7.46A1.034,1.034,0,0,0,8.5,18.728V14.137a1.032,1.032,0,0,0-1.041-1.022H5.72V11.336H15.78v1.779H14.038A1.032,1.032,0,0,0,13,14.137v4.591a1.032,1.032,0,0,0,1.041,1.022h4.673a1.032,1.032,0,0,0,1.039-1.022V14.137a1.032,1.032,0,0,0-1.041-1.022H16.973V11.182a1.025,1.025,0,0,0-1.032-1.013H11.347V8.383h1.8a.974.974,0,0,0,.982-.967v-4.7a.977.977,0,0,0-.984-.969H8.359a.977.977,0,0,0-.982.967v4.7a.977.977,0,0,0,.984.969Z" transform="translate(8450.25 -724.75)" fill="currentColor" fill-rule="evenodd"/>
</g>
</svg>
`

var icon_task = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
<defs>
<clipPath id="clip-path">
  <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)" fill="#fff"/>
</clipPath>
</defs>
<g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
<path id="Path_8121" data-name="Path 8121" d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z" transform="translate(1 1)" fill="currentColor"/>
</g>
</svg>
`


var icon_dataset = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<g id="icon_datasouce" transform="translate(-8523 726)">
<rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24" transform="translate(8523 -726)" fill="none"/>
<path id="database-solid" d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z" transform="translate(8527 -723)" fill="currentColor"/>
</g>
</svg>`



var icon_dataset_icon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<g id="icon_dataset" transform="translate(-8487 726)">
<rect id="Rectangle_1920" data-name="Rectangle 1920" width="24" height="24" transform="translate(8487 -726)" fill="none"/>
<path id="layer-group-solid" d="M.434,5.2,8.623,8.911a.9.9,0,0,0,.748,0L17.561,5.2a.785.785,0,0,0,0-1.407L9.372.074a.9.9,0,0,0-.748,0L.434,3.789A.786.786,0,0,0,.434,5.2ZM17.561,8.3l-2.042-.926L9.836,9.95a2.026,2.026,0,0,1-1.678,0L2.476,7.374.434,8.3a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712a.785.785,0,0,0,0-1.406Zm0,4.493-2.034-.922L9.836,14.45a2.026,2.026,0,0,1-1.678,0l-5.69-2.579-2.035.922a.785.785,0,0,0,0,1.406l8.189,3.712a.9.9,0,0,0,.748,0l8.19-3.712A.785.785,0,0,0,17.561,12.793Z" transform="translate(8490.003 -722.993)" fill="currentColor"/>
</g>
</svg>`

var icon_database = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
<g id="icon-database" transform="translate(-1786 -116)">
<path id="database-solid_1_" data-name="database-solid (1)" d="M15.75,2.813V4.5c0,1.554-3.526,2.813-7.875,2.813S0,6.054,0,4.5V2.813C0,1.259,3.526,0,7.875,0S15.75,1.259,15.75,2.813ZM13.823,7.548A6.877,6.877,0,0,0,15.75,6.543v3.582c0,1.554-3.526,2.813-7.875,2.813S0,11.679,0,10.125V6.543A6.787,6.787,0,0,0,1.927,7.548a18.343,18.343,0,0,0,5.948.889A18.343,18.343,0,0,0,13.823,7.548ZM0,12.168a6.787,6.787,0,0,0,1.927,1.005,18.343,18.343,0,0,0,5.948.889,18.343,18.343,0,0,0,5.948-.889,6.877,6.877,0,0,0,1.927-1.005v3.02C15.75,16.741,12.224,18,7.875,18S0,16.741,0,15.188Z" transform="translate(1790 119)" fill="currentColor"/>
<rect id="Rectangle_4" data-name="Rectangle 4" width="24" height="24" transform="translate(1786 116)" fill="none"/>
</g>
</svg>`

var icon_asterisk = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
 <g id="Group_1" data-name="Group 1" transform="translate(-973 -626)">
   <path id="Path_4" data-name="Path 4" d="M13.895,9.7l-3.7-2.033,3.7-2.033a.625.625,0,0,0,.24-.86l-.507-.878a.625.625,0,0,0-.865-.222L9.151,5.861l.09-4.223A.625.625,0,0,0,8.617,1H7.6a.625.625,0,0,0-.625.638l.09,4.223L3.456,3.671a.625.625,0,0,0-.865.222l-.507.878a.625.625,0,0,0,.24.86l3.7,2.033L2.324,9.7a.625.625,0,0,0-.24.86l.507.878a.625.625,0,0,0,.865.222l3.612-2.19-.09,4.223a.625.625,0,0,0,.625.638H8.617a.625.625,0,0,0,.625-.638l-.09-4.223,3.612,2.19a.625.625,0,0,0,.865-.222l.507-.878A.625.625,0,0,0,13.895,9.7Z" transform="translate(973 626)" fill="currentColor"/>
   <rect id="Rectangle_4" data-name="Rectangle 4" width="16" height="16" transform="translate(973 626)" fill="none"/>
 </g>
</svg>`

var zoom;

var width = $("#outputContainerFlowchart").width();
var height = $("#outputContainerFlowchart").height();
var graphWrapper = $("#outputContainerFlowchart").append("<svg height='"+height+"' width='"+width+"' id='graphWrapper'></svg>");

//The SVG Container
var svgContainer = d3.select("#graphWrapper").attr("cursor", "grabbing");

var g = svgContainer.append("g")
  .attr("width", width).attr("height", height)
  .attr("class", "everything");


  
/*

var rect1 = g.append("rect")
                        .attr("x", 20)
                        .attr("y", 10)
                        .attr("width", 150)
                        .attr("height", 30);

var rect2 = g.append("rect")
                        .attr("x", 300)
                        .attr("y", 300)
                        .attr("width", 300)
                        .attr("height", 300);

var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;

var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;

var midx = ((x2 - x1) / 2) + x1

*/
var arrow = g.append("defs").append("marker")
                .attr("id", "arrow")
                .attr("viewBox", "0 -5 10 10")
                .attr("refX", 0)
                .attr("refY", 0)
                .attr("markerWidth", 5)
                .attr("markerHeight", 5)
                .attr("orient", "auto")
                .append("svg:path")
                        .attr("d", "M0,-5L10,0L0,5")
                        .style("fill","#CED4DA");
                        
                        
          // var arrow = g.append("defs").append("marker")
          //       .attr("id", "arrow")
          //       .attr("viewBox", "0 0 12 12")
          //       .attr("refX", 6)
          //       .attr("refY", 6)
          //       // .attr("markerWidth", 12)
          //       // .attr("markerUnits", "strokeWidth")
          //       // .attr("markerHeight", 12)
          //       .attr("orient", "auto")
          //       .append("svg:path")
          //       .attr("fill", "none")
          //       .attr("d", "M2,2 L10,6 L2,10 L6,6 L2,2")
          //       .attr("fill", "#CED4DA");
          //       // .style("fill","#CED4DA");
                /*

//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
             

             

var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  


//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");  
                        //.style("marker-mid", "url(#arrow)");



                        */

var graph = {
"nodes": [{

  "id": "ExternalAPI1",
  "type":1,
  "group": "ExternalAPI1",
  "task": [],
  "Dataset": []
},
{
  "id": "Data-ETL",
  "type":2,
  "group": "dummyPrdVip",
  "task": [{
    "id": "Ingest",
    "name": "Ingest"
  },
  {
    "id": "Analyse",
    "name": "Analyse"
  },
  {
    "id": "Check",
    "name": "Check"
  },
  {
    "id": "Integrity",
    "name": "Integrity"
  },
  {
    "id": "Report",
    "name": "Report"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Customer Dataset"
  },
  {
    "id": "taskname",
    "name": "Sales Dataset"
  },
  {
    "id": "taskname",
    "name": "Invoice Dataset"
  },
  {
    "id": "taskname",
    "name": "Inventory Dataset"
  }
  ]
},
{
  "id": "Analyse-Api-Data",
  "type":2,
  "group": "dummyPrdLtmp",
  "task": [{
    "id": "taskname",
    "name": "Latencies"
  },
  {
    "id": "taskname",
    "name": "Datasize"
  },
  {
    "id": "taskname",
    "name": "Error code"
  },
  {
    "id": "taskname",
    "name": "Error message"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Sales Stg Dataset"
  },
  {
    "id": "taskname",
    "name": "Invoice Stg Dataset"
  },
  {
    "id": "taskname",
    "name": "Inventory Stg Dataset"
  }
  ]

},

{

"id": "ExternalApi2",
"type":1,
"group": "ExternalApi2",
"task": [],
"Dataset": []
},

{

"id": "MYSQL",
"type":1,
"group": "MYSQL",
"task": [],
"Dataset": []
},
{
  "id": "SQL-DB-operation",
  "type":2,
  "group": "dummyPrdPool",
  "task": [{
    "id": "taskname",
    "name": "Fetch Latest Data"
  },
  {
    "id": "taskname",
    "name": "Analyse & Transform"
  },
  {
    "id": "taskname",
    "name": "Update In Table"
  },
  {
    "id": "taskname",
    "name": "List Table"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Competitor Dataset"
  },
  {
    "id": "taskname",
    "name": "Customer Dataset"
  }
  ]
},
{
  "id": "Data-Insight",
  "type":2,
  "group": "dummyPrdPool1",
  "task": [{
    "id": "taskname",
    "name": "Read data"
  },
  {
    "id": "taskname",
    "name": "Transform"
  },
  {
    "id": "taskname",
    "name": "Notebook Operation"
  },
  {
    "id": "taskname",
    "name": "Validate"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Competitor Dataset"
  },
  {
    "id": "taskname",
    "name": "Customer Dataset"
  },
  {
    "id": "taskname",
    "name": "Sales Dataset"
  }
  ]

},
{
  "id": "Sales",
  "type":2,
  "group": "dummy2PrdVip",
  "task": [{
    "id": "taskname",
    "name": "Analyse"
  },
  {
    "id": "taskname",
    "name": "Check"
  },
  {
    "id": "taskname",
    "name": "Report"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Sales Prod Dataset"
  }
  ]

},
{
"id": "Analytics",
"type":1,
"group": "external",
"task": [],
"Dataset": []
},
{
  "id": "Inventory",
  "type":2,
  "group": "Corporate",
  "task": [{
    "id": "taskname",
    "name": "Analyse"
  },
  {
    "id": "taskname",
    "name": "Check"
  },
  {
    "id": "taskname",
    "name": "Report"
  }
  ],
  "Dataset": [{
    "id": "Dataset",
    "name": "Inventory Prod Dataset"
  }
  ]

}
],
"links": [{
  "source": "ExternalAPI1",
  "target": "Data ETL",
  "value": 1
},
{
  "source": "Data ETL",
  "target": "Analyse-Api-Data",
  "value": 1
}
  ,
{
  "source": "SQL-DB-operation",
  "target": "Sales",
  "value": 1
}
  ,
{
  "source": "Analyse-Api-Data",
  "target": "SQL-DB-operation",
  "value": 1
}
  ,
{
  "source": "SQL-DB-operation",
  "target": "Data-Insight",
  "value": 1
}
  ,
{
  "source": "Data-Insight",
  "target": "JExternalAPI1ob5",
  "value": 1
}
]
}

let rectWidth = 280;
let rectHeight = 440;
var colors = {
dummyprodVip: "#1eb543",
dummyaccVip: "#30cc30",
dummyprodLtmp: "#3be264",
dummyaccLtmp: "#74e874",
dummyprodPool: "#82ffa0",
dummyaccPool: "#bcffbc",
dummy2prodBVip: "#ff8438",
dummy2accAVip: "#becc6a",
dummy2prodBLtmp: "#ffac39",
dummy2accALtmp: "#d9e590",
dummy2prodBPool: "#ffdb3a",
dummy2accAPool: "#f6ffc1",
}

 g.selectAll("foreignObject")
  g.append("xhtml:div")
   .attr("id","tooltipHeader")
   .html(function (d) {
    return `<div>Testing</div>`;
    /*return `<span  class="lg-tooltip">
<div class="lg-tooltip__header">
  <p><label>Pipeline:</label>Analyse-Api-Data</p>
  <p><label>Run Status:</label>
    <i class="status-icon">
      <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
        <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_8128" data-name="Path 8128"
          d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
          fill="#ff4934" />
      </svg>
    </i>
    Failed
  </p>
  <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
</div>
</span>`; */
  })
//g.append("foreignObject").attr("x",100).attr("y",100).attr("width","100").attr("height",100).append("xhtml:div").html(function (d, i) { "<div>Testing</div>" })


var nodes = g.selectAll("foreignObject")
  .data(graph.nodes)
  .enter()
  .append("foreignObject")
  .attr("x", function (d,i) {
    if(i==0 || i==1){
      return  rectWidth+(i*350);
    }
    else if(i==2){
      return  rectWidth+(2*350);
    }
    else if(i==3){
      return  rectWidth+(2*350);
    }
    else if(i==4){
      return  rectWidth+(2*350);
    }
    else if(i==5){
      return  rectWidth+(2*350);
    }
    else if(i==6){
      return  rectWidth+(2*350);
    }
    else if(i==7){
      return  rectWidth+(3*350);
    }
    else if(i==8){
      return  rectWidth+(4*350);
    }
    else if(i==9){
      return  rectWidth+(3*350);
    }
    else {
      return  rectWidth+(i*350);
    }
  })
  .attr("y", function (d,i) {
    if(d.type==1){
      if(i==3){
        return rectHeight+105;
      }
      if(i==4){
        return rectHeight+205;
      }
      else{
        return rectHeight+172;
      }
    }else {

      if(i==2){
        return rectHeight-rectHeight;
      } 
      else if(i==5){
        return 2*rectHeight;
      }
      else if(i==6){
        return 3*rectHeight;
      }
      else if(i==7){
        return rectHeight-rectHeight;
      }
      else if(i==8){
        return 13*rectHeight;
      }

      else {

      if(i%2){
        return rectHeight;
      }else {
        return rectHeight;
      }

    }

    }
  })
  .attr("width", rectWidth)
  .attr("height", function (d) {

    if(d.type==1){
      return 50
    }else {
      return rectHeight;
    }

  })
  .attr("class", function (d) {
    return ("graphNode " + d.group)
  })
  .style("background-color", function (d) {
    return colors[d.group];
  })
  .append("xhtml:div")
 // .classed("lg-panel", true)
// .classed("lg-panel", true)
   .attr('class', function (d) {
    if(d.type==2){
      return 'lg-panel';
    }else {
      return '';
    }
  })
  .html(function (d, i) {
    //console.log(d.id);

    if(d.type==2){
    let nodeHtml = "";
    let strippedId = d.id.replace(/\/.*\//, "")
    if (d.virtual !== undefined) {
      nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
    } else if (d.policy !== undefined) {
      nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"

    } else if (d.pool !== undefined) {
      nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
    }

    let task = '<div class="lg-list__heading">Task</div>';
    for (let looptask = 0; looptask < d.task.length; looptask++) {
      task += '<a href="#" data-type="task" id="' + d.id + '_task' + looptask + '" data-classname = "'+d.task[looptask]["name"]+'" class="highlights jobstooltiptrigger ' + d.task[looptask]["name"] + '"><div class="lg-list__item tltip highlights ' + d.task[looptask]["name"] + '"><div class="lg-list__label tooltipTask">' + icon_task + '&nbsp;' + d.task[looptask]["name"] + '</div></div></a>';
    }

    let dataset = '<div class="lg-list__heading">Dataset</div>';
    for (let looptask = 0; looptask < d.Dataset.length; looptask++) {
      dataset += '<a href="#" data-type="dataset" id="' + d.id + '_dataset' + looptask + '" data-classname = "'+d.Dataset[looptask]["name"]+'"  class="highlights jobstooltiptrigger ' + d.Dataset[looptask]["name"] + '"><div class="lg-list__item tltip highlights ' + d.Dataset[looptask]["name"] + '"><div class="lg-list__label tooltipDataset">' + icon_dataset_icon + '&nbsp;' + d.Dataset[looptask]["name"] + '</div></div></a>';
    }
    nodeHtml = "<div><div>Task</div>" + d.partition + "</div>"
    if (d.task.length > 0 && d.Dataset.length > 0) {
      return "<div class='lg-panel-header tltip tooltipHeading'><a  data-type='tooltip' id='" + d.id + '_jobs' + i + "' href='#' class='highlights jobstooltiptrigger'><span class='lg-panel-title'>" + icon_job + strippedId + "</span>" + "</a></div><div class='lg-panel-body'>" + '<div class="lg-list">' + task + '</div><div class="lg-list">' + dataset + '</div></div>';
    } else {
      return "<div class='lg-panel lg-panel--source'><label>" + icon_database + '&nbsp;' + strippedId + '</label></div>';

    }
  } 
  else{
      let strippedId = d.id.replace(/\/.*\//, "")
      return "<div class='lg-panel lg-panel--source'><label>" + icon_asterisk +  strippedId + '</label></div>';
  }
  })
  .on('mouseover', function(d) {

    return;

    let pos = d3.select(this).node().getBBox().width;
            console.log(pos);

    $(document).mousemove(function(e) {
 //    console.log(e.pageX);
     //console.log(e.pageY);
    });

    
    return;





    
    //.getBoundingClientRect()
    return;

    var fo = g.append('foreignObject')
                    .attr({
                        'x':200,
                        'y': 200,
                        'width': 200,
                        'class': 'svg-tooltip'
                    });
                var div = fo.append('xhtml:div')
                    .append('div')
                    .attr({
                        'class': 'tooltip'
                    });
                div.append('p')
                    .attr('class', 'lead')
                    .html('Holmes was certainly not a difficult man to live with.');
                div.append('p')
                    .html('He was quiet in his ways, and his habits were regular. It was rare for him to be up after ten at night, and he had invariably breakfasted and gone out before I rose in the morning.');

      
  })


  
 g.append("foreignObject")
 .attr("id", "jobstooltip")
// .attr("width", 480)
// .attr("height", 500)
.append("xhtml:div")
.style("font", "14px 'Helvetica Neue'")
.html(`<span  class="lg-tooltip">
<div class="lg-tooltip__header">
  <p><label>Pipeline:</label>Analyse-Api-Data</p>
  <p><label>Run Status:</label>
    <i class="status-icon">
      <svg id="icon_close" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24">
        <path id="Path_8127" data-name="Path 8127" d="M0,0H24V24H0Z" fill="none" />
        <path id="Path_8128" data-name="Path 8128"
          d="M12,2A10,10,0,1,0,22,12,9.991,9.991,0,0,0,12,2Zm5,13.59L15.59,17,12,13.41,8.41,17,7,15.59,10.59,12,7,8.41,8.41,7,12,10.59,15.59,7,17,8.41,13.41,12Z"
          fill="#ff4934" />
      </svg>
    </i>
    Failed
  </p>
  <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
</div>
</span>`);


g.append("foreignObject")
 .attr("id", "tasktooltip")
// .attr("width", 480)
// .attr("height", 500)
.append("xhtml:div")
.style("font", "14px 'Helvetica Neue'")
.html(`  <div id="tooltipTask" class="lg-tooltip">
<div class="lg-tooltip__header">
  <p><label>Task:</label>taskname1</p>
  <p><label>Start time:</label>Apr 26, 2023 02:01:05 PM</p>
</div>
<div class="lg-tooltip__body">
  <div class="lg-tooltip__section">
    <dl>
      <dt>Read from</dt>
      <dd>
        <i>
          <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <g id="icon_datasouce" transform="translate(-8523 726)">
              <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                transform="translate(8523 -726)" fill="none" />
              <path id="database-solid"
                d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                transform="translate(8527 -723)" fill="#898989" />
            </g>
          </svg>
        </i>
        SAP - Daily Global Sales Extract
      </dd>
    </dl>
    <dl>
      <dt>Write to</dt>
      <dd>
        <i>
          <svg xmlns="httdl://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
            <g id="icon_datasouce" transform="translate(-8523 726)">
              <rect id="Rectangle_1921" data-name="Rectangle 1921" width="24" height="24"
                transform="translate(8523 -726)" fill="none" />
              <path id="database-solid"
                d="M15.75,2.571V4.179c0,1.416-3.527,2.571-7.875,2.571S0,5.595,0,4.179V2.571C0,1.155,3.527,0,7.875,0S15.75,1.155,15.75,2.571Zm0,3.616V9.8c0,1.416-3.527,2.571-7.875,2.571S0,11.22,0,9.8V6.188C1.692,7.353,4.789,7.9,7.875,7.9S14.058,7.353,15.75,6.188Zm0,5.625v3.616C15.75,16.845,12.223,18,7.875,18S0,16.845,0,15.429V11.813c1.692,1.165,4.789,1.708,7.875,1.708S14.058,12.978,15.75,11.813Z"
                transform="translate(8527 -723)" fill="#898989" />
            </g>
          </svg>
        </i>
        S3 - Raw Daily Global Sales Extract
      </dd>
    </dl>
  </div>
</div>
</div>`);


g.append("foreignObject")
 .attr("id", "datsettooltip")
// .attr("width", 480)
// .attr("height", 500)
.append("xhtml:div")
.style("font", "14px 'Helvetica Neue'")
.html(` <span id="tooltipDataset" class="lg-tooltip">
<div class="lg-tooltip__header">
  <p><label>Dataset:</label>Dataset1</p>
</div>
<div class="lg-tooltip__body">
  <div class="lg-tooltip__section">
    <dl>
      <dt>Read tasks</dt>
      <dd>
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
            viewBox="0 0 24 24">
            <defs>
              <clipPath id="clip-path">
                <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                  fill="#fff" />
              </clipPath>
            </defs>
            <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
              <path id="Path_8121" data-name="Path 8121"
                d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                transform="translate(1 1)" fill="#898989" />
            </g>
          </svg>
        </i>
        load_prior_day_na_sales_to_databricks_bronze
      </dd>
    </dl>
    <dl>
      <dt>Written Tasks</dt>
      <dd>
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
            viewBox="0 0 24 24">
            <defs>
              <clipPath id="clip-path">
                <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                  fill="#fff" />
              </clipPath>
            </defs>
            <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
              <path id="Path_8121" data-name="Path 8121"
                d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                transform="translate(1 1)" fill="#898989" />
            </g>
          </svg>
        </i>
        extract_regional_sales_to_s3
      </dd>
    </dl>
  </div>
  <div class="lg-tooltip__section">
    <dl>
      <dt>1 Missing Operation</dt>
      <dd>
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18" height="18"
            viewBox="0 0 24 24">
            <defs>
              <clipPath id="clip-path">
                <path id="Path_8122" data-name="Path 8122" d="M0,0H24V24H0Z" transform="translate(0 0.875)"
                  fill="#fff" />
              </clipPath>
            </defs>
            <g id="icon_task" transform="translate(0 -0.875)" clip-path="url(#clip-path)">
              <path id="Path_8121" data-name="Path 8121"
                d="M17.869,7.744,13.522,3.4a1.8,1.8,0,0,0-1.269-.522H5.8a1.8,1.8,0,0,0-1.791,1.8L4,19.075a1.8,1.8,0,0,0,1.791,1.8H16.6a1.805,1.805,0,0,0,1.8-1.8V9.022A1.806,1.806,0,0,0,17.869,7.744ZM9.607,16.636,7.7,14.728a.9.9,0,1,1,1.269-1.269l1.269,1.269,3.186-3.186a.9.9,0,0,1,1.269,1.269l-3.816,3.816A.888.888,0,0,1,9.607,16.636ZM13,9.175a.9.9,0,0,1-.9-.9V4.225l4.95,4.95Z"
                transform="translate(1 1)" fill="#898989" />
            </g>
          </svg>
        </i>
        Data wasn't written by task <strong>extract_regional_sales_to_s3</strong>
      </dd>
    </dl>
  </div>
</div>
</span>`);


var rect1 = g.selectAll(".ExternalAPI1")
var rect2 = g.selectAll(".dummyPrdVip")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");  

var rect1 = g.selectAll(".dummyPrdVip")
var rect2 = g.selectAll(".dummyPrdLtmp")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");  


var rect1 = g.selectAll(".dummyPrdVip")
var rect2 = g.selectAll(".dummyPrdPool")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");  


var rect1 = g.selectAll(".dummyPrdVip")
var rect2 = g.selectAll(".dummyPrdPool1")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)"); 




var rect1 = g.selectAll(".dummyPrdLtmp")
var rect2 = g.selectAll(".dummy2PrdVip")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)"); 


                        
var rect1 = g.selectAll(".ExternalApi2")
var rect2 = g.selectAll(".dummy2PrdVip")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");
                        
var rect1 = g.selectAll(".MYSQL")
var rect2 = g.selectAll(".dummy2PrdVip")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");                            



var rect1 = g.selectAll(".dummy2PrdVip")
var rect2 = g.selectAll(".external")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");
                        
                        
var rect1 = g.selectAll(".dummyPrdLtmp")
var rect2 = g.selectAll(".Corporate")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");


var rect1 = g.selectAll(".dummyPrdLtmp")
var rect2 = g.selectAll(".Corporate")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");
                    

                        
var rect1 = g.selectAll(".dummyPrdPool")
var rect2 = g.selectAll(".Corporate")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");


var rect1 = g.selectAll(".dummyPrdPool1")
var rect2 = g.selectAll(".Corporate")
var x1 = parseInt(rect1.attr("x")) + parseInt(rect1.attr("width"));
var y1 = parseInt(rect1.attr("y")) + parseInt(rect1.attr("height")) / 2;
var x2 = parseInt(rect2.attr("x"));
var y2 = parseInt(rect2.attr("y")) + parseInt(rect2.attr("height")) / 2;
var midx = ((x2 - x1) / 2) + x1
//The data for our line
var lineData = [{ "x": x1,   "y": y1},  
             { "x": midx,  "y": y1}, 
             { "x": midx,  "y": y2}, 
             { "x": x2-10,  "y": y2}];
var lineFunction = d3.line()
.x(function(d) { return d.x; })
.y(function(d) { return d.y; })
.curve(d3.curveBasis);  
//The line SVG Path we draw
var lineGraph = g.append("path")
                        .attr("d", lineFunction(lineData))
                        .attr("stroke", "#CED4DA")
                        .attr("stroke-width", 2)
                        .attr("fill", "none")
                        .attr("marker-end","url(#arrow)");

$(document).on('click', 'a.highlights', function () {
$(".highlights").removeClass("related-active").removeClass("active");
$("."+$(this).attr("data-classname")).addClass("related-active") 
$(this).find('div').removeClass("related-active").addClass("active");

});


$(document).on('mouseout', 'a.jobstooltiptrigger', function () {
// $("#tooltipHeader,#tooltipTask,#tooltipDataset,#jobstooltip,#tasktooltip").hide();
$("#jobstooltip,#tasktooltip,#datsettooltip").hide();
});


$(document).on('mouseover', 'a.jobstooltiptrigger', function (event) {
var elems = this;
var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"
var lefttooltip = $("#" + elems.id).offset().left;
var toptooltip = $("#" + elems.id).offset().top;


var newposx = parseInt($("#" + elems.id).closest(".graphNode").attr("x"))+parseInt($("#" + elems.id).closest(".graphNode").attr("width"));
var newposy = parseInt($("#" + elems.id).closest(".graphNode").attr("y"))

if(tooltip == "tooltip") {

  $("#tooltipHeader").show();
  $("#jobstooltip").show();

  $("#jobstooltip").attr("y", newposy-47);
  $("#jobstooltip").attr("x", newposx);
  $("#jobstooltip").attr("width", "300");
  $("#jobstooltip").attr("height", "300");

  // $("#tooltipHeader").css("top", toptooltip + "px");
  // $("#tooltipHeader").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
}
else if(tooltip == "task") {
  $("#tasktooltip").show();
  $("#tasktooltip").attr("y", newposy);
  $("#tasktooltip").attr("x", newposx);
  $("#tasktooltip").attr("width", "300");
  $("#tasktooltip").attr("height", "300");
  // $("#tooltipTask").css("top", toptooltip + "px");
  // $("#tooltipTask").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
}
else if(tooltip == "dataset") {


  $("#datsettooltip").show();
  $("#datsettooltip").attr("y", newposy+50);
  $("#datsettooltip").attr("x", newposx);
  $("#datsettooltip").attr("width", "400");
  $("#datsettooltip").attr("height", "300");

}

});


$(document).on('click', 'a.jobstooltiptrigger', function () {
var elems = this;
var tooltip = $("#" + elems.id).attr("data-type"); // will return the string "123"
var lefttooltip = $("#" + elems.id).offset().left;
var toptooltip = $("#" + elems.id).offset().top;
if(tooltip == "tooltip") {
  $("#tooltipHeader").show();
  $("#tooltipHeader").attr("y", toptooltip);
  $("#tooltipHeader").attr("x", (lefttooltip + $(".lg-panel").width()));
  $("#tooltipHeader").attr("width", "200");
  $("#tooltipHeader").attr("height", "200");


  // $("#tooltipHeader").css("top", toptooltip + "px");
  // $("#tooltipHeader").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
}
else if(tooltip == "task") {
  $("#tooltipTask").show();
  $("#tooltipTask").css("top", toptooltip + "px");
  $("#tooltipTask").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
}
else if(tooltip == "dataset") {
  $("#tooltipDataset").show();
  $("#tooltipDataset").css("top", toptooltip + "px");
  $("#tooltipDataset").css("left", (lefttooltip + $(".lg-panel").width()) + "px");
}

});



//add zoom capabilities
var svg = d3.select("#graphWrapper");
zoom = d3.zoom()
  .on("zoom", zoom_actions);
zoom(svg);

//Zoom functions
function zoom_actions() {
  g.attr("transform", currentEvent.transform)
}


function zoomIn() {

console.log(zoom);

d3.select('svg')
.transition()
.call(zoom.scaleBy, 2);
}

function zoomOut() {

d3.select('svg')
.transition()
.call(zoom.scaleBy, 0.5);
}

function resetZoom() {
d3.select('svg')
.transition()
.call(zoom.scaleTo, 1);
}

function center() {
d3.select('svg')
.transition()
.call(zoom.translateTo, 0.5 * width, 0.5 * height);
}

function panLeft() {
d3.select('svg')
.transition()
.call(zoom.translateBy, -50, 0);
}

function panRight() {
d3.select('svg')
.transition()
.call(zoom.translateBy, 50, 0);
}

function resetSelection() {
$(".highlights").removeClass("related-active");
$(".highlights").removeClass("active");
}
}

componentDidMount() {
    setTimeout(
        function() {
            this.showGraph();
        }
        .bind(this),
        500
    );
}


    render() { 
      let ranges = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
   };
        return (
          <>
          {/* Modal START */}
          <Modal show={this.state.openModal}  onHide={this.cancelModal} style={{"top":"15%"}}>
          <Modal.Header closeButton className="border-0 justify-content-center d-block">
              <h5 className="modal-title">
                  Data Relationship Filter</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close"/>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Environment <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Environment" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "Stage", "value":1}
                                  ]}
                        defaultValue={{ "label": "Stage", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Data Source <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select DataSource" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "retail-data", "value":1}
                                  ]}
                         defaultValue={{ "label": "retail-data", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Dataset <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Schemas" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "branch", "value":1},
                                    {"label": "branch_group_mst", "value":2},
                                    {"label": "item_category", "value":3},
                                    {"label": "item_master", "value":4}, 
                                    {"label": "sales_dataset", "value":5},
                                    {"label": "stock_ledger", "value":6}
                                  ]}
                         defaultValue={{ "label": "branch", "value":1 }}
                      />
              </div>             
            </div>

            <div className="row">
              <div className="form-group col-md-12">
                <label className="form-label">Column <span className="text-danger">*</span></label>
                <Select
                         placeholder="Select Column" classNamePrefix='select-control'
                         options={[                                  
                                    {"label": "Column 1", "value":1},                                  
                                    {"label": "Column 2", "value":2},
                                    {"label": "Column 3", "value":3},
                                    {"label": "Column 4", "value":4},
                                    {"label": "Column 5", "value":5},
                                    {"label": "Column 6", "value":6}
                                  ]}
                      />
              </div>             
            </div>

            <div className="form-group mb-0 mt-2">
                            <span className="switch switch-sm switch-info">
                                <label className="m-0">
                                    Include PII
                                    <input className="form-check-input" disabled checked={true} type="checkbox"/>
                                    <span className="m-0 mx-2"></span>
                                </label>
                            </span>
                        </div>
          </Modal.Body>
          <Modal.Footer className=" border-0 justify-content-right">
              <button type="button" className="btn btn-outline btn-grey btn-circle mr-2" onClick={this.cancelModal}>Cancel</button>
              <button type="button" onClick={this.cancelModal}
                  className="btn btn-primary btn-circle">
                     <i> <FontAwesomeIcon icon={faCheck} /></i>
                    Apply</button>
          </Modal.Footer>
          </Modal>
  {/* Modal END */}

            <div className="tab-pane active" id="page-nav-tab8__content" role="tabpanel" aria-labelledby="page-nav-tab8">
                <div className="qd-filtered-section">
                  <div className="qd-filtered-body">
                    <div className="qd-filtered_chips">
                      <div className="chip chip-blue">
                        Datasources
                        <i className="close"></i>
                      </div>
                      <div className="chip chip-blue">
                        Table
                        <i className="close"></i>
                      </div>
                      <div className="chip chip-blue">
                        Environment
                        <i className="close"></i>
                      </div>
                    </div>
                    <div className="qd-filtered_button">                  
                      <button className="btn btn-secondary text-white" onClick={this.createModal} data-toggle="modal" data-target="#modal__track-issue">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="24" viewBox="0 0 24 24" width="24">
                          <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
                          <path d="M0 0h24v24H0z" fill="none"></path>
                        </svg> <span>Filters</span>
                      </button>
                    </div>
                  </div>
                </div>
{/* Widgets START */}
                <div className="form-row mt-3">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-info">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-info">Attributes with 0-25% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">10</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartinfo" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-success">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-success">Attributes with 25%-50% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">10</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartsuccess" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-warning">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-warning">Attributes with 50%-75% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">5</span>
                        </div>
                        <div className="chart">
                          <Image src="areachartwarning" />
                          
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                    <div className="dashboard-stat dashboard-stat-danger">
                      <div className="details">
                        <div className="desc-header">
                          <div className="title text-danger">Attributes with > 75% dependency</div>
                        </div>
                        <div className="desc">
                          <span className="desc-value">2</span>
                        </div>
                        <div className="chart">
                        <Image src="areachartdanger" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  {/* Widgets END */}
                <div className="portlet">
                  <div className="portlet-title">
                    <div className="caption">
                      <span className="text-uppercase font-weight-bold">Data visualization</span>
                    </div>
                    <div className="actions">

                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                            <Tooltip>Zoom Reset</Tooltip>
                                                                                        }
                                                                                        >
                                                                                        <i  onClick={this.reset}>
                                                                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g clip-path="url(#clip0_2066_27139)">
                                                                                                <path d="M12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM4 15C3.45 15 3 15.45 3 16V19C3 20.1 3.9 21 5 21H8C8.55 21 9 20.55 9 20C9 19.45 8.55 19 8 19H6C5.45 19 5 18.55 5 18V16C5 15.45 4.55 15 4 15ZM5 6C5 5.45 5.45 5 6 5H8C8.55 5 9 4.55 9 4C9 3.45 8.55 3 8 3H5C3.9 3 3 3.9 3 5V8C3 8.55 3.45 9 4 9C4.55 9 5 8.55 5 8V6ZM19 3H16C15.45 3 15 3.45 15 4C15 4.55 15.45 5 16 5H18C18.55 5 19 5.45 19 6V8C19 8.55 19.45 9 20 9C20.55 9 21 8.55 21 8V5C21 3.9 20.1 3 19 3ZM19 18C19 18.55 18.55 19 18 19H16C15.45 19 15 19.45 15 20C15 20.55 15.45 21 16 21H19C20.1 21 21 20.1 21 19V16C21 15.45 20.55 15 20 15C19.45 15 19 15.45 19 16V18Z" fill="#A1A5B7"/>
                                                                                                </g>
                                                                                                <defs>
                                                                                                <clipPath id="clip0_2066_27139">
                                                                                                <rect width="24" height="24" fill="white"/>
                                                                                                </clipPath>
                                                                                                </defs>
                                                                                            </svg>
                                                                                        </i> 
                                                                                    </OverlayTrigger>
                                                                                    
                      {/* <input type="button" value="reset" onClick={this.reset}></input> */}
                     <div style={{"marginRight":"10px","marginLeft":"10px"}}>
                    <Select
                        placeholder="Select DataSource" classNamePrefix='select-control'
                        options={[                                  
                                    {"label": "retail_data", "value":1}
                                  ]}
                        defaultValue={{ "label": "retail_data", "value":1 }}
                      />
                      </div>
                      <DateRangePicker containerClass="btn btn-datapicker reportrange"
                                             startDate={this.state.startDate}
                                             onApply={this.handleTimeFilter}
                                             endDate={this.state.endDate}
                                             ranges={ranges}>
                                <i>
                                    <FontAwesomeIcon icon={faCalendar} />
                                </i>
                                <span className="ml-2 d-none d-md-inline-block">{this.state.startDate.format('YYYY-MM-DD')} - {this.state.endDate.format('YYYY-MM-DD')}</span>
                            </DateRangePicker>
                    
                      <a href="#!" className="help-icon" data-toggle="modal" data-target="#model__help"><i className="fa fa-question-circle"></i></a>
                    </div>
                  </div>
              {/* Chart START */}
                  <div className="portlet-body">                   
                    <div className="portlet-chart-title">                     
                      <div className="text-center1">
                        <div>
                        <div id="outputContainerFlowchart" class="lineage-graph-container"></div>
                        </div>
                      </div>
                    </div>
                  </div>
  {/* Chart END */}



                </div>

                </div>
                </>
        );
     }
}

export default (LineageV1);