import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Table from 'react-bootstrap/Table';
import { getErrorMsg }  from "../utils/event_handling";
import {dataRunStatuses, IN_PROGRESS_STATUSES} from "../utils/constant";
import { normalizeChildDatasetName,QUALDO_CHILD_DATASET_SEPARATOR } from '../utils/attribute_name_utils';
class DatasetButton extends React.Component {
    constructor(props) {
        super(props);
        this.handleMore = this.handleMore.bind(this);
        this.handleRefreshModalClose = this.handleRefreshModalClose.bind(this);
        this.handleDataset = this.handleDataset.bind(this);
        this.getErrorMsg = getErrorMsg.bind(this);
        this.getStatus = this.getStatus.bind(this);
        this.getProgressPercentage = this.getProgressPercentage.bind(this);
        this.state = {
            showMore: false,
            showDataset: false,
            data: null,
            textColor: "text-success",
            runEndTime: null,
            successCount: 0,
            failedCount: 0,
            type:0
        }
    }

    getProgressPercentage() {

        const run_status_code = this.state.data["run_status_code"];
        if(IN_PROGRESS_STATUSES.includes(run_status_code)) {
             let progressInfoTotal = 40
             let progressInfo = null
             if(this.state.data["progress_info"] !== undefined) {
                progressInfo = this.state.data["progress_info"]
                progressInfoTotal = progressInfo["cache_view"] + progressInfo["data_profiling"] + progressInfo["datatype_identification"] + progressInfo["metrics_computation"] + progressInfo["model_performance"] + progressInfo["relationship_identification"]


             }else {
                let row = this.props.item["value"]["dataset_processing_info"].filter(x => (x.data_set_id === this.state.data["data_set_id"] ))
                if(row.length > 0){
                   progressInfo = row[0]
                   progressInfoTotal = progressInfo["cache_view"] + progressInfo["data_profiling"] + progressInfo["datatype_identification"] + progressInfo["metrics_computation"] + progressInfo["model_performance"] + progressInfo["relationship_identification"]
                }

             }

             let progressPercentage = ((progressInfoTotal/40) * 100)/2
             return progressPercentage+25

       }else if(run_status_code === dataRunStatuses["inQueue"]) {
           return 25
       }else if(run_status_code === dataRunStatuses["success"]) {
           return 100
       }else if(run_status_code === dataRunStatuses["skipMcf"]) {
        return 0
    }
      return 100
    }


    handleRefreshModalClose(){
        this.setState({showDataset:false});
    }

    handleMore() {
       this.setState({showMore: true})
    }


    handleDataset(data, runEndTime, successRefresh=[], failedRefresh=[],type) {
         let successCount = 0;
         let failedCount = 0;
         if(successRefresh.length > 0) {
            successCount = successRefresh[0]["success"]
         }
         if(failedRefresh.length > 0) {
            failedCount = failedRefresh[0]["failed"]
         }
         let runEndTimeValue = null;
         if(runEndTime !== undefined) {
           runEndTimeValue = runEndTime["run_end_time"]
         }

         this.setState({showDataset: true,
             data: data,
             type:type,
             runEndTime: runEndTimeValue,
            successCount: successCount,
            failedCount: failedCount})
    }

    getStatus(statusCode) {
        if (IN_PROGRESS_STATUSES.includes(statusCode)){
            return "In Progress"
        }else if(statusCode === dataRunStatuses["failed"]){
            return "Failed"
        }else if(statusCode === dataRunStatuses["inQueue"]) {
            return "In Queue"
        }else if(statusCode === dataRunStatuses["success"]) {
            return "Completed"
        }else if(statusCode === dataRunStatuses["skipMcf"]) {
            return "Not Yet Started"
        }else{
            return "Failed"
        }
    }
    render() {
        const children = []
        let dataProcessingInfo = this.props.item["value"]["dataset_processing_info"];
        let lastRunTime = this.props.item["value"]["last_run_time"];
        let successRefresh = this.props.item["value"]["dataset_success_refresh"];
        let failedRefresh = this.props.item["value"]["dataset_failed_refresh"];
        // Check Parition value for render New format HTML 
        let datasetlist = (dataProcessingInfo.filter(function(item){
         if (item.data_set_name === undefined) {
                return false;
            }
            return !(item.data_set_name.includes(QUALDO_CHILD_DATASET_SEPARATOR));
        }));
        for(let loop=0;loop<datasetlist.length;loop++){
            let partitionlist = (dataProcessingInfo.filter(function(item){
            if (item.data_set_name === undefined) {
                    return false;
                }

                return (item.data_set_name.includes(datasetlist[loop]["data_set_name"]+QUALDO_CHILD_DATASET_SEPARATOR));
            }));
            datasetlist[loop]["child"]=  partitionlist.length > 0 ? partitionlist : [];
            datasetlist[loop]["partition"]=  partitionlist.length > 0 ? true : false;
        }
        dataProcessingInfo = datasetlist;
        //End Parition
        let length = (dataProcessingInfo.length > 5 && this.state.showMore === false ? 5 : dataProcessingInfo.length)
        for (let i = 0; i < length; i++) {
            const currentDatasetName = normalizeChildDatasetName(dataProcessingInfo[i]["data_set_name"]);
            if (currentDatasetName === undefined) {
                console.error("Dataset name not found");
                continue;
            }
            let data_set_id = dataProcessingInfo[i]["data_set_id"]
            let success_refresh_count, failed_refresh_count = []
            if(successRefresh !== undefined) {
              success_refresh_count = successRefresh.filter(x => x.data_set_id === data_set_id)
            }
            if(failedRefresh !== undefined) {
               failed_refresh_count = failedRefresh.filter(x => x.data_set_id === data_set_id)
            }
            children.push(
                dataProcessingInfo[i]["partition"]=== false ?      
                <Button key={i}
                        onClick={this.props.componentName === "model" ? this.handleDataset.bind(this, dataProcessingInfo[i]) : this.handleDataset.bind(this, dataProcessingInfo[i], lastRunTime[i], success_refresh_count, failed_refresh_count,0)}
                        variant="link"
                        className="px-0">
                    {currentDatasetName.includes(".") ? currentDatasetName.slice(0, currentDatasetName.length - 4) : currentDatasetName}
                </Button>
      :
      <div className="tbl-partition-group">
      <Button key={i}
                        onClick={this.props.componentName === "model" ? this.handleDataset.bind(this, dataProcessingInfo[i]) : this.handleDataset.bind(this, dataProcessingInfo[i], lastRunTime[i], success_refresh_count, failed_refresh_count,0)}
                        variant="link"
                        className="px-0">
                {currentDatasetName.includes(".") ? currentDatasetName.slice(0, currentDatasetName.length - 4) : currentDatasetName.slice(0, 16)}
                {" ("+dataProcessingInfo[i]["child"].length+")"}
                </Button>
                    <div className="tbl-partition-list">
    {dataProcessingInfo[i]["child"].map((partitioninfo) => (
<>        
<Button key={i}
                        onClick={this.props.componentName === "model" ? this.handleDataset.bind(this, partitioninfo["data_set_name"]) : this.handleDataset.bind(this, partitioninfo,lastRunTime[i], success_refresh_count, failed_refresh_count,1)}
                        variant="link"
                        className="px-0">
                 {normalizeChildDatasetName(partitioninfo["data_set_name"]).includes(".") ? normalizeChildDatasetName(partitioninfo["data_set_name"]).slice(0, normalizeChildDatasetName(partitioninfo["data_set_name"]).length - 4) : normalizeChildDatasetName(partitioninfo["data_set_name"])}
</Button>  
</>
))}
  </div>
</div>
)






            // if(checkpartitionflag){
            
            //     children.push(
            //     <Button key={i}
            //             onClick={this.props.componentName === "model" ? this.handleDataset.bind(this, dataProcessingInfo[i]) : this.handleDataset.bind(this, dataProcessingInfo[i], lastRunTime[i], success_refresh_count, failed_refresh_count)}
            //             variant="link"
            //             className="px-0">
            //         {currentDatasetName.includes(".") ? currentDatasetName.slice(0, currentDatasetName.length - 4) : currentDatasetName}
            //     </Button>)

            // }else {
            //     children.push(
            //         <Button key={i}
            //                 onClick={this.props.componentName === "model" ? this.handleDataset.bind(this, dataProcessingInfo[i]) : this.handleDataset.bind(this, dataProcessingInfo[i], lastRunTime[i], success_refresh_count, failed_refresh_count)}
            //                 variant="link"
            //                 className="px-0">
            //             {currentDatasetName.includes(".") ? currentDatasetName.slice(0, currentDatasetName.length - 4) : currentDatasetName}
            //         </Button>)            }
        }
        if (dataProcessingInfo.length > 5 && this.state.showMore === false) {
            children.push(
                <Button key="more"
                        onClick={this.handleMore}
                        className="px-0"
                        variant="link">+{dataProcessingInfo.length - 5} More
                </Button>)
        }
          return (<>
                  {children}
                  <Modal show={this.state.showDataset} size="xl" centered onHide={this.handleRefreshModalClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Dataset Processing Information
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="react-bootstrap-table table-responsive">
                            <Table>
                                <thead>
                                <tr>
                                    {this.state.type===0 ? 
                                    <th>Datasets</th> : 
                                    <th>Partition</th> }
                                    {this.props.componentName === "model" ? <th>Type of Dataset</th> : <></>}
                                    <th>Last Processing Time</th>
                                    <th>Current Processing Status</th>
                                    <th>Successful Refresh</th>
                                    <th>Failed Refresh</th>
                                    <th>Errors</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {this.state.data !== null ? <tr>
                                        <td>{normalizeChildDatasetName(this.state.data["data_set_name"])}</td>
                                        {this.props.componentName === "model" ? <td>{this.state.data["dataset_type"]}</td> : <></>}
                                        <td>{this.props.componentName === "model" ? this.state.data["run_end_time"] : this.state.runEndTime}</td>
                                        <td>
                                            <div className="data-progress-status">
                                                <label className={this.state.textColor}>{this.getStatus(this.state.data["run_status_code"])}</label>
                                                <ProgressBar now={this.getProgressPercentage()} />
                                            </div>
                                        </td>
                                        <td>{this.props.componentName === "model"? this.state.data["success_refreshes"] : this.state.successCount}</td>
                                        <td>{this.props.componentName === "model"? this.state.data["failed_refreshes"] : this.state.failedCount}</td>
                                        <td>{getErrorMsg(this.state.data['run_status_text'], this.state.data["run_status_code"])}</td>
                                    </tr> : <></>}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>
                </Modal>


          </>);

    }
}

export default DatasetButton;
