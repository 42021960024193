import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';
import Image from './image';
import ChartBody from './chartBody';
import DefaultMetricsEdit from './defaultMetrics';
import CustomDefaultMetrics from './customDefaultMetrics';
import {setShow} from "../utils/event_handling";
import {checkPermissions, getMetricTypeFromDefinition} from "../utils/common_utils";


class Card extends React.Component {
    constructor(props) {
        super(props);
        this.setShow = setShow.bind(this);
        this.handleClose = () => this.setShow("show", false);
        this.handleShow = () => this.setShow("show", true);
        this.handleCloseCustom = () => this.setShow("showFollowModal", false);
        this.updateEnableButton = this.updateEnableButton.bind(this);
        let handleFollow = null;
        if (this.props.handleCardFollow !== undefined) {
            handleFollow = this.props.handleCardFollow.bind(this);
        }
        this.handleFollow = handleFollow;

        let tabValue = "";
        if (this.props.ex_metric_details !== undefined && this.props.ex_metric_details !== null) {
            let metricType = getMetricTypeFromDefinition(this.props.ex_metric_details.metrics_definition);
            if (metricType !== null) {
                tabValue = metricType.toUpperCase();
            }
        }

        this.tabValue = tabValue;
        this.state = {
            show: false,
            showFollowModal: false,
            active: null,
            data: this.props.ex_metric_details,
            chartData: this.props.chartData
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ex_metric_details.status_code !== state.data.status_code) {
            let activeStatus = props.ex_metric_details.status_code === 1;
            return {
                active: activeStatus,
                data: props.ex_metric_details
            }
        }

        return null;
    }

    // Commented the following function since it's usage is deprecated in React.
    // As alternative used 'getDerivedStateFromProps()' function
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.ex_metric_details.status_code !== this.state.data.status_code) {
    //         let activeStatus = nextProps.ex_metric_details.status_code === 1;
    //         this.setState({ active: activeStatus, data:  nextProps.ex_metric_details});
    //     }
    // }



    getEditComponent(componentName) {
        // When we get proper component name, if condition can be changed to switch-case
        let activeStatus = this.state.active;
        if (this.state.active === null && this.props.ex_metric_details !== undefined) {
            activeStatus = this.props.ex_metric_details.status_code === 1;
        }
        if (componentName === undefined) {
            const exMetricDetails = this.props.ex_metric_details;
            let editComponent = (<DefaultMetricsEdit closeModal={this.handleClose}
                                                    metricName={this.props.name}
                                                    updateEnableButton={this.updateEnableButton}
                                                    metricId={this.props.id}
                                                    handleFollow={this.handleFollow}
                                                    activeStatus={activeStatus}
                                                    exMetricDetails={exMetricDetails}
                                                    details={this.props.additionalDetails.datasetInfo}
                                                    showToast={this.props.showToast}/>);
            return (
                <Modal show={this.state.show} size="lg" centered onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            Edit the Following {this.tabValue === "DQ" ? "Data Quality" : "Model"} Metric</h5>
                    </Modal.Header>
                    <Modal.Body> {editComponent}
                    </Modal.Body>

                </Modal>
            );
        }
    }

    getFollowButtonComponent(componentName) {
        if (componentName === undefined) {
            if (this.props.metricType === "default" || this.props.metricType === "combined") {
                return (
                    <Modal show={this.state.showFollowModal} size="lg"
                           centered onHide={this.handleCloseCustom}>
                        <Modal.Header closeButton>
                            <h5 className="modal-title" id="exampleModalLongTitle">
                                Apply {this.props.name} to </h5>
                        </Modal.Header>
                        <Modal.Body> {
                            <CustomDefaultMetrics closeModal={this.handleCloseCustom}
                                                  metricName={this.props.name}
                                                  metricId={this.props.id}
                                                  exMetricDetails={this.props.ex_metric_details}
                                                  details={this.props.additionalDetails.datasetInfo}
                                                  showToast={this.props.showToast}/>

                        }
                        </Modal.Body>
                    </Modal>
                );
            }
        }

        return (<>
        </>);
    }

    updateEnableButton(status){
        this.setState({active: status});
    }

    render() {
        let activeStatus = this.state.active;

        if (this.state.active === null && this.props.ex_metric_details !== undefined) {
            activeStatus = this.props.ex_metric_details.status_code === 1;
        }

        let hide_edit = this.props.hide_edit
        let disableMetricsAction = checkPermissions('Metrics')


        let cardBackground = this.props.background ? `dashboard-stat ${this.props.background}` : "dashboard-stat dashboard-stat-info";
        let textColor = this.props.textColor ? `title ${this.props.textColor}` : "title text-info";
        let chartLine = this.props.chartLine ?  this.props.chartLine : "incremental";
        return (
            <>
                <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                    <div className={cardBackground}>
                        <div className="details">
                            <div className="desc-header">
                                <div className={textColor}>
                                    {this.props.name}
                                    {/*
                                    this.props.configuredCount !== false ?
                                        <span className="badge badge-circle badge-dark">{this.props.configuredCount}</span>
                                        : ''
                                    */}
                                </div>
                                {
                                    (this.props.showFollow) ?

                                        <>
                                            <div className="custom-control custom-switch">
                                                <input type="checkbox" onChange={this.handleFollow}
                                                       checked={activeStatus}
                                                       className="custom-control-input"
                                                       disabled={disableMetricsAction}
                                                       id={this.props.name} />
                                                <label className="custom-control-label"
                                                       htmlFor={this.props.name}/>
                                            </div>
                                            {/* <span className="app-icon" onClick={this.handleFollow}>
                                                <i className="fas fa-play"><FontAwesomeIcon icon={ activeStatus ? faPlay : faPause }/></i>
                                            </span> */}
                                        </> :
                                        ''
                                }
                            </div>

                            <div className="desc">
                                {
                                    this.props.value !== null && this.props.value !== undefined ?
                                        <span className="desc-value">{this.props.value}</span> :
                                        ''
                                }
                                {
                                    // <span className="desc-percentage">%</span>
                                }
                            <button 
                                    disabled={disableMetricsAction ? disableMetricsAction : hide_edit}
                                    type="button"
                                    className="btn btn-sm btn-dark"
                                    name={this.props.name.concat("_edit").toLowerCase().replace(" ","_")}
                                    onClick={this.handleShow}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                    Edit Metric
                            </button>
                            </div>
                            {
                                this.props.cardType === undefined ?
                                    <div className="chart">
                                        <Image src={chartLine} alt=""/>
                                    </div>
                                    :
                                    <ChartBody
                                        classValue="chart"
                                        id={this.props.idValue}
                                        chartType="cardArea"
                                        style={{height: "250px"}}
                                        chart_data={this.state.chartData}
                                        colorType={this.props.textColor}
                                    />
                            }

                        </div>
                    </div>
                </div>
                {this.getEditComponent(this.props.cardType)}
                {this.getFollowButtonComponent(this.props.cardType)}
            </>
        );
    }
}

export default Card;