import React, {useState, forwardRef, useImperativeHandle}  from 'react';
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import { DATA_SET, ATTRIBUTE } from "../../utils/constant";
import _ from 'lodash';

export const GovernFilterOptions = forwardRef((props, ref) => {
    let data_source_option=[{"label":"Mike", "value":'a'},
            {"label":"Jordon", "value":'b'},{"label":'Rob', "value":'c'},
            {"label":"Rakesh", "value":'d'},{"label":'Thomas', "value":'e'}];
    let [ dataSourceOption, setDataSourceOption ] = useState(props.dataSourceOption);
    let [ dataSetOption, setDataSetOption ] = useState(props.dataSetOptions);
    let [ attributeOption, setAttributeOption ] = useState(props.attributeOptions);

    let [ selectedEnvironmentOption, setSelectedEnvironmentOption ] = useState(props.preSelectedEnvironment);
    let [ selectedDataSourceOption, setSelectedDataSourceOption ] = useState(props.preSelectedDataSource);
    let [ selectedDataSetOption, setSelectedDataSetOption ] = useState(props.preSelectedDatasets);
    let [ selectedAttributeOption, setSelectedAttributeOption ] = useState(props.preSelectedAttributes);
    let [ isFilterSubmitted, setFilterSubmitted ] = useState(false);

    useImperativeHandle(ref, () => ({
        setDefaultValuesFromParent(environment, dataSource, dataSet, attribute) {
            if (environment !== null) {
                setSelectedEnvironmentOption(environment);
            }
            if (dataSource !== null) {
                setSelectedDataSourceOption(dataSource);
            }
            if (dataSet !== null) {
                setSelectedDataSetOption(dataSet);
            }
            if (attribute !== null) {
                setSelectedAttributeOption(attribute);
            }
        }
    }));

    React.useEffect(() => {
        setSelectedEnvironmentOption(props.preSelectedEnvironment);
    }, [props.preSelectedEnvironment])

    React.useEffect(() => {
        setSelectedDataSourceOption(props.preSelectedDataSource);
    }, [props.preSelectedDataSource])

    React.useEffect(() => {
        setSelectedDataSetOption(props.preSelectedDatasets);
    }, [props.preSelectedDatasets])

    React.useEffect(() => {
        setSelectedAttributeOption(props.preSelectedAttributes);
    }, [props.preSelectedAttributes])


    React.useEffect(() => {
        setDataSourceOption(props.dataSourceOption);
    }, [props.dataSourceOption])

    React.useEffect(() => {
        setDataSetOption(props.dataSetOptions);
    }, [props.dataSetOptions])



    function parseMetaMapping(type, parentId, id) {
        let metaMapping = _.cloneDeep(props.dataModule.metaData);
        let prefix;
        switch (type) {
            case DATA_SET: {
                prefix = `${selectedEnvironmentOption.value}.${parentId}.${id}`;
                const filtered = Object.keys(metaMapping)
                    .filter( key => key.startsWith(prefix))
                    .reduce((obj, key) => {
                        obj[key] = metaMapping[key];
                        return obj;
                    }, {});
                let dataSetName = filtered[Object.keys(filtered)[0]];
                return dataSetName.split(".")[2];
            }
            case ATTRIBUTE: {
                prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}.${parentId}.${id}`;
                const filtered = Object.keys(metaMapping)
                    .filter(key => key === prefix)
                    .reduce((obj, key) => {
                        obj[key] = metaMapping[key];
                        return obj;
                    }, {});
                let attributeName = filtered[Object.keys(filtered)[0]];
                return attributeName.split(".")[attributeName.split(".").length - 1];
            }
            default:
                return "NA";
        }
    }


    function setSelectedEnvironment(event) {
        let option = [];
        let selectedEnvironment = event.value;
        setAttributeOption([]);
        setDataSetOption([]);
        setDataSourceOption(option);
        setSelectedEnvironmentOption(event);
        setSelectedDataSourceOption(-1);
        setSelectedDataSetOption(-1);
        setSelectedAttributeOption(-1);
    }

    function handleFilterClose(){
        props.closeFilterModal();
    }



    function setFilteredValues() {
        setFilterSubmitted(true);
        let filteredValues = [];
        let prefix;
        if (selectedEnvironmentOption !== -1 && selectedDataSourceOption !== -1) {
            prefix = `${selectedEnvironmentOption.value}.${selectedDataSourceOption.value}`;
            if (selectedDataSetOption !== null && selectedDataSetOption !== -1 && selectedDataSetOption.length > 0) {
                selectedDataSetOption.forEach((dataSet) => {
                    if(selectedAttributeOption !== null && selectedAttributeOption !== -1 && selectedAttributeOption.length > 0) {
                        selectedAttributeOption.forEach((attribute) => {
                            filteredValues.push(`${prefix}.${dataSet.value}.${attribute.value}`);
                        });
                    } else {
                        filteredValues.push(`${prefix}.${dataSet.value}`);
                    }
                });
            } else {
                filteredValues.push(prefix);
            }
            props.setFilteredValues(filteredValues)
            props.closeFilterModal();
            setFilterSubmitted(false);
            props.setChipData(selectedEnvironmentOption, selectedDataSourceOption, selectedDataSetOption, selectedAttributeOption);
        }

    }


   return (
         <Modal show={props.show}  style={{display: "block", paddingRight: "15px"}} centered
                onHide={handleFilterClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title" id="exampleModalLongTitle">
                            {props.filterTitle}
                        </h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form-group">
                            <label htmlFor="">Existing Users <span className="text-danger">*</span>:</label>
                            <Select
                                name="environments"
                                id={props.id + "users"}
                                onChange={setSelectedEnvironment}
                                options={data_source_option}
                                placeholder="Select a user to take action"
                                classNamePrefix='select-control'
                                defaultValue={selectedEnvironmentOption === -1 ? '' : selectedEnvironmentOption}
                            />
                            { selectedEnvironmentOption === -1 && isFilterSubmitted ?
                                <span className='error'> {"Please Select an user"} </span> : ""}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button"
                                className="btn btn-outline btn-grey btn-circle">Remove</button>
                        <button type="button"
                                className="btn btn-primary btn-circle show-success-toast">Add</button>
                    </Modal.Footer>
         </Modal>
    );
});