import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
export default class Table extends React.Component {
    
    constructor(props){
      super(props);
      this.getHeader = this.getHeader.bind(this);
      this.getRowsData = this.getRowsData.bind(this);
      this.getKeys = this.getKeys.bind(this);
    }
    
    getKeys = function(){
      return Object.keys(this.props.data[0]);
    }
    
    getHeader = function(){
      var keys = this.getKeys();
      return keys.map((key, index)=>{
        return <th key={key}>{key.toUpperCase()}</th>
      })
    }
    
    getRowsData = function(){
      var items = this.props.data;
      var keys = this.getKeys();
      return items.map((row, index)=>{
        return <tr key={index}><RenderRow key={index} data={row} keys={keys}/></tr>
      })
    }
    
    render() {
        return (
          <div>
            <table className="table">
            <thead>
              <tr>{this.getHeader()}</tr>
            </thead>
            <tbody>
              {this.getRowsData()}
            </tbody>
            </table>
          </div>
          
        );
    }
}

const RenderRow = (props) =>{
  return props.keys.map((key, index) => {
    var retVal = "";
    if(key === "Key")
        {
            if(props.data["Key"] === "1")
              retVal =  <td><FontAwesomeIcon icon={faKey} className="rotate-key text-warning"/></td>;
            else if(props.data["Key"] === "2")
              retVal = <td><FontAwesomeIcon icon={faKey} className="rotate-key text-success"/></td>;
            else if(props.data["Key"] === "0")
              retVal = <td></td>;    
        }   
        else
          retVal = <td>{props.data[key]}</td>;
    return retVal;
  })
}