import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import $ from "jquery";
import {AlertEdit, ConsumeEdit, IntegrationEdit, MetricsEdit, MLModelEdit, TeamEdit, PipelineIntegrationEdit} from './tableEdit';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone,
    SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import {TeamTableButtons} from "./tableButtons";
import {ImageComponent} from "./imageComponent";
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import Select from "react-select";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTable, faInfoCircle, faSearch, faCheckCircle, faTimesCircle, faExternalLinkAlt, faSitemap,faPencilAlt} from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleRight, faWindowRestore} from '@fortawesome/free-regular-svg-icons';
import RcTooltip from 'rc-tooltip';
import {
    NO_DATA_SET_FOUND_TEXT, ROW_WARN_CLASS, EXCLUDED_COMPONENTS,
    NOT_SORTABLE, ABOVE_ALERT_SUPPORTED_TABLES, LIST_VIEW_OPTIONS, QUALDO_DOCUMENTATION,
    NO_PIPELINES_FOUND
} from "../utils/constant";
import {get_document_link} from "../utils/common_utils";
import NoErrorInPreview from '../monitor/components/noErrorPreview';
import NoModelComponent from "../monitor/components/noModelComponent";
import Image from "../components/image"
import { Link } from 'react-router';
import Form from 'react-bootstrap/Form';
import { Button } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';


const renderTooltip = (props) => (
    <span {...props}>
      Click here to Mapping
    </span>
);

class QualdoDataTable extends React.Component {
    constructor(props) {
        super(props);
        this.data = this.props.data;
        this.paginationSize = this.props.paginationSize;
        this.onPageChange = this.onPageChange.bind(this);
        this.component_name = this.props.component_name;
        this.handleSearchOperation = null;
        this.handleDeleteOperation = this.handleDeleteOperation.bind(this);
        this.handleSeeMore = this.handleSeeMore.bind(this);
        this.input = null;
        this.state = {
            hideSizePerPage: this.props.hideSizePerPage !== undefined ? this.props.hideSizePerPage : false,
            paginationSize: this.props.paginationSize,
            tableData: this.props.data,
            isDelete: false,
            tempData: this.props.data,
            selectedGroup: this.props.selectedGroup ? this.props.selectedGroup : LIST_VIEW_OPTIONS[0],
            isSeeMore:this.props.isSeeMore !== undefined ? this.props.isSeeMore : false,
            isSeeMoreId:Math.random().toString(36).replace('0.','btn_seemore_' || '')
                }
    }

    componentDidMount() {
        if(this.state.isSeeMore===true){
            this.handleSeeMore();
        }

        if(this.props.component_name === "pipelineListViewTable"){
            setTimeout(
                () => {

                    $(".table>thead>tr").append("<th>Datasource Type</th>");
                    $(".table>thead>tr").append("<th>Last Run</th>");
                    $(".table>thead>tr").append("<th>Last Run <br/>Duration</th>");
                    $(".table>thead>tr").append("<th>Avg.<br/>Duration</th>");
                    $(".table>thead>tr").append("<th>Next<br/>Scheduled<br/>Run</th>");
                    $(".table>thead>tr").append("<th>Runs Count</th>");
                    $(".table>thead>tr").append("<th>Last Run<br/>Status</th>");
                    $(".table>thead>tr").append("<th>Action</th>");
                    $('.table th:nth-child(1)').html("");
                    // $('.table th:nth-child(1)').width("5px");
                    $('.react-bootstrap-table > table:nth-child(1) > tbody:nth-child(2) > tr:nth-child(1) > td:nth-child(1)').html("");
                    // $('.react-bootstrap-table > table:nth-child(1) > tbody:nth-child(2) > tr:nth-child(1) > td:nth-child(1)').width("5px");
                    let ths = $('th');
                    let element = ths.eq(0);
                    element.width(0);
                    element = ths.eq(1);
                    element.width(190);
                    element = ths.eq(2);
                    element.width(216);
                    element = ths.eq(3);
                    element.width(402);
                    element = ths.eq(4);
                    element.width(85);
                    element = ths.eq(5);
                    element.width(70);
                    element = ths.eq(6);
                    element.width(60);
                    element = ths.eq(7);
                    element.width(85);
                    element = ths.eq(8);
                    element.width(100);
                    element = ths.eq(9);
                    element.width(85);
                    element = ths.eq(10);
                    element.width(70);
                    $(".react-table__info,.react-table__length").hide();
                     this.props.applyTimeFilter(parseInt(this.props.timefilter),'initialcall');
                    // this.props.applyTimeFilter.bind(this,(parseInt(this.props.timefilter)));

                     $('.react-bootstrap-table>table>tbody tr td:nth-child(3)').each(function(index) {
                        if($(this).attr('class')===undefined){
                            $(this).attr('colspan',9);
                        }
                      });

                },
                100
              );
        }
    }
    shouldComponentUpdate(prevProps){
        if(this.props.id==="configuredDatasources"){
            if(JSON.stringify(prevProps.data.data) !== JSON.stringify(this.props.data.data)){
                // To handle rendering while edit and delete actions
                return true
            }
            // Only for Configure page : To avoid rerendering of Qualdo Data Table
            // While the first call all the datas will received here
            // Only once this table will gets rendered  to avoid the rerendering
            return false
        }
        // else if(this.props.component_name==="pipelineListViewTable"){
        //     if(JSON.stringify(prevProps.data.data) !== JSON.stringify(this.props.data.data)){
        //         // To handle rendering while edit and delete actions
        //         setTimeout(
        //             () => {
        //                 $(".table>thead>tr").append("<th>Datasource Type</th>");
        //                 $(".table>thead>tr").append("<th>Last Run</th>");
        //                 $(".table>thead>tr").append("<th>Last Run <br/>Duration</th>");
        //                 $(".table>thead>tr").append("<th>Avg.<br/>Duration</th>");
        //                 $(".table>thead>tr").append("<th>Next<br/>Scheduled<br/>Run</th>");
        //                 $(".table>thead>tr").append("<th>Total Attempts</th>");
        //                 $(".table>thead>tr").append("<th>Last Run<br/>Status</th>");
        //                 $(".table>thead>tr").append("<th>Action</th>");

        //                 let ths = $('th');
        //                 let element = ths.eq(0);
        //                 element.width(40);
        //                 element = ths.eq(1);
        //                 element.width(190);
        //                 element = ths.eq(2);
        //                 element.width(219).css('padding-left', '15px');
        //                 element = ths.eq(3);
        //                 element.width(390);
        //                 element = ths.eq(4);
        //                 element.width(85);
        //                 element = ths.eq(5);
        //                 element.width(70);
        //                 element = ths.eq(6);
        //                 element.width(60);
        //                 element = ths.eq(7);
        //                 element.width(85);
        //                 element = ths.eq(8);
        //                 element.width(100);
        //                 element = ths.eq(9);
        //                 element.width(85);
        //                 element = ths.eq(10);
        //                 element.width(70);
        //             },
        //             100
        //           );
        //           return false
        //     }
        // }
        else{
            return true
        }
    }

    onPageChange(pm) {

       if(this.state.component_name==="pipelineListViewTable"){
            this.props.pageTrack(pm)
            // $('.react-bootstrap-table>table>tbody tr td:nth-child(3)').each(function(index) {
            //    $(this).attr('colspan',9).addClass('p-0');
            // });

        }

        if(ABOVE_ALERT_SUPPORTED_TABLES.includes(this.props.component_name)){
       $('tr.alert-warning-row').remove();
       setTimeout(function(){

        var prevClassFlag = 1;
        $('.table tbody tr').each(function() {

            if($(this).hasClass("row-highlight has-above-alert") && prevClassFlag === 1) {
                $(this).before('<tr class="alert-warning-row"><td colspan="6" class="p-0"><div class="alert alert-warning mb-0 py-sm-1 mt-2" role="alert"><p class="mb-0"><strong>No Refreshes found in the chosen date range. Displaying the data points of earlier refreshes.</strong></p></div></td></tr>');
            }

            if($(this).hasClass("alert-warning-row")){
                prevClassFlag = 0;
            }else {
                prevClassFlag = 1;
            }

        });

        }, 500);

     }

    }

    getActionComponent(item) {
        switch (this.props.component_name) {
            case 'pipelinesource':
                return (
                    <PipelineIntegrationEdit
                        showToast={this.props.showToast}
                        integration_details={item.pipeline_integration_data}
                        handleDelete={this.handleDeleteOperation}
                        environment={this.props.environment}
                        component_name={this.props.component_name}
                        updateTable={this.props.updateTable}
                        setEnvData={this.props.setEnvData}
                    />
                );
            case 'datasource':
                return (
                    <IntegrationEdit
                        showToast={this.props.showToast}
                        integration_details={item.integration_data}
                        handleDelete={this.handleDeleteOperation}
                        environment={this.props.environment}
                        component_name={this.props.component_name}
                        updateTable={this.props.updateTable}
                        setEnvData={this.props.setEnvData}
                        setAddDS={this.props.setAddDS}

                    />
                );

            case 'ml_model':
                return (
                    <MLModelEdit
                        showToast={this.props.showToast}
                        mlData={item.ml_model_data}
                        environment={this.props.environment}
                        getExistingABData={this.props.getExistingABData}
                        handleDelete={this.handleDeleteOperation}
                        modelHasModelABConfiguration={this.props.modelHasModelABConfiguration}
                        integrationOption={this.props.integrationOption}
                        datasets={this.props.datasets}
                        showModelABPopup={this.props.showModelABPopup}
                        handleModelABAddition={this.props.handleModelABAddition}
                        availableModels={this.props.availableModels}
                        modelVersionMap={this.props.modelVersionMap}
                        modelsDataMap={this.props.modelsDataMap}
                        modelTypeMap={this.props.modelTypeMap}
                        attributes={this.props.attributes}
                        component_name={this.props.component_name}/>
                );

            case 'default_metrics':
                let metricsDataDefault = item.metrics_data;
                return (
                    <MetricsEdit
                        unique_key={metricsDataDefault.key}
                        modelOptions={this.props.modelOptions}
                        showToast={this.props.showToast}
                        metrics_data={metricsDataDefault}
                        handleDelete={this.handleDeleteOperation}
                        modalTitle={this.props.modalTitle}
                        integrationOption={this.props.integrationOption}
                        additional_details={this.props.additional_details}
                        isSeeMore = {this.props.isSeeMore}
                        isSeeMoreId = {this.props.isSeeMoreId}
                        datasetInfo={this.props.datasetInfo}
                        component_name={this.props.component_name}
                        updateFollowStatus={this.props.updateFollowStatus}
                    />
                );
                case 'metrics':
                    const metricsData = item.metrics_data;
                    return (
                        <MetricsEdit
                            unique_key={metricsData.key}
                            modelOptions={this.props.modelOptions}
                            showToast={this.props.showToast}
                            metrics_data={metricsData}
                            handleDelete={this.handleDeleteOperation}
                            modalTitle={this.props.modalTitle}
                            integrationOption={this.props.integrationOption}
                            additional_details={this.props.additional_details}
                            datasetInfo={this.props.datasetInfo}
                            component_name={this.props.component_name}
                            updateFollowStatus={this.props.updateFollowStatus}
                        />
                    );
            case 'consume':
                let consume_item = item.consume;
                return (
                    <ConsumeEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={"Notification Channel"}
                        delete_case={"delete_consume"}
                        consume_item={consume_item}
                        component_name={this.props.component_name}
                    />
                );
            case 'alert':
                let alert_item = item.alert;
                return (
                    <AlertEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={this.props.modelTitle}
                        dataset={this.props.dataset}
                        integrationOption={this.props.integrationOption}
                        modelOptions={this.props.modelOptions}
                        delete_case={"delete_alert"}
                        alert_item={alert_item}
                        component_name={this.props.component_name}
                        metricsData={this.props.details}
                        tab={this.props.tab}
                    />
                );
            case 'consume_pipeline':
                let consume_pipeline = item.consume;
                return (
                    <ConsumeEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        edit_title={"Pipeline Channel"}
                        hideModelAlertsTab={this.props.hideModelAlertsTab}
                        changeTab={this.props.changeTab}
                        delete_case={"delete_pipeline"}
                        consume_item={consume_pipeline}
                        component_name={this.props.component_name}
                    />
                );
            case 'teamMate':
                let userDetails = item.userDetails;
                return (
                    <TeamEdit
                        showToast={this.props.showToast}
                        handleDelete={this.handleDeleteOperation}
                        userDetails={userDetails}
                        ugmDetails={this.props.ugmDetails}
                        component_name={this.props.component_name}
                    />
                );
            case 'billingHistory':

                let billingDetails = item.billingDetails;
                return (
                    <>
                        <strong className="text-success mr-1">Paid</strong>
                        {
                            billingDetails.showTooltip
                                ?
                                <RcTooltip placement="top"
                                                overlay={
                                                    <span id={'tooltip-top'}>
                                                        Your next Billing date is
                                                        <br/> {billingDetails.nextDate}.
                                                    </span>
                                                }
                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                            >
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                </RcTooltip>
                                :
                                ''
                        }

                    </>
                );
                case 'billingHistoryLink':
                    return (
                        <>
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>

                        </>
                    );
                case 'pbierrordashboard':
                    return (
                            <>
                         <div class="custom-control custom-switch d-inline-block" >
                                <input type="checkbox" checked={item.pbierrordashboard.actions === "1" ? "true" : ""} className="custom-control-input" onChange={(e) => this.props.pbistatus(e)}  id={item.pbierrordashboard.index}  /><label class="custom-control-label" for={item.pbierrordashboard.index}></label>
                        </div>
                            </>
                        );
        
                case 'pbimapping':
                    if(item['pbimapping'].status === 1) {
                        return (
                            <>
                                <button type="button" className="btn btn-sm btn-success mr-1"><FontAwesomeIcon icon={faCheckCircle} /> Mapped</button>
                                <button type="button" data-mappingid={item.pbimapping.id}  id={"mapping_"+item.pbimapping.id} onClick={(e) => this.props.setupMapping(item.pbimapping.id,item['pbimapping'].status)} className="btn btn-sm btn-outline-dark btn__ds-mapped mr-0"><FontAwesomeIcon icon={faSitemap} className="d-none" /> <FontAwesomeIcon icon={faPencilAlt} /><span></span></button> 
                            </>
                        );    
                        }else {
                            return (
                                <>
                                    <button type="button" data-mappingid={item.pbimapping.id}  id={"mapping_"+item.pbimapping.id} onClick={(e) => this.props.setupMapping(item.pbimapping.id,item['pbimapping'].status)} className="btn btn-sm btn-outline-dark  mr-1"><FontAwesomeIcon icon={faSitemap} /><span>Mapping</span></button> 
                                </>
                            );  
                        }

                   
            default:
                return (
                    <div/>
                );
        }

    }

    getButtonComponent(item) {
        switch (this.props.component_name) {
            case 'requests':
                return (
                    <TeamTableButtons
                        showToast={this.props.showToast}
                        userDetails={item.userDetails}
                        componentName={this.props.component_name}
                    />
                );
            case 'teamMate':
                if (item.userDetails !== undefined) {
                    return (
                        <ImageComponent
                            showToast={this.props.showToast}
                            userDetails={item.userDetails}
                            componentName={this.props.component_name}
                        />
                    );
                }
                else {
                    return (<span className="label label-success label-inline">{item}</span>);
                }
            default:
                return (
                    <div/>
                );
        }
    }

    async navigateFirstPage(props) {
        return props.onPageChange(1);
    }

    async handlePageChange(value) {
        this.setState({paginationSize: value});
        return;
    }

    async clearSearch(props) {
        this.setState({isDelete: false});
        return;
    }

    renderRow() {
        let result = []
        let headerKeys = {}
        this.state.tableData.headers.forEach((headerValue) => {
            headerKeys[headerValue] = null;
        });
        let j = 0;
        let isDatasetRowFound = false
        let datasetData;
        let selectedGroup;
        if(this.state.selectedGroup){
          selectedGroup = this.state.selectedGroup.value;
        }
        let fullData = this.props.component_name === "dqErrorListViewTable" ? this.state.tempData.data : this.state.tableData.data;
        fullData = _.cloneDeep(fullData)
        for (let i = 0; i < fullData.length; i++) {
            let rowItems = fullData[i];
            if(this.props.component_name === "dqErrorListViewTable"){
              if(rowItems[0].group === "dataset" && selectedGroup === "attributes"){
                isDatasetRowFound = true
                datasetData = rowItems[1]
                continue;
              }
              if(rowItems[0].group === "attribute" && selectedGroup === "datasets"){
                continue;
              }

              if(Object.keys(this.props.follow_attribute_details).length === 0){
                continue
            }

            let updatedAttributeStatus = this.props.follow_attribute_details[rowItems[4]['dataSourceId']][rowItems[4]['attributeId']];
            if((selectedGroup === "followedAttributes" && updatedAttributeStatus !== true) && (rowItems[0].group === "dataset" || updatedAttributeStatus !== true)){
                if(rowItems[0].group === "dataset"){
                   isDatasetRowFound = true
                   datasetData = rowItems[1]
                }
                continue;
              }

              if(selectedGroup === "attributes" && isDatasetRowFound === true){
                rowItems[0].value = rowItems[4].dataSourceName
                rowItems[1].value = datasetData
              }
              if(selectedGroup === "followedAttributes" && isDatasetRowFound === true && updatedAttributeStatus === true){
                rowItems[0].value = rowItems[4].dataSourceName
                rowItems[1].value = datasetData
              }
              isDatasetRowFound = false

            }
            let obj = _.cloneDeep(headerKeys);
            let keys = Object.keys(obj);
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === 'td') {
                    obj[keys[k]] = items.value
                } else if (items.type === "buttons") {
                    obj[keys[k]] = items;
                } else if (items.type === "component") {
                    obj[keys[k]] = items;
                } else if (items.type === "image") {
                    obj[keys[k]] = items;
                } else if (items.type === "singlebutton") {
                    obj[keys[k]] = items.value;
                }else if (items.type === "billingComponent") {
                    obj[keys[k]] = items;
                }else if (items.type === "pipeline") {
                    obj[keys[k]] = items.value;
                }
                else if (items.type === "billingComponentLink") {
                    obj[keys[k]] = items;
                }
                else {
                    obj[keys[k]] = items;
                }
            }

            if (this.props.component_name === "dqErrorListViewTable") {
                obj["ID"] = i + 1;
                if (rowItems[0]["group"] === "attribute" && !["attributes","followedAttributes"].includes(selectedGroup)) {
                    obj["R.No"] = "";
                }else {
                    j = j + 1
                    obj["R.No"] = j;
                }
            } else if (this.props.component_name === "modelABTestTable") {
                obj["ID"] = i + 1;
            }
            else {
                if( this.props.component_name !== "pipelineListViewTable"){
                    obj["ID"] = i + 1;
                }
            }

            result.push(obj);
        }
        return result
    }


    getDefaultPaginationOptions() {
        let pageLimit = [10,25,50,100]
        let pageList = [{text: 5, value: 5}]
        for(let i=0;i<=(pageLimit.length-1);i++) {
           if(this.state.tableData.data.length >= pageLimit[i]){
              pageList.push({text: pageLimit[i], value: pageLimit[i]});
           }else{
              break
           }

        }
        pageList.push({text: "All" , value: this.state.tableData.data.length});
        const options = {
            custom: true,
            // totalSize: this.state.tableData.data.length,
            page: 1,  // which page you want to show as default
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: 5,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'top'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }



    getCustomPaginationOptions(pageLimitCustom) {
        let pageLimit = this.props.pageOptions;
        if (pageLimit === undefined || pageLimit === null) {
            pageLimit = pageLimitCustom;
        }

        let sizePerPage = this.props.paginationSize;

        // Add the first option
        let pageList = [];

        for (let i = 0; i <= (pageLimit.length - 1); i++) {
            const currentValue = pageLimit[i];

            if (this.state.tableData.data.length >= currentValue) {
                pageList.push({text: currentValue, value: currentValue});
            } else {
                break;
            }

        }

        // Add the 'All' option
        if(this.props.component_name === 'dqErrorListViewTable'){
            pageList.push({text: "All" , value: this.state.tableData.data.length});
        }

        if(this.props.component_name === "pipelineListRunTable"){
            pageList.push({text: "20" , value: 20});
            // pageList.push({text: "3" , value: 3});
            pageList.push({text: "All" , value: this.state.tableData.data.length});

        }

        const options = {
            custom: true,
            onPageChange: this.onPageChange,
            page:this.props.component_name === "pipelineListViewTable" ? this.props.paginationcurpage : 1,  // which page you want to show as default
            // totalSize: this.state.tableData.data.length,
            sizePerPageList: pageList, // you can change the dropdown list for size per page
            sizePerPage: sizePerPage,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 5,  // the pagination bar size.
            prePage: 'Prev', // Previous page button text
            nextPage: 'Next', // Next page button text
            firstPage: 'First', // First page button text
            lastPage: 'Last', // Last page button text
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return options;
    }

    convertData(actionType, headerValue, cell, row,rowIndex) {

        
        // If getActionComponent is provided in the props we can use that method itself
        if (this.props.customGetActionComponent !== undefined && this.props.customGetActionComponent !== null) {
            return this.props.customGetActionComponent(actionType, headerValue, cell, this.props,rowIndex);
        }

        if (headerValue === "Actions" && actionType === "component") {
            return this.getActionComponent(cell);
        } else if (headerValue === "Action" && actionType === "button") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Status" && actionType === "component") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Teammate" && actionType === "component") {
            return this.getButtonComponent(cell);
        } else if (headerValue === "Status" && actionType === "billingComponent") {
            return this.getActionComponent(cell);
        } else if (headerValue === "Receipt") {
           return <label className="btn btn-link"
                         onClick={() => this.props.pdfdownload(row)}>Download</label>
           // return <button onClick={() => this.props.pdfdownload(row)} type="button" class="btn btn-link">Download</button>

        } else if (headerValue === "Schema Learning Status") {
            let integrations = row.Actions;
            if(integrations !== undefined && integrations !== null && integrations.integration_data !== undefined && integrations.integration_data !== null) {
              let status_text = integrations.integration_data.status_text
              if(status_text === NO_DATA_SET_FOUND_TEXT) {
                 return <>{cell} <RcTooltip placement="top"
                                                overlay={
                                                    <span id={'tooltip-top'}>
                                                        No Datasets Found
                                                    </span>
                                                }
                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                >
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                </RcTooltip></>
              }
            }

        }

        else if (headerValue === "Pipeline Status") {
            let integrations = row.Actions;
            if(integrations !== undefined && integrations !== null && integrations.pipeline_integration_data !== undefined && integrations.pipeline_integration_data !== null) {
              let status_text = integrations.pipeline_integration_data.status_text
              if (status_text === NO_PIPELINES_FOUND) {
                    return (<>
                        {cell}
                        <RcTooltip placement="top"
                            overlay={
                                <span id={'tooltip-top'}>
                                    No Pipeline Found
                                </span>
                            }
                            arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                        >
                            <i>
                                <FontAwesomeIcon icon={faInfoCircle} />
                            </i>
                        </RcTooltip>
                    </>);
            }

        }
    }

        else if (headerValue === "Pipeline Status") {
            let integrations = row.Actions;
            if(integrations !== undefined && integrations !== null && integrations.pipeline_integration_data !== undefined && integrations.pipeline_integration_data !== null) {
              let status_text = integrations.pipeline_integration_data.status_text
              if(status_text === NO_DATA_SET_FOUND_TEXT) {
                 return <>{cell} <OverlayTrigger placement="top"
                                                overlay={
                                                    <Tooltip id={'tooltip-top'}>
                                                        No Datasets Found
                                                    </Tooltip>
                                                }>
                                    <i>
                                        <FontAwesomeIcon icon={faInfoCircle}/>
                                    </i>
                                </OverlayTrigger></>
              }
            }

        }

          // Power BI
          else if (headerValue === "Reports & Dashboards" && actionType === "component") {

            return (
                <span style={{cursor:'pointer'}}  className="text-blue cursor-pointer" onClick={() => this.props.pireportdatasetfullscreen(row)}>{row["Reports & Dashboards"]["pbierrordataset"]["name"]}<i className="small ml-1"><FontAwesomeIcon icon={faWindowRestore} /></i></span>
                //  <span  className="text-blue cursor-pointer" onClick={() => this.props.pireportdatasetfullscreen(row)}>{row["Reports & Dashboards"]["pbierrordataset"]["name"]}<i className="small ml-1"></i></span>
            )
    }
    else if (headerValue === "Dataset Summary" && actionType === "component") {
        
        return (
                <>
                <dl><dt>Last refresh time</dt><dd><strong>{row["Reports & Dashboards"]["pbierrordataset"]["refreshed_date"].substring(4)}</strong></dd></dl>
                <dl><dt>Last processed time</dt><dd><strong>{row["Reports & Dashboards"]["pbierrordataset"]["processedtime"].substring(4)}</strong></dd></dl>
                </>
        )
    }
    else if (headerValue === "Name" && actionType === "component") {

        return (
            <>
            <span style={{cursor:'pointer'}}  className="text-blue cursor-pointer" onClick={() => this.props.pireportfullscreen(row)}>{row["Name"]}<i className="small ml-1"><FontAwesomeIcon icon={faWindowRestore} /></i></span>
            <dl><dt>Datasource</dt><dd><strong>{row["Actions"]["pbierrordashboard"]["integration_name"]}</strong></dd></dl>
            <dl><dt>Workspace</dt><dd><strong>{row["Actions"]["pbierrordashboard"]["workspace"]}</strong></dd></dl>
            </>
            )
    }
   else if(headerValue === "Errors" && actionType === "component") {

    if(row["Quality Check Status"] === "Not yet started" ||  row["Quality Check Status"] === "In Progress"){
        return (<Image src="icon_powerbi_process" width="50%" height="20px" alt=""/>)
    }

    else if(row["Quality Check Status"] === "Completed"){
        
        let pageredirection = JSON.stringify(row["Actions"]["pbierrordashboard"]["redirection"]);
        if(row["Errors"] === "Error"){
            return (
             <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="btn btn-md btn-outline-danger">
             <span className="mr-1">{row["Actions"]["pbierrordashboard"]["error_count"]} widgets have error</span><i className="small"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-0" /></i></Link>
             );
        }
        else if(row["Errors"] === "No Error"){
            return ( <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="btn btn-md btn-outline-success">
            <span className="mr-1">No Error</span><i className="small"><FontAwesomeIcon icon={faExternalLinkAlt} className="mr-0" /></i></Link>);
        }
        else {
            return("---")
        }
    }
    else{
        return ("--");
    }
   }
   else if(headerValue === "Quality Status" && actionType === "component") {

    let pageredirection = JSON.stringify(row["Reports & Dashboards"]["pbierrordataset"]["redirection"]);

    if(row["Reports & Dashboards"]["pbierrordataset"]["qualitystatus"] === "Error"){
        return ( <Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="badge badge-light-danger border-0"><span ><FontAwesomeIcon icon={faTimesCircle} className="mr-2" /> 
        Error</span></Link>);
    }
    else if(row["Reports & Dashboards"]["pbierrordataset"]["qualitystatus"] === "No Error"){
        return (<Link to={"/monitor/dq/dq_list_view/"+pageredirection} className="badge badge-light-success border-0"><span ><FontAwesomeIcon icon={faCheckCircle} className="mr-2" /> No Error</span></Link>);
    }
    else {
        return("---")
    }
   }
   else if(headerValue === "Quality Check Status" && actionType === "component") {
        if(row["Quality Check Status"] === "Not yet started"){
            return (<span className="text-muted">Not yet started</span>);
        }
        else if(row["Quality Check Status"] === "Completed"){
            return (<span className="text-success">Completed</span>);
        }
        else if(row["Quality Check Status"] === "in-progress"){
            return (<span className="text-warning">In Progress</span>);
        }
        else if(row["Quality Check Status"] === "Failed Authentication"){
            return (<span className="text-danger">Failed Authentication</span>);
        }
        else if(row["Quality Check Status"] === "Mapping Not Found"){
            return (<><span className="text-danger">Mapping Not Found</span><RcTooltip placement="top" overlay={renderTooltip} arrowContent={<div className="rc-tooltip-arrow-inner"></div>}><Link to={"/pbi-mappiing"}><button className="btn btn-sm btn-outline-dark ml-2"><FontAwesomeIcon icon={faSitemap} className="mr-0" /></button></Link></RcTooltip></>);
        }

        }

   else if(headerValue === "Pipeline Source" &&  this.props.component_name === "pipelineListViewTable" && actionType === "component"){
    return (this.props.renderPipelineSourceHtml(row,rowIndex));
}
    else if(headerValue === "Jobs" &&  this.props.component_name === "pipelineListViewTable" && actionType === "component"){
        return (this.props.renderJobsSourceHtml(row,rowIndex));
    }

   else if(headerValue === "Pipeline Source" &&  this.props.component_name === "pipelineListViewTable" && actionType === "component"){
    return (this.props.renderPipelineSourceHtml(row,rowIndex));
}
    else if(headerValue === "Jobs" &&  this.props.component_name === "pipelineListViewTable" && actionType === "component"){
        return (this.props.renderJobsSourceHtml(row,rowIndex));
    }

        return cell;
    }

     static getDerivedStateFromProps(props, state) {
        if (props.data !== state.tableData) {
            return {tableData : props.data};
        } else if (props.paginationSize !== state.paginationSize && !EXCLUDED_COMPONENTS.includes(props.component_name)){
            return {paginationSize: props.paginationSize}
        }

        return null;
     }



    componentDidUpdate(prevProps) {
        if (prevProps.data !== undefined && this.props.data !== undefined) {
            // if (prevProps.data.data.length !== this.props.data.data.length) {
            if (JSON.stringify(prevProps.data.data) !== JSON.stringify(this.props.data.data)) {
                this.setState({tableData: this.props.data, tempData: this.props.data, selectedGroup: this.props.selectedGroup});
                this.setState({paginationSize: this.props.paginationSize});
            }
        }
        if(prevProps.selectedGroup !== this.props.selectedGroup){
          this.setState({selectedGroup: this.props.selectedGroup})
        }
        if(this.props.component_name==="pipelineListViewTable"){
            $('.react-bootstrap-table>table>tbody tr td:nth-child(3)').each(function(index) {
                        if($(this).attr('class')===undefined){
                            $(this).attr('colspan',9);
                        }
          });
        }

    }

    handleDeleteOperation() {
        this.setState({isDelete: this.props.data});
    }

    handleSeeMore() {
            var curobj = $("#"+this.state.isSeeMoreId);
            curobj.toggleClass("active");
            curobj.parent().parent().find('.react-bootstrap-table__footer').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr').toggleClass("d-none");
            curobj.parent().parent().find('table tbody tr:first').removeClass("d-none");
            curobj.parent().parent().find('.react-table__length').toggleClass("d-none");
            curobj.parent().parent().find('.react-table__filter').toggleClass("d-none");
            $("#"+this.state.isSeeMoreId+" span").text(function(i, v){
                return v === 'See Less' ? 'See More' : 'See Less'
            })

    }

    render() {
        // console.log("Count"+this.state.tableData.data.length);

        let hasData = this.state.tableData.data.length > 0;
        const customTotal = (from, to, size) => (
            <span className="react-table__info">
                    Showing <b>{from}</b> to <b>{to}</b> of <b>{size}</b> results
            </span>
        );

        const addClassFailure = (row, rowIndex) => {
            if (row === undefined || row === null) {
                return "";
            }

            if (row.Actions === undefined || row.Actions === null) {
                return "";
            }

            let integration_data = row.Actions;
            if (integration_data === undefined || integration_data === null) {
                return "";
            }

            if (integration_data.integration_data === undefined || integration_data.integration_data === null){
                return "";
            }

            let connection_status = integration_data.integration_data["status_text"];
            if (connection_status === "connection_failure") {
                return "has-table-error";
            }

            if (connection_status === NO_DATA_SET_FOUND_TEXT) {
                return ROW_WARN_CLASS;
            }

            return "";
        };

        const pageListRenderer = ({
                                      pages,
                                      onPageChange
                                  }) => {
            return (
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        {
                            pages.map(p => (
                                <button className="btn btn-success"
                                        onClick={() => onPageChange(p.page)}>
                                    {p.page}
                                </button>
                            ))
                        }
                    </div>
                </div>
            );
        };

        let options;
        let lengthLimit = 0;
        if (this.state.paginationSize === undefined || this.state.paginationSize === null ) {
            options = this.getDefaultPaginationOptions();

            lengthLimit = 5;
            if(this.props.component_name==="pipelineListViewTable"){
                lengthLimit = 1;
            }
        }
        else {

            if(this.props.component_name === "dqErrorListViewTable"||this.props.component_name ==="pipelineListRunTable") {
                options = this.getCustomPaginationOptions([1,2, 5,10,30,50,100,150,200,250]);
                lengthLimit = this.state.paginationSize;
            }else if(this.props.component_name === "modelErrorListViewTable" || this.props.component_name === "mpErrorListViewTable"){
                options = this.getCustomPaginationOptions([2, 4, 10, 25, 50]);
                lengthLimit = this.state.paginationSize;
            } else if (this.props.pageOptions !== undefined) {
                options = this.getCustomPaginationOptions(this.props.pageOptions);
                lengthLimit = this.state.paginationSize;
            }else {
                options = this.getCustomPaginationOptions([3,6,10,25,50,100]);
                lengthLimit = this.state.paginationSize;
            }

             if(ABOVE_ALERT_SUPPORTED_TABLES.includes(this.props.component_name)){
                $('tr.alert-warning-row').remove();
                setTimeout(function(){
                    var prevClassFlag = 1;
                    $('.table tbody tr').each(function() {
                            if($(this).hasClass("row-highlight has-above-alert") && prevClassFlag === 1) {
                               $(this).before('<tr class="alert-warning-row"><td colspan="6" class="p-0"><div class="alert alert-warning mb-0 py-sm-1 mt-2" role="alert"><p class="mb-0"><strong>No Refreshes found in the chosen date range. Displaying the data points of earlier refreshes.</strong></p></div></td></tr>');
                            }
                    if($(this).hasClass("alert-warning-row")){
                        prevClassFlag = 0;
                    }else {
                        prevClassFlag = 1;
                    }

             });

        }, 100);                }
        }
        options["paginationTotalRenderer"] = customTotal;
        options["pageListRenderer"] = pageListRenderer;
        options["hideSizePerPage"] = this.state.hideSizePerPage;

        let data = this.renderRow();

        const sizePerPageRenderer = ({
                                         options,
                                         currSizePerPage,
                                         onSizePerPageChange
                                     }) => (
                                        <div className="react-bootstrap-table__header">
                                        <div className="row">
                                        <div className={this.props.component_name === "dqErrorListViewTable" || this.props.component_name === "pipelineListRunTable" ? "col-12 col-lg-8" : this.props.component_name ==="pipelineListViewTable" ?
                                         "col-12 col-md-3" : "col-6 col-md-6"}> {

                                            // $('.react-bootstrap-table>table>tbody tr').each(function(index) {
                                            //     var $td = $(this).find('td:nth-child(3)');
                                            //     if ($(this).find('td').length < 4 && $td.length > 0) {
                                            //         $td.attr('colspan', 9);
                                            //     }
                                            // })
                                         }
                                                    <div className={this.props.component_name === "dqErrorListViewTable" || this.props.component_name === "pipelineListRunTable" ? "lv_tbl-filter" : "d-flex"}>
                                                    <div className="btn-group react-table__length" role="group">
                                                        <span>Show</span>
                                                        <Select
                                                            classNamePrefix='select-control'
                                                            defaultValue={lengthLimit > this.state.tableData.data.length  ? {"label": "All","value":this.state.tableData.data.length} : {"label":lengthLimit,"value":lengthLimit}}
                                                            options={options.map((option) => ({'label': option.text, 'value': option.page}))}
                                                            onChange={(selectedOption) => {
                                                                this.handlePageChange(selectedOption.value).then((res) => {
                                                                   onSizePerPageChange(selectedOption.value);
                                                                });
                                                            }}
                                                        />
                                                        <span>items</span>
                                                    </div>
                                                    {this.props.component_name === "dqErrorListViewTable" ? 
                                                        <>
                                                            <div className="row g-1 align-items-center">
                                                                <div className="col-auto pr-0 d-none d-md-inline-flex">
                                                                    <label className="col-form-label">View: </label>
                                                                </div>
                                                                <div className="col-auto pr-0" style={{minWidth: "200px"}}>
                                                                    <Select
                                                                        classNamePrefix='select-control'
                                                                        value={this.state.selectedGroup}
                                                                        options={LIST_VIEW_OPTIONS}
                                                                        isOptionDisabled={(option) => option.disabled}
                                                                        onChange={this.props.changeGroup}
                                                                    />
                                                                </div>
                                                            </div> 
                                                            <Form.Check 
                                                                custom
                                                                type='checkbox'
                                                                id='grid_include-partitions'
                                                                label="Include Partitions"
                                                                defaultChecked={this.props.showpartitions}
                                                                onChange={this.props.changePartitions}
                                                            />
                                                        </>
                                                        : ""
                                                    }
                                                </div>
                                            </div>
                                            <div id="testss" className={this.props.component_name === "dqErrorListViewTable" || this.props.component_name === "pipelineListRunTable" ? "col-12 col-lg-4" : this.props.component_name ==="pipelineListViewTable" ? "col-12 col-md-9" : "col-6 col-md-6"}>
                                            <div className="d-flex align-items-center justify-content-end">
                                            {
                                            this.props.component_name === "pipelineListRunTable" && this.props.current_tab ?
                                            <div className="btn-toolbar mr-3">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                            {(this.props.filterChanged !== true || this.props.filterCount === 0 )&&
                                                                            <svg className="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="18"
                                                                                viewBox="0 0 24 24" width="18">
                                                                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                            </svg>}
                                                                            <span className={ this.props.filterChanged===true && this.props.filterCount> 0 ? "filter-count d-inline-flex" : "filter-count d-none"}>{this.props.filterCount}</span>
                                                                            <span className="ml-1">Filter</span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#/action-1" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        value="Succeeded"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        onChange={this.props.successfulRunsCheck}
                                                                                        onClick={this.props.handleFilter}
                                                                                        id="pipeline-done" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-done">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                        <span className="ml-1">Successful Runs</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-2" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        value="Failed"
                                                                                        name="defaulErrorCheck"
                                                                                        onChange={this.props.failedRunsCheck}
                                                                                        onClick={this.props.handleFilter}
                                                                                        id="pipeline-failed" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59" /></svg>
                                                                                        <span className="ml-1">Failed Runs</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3" className="py-1">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        className="custom-control-input"
                                                                                        value="In Progress"
                                                                                        name="defaulErrorCheck"
                                                                                        onChange={this.props.stageFailedCheck}
                                                                                        onClick={this.props.handleFilter}
                                                                                        id="pipeline-progress" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <g clip-path="url(#clip0_9374_8920)">
                                                                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.58 2.58L19.41 7.41C19.79 7.79 20 8.3 20 8.83V20C20 21.1 19.1 22 18 22H5.99C4.89 22 4 21.1 4 20L4.01 4C4.01 2.9 4.9 2 6 2H13.17C13.7 2 14.21 2.21 14.58 2.58ZM13 8C13 8.55 13.45 9 14 9H18.5L13 3.5V8ZM10.675 16.888L12.075 15.513L13.475 16.888C13.6583 17.0713 13.8917 17.163 14.175 17.163C14.4583 17.163 14.6917 17.0713 14.875 16.888C15.075 16.688 15.1708 16.4505 15.1625 16.1755C15.1542 15.9005 15.0583 15.6713 14.875 15.488L13.5 14.088L14.875 12.688C15.0583 12.5047 15.15 12.2713 15.15 11.988C15.15 11.7047 15.0583 11.4713 14.875 11.288C14.675 11.088 14.4375 10.9922 14.1625 11.0005C13.8875 11.0088 13.6583 11.1047 13.475 11.288L12.075 12.688L10.675 11.288C10.4917 11.1047 10.2583 11.013 9.975 11.013C9.69167 11.013 9.45833 11.1047 9.275 11.288C9.09167 11.4713 9 11.7047 9 11.988C9 12.2713 9.09167 12.5047 9.275 12.688L10.675 14.088L9.275 15.488C9.09167 15.6713 9 15.9047 9 16.188C9 16.4713 9.09167 16.7047 9.275 16.888C9.475 17.088 9.7125 17.1838 9.9875 17.1755C10.2625 17.1672 10.4917 17.0713 10.675 16.888Z" fill="currentColor"/>
                                                                                            </g>
                                                                                            <defs>
                                                                                            <clipPath id="clip0_9374_8920">
                                                                                            <rect width="24" height="24" fill="white"/>
                                                                                            </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                        <span className="ml-1">Task Failed</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div> : ""
                                            }

                        { this.props.component_name === "pipelineListViewTable" ?
                        <>
                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            label="&nbsp;Active Runs Only"
                                                            className="custom-switch-sm mr-3"
                                                            onChange={this.props.activeRun}
                                                        />
                                                        {/* Start: Btn Group Toolbar */}
                                                        <div className="btn-toolbar mr-2">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                <RcTooltip placement="top"
                                                                                overlay={
                                                                                    <span id={'tooltip-top'}>
                                                                                        Previous {this.props.timefilter} hr
                                                                                        </span>
                                                                                }
                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                >
                                                                                <Button id="btn_pipline_prev" onClick={this.props.prevDate} variant=""><i><svg xmlns="http://www.w3.org/2000/svg" height="18" fill="#8e9398" viewBox="0 -960 960 960" width="18"><path d="M480-175.386 175.386-480 480-784.614l42.768 42.153-232.462 232.462h494.308v59.998H290.306l232.462 232.462L480-175.386Z"/></svg></i></Button>
                                                                </RcTooltip>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                <RcTooltip placement="top"
                                                                                overlay={
                                                                                    <span id={'tooltip-top'}>
                                                                                        Now                                                                                    </span>
                                                                                }
                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                >
                                                                    <Button id="btn_pipline_now" onClick={this.props.nowDate} variant="">Now</Button>
                                                                    </RcTooltip>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                <RcTooltip placement="top"
                                                                                overlay={
                                                                                    <span id={'tooltip-top'}>
                                                                                        Next {this.props.timefilter} hr
                                                                                    </span>
                                                                                }
                                                                                arrowContent={<div className="rc-tooltip-arrow-inner"></div>}
                                                                                >
                                                                    <Button id="btn_pipline_next" onClick={this.props.nextDate} variant=""><i><svg xmlns="http://www.w3.org/2000/svg" height="18" fill="#8e9398" viewBox="0 -960 960 960" width="18"><path d="m480-175.386-42.768-42.153 232.462-232.462H175.386v-59.998h494.308L437.232-742.461 480-784.614 784.614-480 480-175.386Z"/></svg></i></Button>
                                                                    </RcTooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-toolbar mr-2">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Button  onClick={this.props.applyTimeFilter.bind(this, "1","functioncall")} variant=" " className={parseInt(this.props.timeFilterActive) === 1 ? "active" : " "}>1hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button  onClick={this.props.applyTimeFilter.bind(this, "6","functioncall")} variant=" " className={parseInt(this.props.timeFilterActive) === 6 ? "active" : " "}>6hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button  onClick={this.props.applyTimeFilter.bind(this, "12","functioncall")} variant=" " className={parseInt(this.props.timeFilterActive) === 12 ? "active" : ""}>12hr</Button>
                                                                </div>
                                                                <div className="btn-toolbar__item">
                                                                    <Button onClick={this.props.applyTimeFilter.bind(this, "24","functioncall")}  variant=" " className={parseInt(this.props.timeFilterActive) === 24 ? "active" : " "} >24hr</Button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-toolbar mr-3">
                                                            <div className="btn-toolbar__wrap">
                                                                <div className="btn-toolbar__item">
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                        {this.props.pipelinesource_filter.length > 0   ? "" :
                                                                            <svg className="" xmlns="http://www.w3.org/2000/svg" fill="currentColor" height="18"
                                                                                viewBox="0 0 24 24" width="18">
                                                                                <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                                                                                <path d="M0 0h24v24H0z" fill="none" />
                                                                            </svg>}
                                                                            <span className={this.props.pipelinesource_filter.length > 0   ? "filter-count d-inline-flex" : "filter-count d-none"}>{this.props.pipelinesource_filter.length}</span>
                                                                            <span className="ml-1">Filter</span>
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        type="checkbox"
                                                                                        value="Scheduled"
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-scheduled" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-scheduled">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><g><g><path d="M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2z M16.2,16.2L11,13V7h1.5v5.2l4.5,2.7L16.2,16.2z" /></g></g></g></svg>
                                                                                        <span className="ml-1">Scheduled</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        className="custom-control-input"
                                                                                        value="In Progress"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-progress" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-progress">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><g><rect fill="none" height="24" width="24" /></g><g><path d="M6,2l0.01,6L10,12l-3.99,4.01L6,22h12v-6l-4-4l4-3.99V2H6z M16,16.5V20H8v-3.5l4-4L16,16.5z" /></g></svg>
                                                                                        <span className="ml-1">In Progress</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        value="Queued"
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-queued" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-queued">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9h-4v4h-2v-4H9V9h4V5h2v4h4v2z" /></svg>
                                                                                        <span className="ml-1">Queued</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        value="Succeeded"
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-done" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-done">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" /></svg>
                                                                                        <span className="ml-1">Success</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        value="Failed"
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-failed" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-failed">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="currentColor"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" /></svg>
                                                                                        <span className="ml-1">Failed</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="py-1 dropdown-item">
                                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                                    <Form.Control
                                                                                        type="checkbox"
                                                                                        value="Cancelled"
                                                                                        onClick={this.props.pipelinesourceFilterSelection}
                                                                                        className="custom-control-input"
                                                                                        name="defaulErrorCheck"
                                                                                        id="pipeline-cancelled" />
                                                                                    <Form.Label className="custom-control-label" for="pipeline-cancelled">
                                                                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1 1.7V4.703C10.1 4.801 10.065 4.885 10.002 4.948L8.252 6.698C8.112 6.838 7.895 6.838 7.755 6.698L6.005 4.948C5.935 4.885 5.9 4.794 5.9 4.703V1.7C5.9 1.315 6.215 1 6.6 1H9.4C9.785 1 10.1 1.315 10.1 1.7ZM1.7 5.9H4.703C4.801 5.9 4.885 5.935 4.948 5.998L6.698 7.748C6.838 7.888 6.838 8.105 6.698 8.245L4.948 9.995C4.885 10.065 4.794 10.1 4.703 10.1H1.7C1.315 10.1 1 9.785 1 9.4V6.6C1 6.215 1.315 5.9 1.7 5.9ZM5.9 14.3V11.297C5.9 11.199 5.935 11.115 5.998 11.052L7.748 9.302C7.888 9.162 8.105 9.162 8.245 9.302L9.5534 10.6104C9.20316 11.1554 9 11.804 9 12.5C9 13.3871 9.33003 14.1971 9.874 14.814C9.74907 14.9293 9.58239 15 9.4 15H6.6C6.215 15 5.9 14.685 5.9 14.3ZM14.8175 9.87713C14.9307 9.75264 15 9.58752 15 9.407V6.607C15 6.222 14.685 5.907 14.3 5.907H11.297C11.199 5.9 11.115 5.935 11.045 6.005L9.295 7.755C9.155 7.895 9.155 8.112 9.295 8.252L10.6019 9.55889C11.1487 9.20528 11.8004 9 12.5 9C13.3888 9 14.2002 9.33129 14.8175 9.87713Z" fill="currentColor"/>
                                                                                        <path d="M11.72 13.9867L12.6533 13.0533L13.5867 13.9867L13.9867 13.5867L13.0667 12.6667L14 11.7333L13.6 11.3333L12.6667 12.2667L11.7333 11.3333L11.3333 11.7333L12.2667 12.6667L11.3333 13.6L11.72 13.9867ZM12.6667 15.3333C11.9289 15.3333 11.3 15.0733 10.78 14.5533C10.26 14.0333 10 13.4044 10 12.6667C10 11.9289 10.26 11.3 10.78 10.78C11.3 10.26 11.9289 10 12.6667 10C13.4044 10 14.0333 10.26 14.5533 10.78C15.0733 11.3 15.3333 11.9289 15.3333 12.6667C15.3333 13.4044 15.0733 14.0333 14.5533 14.5533C14.0333 15.0733 13.4044 15.3333 12.6667 15.3333Z" fill="currentColor"/>
                                                                                    </svg>

                                                                                        <span className="ml-1">Cancelled</span>
                                                                                    </Form.Label>
                                                                                </div>
                                                                            </div>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div></> : "" }
                                                        <div className={this.props.component_name === "dqErrorListViewTable" || this.props.component_name === "pipelineListRunTable" ? "btn-group react-table__filter justify-content-center justify-content-lg-end" : "btn-group react-table__filter"} role="group">
                                                    <span>Search:</span>
                                                    <div className="table-search">
                                                        <input
                                                            placeholder={this.props.component_name==="pipelineListViewTable"?"Search Jobs":"Search"}
                                                            id={"searchBox"}
                                                            className="form-control"
                                                            ref={n => this.input = n}
                                                            onChange={this.handleSearchOperation}
                                                            style={{backgroundColor: 'white', align: 'right'}}
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                        </div>
                                               
                                            </div>
                                        </div>
                                    </div>
        );
        options["sizePerPageRenderer"] = sizePerPageRenderer.bind(this);
        let actionType = null;
        if (this.state.tableData.data != null && this.state.tableData.data.length > 0) {
            let rowItems = this.state.tableData.data[0];
            for (let k = 0; k < rowItems.length; k++) {
                let items = rowItems[k];
                if (items.type === "buttons") {
                    actionType = "button";
                } else if (items.type === "component") {
                    actionType = "component";
                } else if (items.type === "image") {
                    actionType = "image";
                } else if (items.type === "billingComponent") {
                    actionType = "billingComponent";
                }
                else if (items.type === "billingComponentLink") {
                    actionType = "billingComponentLink";
                }
            }
        }

        const addClassForColumns = (cell, row, rowIndex, colIndex) => {
            return "";
        }

        const rowClasses = (row, rowIndex) => {

            if (this.props.component_name === 'dqErrorListViewTable') {
                if (row['Attribute Name'] === 'Dataset Level - Includes All Attributes') {
                    let previousTime = row["Dataset Name"]["previousTime"];
                    if (previousTime === null) {
                        return 'row-highlight';
                    }

                    return 'row-highlight has-above-alert';
                }

            } else {
                return this.props.customRowHighlighter === undefined ?
                    addClassFailure(row, rowIndex) : this.props.customRowHighlighter(row, rowIndex);
            }
        };

        const classFuncForCols = this.props.getCustomClassForColumns === undefined ?
            addClassForColumns :
            this.props.getCustomClassForColumns;

        let is_table_not_sortable = (this.state.tableData.not_sortable !== undefined &&
            this.state.tableData.not_sortable === true);

        // let columns = this.state.tableData.headers.map(x => ({
        //     'dataField': x,
        //     'text': x,
        //     'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
        //     'searchable': true,
        //     'formatter': this.convertData.bind(this, actionType, x),
        //     'classes': classFuncForCols
        // }));

        let columns = this.state.tableData.headers.map((x) => (
            x==="Metric Value" ?
            {
            'dataField': x,
            'text': x,
            'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true) ? false : true,
            'searchable': true,
            'formatter': this.convertData.bind(this, actionType, x),
            'classes': classFuncForCols,
            'sortFunc': (a, b, order, dataField, rowA, rowB) => {
                if(dataField==="Metric Value"){
                a = String(a).replace(/[^0-9.-]/g, '');
                b = String(b).replace(/[^0-9.-]/g, '');
                if (order === "asc") return Number(a) - Number(b);
                 else return Number(b) - Number(a);
                }
            }
        }
        :
        {
            'dataField': x,
            'text': x,
            'sort': (NOT_SORTABLE.includes(x.toLowerCase()) || is_table_not_sortable === true || this.props.component_name === 'pipelineListViewTable' || this.props.component_name === "pipelineListRunTable") ? false : true,
            'searchable': true,
            'formatter': this.convertData.bind(this, actionType, x),
            'classes': classFuncForCols,
            'filterValue': (cell,row)=>{
                if(this.props.component_name === 'pipelineListViewTable'){
                    if(x === "Jobs"){
                        // Here we're concating the job name, so that we can search the individual jobs
                        let jobName="";
                        row[x].value.pipelinedata.map((data)=>{
                            jobName = jobName + data['job_name']
                            return null
                        })
                        return jobName
                    }
                }
                else if(this.props.component_name === "pipelinesource"){
                    if(x === "Pipeline Source Name"){
                        return row['Pipeline Source Name']
                    }
                }
                else if(this.props.component_name === "pipelineListRunTable"){
                    if (x === "Run ID") {
                        return row[x]['job_run_id'];
                    }else if (x === "Status") {
                        let status = row[x];
                        if (status === 'Succeeded') {
                            status = 'Success';
                        }
                        return status;
                    } else {
                        return "";
                    }
                }
            }
        }
        ));

        switch (this.props.component_name) {

            case "dqErrorListViewTable":
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': false,
                    'classes': classFuncForCols,
                    'formatter': (cell, row) => row["R.No"]
                }, ...columns];
                break;
            case "modelABTestTable":
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': false,
                    'classes': classFuncForCols,
                    'formatter': this.props.tableIDFormatter
                }, ...columns];
                break;
            case "pipelineListViewTable":
                    columns = [{
                        'dataField': "ID",
                        'text': "ID",
                        'sort': false,
                        'classes': classFuncForCols,
                        'formatter': this.props.tableIDFormatter
                    }, ...columns];
                    break;
            default:
            // if(this.props.component_name!=="datasourceView") {
                columns = [{
                    'dataField': "ID",
                    'text': "ID",
                    'sort': this.props.component_name === 'pipelineListViewTable'||this.props.component_name === 'pipelineListRunTable' ? false: true,
                    'classes': classFuncForCols
                }, ...columns];
            // }
                break;
        }

        const afterSearch = (newResult) => {
            $('tr.alert-warning-row').remove();
        };

        let CustomSearchBar = (props) => {
            if (this.state.isDelete) {
                this.clearSearch(props).then((res) => {
                    props.onClear();
                    if (this.input !== null && this.input !== undefined) {
                        this.input.value = "";
                    }
                });
            }
            const handleClick = () => {
                this.navigateFirstPage(props).then((res) => {
                    if(this.props.component_name === "pipelineListViewTable"){
                            this.props.searchResult(this.input.value, data)
                    }
                    else{
                        props.onSearch(this.input.value)
                    }
                });
            };
            this.handleSearchOperation = handleClick.bind(this);
            return (""
                // <div>
                //     <label htmlFor={"searchBox"}>Search: </label>
                //     <input
                //         placeholder={"Search"}
                //         id={"searchBox"}
                //         className="form-control"
                //         style={{backgroundColor: 'white', align: 'right'}}
                //         ref={n => input = n}
                //         onChange={handleClick}
                //         type="text"
                //     />
                // </div>
            );
        };

        let BindedCustomSearchBar = CustomSearchBar.bind(this);
        const contentTable = ({ paginationProps, paginationTableProps }) => (
            <>
                {
                    hasData && (this.state.tableData.data.length > lengthLimit || (this.props.component_name === "dqErrorListViewTable"))
                        ?
                        // This component is used for showing showing Dropdown and search option on table header
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        :
                        (this.props.component_name === "pipelineListViewTable" || this.props.component_name === "pipelineListRunTable"
                            ?
                            <SizePerPageDropdownStandalone {...paginationProps} />
                            :
                            ""
                        )
                }

                {hasData ? <><ToolkitProvider
                    keyField="ID"
                    columns={columns}
                    data={data}
                    search={{ afterSearch }}
                >
                    {
                        toolkitprops => {
                            let classNameForTable = this.props.component_name === 'dqErrorListViewTable' ? "table-striped" : "";
                            if (this.props.classNameForTable !== undefined) {
                                classNameForTable = this.props.classNameForTable;
                            }

                            return (
                                <>
                                    {
                                        hasData
                                            ?
                                            <>
                                                <BindedCustomSearchBar {...toolkitprops.searchProps}
                                                    {...paginationProps} />
                                            </>
                                            :
                                            ''
                                    }
                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        responsive
                                        // striped
                                        // hover
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        wrapperClasses={this.props.component_name === "environmentInfo" ? "table-responsive" :
                                            this.props.component_name === "pipelineListViewTable" ? this.props.applyTablewrapperClasses() :
                                                "table-responsive action-sticky-cell"}
                                        classes={classNameForTable}
                                        tableHeaderClass="qd-table mb-0"
                                        tableBodyClass="qd-table mb-0"
                                        className="action-sticky-cell"
                                        rowClasses={rowClasses}
                                    />
                                </>
                            );
                        }
                    }
                </ToolkitProvider></> : 

                (this.props.component_name === "pipelineListViewTable" 
                // This condition required only for pipeline list view tables
                    ? 
                        <>
                            <div className="row text-center">
                                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                    <div className="placeholder-msg">
                                        <div className="placeholder-msg__icon">
                                            <Image src="icon_placeholder_no_pipeline_data" />
                                        </div>
                                        <p>No runs identified for the chosen date</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    :
                        ""
                )
                }

                <div className="react-bootstrap-table__footer">
                    {/* This Element is for showing table footer Eg : (Showing 1 to 5 of 7 results) */}
                    <div className="row">
                        {
                            hasData ? <div className="col-sm-12 col-md-6"> <PaginationTotalStandalone {...paginationProps} /> </div>
                                :
                                ""
                        }
                        <div className="col-sm-12 col-md-6">
                            {/* This Element is for showing table footer page listing Eg: 1,2,3,4,5  */}

                            {
                                hasData && this.state.tableData.data.length > lengthLimit
                                    ?
                                    <PaginationListStandalone {...paginationProps} />
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.isSeeMore === true ?
                        <div className="table__seemore">
                            <button id={this.state.isSeeMoreId} className="btn active" onClick={this.handleSeeMore} ><span>See Less</span>
                                <i>
                                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                                </i>
                            </button>
                        </div>
                        :
                        ''
                }
            </>
        );

        const contentTable2 = ({ paginationProps, paginationTableProps }) => (
            <>
                {
                        // This component is used for showing showing Dropdown and search option on table header
                        <SizePerPageDropdownStandalone {...paginationProps} />
                }

                <>
                    <ToolkitProvider
                        keyField="ID"
                        columns={columns}
                        data={data}
                        search={{ afterSearch }}
                    >
                    {
                        toolkitprops => {
                            let classNameForTable = this.props.component_name === 'dqErrorListViewTable' ? "table-striped" : "";
                            if (this.props.classNameForTable !== undefined) {
                                classNameForTable = this.props.classNameForTable;
                            }
                            return (
                                <>
                                    {
                                            <>
                                                <BindedCustomSearchBar {...toolkitprops.searchProps}
                                                    {...paginationProps} />
                                            </>
                                    }
                                    <BootstrapTable
                                        bootstrap4
                                        bordered={false}
                                        responsive
                                        // striped
                                        // hover
                                        {...toolkitprops.baseProps}
                                        {...paginationTableProps}
                                        wrapperClasses={this.props.component_name === "environmentInfo" ? "table-responsive" :
                                            this.props.component_name === "pipelineListViewTable" ? this.props.applyTablewrapperClasses() :
                                                "table-responsive action-sticky-cell"}
                                        classes={classNameForTable}
                                        tableHeaderClass="qd-table mb-0"
                                        tableBodyClass="qd-table mb-0"
                                        className="action-sticky-cell"
                                        rowClasses={rowClasses}
                                    />
                                </>
                            );
                        }
                    }
                </ToolkitProvider>
                    </>
                {this.props.component_name === "pipelineListRunTable" && this.state.tableData.data.length === 0 && this.props.current_tab
                                    ?
                                        <>
                                            <div className="row text-center">
                                                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                                    <div className="placeholder-msg">
                                                        <div className="placeholder-msg__icon">
                                                            <Image src="icon_placeholder_no_pipeline_data" />
                                                        </div>
                                                        <p>No Runs Found</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    : (this.props.component_name === "pipelineListRunTable" && this.state.tableData.data.length === 0 ?
                                        <>
                                            <div className="row text-center">
                                                <div className="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                                                    <div className="placeholder-msg">
                                                        <div className="placeholder-msg__icon">
                                                            <Image src="icon_placeholder_no_pipeline_data" />
                                                        </div>
                                                        <p>No Failed Runs Found</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :"")
                                    }
            </>
        );

         if (hasData) {
            return (
                <>
                    <PaginationProvider
                        pagination={
                            paginationFactory(options)
                        }
                    >
                        {contentTable}
                    </PaginationProvider>
                </>
            );

        } else {
            switch(this.props.component_name) {
                case "modelErrorListViewTable":
                case "pipelineListViewTable":
                    return (
                        <>
                            <PaginationProvider
                                pagination={
                                    paginationFactory(options)
                                }
                            >
                                {contentTable}
                            </PaginationProvider>
                        </>
                    );
                case "pipelineListRunTable":
                    return (
                        <>
                            <PaginationProvider
                                pagination={
                                    paginationFactory(options)
                                }
                            >
                                {contentTable2}
                            </PaginationProvider>
                        </>
                    );
                case "mpErrorListViewTable":
                      if (this.props.message != null && this.props.message === "in-progress"){
                         return <NoErrorInPreview
                                message={'Model Profiling is in-progress.'} footer={''}/>
                      } else {
                        return <NoModelComponent model = {true}/>
                      }
                case "mpCompareViewTable":
                    if (this.props.message != null && this.props.message === "in-progress") {
                        return <NoErrorInPreview
                            message={'Model Profiling is in-progress.'} footer={''}/>
                    } else {
                        const subText = "No model AB test configured yet.";
                        return <NoModelComponent model = {true} customButtonName={"Configure Model AB"}
                                                 noContentSubtext={subText}
                        />
                    }
                case "dqErrorListViewTable":
                   if (this.props.message !== null){
                        let message = this.props.message;
                        return <NoErrorInPreview
                            message={message}
                            footer={''}
                        />
                   } else {
                        return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4 className='opacity-50'>No data available</h4>
                                <p>For help, check out the <a href={get_document_link(this.props.component_name)} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div>
                    );
                   }
                case "teamMate":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>Teammates not available</h4>
                            <p>Try broadening your filters or search terms.</p>
                          </div>
                    );
                case "invitation":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>We couldn't find any invitations sent from this account</h4>
                            <p>No invitation at the moment.</p>
                          </div>
                    );
                case "requests":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </i>
                            </h1>
                            <h4>You have no incoming team requests.</h4>
                          </div>
                    );
                case "modelABTestTable":
                    return(<div className="text-center text-muted py-5 w-100">
                            <h1 className="text-muted">
                                <i>
                                    <FontAwesomeIcon icon={faTable}/>
                                </i>
                            </h1>
                            <h4>Model AB is not configured</h4>
                            <p>Please configure Model AB test</p>
                          </div>
                    );
                    // case "pipelineListViewTable":
                    //     return(this.props.errMessage());
                    default:
                    return (<div className="text-center text-muted py-5">
                                <h1><i><FontAwesomeIcon icon={faTable}/></i></h1>
                                <h4>No data available</h4>
                                <p>For help, check out the <a href={get_document_link(this.props.component_name)} rel="noopener noreferrer" target="_blank">{QUALDO_DOCUMENTATION}</a></p>
                            </div>
                    );
            }
        }
    }
}

export default QualdoDataTable;