import React from 'react';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import { postPagerdutyData }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils';
import InputGroup from 'react-bootstrap/InputGroup';

class PagerdutyForm extends React.Component {

    constructor(props) {
        super(props);
        this.postPagerdutyData = postPagerdutyData.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeAccessTokenIcon = this.ChangeAccessTokenIcon.bind(this);
        this.state = {
            accessTokenIcon:faEye,
            accessTokenType:'password',
            channels: [],
            hideSubmitButton: false,
            pagerdutyData: {
                consumeName: '',
                token: ''
            }
        };
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    componentDidMount() {
        if (this.props.exConsumeDetails === undefined) {
            return;
        }
        let pagerdutyData = this.state.pagerdutyData;
        const exConsumeItem = this.props.exConsumeDetails.consume_item;

        pagerdutyData.consumeName = exConsumeItem.consume_group_name;

        const config_details = exConsumeItem.config_details;
        pagerdutyData.token = config_details.token;
        this.setConsumeData(pagerdutyData);

    }
    setConsumeData(data) {
        this.setState({
            pagerdutyData: data
        });
    }

    requiresConsumeUpdate(pagerdutyPostData) {
        const exConsumeItem = this.props.exConsumeDetails.consume_item;
        if (pagerdutyPostData.consume_group_name !== exConsumeItem.consume_group_name) {
            return true;
        }
        const currentConfiguration = pagerdutyPostData.config_details;
        const exConfiguration = exConsumeItem.config_details;
        if (currentConfiguration.token !== exConfiguration.token) {
           return true;
        }
    }

    ChangeAccessTokenIcon() {
        let state = this.state.accessTokenIcon;
        if (state.iconName === "eye") {
            this.setState({accessTokenIcon: faEyeSlash});
            this.setState({accessTokenType: "text"})
          } else {
            this.setState({accessTokenIcon: faEye});
            this.setState({accessTokenType: "password"})
          }
    }

    render() {
        const pagerdutyValidationSchema = yup.object({
            consumeName: yup.string().min(1,"Name must be at least 1 characters").required("Notification name is a required field").test(
                'Notification name test',
                'invalid Notification name',
                function test(value) {
                   let val = userValidation(value,"input");
                   return val;
            }
            ),
            token: yup.mixed().required("Key is a required field")
        });

        return (
                <>
                {this.state.pagerdutyData.consumeName && (
                    <Formik
                          validationSchema={pagerdutyValidationSchema}
                          onSubmit={this.postPagerdutyData}
                          initialValues={this.state.pagerdutyData}
                          enableReinitialize={true}
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                        <Form className="form-label-right-md" onSubmit={handleSubmit}>
                            <Form.Group controlId="consumeName" className="row">
                                <Form.Label className="col-md-4 col-form-label">Notification Name <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                      <Form.Control
                                            type="text"
                                            name="consumeName"
                                            value={values.consumeName}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={errors.consumeName && touched.consumeName}
                                            placeholder="Pagerduty Integration Name"/>
                                      <Form.Control.Feedback type="invalid">
                                            { touched.consumeName && errors.consumeName }
                                      </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="token" className="row">
                                <Form.Label className="col-md-4 col-form-label">Events API V2 - Integration Key <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                  <Form.Control
                                        type={ this.state.accessTokenType }
                                        name="token"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.token}
                                        className="form-control"
                                        isInvalid={errors.token && touched.token}
                                        placeholder="Pagerduty - Events API V2 - Integration Key"
                                        autoComplete={this.props.autocomplete}
                                        />
                                  <Form.Control.Feedback type="invalid">
                                       { touched.token && errors.token }
                                  </Form.Control.Feedback>
                                  <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.ChangeAccessTokenIcon} icon={this.state.accessTokenIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                                </div>
                            </Form.Group>
                            <Form.Group className="row">
                                <div className="col-md-5 offset-sm-4">
                                    <button type="button" onClick={() => this.props.exConsumeDetails ? this.props.closeModal() : this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                    </button>
                                </div>
                            </Form.Group>
                        </Form>
                        )}
                    </Formik>
                    )}
                </>
            );

    }
}

export default PagerdutyForm;
