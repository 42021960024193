import React from 'react';
import Select from 'react-select';
import { faCheck} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
class Integration extends React.Component {
    constructor(props) {
        super(props);
        this.setForm = this.setForm.bind(this);
        this.closeTestConnection = this.closeTestConnection.bind(this);
        this.setIntegrationData = this.setIntegrationData.bind(this);
        this.setSubmitButton = this.setSubmitButton.bind(this);
        this.setData = this.setData.bind(this);
        this.fill_details= this.fill_details.bind(this);
        this.state = {
            submit_button:false,
            testConnection:null,
            formType:  "azure_blob",
            formType_option:null,
            integration_data:{
                integration_type:"azure_blob",
                data_store_type: "filesystem"
            }
        };
       // this.fill_details();
    }

    fill_details() {
        let defaultValues = null;
        if(this.props.env_id !==undefined && this.props.env_id !==null ) {
            localStorage.setItem("env_id", this.props.env_id);
        }
        if (defaultValues === null ) {
            //eslint-disable-next-line
            this.state.formType_option = {"label":"Azure Blob", "value":'azure_blob'};
        }
    }

    setForm = (selectedOption) => {
        this.setState ({
            formType: selectedOption["value"],
        });
        this.closeTestConnection();
    }

    closeTestConnection() {
        this.setState ({
            testConnection: null
        });
    }

    setIntegrationData(data) {
        this.setState ({
            integration_data: data
        });
    }

    setData(integration_data,testConnection_data) {
       this.setState({testConnection:testConnection_data,
                      integration_data:integration_data,
       });
    }

    setSubmitButton(value) {
        this.setState({submit_button:value});
    }

    
  


    FillFormDetails(integration_details) {
        let defaultValue, formType = null;
        const integration_type = integration_details["integration_type"];
        if (integration_type === undefined){
            return [null, null];
        }
        if(integration_type === "azure_blob") {
            formType = "azure_blob";
            defaultValue = {"label":"Azure Blob", "value":'azure_blob'};
        } else if (integration_type === "azure_data_lake") {
             formType = "azure_data_lake";
             defaultValue = {"label":'Azure Data Lake', "value":'azure_data_lake'};
        } else if (integration_type.includes("snow_flake")) {
             formType = "snowflake";
             defaultValue = {"label":'Snowflake', "value":'snowflake'};
        }  else if (integration_type.includes("s3")) {
             formType = "s3";
             defaultValue = {"label":'AWS S3', "value":'s3'};
        } else if (integration_type.includes("big_query")) {
             formType = "big_query";
             defaultValue = {"label":"Google BigQuery", "value":'big_query'};
        }
        else {
             formType = "AWS";
             defaultValue = {"label":'AWS', "value":'AWS'};
        }
        return [defaultValue, formType];
    }

    getAddIntegrationContent(data_source_option){
        return (
            <>
                <div className = "portlet portlet-2col">
                    <div className="portlet-title">
                        <div className="caption">
                            <span className="text-uppercase font-dark">Add a PII Rule</span>
                        </div>
                        <div className="actions"></div>
                    </div>
                    <div className = "portlet-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="portlet-body">
                                    <div className="form-row">
                                        <div className="form-group col-md-3 hello">
                                            <label>Choose a Datasource type </label>
                                            <Select
                                                id="choose_datasource_type_add"
                                                onChange={this.setForm}
                                                defaultValue={this.state.formType_option}
                                                options={data_source_option}
                                                placeholder="Choose Data Source"
                                                classNamePrefix='select-control'
                                            />
                                        </div>
                                        <div className="form-group col-md-3 hello">
                                            <label class="form-label">PII Regular Expression</label>
                                            <input type="text" class="form-control" placeholder="Regular Expression" />
                                        </div>
                                        <div class="form-group col-md-3 hello text-right" style={{"marginTop":"28px"}}>                                           
                                            <button type="button" class="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                            <button type="button" class="btn btn-primary btn-circle mr-2"> <FontAwesomeIcon icon={faCheck}/>Add</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>    
                </div>
             </>
        );
    }

    getEditIntegrationContent(data_source_option){
        return (

            <div className = "portlet portlet-2col">
            <div className="portlet-title">
                <div className="caption">
                    <span className="text-uppercase font-dark">EDIT A PII RULE</span>
                </div>
                <div className="actions"></div>
            </div>
            <div className = "portlet-body">
                <div className="row">
                    <div className="col-md-12">
                        <div className="portlet-body">
                            <div className="form-row">
                                <div className="form-group col-md-3 hello">
                                    <label>PII Name </label>
                                    <Select
                                        id="choose_datasource_type_add"
                                        onChange={this.setForm}
                                        defaultValue={this.state.formType_option}
                                        options={data_source_option}
                                        placeholder="Choose PII Name"
                                        classNamePrefix='select-control'
                                    />
                                </div>
                                <div className="form-group col-md-3 hello">
                                    <label class="form-label">PII Name :</label>
                                    <input type="text" class="form-control" placeholder="PII Name" />
                                </div>
                                <div className="form-group col-md-3 hello">
                                    <label class="form-label">PII Regular Expression :</label>
                                    <input type="text" class="form-control" placeholder="Regular Expression" />
                                </div>
                                <div class="form-group col-md-3 hello text-right" style={{"marginTop":"28px"}}>                                           
                                            <button type="button" class="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                            <button type="button" class="btn btn-primary btn-circle mr-2"> <FontAwesomeIcon icon={faCheck}/>Update</button>
                                </div>

                            </div>
                        </div>
                    </div></div>
            </div>    
        </div>
        );
    }

    render() { 
        console.log('integration');
        console.log(this.props.isEdit);
        let data_source_option=[{"label":"Azure Blob", "value":'azure_blob'},
        {"label":"Azure Data Lake", "value":'azure_data_lake'},{"label":'Snowflake', "value":'snowflake'}];
        return (
            <>
            { this.props.isEdit === true? this.getEditIntegrationContent(data_source_option) : this.getAddIntegrationContent(data_source_option)} 
            </>
        );
    }
}
export default Integration;