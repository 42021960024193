// App base url will be fetched from browser of ui

// Patch Base URL
// export const APP_BASE_URL = "patch.qualdo.ai"

// Dev Base URL
// export const APP_BASE_URL = "dev-qualdo.eastus.cloudapp.azure.com"

// Staging Base URL
// export const APP_BASE_URL = "stg-qualdo.eastus.cloudapp.azure.com"

// Enterprise Base URL
// export const APP_BASE_URL = "enterprise.qualdo.ai"

export const APP_BASE_URL = window.location.hostname

export const iam_ip = APP_BASE_URL+"/iam"
export const iam_google_signup = APP_BASE_URL+"/iam"
export const ui_service_ip = APP_BASE_URL+"/ui-api-service"
export const console_ip = APP_BASE_URL+"/console"

// Powerbi base url will be fetched from env variable.
export const powerbi_service_ip = process.env.REACT_APP_POWERBI_BASE_URL+"/ui-api-service"
