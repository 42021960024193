import React from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faChartLine, faArrowCircleDown,  faPercentage} from '@fortawesome/free-solid-svg-icons';
import Image from '../components/image';
import ReactECharts from 'echarts-for-react';

class ModelBias extends React.Component {

    constructor(props) {
        super(props);
        this.changeModel = this.changeModel.bind(this);
    }

    changeModel(event, obj) {

    }

    render() {

        let modelOption = [{"label": "Loan Data Model", "value": "Loan Data Model"}];
        let attributeOption = [{"label": "Gender", "value": "Gender"}];
        const options1 = {
                          xAxis: {
                            type: 'category',
                            data: ['Gender']
                          },
                          yAxis: {
                            type: 'value',
                            min: 0,
                            max: 0.6,
                            name: "Parity Difference",
                            nameLocation: 'center',
                            nameGap: 27
                          },
                          color:["#4fd2cc"],
                          series: [
                            {
                              data: [0.38],
                              type: 'bar',
                              showBackground: true,
                              backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                              },
                              markLine: {
                                lineStyle: {color: "blue"},
                                data: [{ yAxis: 0, name: 'Low' },
                                { yAxis: 0.1, name: 'High' }]
                              }
                            }
                          ]
                        };
        const options2 = {
                          xAxis: {
                            type: 'category',
                            data: ['Gender']
                          },
                          yAxis: {
                            type: 'value',
                            min: 0,
                            max: 0.6,
                            name: "Equalized Odds Difference",
                            nameLocation: 'center',
                            nameGap: 27
                          },
                          color:["#4fd2cc"],
                          series: [
                            {
                              data: [0.51],
                              type: 'bar',
                              showBackground: true,
                              backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                              },
                              markLine: {
                                lineStyle: {color: "blue"},
                                data: [{ yAxis: 0, name: 'Low' },
                                { yAxis: 0.1, name: 'High' }]
                              }

                            }
                          ]
                        };
        const options3 = {
                          responsive: true,
                          xAxis: {
                            type: 'category',
                            data: ['Gender']
                          },
                          yAxis: {
                            type: 'value',
                            min: 0,
                            max: 1.5,
                            name: "Demographic Parity Ratio",
                            nameLocation: 'center',
                            nameGap: 27
                          },
                          color:["#4fd2cc"],
                          series: [
                            {
                              data: [0.44],
                              type: 'bar',
                              showBackground: true,
                              backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                              },
                              markLine: {
                                lineStyle: {color: "blue"},
                                data: [{ yAxis: 0.8, name: 'Low' },
                                { yAxis: 1.25, name: 'High' }]
                              }

                            }
                          ]
                        };

        const options4 = {

                          xAxis: [
                            {
                              type: 'value',
                              min: 0,
                              max: 100
                            }
                          ],

                          yAxis: [
                            {
                              type: 'category',
                              show: false,
                              axisTick: {
                                show: false
                              },
                              data: ['Mon']
                            }
                          ],
                          series: [

                            {
                              name: 'Selection Rate',
                              type: 'bar',
                              color: "#4ca3ff",
                              label: {
                                show: true,
                                color: 'black',
                                position: 'right',
                                fontSize: 20,
                                formatter: function(d) {
                                        return  d.data + "%";
                                    }
                              },
                              emphasis: {
                                focus: 'series'
                              },
                              data: [29.41]
                            }
                          ]
                        };
        const options5 = {

                          xAxis: [
                            {
                              type: 'value',
                              min: 0,
                              max: 100
                            }
                          ],

                          yAxis: [
                            {
                              type: 'category',
                              show: false,
                              axisTick: {
                                show: false
                              },
                              data: ['Mon']
                            }
                          ],
                          series: [

                            {
                              name: 'Selection Rate',
                              type: 'bar',
                              color: "#4ca3ff",
                              label: {
                                show: true,
                                color: 'black',
                                position: 'right',
                                fontSize: 20,
                                formatter: function(d) {
                                    return  d.data + "%";
                                }
                              },
                              emphasis: {
                                focus: 'series'
                              },
                              data: [67.09]
                            }
                          ]
                        };

        const options6 = {

                          xAxis: [
                            {
                              type: 'value',
                              show:false,
                              min: -100,
                              max: 100
                            }
                          ],
                          yAxis: [
                            {
                              type: 'category',
                              axisTick: {
                                show: false
                              },
                              data: ['Error Rate'],
                              axisLabel:{
                                show: false
                              },
                            }
                          ],
                          series: [

                            {
                              name: 'Income',
                              type: 'bar',
                              stack: 'Total',
                              color: '#ffbc39',
                              label: {
                                show: true,
                                position: 'right',
                                formatter: function(d) {
                                    return  d.data + "%";
                                }

                              },
                              emphasis: {
                                focus: 'series'
                              },
                              data: [5.88]
                            },
                            {
                              name: 'Expenses',
                              type: 'bar',
                              stack: 'Total',
                              color: "#f64f61",
                              label: {
                                show: true,
                                position: 'left',
                                formatter: function(d) {
                                    return ((-1) * d.data) + "%";
                                }
                              },
                              emphasis: {
                                focus: 'series'
                              },
                              data: [-35.29]
                            }
                          ]
                        };


        const options7 = {

                  xAxis: [
                    {
                      type: 'value',
                      show:false,
                      min: -100,
                      max: 100,

                    }
                  ],
                  yAxis: [
                    {
                      type: 'category',
                      axisTick: {
                        show: false
                      },
                      axisLabel:{
                        show: false
                      },
                      data: ['Error Rate']
                    }
                  ],
                  series: [

                    {
                      name: 'Income',
                      type: 'bar',
                      stack: 'Total',
                      color: '#ffbc39',
                      label: {
                        show: true,
                        position: 'right',
                        formatter: function(d) {
                            return  d.data + "%";
                        }

                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [2.53]
                    },
                    {
                      name: 'Expenses',
                      type: 'bar',
                      stack: 'Total',
                      color: "#f64f61",
                      label: {
                        show: true,
                        position: 'left',
                        formatter: function(d) {
                            return ((-1) * d.data) + "%";
                        }
                      },
                      emphasis: {
                        focus: 'series'
                      },
                      data: [-6.3]
                    }
                  ]
                };
        return (
            <>
                {
                    <div className="qd-tab__content-action">
                        <div className="caption">
                            <div className="alert moniker-alert" role="alert">
                                <p className="mb-0">
                                    <strong> Your data last profiled at Thu, 15 Apr 2021 09:41:54 GMT</strong>
                                </p>
                            </div>
                        </div>
                        <div className="actions d-flex" >
                             <Select
                                id="chooseModelForBias"
                                name="data_sources"
                                filterOption={({label}, query) => label.includes(query)}
                                className="mr-2"
                                options={modelOption}
                                onChange = {this.changeModel}
                                classNamePrefix='form-control'
                                placeholder="Choose Model"
                              />

                              <Select
                                id="choosePVForBias"
                                name="data_sources"
                                filterOption={({label}, query) => label.includes(query)}
                                className="mr-3"
                                options={attributeOption}
                                onChange = {this.changeModel}
                                classNamePrefix='form-control'
                                placeholder="Choose Protected Attributes"
                              />
                        </div>
                    </div>
                }
                {
                    <div className="form-row mt-3">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div className="dashboard-stat dashboard-stat-info">
                                <div className="details">
                                    <div className="desc-header">
                                        <div className="title text-info">Accuracy</div>
                                        <div className="help-icon" data-toggle="modal" data-target="#model__help">
                                            <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                        </div>
                                    </div>
                                    <div className="desc">
                                        <span className="desc-value">85.42</span>
                                        <span className="desc-percentage">%</span>
                                    </div>
                                    <div className="chart">
                                        <Image src="areachartinfo" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div className="dashboard-stat dashboard-stat-success">
                                <div className="details">
                                    <div className="desc-header">
                                        <div className="title text-success">Precision</div>
                                        <div className="help-icon" data-toggle="modal" data-target="#model__help">
                                            <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                        </div>
                                    </div>
                                    <div className="desc">
                                        <span className="desc-value">94.83</span>
                                        <span className="desc-percentage">%</span>
                                    </div>
                                    <div className="chart">
                                        <Image src="areachartsuccess" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div className="dashboard-stat dashboard-stat-warning">
                                <div className="details">
                                    <div className="desc-header">
                                        <div className="title text-warning">Recall</div>
                                        <div className="help-icon" data-toggle="modal" data-target="#model__help">
                                            <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                        </div>
                                    </div>
                                    <div className="desc">
                                        <span className="desc-value">83.33</span>
                                        <span className="desc-percentage">%</span>
                                    </div>
                                    <div className="chart">
                                        <Image src="areachartwarning" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div className="dashboard-stat dashboard-stat-danger">
                                <div className="details">
                                    <div className="desc-header">
                                        <div className="title text-danger">Selection Rate</div>
                                        <div className="help-icon" data-toggle="modal" data-target="#model__help">
                                            <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                        </div>
                                    </div>
                                    <div className="desc">
                                        <span className="desc-value">60.42</span>
                                        <span className="desc-percentage">%</span>
                                    </div>
                                    <div className="chart">
                                        <Image src="areachartdanger" alt=""/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div className="portlet has-numbered-title">
                        <div className="portlet-title">
                            <div className="caption">
                                <span className="circle-number circle-number-gray">1</span>
                                <span className="text-uppercase font-dark">Fairness Metrics</span>
                            </div>
                            <div className="actions d-flex">
                                <span>
                                    <a className="help-icon" data-toggle="modal" data-target="#modal__help">
                                        <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="qd-grid qd-grid-monitor">
                                <div className="qd-grid-item qd-section">
                                    <div className="row row-sm">
                                        <div className="col">
                                            <div className="qd-monitor__stat">
                                                <div className="qd-monitor__body pt-4">
                                                    <div id="bias-chart-1" style={{"height":"250px"}}>
                                                        <ReactECharts option={options1} />
                                                    </div>
                                                </div>
                                                <div className="qd-monitor__stat-title">
                                                    <span className="dot bg-success"></span>
                                                    Parity Difference
                                                </div>
                                                <div className="qd-monitor__footer">
                                                    <div className="error-detail">
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faChartLine}/></i>
                                                            <span><label>High Value:</label>0.1</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faArrowCircleDown}/></i>
                                                            <span><label>Low Value:</label>0</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faPercentage}/></i>
                                                            <span><label>Current Value:</label>0.38</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="qd-monitor__stat">
                                                <div className="qd-monitor__body pt-4">
                                                    <div id="bias-chart-2" style={{"height":"250px"}}>
                                                        <ReactECharts option={options2} />
                                                    </div>
                                                </div>
                                                <div className="qd-monitor__stat-title">
                                                    <span className="dot bg-success"></span>
                                                    Equalized Odds Difference
                                                </div>
                                                <div className="qd-monitor__footer">
                                                    <div className="error-detail">
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faChartLine}/></i>
                                                            <span><label>High Value:</label>0.1</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faArrowCircleDown}/></i>
                                                            <span><label>Low Value:</label>0</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faPercentage}/></i>
                                                            <span><label>Current Value:</label>0.51</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="qd-monitor__stat">
                                                <div className="qd-monitor__body pt-4">
                                                    <div id="bias-chart-3" style={{"height":"250px"}}>
                                                        <ReactECharts option={options3} />
                                                    </div>
                                                </div>
                                                <div className="qd-monitor__stat-title">
                                                    <span className="dot bg-success"></span>
                                                    Demographic Parity Ratio
                                                </div>
                                                <div className="qd-monitor__footer">
                                                    <div className="error-detail">
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faChartLine}/></i>
                                                            <span><label>High Value:</label>0.8</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faArrowCircleDown}/></i>
                                                            <span><label>Low Value:</label>1.25</span>
                                                        </p>
                                                        <p>
                                                            <i><FontAwesomeIcon icon={faPercentage}/></i>
                                                            <span><label>Current Value:</label>0.44</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div className="portlet has-numbered-title">
                        <div className="portlet-title">
                            <div className="caption">
                                <span className="circle-number circle-number-gray">2</span>
                                <span className="text-uppercase font-dark">Model Performance Metrics (Class-Wise)</span>
                            </div>
                            <div className="actions d-flex">
                                <span>
                                    <a className="help-icon" data-toggle="modal" data-target="#modal__help">
                                        <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="react-bootstrap-table table-responsive demo-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Gender</th>
                                            <th>Accuracy</th>
                                            <th>Precision</th>
                                            <th>Recall</th>
                                            <th style={{"width": "450px"}}>Error rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>0</td>
                                            <td><span className="text-danger">58.82%</span></td>
                                            <td><span className="text-danger">80%</span></td>
                                            <td><span className="text-danger">40%</span></td>
                                            <td><div id="distchart1">
                                                <ReactECharts style={{"height": "50px"}} option={options6} />
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td><span className="text-success">91.14%</span></td>
                                            <td><span className="text-success">96.23%</span></td>
                                            <td><span className="text-success">91.07%</span></td>
                                            <td><div id="distchart2">
                                                <ReactECharts style={{"height": "50px"}} option={options7} />
                                            </div></td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Class disparities in Performance</td>
                                            <td className="font-weight-bold"><span>32.32%</span></td>
                                            <td className="font-weight-bold"><span>16.23%</span></td>
                                            <td className="font-weight-bold"><span>51.07%</span></td>
                                            <td className="font-weight-bold"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    <div className="portlet has-numbered-title">
                        <div className="portlet-title d-none">
                            <div className="caption">
                                <span className="circle-number circle-number-gray">3</span>
                                <span className="text-uppercase font-dark">Model Performance Metrics (Class-Wise)</span>
                            </div>
                            <div className="actions d-flex">
                                <span>
                                    <a className="help-icon" data-toggle="modal" data-target="#modal__help">
                                        <i><FontAwesomeIcon icon={faQuestionCircle}/></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                        <div className="portlet-body">
                            <div className="react-bootstrap-table table-responsive demo-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Gender</th>
                                            <th>Selection Rate</th>
                                            <th style={{"width": "500px"}}>Selection Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>0</td>
                                            <td><span className="text-danger">29.41%</span></td>
                                            <td>
                                                <div id="barplot1" >
                                                    <ReactECharts style={{"height": "50px"}} option={options4} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>1</td>
                                            <td><span className="text-success">67.09%</span></td>
                                            <td>
                                                <div id="barplot2" >
                                                    <ReactECharts style={{"height": "50px"}} option={options5} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold">Class disparities in Predictions</td>
                                            <td className="font-weight-bold"><span>32.32%</span></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </>
        );
    }
}

export default ModelBias;