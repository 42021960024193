import React from 'react';
import Image from '../components/image';
import Load from '../components/loadAction';
import BasicPortlet from '../components/basicPortlet';
import DatabricksForm from './components/DatabricksForm';
import WebhookForm from './components/WebhookForm';
import AwsLambdaForm from './components/awsLambdaForm';
import GCPCloudFunctionForm from './components/gcpCloudFunctions';
import AirflowForm from './components/AirflowForm';
import AWSSagemakerForm from './components/AWSSagemakerForm';
import KubeflowForm from './components/KubeflowForm';
import QualdoDataTable from '../components/bootstrapTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import {postMixpanel} from "../mixpanel";
import { checkPermissions } from '../utils/common_utils';


class PipelineTrigger extends React.Component {

    constructor(props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleButton = this.handleButton.bind(this);
        this.handleRadioButton = this.handleRadioButton.bind(this);
        this.state={
            // For Alpha Release only webhook and Databricks option is there. Remaining features will be enabled in the future.
            options: [{"label": "Webhook", "value": "Webhook"},{"label":"Databricks","value":"Databricks"},{"label":"AWSLambda","value":"awsLambda"}],
            selectedOption: null,
            isClicked: false,
            pipelineType: "webhook",
            title: "Add New Webhook"
        }
    }

    handleCancel() {
        let value = this.state.isClicked ? false : true;
        this.setState ({isClicked: value});
    }

    handleRadioButton = e =>{
       this.setState({pipelineType:e.target.value});
       if(e.target.value === "webhook") {
          this.setState({title:"Add New Webhook"});
       }else if(e.target.value === "databricks") {
          this.setState({title:"Add Databricks"});
       }else if(e.target.value === "awsLambda") {
          this.setState({title:"Add AWS Lambda"});
       }else if(e.target.value === "gcpCloudFunction") {
        this.setState({title:"Add GCP Cloud Function"});
       }else if(e.target.value === "airflow") {
        this.setState({title:"Add Airflow"});
       }else if(e.target.value === "awsSagemaker") {
        this.setState({title:"Add AWS Sagemaker"});
       }

    }

    handleButton() {
      postMixpanel("clicked Add Pipeline Channel in Alerts-> pipeline tab")
      let value = this.state.isClicked ? false : true;
      this.setState ({isClicked: value});
    }

    renderPipelineTriggerForm(pipelineType){
      switch(pipelineType){
        case "webhook":
            return(
                <WebhookForm  
                    showToast={this.props.showToast} 
                    handleCancel={this.handleCancel}
                />
            );
        case "databricks":
            return(
                <DatabricksForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        case "awsLambda":
            return(
                <AwsLambdaForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        case "gcpCloudFunction":
            return(
                <GCPCloudFunctionForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                />
            );
        case "airflow":
            return(
                <AirflowForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        case "awsSagemaker":
            return(
                <AWSSagemakerForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        case "kubeflow":
            return(
                <KubeflowForm  
                    showToast={this.props.showToast}  
                    handleCancel={this.handleCancel}
                    autocomplete = "new-password"
                />
            );
        default:
            break;
      }
    }

    render() {
        
        let disableAlertAction = checkPermissions('Alerts')
        let pipelineType = this.state.pipelineType;
        return (
            <>
            <div className="col-md-8 offset-md-2 text-center p-3">
                <i className="d-inline-block mb-3">
                    <Image src="pipeline trigger"/>
                </i>
                <h4 className="mb-3">Add a Pipeline Channel</h4>
                <p>Configure a Pipeline Channel to trigger an external hook for a custom alert on a selected metric.</p>
                <div className="d-flex justify-content-center">
                    <form className="form-inline">
                        <div className="form-group mx-sm-3 mb-2 text-left">
                        </div>
                    </form>
                    <button disabled={disableAlertAction} type="button" onClick={this.handleButton} className="btn btn-primary mb-2 navto_dq-add-alert-metrics"><FontAwesomeIcon icon={faBolt}/>  Add a Pipeline Channel</button>
                </div>
            </div>
            {this.state.isClicked ?
                    <BasicPortlet
                        className="pb-0" title={"Add a Pipeline Channel"} bodyClassName="pb-0" id="addPipelineChannel"
                        content={<div className="row">
                                      <div className="col-lg-10 col-md-12 pt-4">
                                           <div className="form-label-right-md">
                                                <Form.Group className="row">
                                                      <Form.Label className="col-md-4 col-form-label">Pipeline channels :</Form.Label>
                                                      <div className="col-md-5">
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="pipelineTriggerType"
                                                                  id="webhook"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.pipelineType === "webhook"}
                                                                  value="webhook"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="webhook">
                                                                Webhook
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                  type="radio"
                                                                  name="pipelineTriggerType"
                                                                  id="databricks"
                                                                  onChange={this.handleRadioButton}
                                                                  checked={this.state.pipelineType === "databricks"}
                                                                  value="databricks"
                                                                  className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="databricks">
                                                                Databricks
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                type="radio"
                                                                name="pipelineTriggerType"
                                                                id="awsLambda"
                                                                onChange={this.handleRadioButton}
                                                                checked={this.state.pipelineType === "awsLambda"}
                                                                value="awsLambda"
                                                                className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="awsLambda">
                                                                AWS Lambda
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                type="radio"
                                                                name="pipelineTriggerType"
                                                                id="awsSagemaker"
                                                                onChange={this.handleRadioButton}
                                                                checked={this.state.pipelineType === "awsSagemaker"}
                                                                value="awsSagemaker"
                                                                className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="awsSagemaker">
                                                                AWS Sagemaker
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                type="radio"
                                                                name="pipelineTriggerType"
                                                                id="gcpCloudFunction"
                                                                onChange={this.handleRadioButton}
                                                                checked={this.state.pipelineType === "gcpCloudFunction"}
                                                                value="gcpCloudFunction"
                                                                className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="gcpCloudFunction">
                                                                GCP Cloud Function
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                type="radio"
                                                                name="pipelineTriggerType"
                                                                id="airflow"
                                                                onChange={this.handleRadioButton}
                                                                checked={this.state.pipelineType === "airflow"}
                                                                value="airflow"
                                                                className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="airflow">
                                                                Airflow
                                                            </Form.Label>
                                                        </div>
                                                        <div className="custom-control custom-radio custom-control-inline pt-2">
                                                            <Form.Control
                                                                type="radio"
                                                                name="pipelineTriggerType"
                                                                id="kubeflow"
                                                                onChange={this.handleRadioButton}
                                                                checked={this.state.pipelineType === "kubeflow"}
                                                                value="kubeflow"
                                                                className="custom-control-input"/>
                                                            <Form.Label className="custom-control-label" htmlFor="kubeflow">
                                                                Kubeflow
                                                            </Form.Label>
                                                        </div>
                                                      </div>
                                                </Form.Group>
                                           </div>
                                        {this.renderPipelineTriggerForm(pipelineType)}
                                      </div>
                                </div>}
                    /> : ''
            }
            {this.props.pipeData !== null ? <BasicPortlet
                        className="mb-0 pb-0"
                        id="pipelineChannelTable"
                        title= "PIPELINE CHANNELS"
                        content={<QualdoDataTable
                                    data={this.props.pipeData}
                                    hideModelAlertsTab={this.props.hideModelAlertsTab}
                                    changeTab={this.props.changeTab}
                                    showToast={this.props.showToast}
                                    component_name='consume_pipeline'
                                 />}
                    /> : <BasicPortlet
                        className="mb-0 pb-0"
                        id="pipelineChannelTableLoading"
                        title="PIPELINE CHANNELS"
                        content={
                                    <Load />
                                }
                   />}
            </>
        );
    }
}

export default PipelineTrigger;