import React from 'react';
import Image from '../../components/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import Load from '../../components/loadAction';
import AlertForm from './alertForm';
import { getApi } from "../../utils/event_handling";
import {checkPermissions} from "../../utils/common_utils";
import {postMixpanel} from '../../mixpanel';



class DqAlertTab extends React.Component {
    constructor(props) {
        super(props);
        let check = false;
        let pipelineTriggerName = localStorage.getItem("pipelineTriggerName")
        if(pipelineTriggerName !== null) {
          check = true
        }
        this.handleClick = this.handleClick.bind(this);
        this.getApi = getApi.bind(this);
        this.state={
            tab: "dq",
            check: check,
        }
    }


    handleClick() {
        postMixpanel("clicked Add Data Quality Alerts in Alerts-> alerts tab")
        if (this.state.check === true) {
            this.setState({check:false});
        }else {
            this.setState({check:true});
        }
    }

    render() {
        let disableAlertAction = checkPermissions('Alerts')
        return (
                <div className="tab-pane">
                    <div className="triggers-alert-tab-content" >
                        <div className="triggers-alert-tab-dq-content">
                            <div className="tab-pane">
                                 <div className="col-md-8 offset-md-2 text-center p-3">
                                      <i className="d-inline-block mb-3"><Image src="alert"/></i>
                                      <h4 className="mb-3">Data Quality Alert</h4>
                                      <p>Configure alerts on any important conditions for monitoring your data quality.</p>
                                      <div className="d-flex justify-content-center">
                                           <button disabled={disableAlertAction} type="button" onClick={() => this.handleClick()} className="btn btn-primary mb-2 navto_dq-add-alert-metrics"><FontAwesomeIcon icon={faExclamationTriangle}/>  Add Data Quality Alert</button>
                                      </div>
                                 </div>
                                 {this.state.check === false ? "" :
                                 <BasicPortlet
                                    className="pb-0" title="Add Data Quality Alert" bodyClassName="pb-0" id="addDqAlert"
                                    content={<AlertForm details={this.props.details} tab={"dq"} showToast={this.props.showToast} handleClick={this.handleClick} integrationOption={this.props.integrationOption}/>}
                                 />}
                                 {this.props.alertsDataDq !== null && this.props.details.dqMetrics.length > 0 && this.props.details.data_set !== null && this.props.integrationOption !== null ?
                                        <BasicPortlet
                                             className="mb-0 pb-0"
                                             id="dqCustomAlertsTable"
                                             title="Custom Alerts - Data Quality"
                                             content={<QualdoDataTable
                                                data={this.props.alertsDataDq}
                                                showToast={this.props.showToast}
                                                integrationOption={this.props.integrationOption}
                                                modelTitle={"Custom Data Quality Alert"}
                                                component_name='alert'
                                                details = {this.props.details}
                                                tab={"dq"}
                                             />}
                                        /> :
                                        <BasicPortlet
                                            className="mb-0 pb-0"
                                            id="dqCustomAlertsTableLoading"
                                            title="Custom Alerts - Data Quality"
                                            content={
                                                <Load />
                                            }
                                        />
                                 }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default DqAlertTab;