import React from 'react';
import Image from '../../components/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BasicPortlet from '../../components/basicPortlet';
import QualdoDataTable from '../../components/bootstrapTable';
import Load from '../../components/loadAction';
import { getApi } from "../../utils/event_handling";
import AlertForm from './alertForm';
import {postMixpanel} from '../../mixpanel';



class ModelAlertTab extends React.Component {
    constructor(props) {
        super(props);
        let check = false;
        let pipelineTriggerName = localStorage.getItem("pipelineTriggerName")
        if(pipelineTriggerName !== null) {
          check = true
        }
        this.handleClick = this.handleClick.bind(this);
        this.getApi = getApi.bind(this);
        this.state={
            tab: "ml",
            check: check,
        }

    }

    handleClick() {
        postMixpanel("clicked Add Data Quality Alerts in Alerts-> alerts tab")
        if (this.state.check === true) {
            this.setState({check:false});
        }else {
            this.setState({check:true});
        }
    }


    render() {
        return (
                <div className="tab-pane">
                    <div className="triggers-alert-tab-content" >
                        <div className="triggers-alert-tab-dq-content">
                            <div className="tab-pane">
                                 <div className="col-md-8 offset-md-2 text-center p-3">
                                      <i className="d-inline-block mb-3"><Image src="alert"/></i>
                                      <h4 className="mb-3">Model Alert</h4>
                                      <p>Configure alerts on any important conditions for monitoring your model.</p>
                                      <div className="d-flex justify-content-center">
                                           <button type="button" onClick={() => this.handleClick()} className="btn btn-primary mb-2 navto_dq-add-alert-metrics"><FontAwesomeIcon icon={faExclamationTriangle}/>  Add Model Alert</button>
                                      </div>
                                 </div>
                                 {this.state.check === false ? "" :
                                        <BasicPortlet
                                            className="pb-0" title="Add Model Alert" bodyClassName="pb-0" id="addModelAlert"
                                            content={<AlertForm tab={"ml"}
                                                                modelOptions={this.props.modelOptions}
                                                                details={this.props.details}
                                                                showToast={this.props.showToast}
                                                                handleClick={this.handleClick}/>}
                                        />
                                 }
                                 {this.props.alertsDataMl !== null && this.props.details.mlMetrics.length > 0 && this.props.details.data_set !== null && this.props.modelOptions !== null?
                                        <BasicPortlet
                                             className="mb-0 pb-0"
                                             id="modelCustomAlertsTable"
                                             title="Custom Alerts - Model Metrics"
                                             content={<QualdoDataTable
                                                data={this.props.alertsDataMl}
                                                modelOptions={this.props.modelOptions}
                                                showToast={this.props.showToast}
                                                component_name='alert'
                                                modelTitle={"Custom Model Alert"}
                                                details = {this.props.details}
                                                tab={"ml"}
                                             />}
                                        /> :
                                        <BasicPortlet
                                             className="mb-0 pb-0"
                                             id="modelCustomAlertsTableLoading"
                                             title="Custom Alerts - Model Metrics"
                                             content={
                                                <Load />
                                             }
                                        />
                                 }
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

export default ModelAlertTab;