import React from 'react';
import {faCheckCircle, faLayerGroup, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ChartBody from '../../components/chartBody';
import {getNormalizedName, getErrorTypeForStatsComponent, filterBoxPlotData} from "../../utils/common_utils";
import MonitorStatsComponent from "./monitorStatsComponent";
import {SINGLE_ARROW_CHART_TYPE} from "../../utils/constant";
import SingleArrowChart from "../../components/charts/singleArrowChart";

class ChartGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chartTitle: this.props.title,
            data: this.props.data,
            chartType: this.props.chartType,
            variant: this.props.variant,
            hideChartHeaderLabel: this.props.hideChartHeaderLabel
        }
    }

    render() {
        let ErrorPercentage;
        let LastErrorPercentage;
        let dataSource;
        let MetricName;
        let propsData = this.props.data;
        if (propsData === undefined) {
            return "";
        }


        ErrorPercentage = propsData["error_percentage"];
        LastErrorPercentage = propsData["last_error"];
        dataSource = propsData["data_source_name"];
        MetricName = propsData["name"];


        let chartTitle = propsData.attribute_name;
        let chartKey = this.state.variant + "_" + chartTitle;
        let defaultKey = propsData.key;
        if (defaultKey !== undefined && defaultKey !== null) {
            chartKey = `${chartKey}_${defaultKey}`;
        }
        chartKey = getNormalizedName(chartKey);
        if (this.props.index !== undefined) {
            chartKey = `${chartKey}_${this.props.index}`;
        }
        let colorType = "success";
        if (propsData.hasDrift) {
            colorType = "error";
        }

        let containerLabel = "Datasource:";
        let canShowError = true;
        let metricLabel = "Metric with issue:";
        if (this.props.variant === "mlModelPerformance"  || propsData.isModelPerformance){
            containerLabel = "Model:";
            canShowError = false;
            metricLabel = "Metric:";
        } else if (this.props.variant === "mlModel") {
            containerLabel = "Model:";
        } else if(this.props.dqpGrid !== undefined && this.props.dqpGrid === true){
            canShowError = false;
            metricLabel = "Metric";
        }

        if (propsData.containerLabel1 !== undefined && propsData.containerLabel1 !== null) {
            containerLabel = propsData.containerLabel1;
        }
        if(MetricName === "Recency") {
            canShowError = false;
        }

        let attributeName = propsData["attribute_name"];
        const isModelPerformance = propsData.isModelPerformance;
        if (isModelPerformance !== undefined && isModelPerformance) {
            // Model performance charts coming in preview page of model error page
            attributeName = undefined;
        }
        if (propsData["name"] === "Data Drift" && this.props.chartType === "boxPlotZoomable") {
            propsData["boxPlotData"] = filterBoxPlotData(propsData["boxPlotData"])
        }

        return (
            <div className="col-md-6 col-lg-4" id={this.props.idValue}>
                <div className="qd-monitor__stat">
                    {this.state.hideChartHeaderLabel === true ? ""
                        :
                        <div className="qd-monitor__head">
                            <div className="btn-card-alert">
                                {
                                    propsData.type !== "success" && propsData.type !== undefined ?
                                        <button type="button" className="btn btn-outline-danger"
                                                data-toggle="tooltip"
                                                data-placement="top" title="Tooltip on top">
                                            <i>
                                                <FontAwesomeIcon icon={faTimesCircle}/>
                                            </i>

                                            {(propsData.type === undefined ||
                                                propsData.type === null) ?
                                                '' :
                                                <span>{getErrorTypeForStatsComponent(propsData.type)}</span>
                                            }
                                        </button>
                                        :
                                        <button type="button" className="btn btn-outline-success"
                                                data-toggle="tooltip"
                                                data-placement="top" title="Tooltip on top">
                                            <i>
                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                            </i>
                                            <span>No Errors</span>
                                        </button>
                                }

                            </div>
                            <span className="chart-indication">
                        <i><FontAwesomeIcon icon={faLayerGroup}/></i>
                        <label>Aggregate: </label> Self
                    </span>
                        </div>
                    }
                    <div className="qd-monitor__body">
                        {this.props.chartType === SINGLE_ARROW_CHART_TYPE ?
                            <SingleArrowChart
                                key={chartKey}
                                id={chartKey}
                                data={propsData}/>
                            :
                            <ChartBody
                                y2Value={this.props.y2Value}
                                yValue={this.props.yValue}
                                y1Value={this.props.y1Value}
                                xValue={this.props.xValue}
                                isError={this.props.isError}
                                toolTipTitle={this.props.toolTipTitle}
                                compareChart={this.props.compareChart}
                                label={this.props.label}
                                colorCode={this.props.colorCode}
                                lineColorCode={this.props.lineColorCode}
                                scale={this.props.scale}
                                id={chartKey}
                                chartType={this.props.chartType}
                                colorType={colorType}
                                chart_data={propsData}
                                style={{height: "250px"}}
                            />
                        }



                    </div>

                    <MonitorStatsComponent containerLabel={containerLabel}
                                           containerName={dataSource}
                                           isDqErrorPreview={this.props.isDqErrorPreview}
                                           isPreviewPage={true}
                                           metricLabel={metricLabel}
                                           metricName={MetricName}
                                           isModelPerformance={isModelPerformance}
                                           canShowError={canShowError}
                                           errorPercentage={ErrorPercentage}
                                           lastErrorPercentage={LastErrorPercentage}
                                           dataSetName = {propsData["datasetName"]}
                                           attributeName = {attributeName}
                                           variant = {this.props.variant}
                                           color = {this.props.colorCode}
                    >

                    </MonitorStatsComponent>
                </div>
            </div>
        );

    }
}

export default ChartGrid;