import React from 'react';
import Select from 'react-select';
import { faCheck, faEye, faLock, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { validateSlackToken, postSlackData }  from "../../utils/event_handling";
import { userValidation } from '../../utils/common_utils';
import InputGroup from 'react-bootstrap/InputGroup';

class SlackForm extends React.Component {

    constructor(props) {
        super(props);
        this.validateSlackToken = validateSlackToken.bind(this);
        this.postSlackData = postSlackData.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.disableButton = this.disableButton.bind(this);
        this.ChangeAccessTokenIcon = this.ChangeAccessTokenIcon.bind(this);
        this.state = {
            check: false,
            accessTokenIcon:faEye,
            accessTokenType:'password',
            channels: [],
            hideSubmitButton: false,
            slackData: {
                consumeName: '',
                token: '',
                channelName: '',
                description: ""
            }
        };
    }
    handleCancel() {
        this.setState({check:false});
    }

    disableButton() {
        this.setState({hideSubmitButton: true});
    }

    componentDidMount() {
        if (this.props.exConsumeDetails !== undefined) {
            this.SlackData();
        }
    }
    SlackData() {
        const exConsumeItem = this.props.exConsumeDetails.consume_item;
        let slackData = {
            consumeName: exConsumeItem.consume_group_name || '',
            token: exConsumeItem.config_details.token || '',
            channelName: exConsumeItem.config_details.channel_name || '',
            description: exConsumeItem.config_details.description || ''
        };
        this.setState({slackData});
    }
    setConsumeData(data) {
        this.setState({
            slackData: data
        });
    }

    requiresConsumeUpdate(slackPostData) {
        const exConsumeItem = this.props.exConsumeDetails.consume_item;
        if (slackPostData.consume_group_name !== exConsumeItem.consume_group_name) {
            return true;
        }
        const currentConfiguration = slackPostData.config_details;
        const exConfiguration = exConsumeItem.config_details;
        if (currentConfiguration.token !== exConfiguration.token) {
           return true;
        }

        if (currentConfiguration.channel_name !== exConfiguration.channel_name) {
            return true;
        }
        if (currentConfiguration.description !== exConfiguration.description) {
            return true;
        }
    }

    ChangeAccessTokenIcon() {
        let state = this.state.accessTokenIcon;
        if (state.iconName === "eye") {
            this.setState({accessTokenIcon: faEyeSlash});
            this.setState({accessTokenType: "text"})
          } else {
            this.setState({accessTokenIcon: faEye});
            this.setState({accessTokenType: "password"})
          }
    }

    render() {
        const slackValidationSchema = yup.object({
            consumeName: yup.string().min(1,"Name must be at least 1 characters").required("Notification name is a required field").test(
                'Notification name test',
                'invalid Notification name',        
                function test(value) {        
                   let val = userValidation(value,"input");       
                   return val;
            }
            ),
            token: yup.mixed().required("Token is a required field")

        });
        const slackSchema =  yup.object({
            channelName : yup.string().required("Channel Name is a required field"),
        });
        let optionArr = [];
        if (this.state.options !== []) {
            optionArr = this.state.channels.map(function (row) {
                return {"label": row, "value": row};
            });
        }        
        if (this.state.check === true) {
            return (
              <>
                    <Formik
                          validationSchema={slackSchema}
                          onSubmit={this.postSlackData}
                          initialValues={this.state.slackData}
                          enableReinitialize={true}                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                        <Form className="form-label-right-md" onSubmit={handleSubmit} name="notification_configure">
                            <Form.Group controlId="description" className="row">
                                <Form.Label className="col-md-4 col-form-label">Description :</Form.Label>
                                <div className="col-md-5">
                                    <textarea
                                          rows="3"
                                          type="textarea"
                                          name="description"
                                          onChange={handleChange}
                                          value={values.description}
                                          className="form-control">
                                    </textarea>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="channelName"  className="row">
                                    <Form.Label className="col-md-4 col-form-label">Select Channel<span className="text-danger">*</span> :</Form.Label>
                                    <div className="col-md-5">
                                         <Select
                                            name="channelName"
                                            filterOption={({label}, query) => label.includes(query)}
                                            id="slackChannelName"
                                            options={optionArr}
                                            classNamePrefix='select-control'
                                            isInvalid={errors.channelName && touched.channelName}
                                            onChange={selectedOption => {
                                                  handleChange("channelName")(selectedOption.value);
                                            }}
                                            defaultValue={{ label: this.state.slackData.channelName, value: this.state.slackData.channelName }}
                                         />
                                         <ErrorMessage component="div" className="error-text" name="channelName" />
                                    </div>
                            </Form.Group>
                            <Form.Group className="row">
                                <div className="col-md-5 offset-sm-4">
                                    <button type="button" onClick={() => this.props.exConsumeDetails ? this.props.closeModal() : this.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                        <FontAwesomeIcon icon={faCheck}/>Submit
                                    </button>
                                </div>
                            </Form.Group>
                        </Form>
                    )}
                    </Formik>
              </>
        );

        }
        else {
            return (
                <>
                    <Formik
                          validationSchema={slackValidationSchema}
                          onSubmit={this.validateSlackToken}
                          initialValues={this.state.slackData}
                          enableReinitialize={true}                    
                    >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                          handleSubmit,
                          isSubmitting,
                          isValid,
                    }) => (
                        <Form className="form-label-right-md" onSubmit={handleSubmit}>
                            <Form.Group controlId="consumeName" className="row">
                                <Form.Label className="col-md-4 col-form-label">Notification Name <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                      <Form.Control
                                            type="text"
                                            name="consumeName"
                                            value={values.consumeName}
                                            className="form-control"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={errors.consumeName && touched.consumeName}
                                            placeholder="Slack Integration"/>
                                      <Form.Control.Feedback type="invalid">
                                            { touched.consumeName && errors.consumeName }
                                      </Form.Control.Feedback>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="token" className="row">
                                <Form.Label className="col-md-4 col-form-label">Access Token <span className="text-danger">*</span> :</Form.Label>
                                <div className="col-md-5">
                                <InputGroup bsPrefix="icon-input-group">
                                    <i>
                                        <FontAwesomeIcon icon={faLock}/>
                                    </i>
                                  <Form.Control
                                        type={ this.state.accessTokenType }
                                        name="token"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.token}
                                        className="form-control"
                                        isInvalid={errors.token && touched.token}
                                        placeholder="Slack Bot Access Token"
                                        autoComplete={this.props.autocomplete}
                                        />
                                  <Form.Control.Feedback type="invalid">
                                       { touched.token && errors.token }
                                  </Form.Control.Feedback>
                                  <i className="security-eye">
                                        <FontAwesomeIcon onClick={this.ChangeAccessTokenIcon} icon={this.state.accessTokenIcon} className="security-eye"/>
                                    </i>
                                </InputGroup>
                                </div>
                            </Form.Group>
                            <Form.Group className="row">
                                <div className="col-md-5 offset-sm-4">
                                    <button type="button" onClick={() => this.props.exConsumeDetails ? this.props.closeModal() :this.props.handleCancel()} className="btn btn-outline btn-grey btn-circle mr-2">Cancel</button>
                                    <button type="submit" disabled={this.state.hideSubmitButton} className="btn btn-primary btn-circle">
                                        <FontAwesomeIcon icon={faCheck}/>Validate
                                    </button>
                                </div>
                            </Form.Group>
                        </Form>
                        )}
                    </Formik>
                </>
            );
        }
    }
}

export default SlackForm;
